// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__8GqK- {
  height: 100%;
  width: 55px;
  position: fixed;
  z-index: 1;
  margin-top: 4.5rem;
  left: 0;
  overflow: hidden;
  padding-top: 25px;
  background-color: #f5f5f5;
}
.Sidebar_sidebar__8GqK- a:first-child {
  margin-bottom: 15px;
}
.Sidebar_sidebar__8GqK- a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block;
}

.Sidebar_sidebar__8GqK- a:hover {
  color: #45c096;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,UAAU;EACV,kBAAkB;EAClB,OAAO;EACP,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".sidebar {\n  height: 100%;\n  width: 55px;\n  position: fixed;\n  z-index: 1;\n  margin-top: 4.5rem;\n  left: 0;\n  overflow: hidden;\n  padding-top: 25px;\n  background-color: #f5f5f5;\n}\n.sidebar a:first-child {\n  margin-bottom: 15px;\n}\n.sidebar a {\n  padding: 6px 8px 6px 16px;\n  text-decoration: none;\n  font-size: 18px;\n  color: #818181;\n  display: block;\n}\n\n.sidebar a:hover {\n  color: #45c096;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__8GqK-`
};
export default ___CSS_LOADER_EXPORT___;
