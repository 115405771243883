import { useState } from 'react';
import Pagination from '../UI/Pagination/Pagination';
import AuthenticationCode from '../SchoolAuthenticationCodeGenerator/AuthenticationCode';
import tableStyle from '../Style/TableModel.module.css';
import { getActiveUserCount } from '../../services/SchoolAuthenticationCodeGenerator.services';
import '../Style/Table-style.css';
import { BiSort } from 'react-icons/bi';
let pageSize = 10;

const AuthCodeList = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(pageSize);
    const [codeSort, setCodeSort] = useState('ACTIVE');
// const [statusClass,setStatusClass]=useState
    const [organisationCodesArr, setOrganisationCodesArr] = useState(props.organisationCodes);


    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    let organisationCodes = props.organisationCodes.slice(firstPageIndex, lastPageIndex);
    console.log("codeList",organisationCodes)
    // organisationCodes.map(async (val,index)=>{
    //     //console.log(val);
    //     var  ActiveUser = await getActiveUserCount ('1',val.code)
    //     //console.log(ActiveUser.data[index]);
    //    organisationCodes[index].useractive = ActiveUser.data.activeUser;
    //     //response.data['index']['activeuser'] = ActiveUser.data.activeUser
    // })

    const showHandler = (e) => {
        setPageSize(+e.target.value);
        setCurrentPage(1);
    }
    const SortVerifyCode = (e) => {
        if(codeSort=='ACTIVE'){
            setCodeSort('INACTIVE');
        }else{
            setCodeSort('ACTIVE');
        }
        props.organisationCodes.sort((a, b) => (a.codeStatus == codeSort) ? 1 : -1)

        //console.log(organisationCodesArr);
        setOrganisationCodesArr(organisationCodesArr);
        organisationCodes = props.organisationCodes.slice(firstPageIndex, lastPageIndex);
    }

    const totalCount = organisationCodes.length;


    return (
        <>
        <div className="table-design">
            <table className="table  table-hover">
                <thead className="table">
                    <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Creater</th>
                        <th scope="col">Date Created</th>
                        <th scope="col">Active Users</th>
                        <th scope="col">Copy Code</th>
                        <th scope="col"><a style={{padding:0, cursor:'pointer'}} className={codeSort} onClick={e=>SortVerifyCode()}>Status {codeSort=='INACTIVE' &&<BiSort size="14px" style={{marginLeft:'-4px'}} />}</a></th>

                    </tr>
                </thead>
                <tbody>
                    {organisationCodes.map((code) => <AuthenticationCode
                        style={{ backgroundColor: "#fff" }}
                        key={code.code}
                        code={code.code}
                        fkOrgID={code.fkOrgID}
                        codeStatus={code.codeStatus}
                        createdOn={code.createDate}
                        usersCount={code.activeUser}
                        status={code.codeStatus}
                        setOrganisationCodes={props.setOrganisationCodes}
                        setKey={props.setKey}
                        setSelectedCode={props.setSelectedCode}
                        showTab={props.showTab}
                    />)}
                </tbody>
            </table>
            <div className={"row justify-content-between " + tableStyle.listShow}>
                {totalCount > pageSize && <div className="col-4">
                    <label className={tableStyle.listLabel}>
                        Show
                        <select className={"form-select " + tableStyle.listSelect} value={PageSize} onChange={showHandler}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>}

                <div className="col-12">
                    <div style={{ float: 'right' }}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={props.organisationCodes.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>

            </div>
            </div>


        </>
    )
}

export default AuthCodeList;
