// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_backdrop__ERdwy {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9990;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .Loader_loader__s4N3W {
    position: fixed;
    top: 40vh;
    left: 40%;
    width: 15%;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    animation: Loader_slide-down__AY8mn 300ms ease-out forwards;
  }
  
  .Loader_text-loader__MDTHr{
    display:inline-block;
    margin-right: 11px;
  }

  @keyframes Loader_slide-down__AY8mn {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/UI/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,aAAa;IACb,qCAAqC;EACvC;EACA;IACE,eAAe;IACf,SAAS;IACT,SAAS;IACT,UAAU;IACV,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,aAAa;IACb,2DAA6C;EAC/C;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;EACpB;;EAEA;IACE;MACE,UAAU;MACV,4BAA4B;IAC9B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF","sourcesContent":[".backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 9990;\n    background-color: rgba(0, 0, 0, 0.75);\n  }\n  .loader {\n    position: fixed;\n    top: 40vh;\n    left: 40%;\n    width: 15%;\n    background-color: white;\n    padding: 1rem;\n    border-radius: 14px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    z-index: 9999;\n    animation: slide-down 300ms ease-out forwards;\n  }\n  \n  .text-loader{\n    display:inline-block;\n    margin-right: 11px;\n  }\n\n  @keyframes slide-down {\n    from {\n      opacity: 0;\n      transform: translateY(-3rem);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `Loader_backdrop__ERdwy`,
	"loader": `Loader_loader__s4N3W`,
	"slide-down": `Loader_slide-down__AY8mn`,
	"text-loader": `Loader_text-loader__MDTHr`
};
export default ___CSS_LOADER_EXPORT___;
