
const Footer = () => {

    return (
        <footer className="page-footer font-small indigo" style={{clear:'both'}}>

            <div className="footer-copyright text-center py-3" style={{fontFamily:'Roboto-Regular', fontSize:'13px'}}>© 2021 Copyright:
            <a href="#"> ePublisher</a>
            </div>
        </footer>
    )
}

export default Footer;