// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SchoolAuthenticationCodeGenerator_margin__neCEq{
    margin-bottom: 10px;
}
.SchoolAuthenticationCodeGenerator_Searchinput__WUs6-{
border-radius: 5px;
background-color: #f2f2f2;
}`, "",{"version":3,"sources":["webpack://./src/components/SchoolAuthenticationCodeGenerator/SchoolAuthenticationCodeGenerator.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;AACA,kBAAkB;AAClB,yBAAyB;AACzB","sourcesContent":[".margin{\n    margin-bottom: 10px;\n}\n.Searchinput{\nborder-radius: 5px;\nbackground-color: #f2f2f2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"margin": `SchoolAuthenticationCodeGenerator_margin__neCEq`,
	"Searchinput": `SchoolAuthenticationCodeGenerator_Searchinput__WUs6-`
};
export default ___CSS_LOADER_EXPORT___;
