import axios from "axios";
import { MESSAGES_API_BASE_URL, USERS_API_BASE_URL,EPUBLISHER_API } from '../constants';
//const MESSAGES_API_BASE_URL = 'http://api.dev-n-test.com/messages/'; // Base url for development server
//const USERS_API_BASE_URL = 'http://api.dev-n-test.com/users/'; // Base url for development server
//const USERS_API_BASE_URL = 'http://localhost:5001/users/';  // Base url for local server
//const MESSAGES_API_BASE_URL = 'http://localhost:5050/messages/';  // Base url for local server
//const MESSAGES_API_BASE_URL = 'http://apidocs.dev-n-test.com/'; Base url for mock server

/** For getting contact group ids **/
export const getContactGroupIds = (authToken='',offset=0) => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}

    return axios.get(`${USERS_API_BASE_URL}/group/groupList/`+offset,{headers:{'Authorization':authToken}});
}

// fetch notification threads list by filter and search parameters with offset for pagination
export const getNotificationThreads = (channels, search,authToken='',offset=0) => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}

    return axios.get(`${USERS_API_BASE_URL}/notification-threads/filtered-result/`+offset+'?search='+search+'&channels='+channels,{headers:{'Authorization':authToken}});
}

export const sendMessage = (messageUuid ,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${MESSAGES_API_BASE_URL}/send-message/`+messageUuid,{headers:{'Authorization':authToken}});
}

/** For notification listing **/
export const notificationList = (postData,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/latest-messages-for-groups`,
        postData,
        {headers:{ 'Content-Type': 'application/json', 'Cross-Origin-Embedder-Policy': 'credentialless','Authorization':authToken }}
    );
}

/* For notification selection
 * When we click on notification
 * Get all notification by contact group uuid
 */
export const getNotificationByContactGroupUUID = (contactGroupUUID,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${MESSAGES_API_BASE_URL}/group-messages/${contactGroupUUID}`,{headers:{'Authorization':authToken}});
}
/*
* For creating new notification
*/
export const createNewNotification = (postData,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/message`, postData,{headers:{'Authorization':authToken}});
}
/*
For editing message
 */
export const editNotificationService = (id, postData,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.put(`${MESSAGES_API_BASE_URL}/` + id, postData,{headers:{'Authorization':authToken}});
}
/*
* API call to update the last modified date of group
* */
export const updateLastModifiedTimeStampOfNotificationThread = (threadUuid, authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/notification-threads/update-thread-modified-time/${threadUuid}`,{headers:{'Authorization':authToken}});
}
/*
For creating new recipient/ contact group
*/
export const createContactGoup = (postData,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${USERS_API_BASE_URL}/group`,
        postData,
        {
            headers: { 'Content-Type': 'application/json','Authorization':authToken  }
        }
    );
}
/*

For login and getting token
*/
export const login = (postData) => {
    return axios.post(`${USERS_API_BASE_URL}/login`,
        postData,
        {
            headers: { 'Content-Type': 'application/json' }
        }
    );
}

export const loginepub = (postData) => {
    return axios.post(`${USERS_API_BASE_URL}/loginepub`,
        postData,
        {
            headers: { 'Content-Type': 'application/json' }
        }
    );
}

/*
For updating recipient contact group
*/
export const updateContactGoup = (postData, groupId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.put(`${USERS_API_BASE_URL}/group/${groupId}`,
        postData,
        {
            headers: { 'Content-Type': 'application/json','Authorization':authToken  }
        }
    );
}

/*
*For getting category tags
*SchoolLink app users
*/
export const getCategoryTags = (orgID='',authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/category/${orgID}`,{headers:{'Authorization':authToken}});
}


export const logout = (authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/logout`,{headers:{'Authorization':authToken}});
}

/*
*For getting category tags
*SchoolLink app users
*/
export const getOrganisation = (orgUuid,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orgUuid,{headers:{'Authorization':authToken}});
}

export const getOrganisationList = (authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/organisation`,{headers:{'Authorization':authToken}});
}
/*


*For Select individual app users
*/
export const appUsers = (authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/`,{headers:{'Authorization':authToken}});
}
export const appUsersAdmin = (authToken='',userAddedBy='',orgVal='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/admin/${orgVal}`,{headers:{'Authorization':authToken}});
}
export const internalUsersAdmin = (authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/internaluser`,{headers:{'Authorization':authToken}});
}
export const getModuleList = (authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/module-list`,{headers:{'Authorization':authToken}});
}
export const getUserPermission = (authToken='',useruuid) => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/user-permission/${useruuid}`,{headers:{'Authorization':authToken}});
}

export const fetchEmailOnlySubscribers = (epubOrgId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(EPUBLISHER_API+`/category/${epubOrgId}`,{headers:{'Authorization':authToken}});
}

/*
    For recipient group (Edit)  selected user and tags
*/

export const getSelectedRecipientData = (groupId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/group/${groupId}`,{headers:{'Authorization':authToken}});
}
// get users associated to this group
export const getSelectUsersForGroup = (groupId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/group/msg-recipients/${groupId}`,{headers:{'Authorization':authToken}});
}

export const removeMessage = (messageId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.delete(`${MESSAGES_API_BASE_URL}/message/${messageId}`,{headers:{'Authorization':authToken}})
}

export const createPinMessage = (postData,authToken) => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/pin`,
        postData,
        {
            headers: { 'Content-Type': 'application/json','Authorization':authToken  }
        }
    )
}

export const getPinnedMessage = (orgId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return [];}
    if(orgId==''){alert("OrgId is required before sending request to server");return [];}
    return axios.get(`${MESSAGES_API_BASE_URL}/pinned-message/${orgId}`,{headers:{'Authorization':authToken}})
}

export const deactivateNotificationThread = (id, postData,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.patch(USERS_API_BASE_URL+`/group/${id}`, postData,{headers:{'Authorization':authToken}});

}

export const deleteNotificationThread = (id, authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.delete(USERS_API_BASE_URL+`/group/${id}`, {headers:{'Authorization':authToken}});

}

export const addNewTag=(body,authToken)=> {
    return fetch(USERS_API_BASE_URL + "/tag", {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",'Authorization':authToken
        },
        "body":body
    });
}

export const  addNewCategory=(body = "",authToken)=>{
    return fetch(USERS_API_BASE_URL + "/category/admin", {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",'Authorization':authToken
        },
        "body":
        body
    });
}

export const appPlans = (orgUuid,authToken) => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/plans/`,{headers:{'Authorization':authToken}});
}
export const getUser = (userUuid,authToken) => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/user-detail/`+userUuid,{headers:{'Authorization':authToken}});
}

export const getNewToken = (postData) => {
    console.log(postData)
    return axios.post(`${USERS_API_BASE_URL}/refresh-token`,
        postData,
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
}

/*
*For getting Users Detail
*SchoolLink app users
*/
export const getUsersDetailBGgroup = (argGroupId,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${USERS_API_BASE_URL}/group/groupUsersDetail/`+argGroupId,{headers:{'Authorization':authToken}});
}

/*
* For creating new notification
*/
export const addMessageReadStatus = (postData,authToken='') => {
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/addMessageRecepient`, postData,{headers:{'Authorization':authToken}});
}

export const fetchAppUsers = (argOrgID,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${MESSAGES_API_BASE_URL}/linkeduser/`+argOrgID,{headers:{'Authorization':authToken}});
}

export const fetchParents = (argOrgID,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${MESSAGES_API_BASE_URL}/linkedparent/`+argOrgID,{headers:{'Authorization':authToken}});
}

export const fetchAppUsersByCode = (argOrgID,sCode,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${MESSAGES_API_BASE_URL}/linkeduserbycode/`+argOrgID+'/'+sCode,{headers:{'Authorization':authToken}});
}
export const fetchAppUsersByCatID = (argOrgID,sCode,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.get(`${MESSAGES_API_BASE_URL}/linkeduserbycatId/`+argOrgID+'/'+sCode,{headers:{'Authorization':authToken}});
}
export const disableParentCode = (postData,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/disableparentuser`,postData,{headers:{'Authorization':authToken}});
}

export const previewSelectedRecipients = (postData, authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${USERS_API_BASE_URL}/contact-groups-preview-recipients`,postData,{headers:{'Authorization':authToken}});
}

export const enableParentCode = (postData,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/enableparentuser`,postData,{headers:{'Authorization':authToken}});
}

export const disableVerificationCode = (postData,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/disableverificationcode`,postData,{headers:{'Authorization':authToken}});
}


export const enableVerificationCode = (postData,authToken='') =>{
    if(authToken==''){alert("Trying to send request without auth token");return null;}
    return axios.post(`${MESSAGES_API_BASE_URL}/enableverificationcode`,postData,{headers:{'Authorization':authToken}});
}

export const parseJToken = (token) =>{
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

