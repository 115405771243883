// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_mainContainer__rDoUE{
    height: calc(100vh - 77px);
}
.Layout_headerContainer__rpj9E{
    height: 77px;
}

/* box height is same as parent height */
/* .bottomPart .box{ */
/*     height: 100%; */
/* } */

/* in flex layout fill the remaining height */
/* .bottomPart .box:nth-child(2){ */
/*     flex: 1; */
/* } */

`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,YAAY;AAChB;;AAEA,wCAAwC;AACxC,sBAAsB;AACtB,sBAAsB;AACtB,MAAM;;AAEN,6CAA6C;AAC7C,mCAAmC;AACnC,iBAAiB;AACjB,MAAM","sourcesContent":[".mainContainer{\n    height: calc(100vh - 77px);\n}\n.headerContainer{\n    height: 77px;\n}\n\n/* box height is same as parent height */\n/* .bottomPart .box{ */\n/*     height: 100%; */\n/* } */\n\n/* in flex layout fill the remaining height */\n/* .bottomPart .box:nth-child(2){ */\n/*     flex: 1; */\n/* } */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `Layout_mainContainer__rDoUE`,
	"headerContainer": `Layout_headerContainer__rpj9E`
};
export default ___CSS_LOADER_EXPORT___;
