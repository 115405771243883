import { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {

    const authCtx = useContext(AuthContext);

    return (
        <Route {...rest} render={props => (
            authCtx.isLoggedIn && restricted ?
                <Navigate to="/notifications" />
                : <Component {...props} />
        )} />

    );
};

export default PublicRoute;
