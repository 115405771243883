import { useState, useEffect,useContext } from 'react';
import { Modal } from 'react-bootstrap';
import tableStyle from '../Style/TableModel.module.css';
import formStyle from '../Style/modalForm.module.css';
import button from '../Style/ButtonStyle.module.css';
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from "../../store/auth-context";
const ChangePassword = (props) => {
    const authCtx = useContext(AuthContext);
    const [userID, setUserID] = useState(0);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [usermessage, setUserMessage] = useState('');

    
    const PasswordUpdate = (e) => {
        e.preventDefault();
        
        fetch(`${USERS_API_BASE_URL}/change-password`, {
            "method": "PATCH",
            "headers": {
                "Content-Type": "application/json",
                'Authorization':authCtx.token
            },
            "body":
               "{\"oldpassword\":\"" + password + "\",\"newpassword\":\"" + newPassword + "\",\"confirmpassword\":\"" + confirmPassword + "\",\"userID\":\"" + userID + "\"}"

        }).then(res=>res.json()).then(data=>{
            
            //alert(data.status==302?data.error:data.msg);props.onAction(false)

            if(data.status==400){
                setUserMessage(data.error)
            }else{
                props.setMessage(data.msg)
                setUserID(0)
                setPassword('');
                setNewPassword('');
                setConfirmPassword('');
                props.onAction(false);
            }
            



        
        })

    }
    useEffect(()=>{
        setUserID(props.userDetail.user.uuid);
        console.log('udetail',props.userDetail.user)
    },[props.userDetail])
    //console.log('useridhitesh',userID);
    return (
        <>
            <Modal
                size="md"
                show={props.modalStatus}
                onHide={props.onAction}
                shouldCloseOnOverlayClick={false} 
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className={formStyle.headArea}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h4 className={tableStyle.modalTitle}> Change Password</h4>
                    </Modal.Title>
                </div>
                <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14,"color":"red","margin-left":"15px"}}>{usermessage}</div>
                <form onSubmit={PasswordUpdate} >
                <div className={formStyle.bodyArea}>
                    
                        <div className="row">
                            <div className="col-ms-12">
                                
                                <div className={formStyle.group}>
                                    <input autoFocus type="password" placeholder="Old Password" title='Old Password' className={formStyle.formControl} value={password} onChange={e => setPassword(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="password" placeholder="New Password" title='New Password' className={formStyle.formControl} value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="password" placeholder="Confirm Password" title='Confirm Password' className={formStyle.formControl} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                </div>
                                
                            </div>
                        </div>
                    
                </div>
                <div className={formStyle.FooterArea}>
                    <button type="button" onClick={props.onAction} className={button.btn + ' ' + button.brownButton}>Cancel</button>
                    <button type="submit" className={button.btn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Save</button>  
                </div>
                </form>
            </Modal>
        </>
    )
}
export default ChangePassword;