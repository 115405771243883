import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import style from './OrgSetting.module.css';
import { getOrganisationList  } from '../../services/notification.services';
import '../Style/Table-style.css';
import Pagination from '../UI/Pagination/Pagination';
import AuthContext from "../../store/auth-context";
import tableStyle from '../Style/TableModel.module.css';
import AddUser from './AddUser';
import AddPlan from './AddPlan';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import { Tabs, Tab } from 'react-bootstrap';

import CategoryTags from './CategoryTags';
import SchoolAuthenticationcode from './AuthCode';
import Parents from './ParentsDetails';
import AddOrganistaion from './AddOrganistaion';
import HelpText from './HelpText';
import { GoSearch } from "react-icons/go";
import { USERS_API_BASE_URL } from './../../constants';
import dateFormat from 'dateformat';

let pageSize = 10;
const Plans = (props) => {

    const authCtx = useContext(AuthContext);
    const [organisationList, setOrganisationList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(pageSize);

    const [isRecipientModalOpenOrg, setIsRecipientModalOpenOrg] = useState(false);

    const [message, setMessage] = useState('');

    const [organisationDetail, setOrganisationDetail] = useState('');

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    let orgUuid=authCtx.userData.orgId;
    const navigate = useNavigate();
    console.log('userdetail======',authCtx.userData);
    const showHandler = (e) => {
        setPageSize(+e.target.value);
        setCurrentPage(1);
    }

    const recipientModalHendlerOrg = () => {
        setOrganisationDetail('');
        setIsRecipientModalOpenOrg((prevState) => !prevState);
    }

    const organisationUser = (org) => {
        let orgDetail = org.split('&&&');
        navigate("/Organisation/1#users?"+orgDetail[0]);
        props.setKey('third');
        props.setSelectedCode(org)
    }


    const handleEdit = (id) => {
       // alert(id)

        fetch(`${USERS_API_BASE_URL}/organisation/`+id, {
            "method": "GET",
            "headers": {
                "Content-Type": "application/json"
            }

        }).then(res=>res.json()).then(data=>{
            if(data.status==302){
                setMessage(data.error)
            }else{
                recipientModalHendlerOrg();
                //console.log('orgDetail',data)
                setOrganisationDetail(data);

            }


        })
    }

    const searchHandler = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    }

    useEffect(() => {

        getOrganisationList(authCtx.token)
            .then((response) => {
               // console.log(response)
                setIsLoading(false);
                setOrganisationList(response.data);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
    }, []);

    const totalCount = organisationList.length;

   // console.log(planList);
    const searchTextArray = searchText.toLowerCase().trim().split(' ');
    const searchData = organisationList.filter(plandata => {
        return searchTextArray.every(i => plandata.name.toLowerCase().indexOf(i) > -1)
        }
    );
    const orgData = searchData.slice(firstPageIndex, lastPageIndex);
    return (
        <>
            <div className="">
            {isRecipientModalOpenOrg && <AddOrganistaion
                modalStatus={isRecipientModalOpenOrg}
                onAction={recipientModalHendlerOrg}
                setOrganisationList={setOrganisationList}
                setOrganisationDetail={setOrganisationDetail}
                organisationList={organisationList}
                setMessage={setMessage}
                organisationDetail={organisationDetail}
            />}
            <div className={style.creatUserArea}>
                <div className={style['page-title']}>
                    {/* <h4 className={[fontSize.lgSize + ' ' + common.pageTitle + ' ' + font.fontMedium]} >Settings</h4> */}
                    <div className={style.settingbutton}>
                    {/* {authCtx.userData.user_id == "39" &&
                        <button onClick={recipientModalHendlerOrg} className={button.iconBtn + ' ' + button.modalBtn + ' ' + button.redButton}>
                            <i className="icon icon-plus-circle"></i> Create Organisation</button>} */}

                    {((authCtx.userData.usertype=="INTERNALADMIN" && JSON.stringify(authCtx.userData.userPermission).includes("1"))  || authCtx.userData.usertype=="OWNER")?<button onClick={recipientModalHendlerOrg} className={button.iconBtn + ' ' + button.modalBtn + ' ' + button.redButton}>
                            <i className="icon icon-plus-circle"></i> Create Organisation</button>:''}
                    </div>
                </div>


                <div className={style.tabs}>


                    <div className={'form-group ' + tableStyle.superAdminSearch}>
                    <input value={searchText} onChange={searchHandler} className={tableStyle.searchInput} type="search" placeholder="Search..." />
                    <i className={"icon icon-search2 " + tableStyle.searchIcon}></i>
                    </div>

                </div>

            </div>

            <div className="table-design">
            <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14}}>{message}</div>
            <table className={"table table-hover " + style.categorytable}>
                <thead className={style.heading}>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Create Date</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody className={style.tablebody}>

                    {orgData.map((organisation) => {
                        return (
                            <tr key={organisation.id}>
                                <td>{organisation.name}</td>
                                <td><i className="fa fa-check" style={{ color: '#45c096' }}></i></td>
                                <td>{ dateFormat(organisation.createDate, "dd-mm-yyyy HH:mm:ss")}</td>
                                <td>
                                <span className='actionStyleButton'><i className="icon icon-pencil" onClick={k => handleEdit(organisation.uuid)} ></i></span>
                                <span className='actionStyleButton'><a onClick={i => organisationUser(organisation.uuid+'&&&'+organisation.name)}><i className="icon icon-user" ></i></a></span>
                                </td>

                            </tr>
                        )
                    })}

                </tbody>
            </table>
            <div className={"row justify-content-between " + tableStyle.listShow}>
                {totalCount > pageSize && <div className="col-4">
                    <label className={tableStyle.listLabel}>
                        Show
                        <select className={"form-select " + tableStyle.listSelect} value={PageSize} onChange={showHandler}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>}

                <div className="col-12">
                    <div style={{ float: 'right' }}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={searchData.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>

            </div>
            </div>
            </div>
       </>
    );
}

export default Plans;
