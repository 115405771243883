import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import style from './Loader.module.css';
import { Spinner } from 'react-bootstrap';


const Backdrop = () => {
    return <div className={style.backdrop} />
}

const LoaderOverlay = props => {

    return (
        <div className={style.loader}>

            <Spinner className={style['text-loader']} animation="border" variant="primary" />
            <div className={style['text-loader']}>Loading . . .</div>
        </div>
    );
}

const Loader = () => {

    const portalElement = document.getElementById('overlays');
    return (
        <Fragment>
            {ReactDOM.createPortal(<Backdrop />, portalElement)}
            {ReactDOM.createPortal(<LoaderOverlay></LoaderOverlay>, portalElement)}

        </Fragment>
    )
}
export default Loader;