import {Route, Routes} from "react-router-dom";
import Layout from './components/common/Layout/Layout';
import Notifications from './components/NotificationDashboard/Notifications';
import Dashboard from './components/MyAccount/Dashboard';
import Settings from './components/Settings/OrgSetting';
import Users from './components/Settings/users';
import Plans from './components/Settings/plans';
import Login from './components/Login/Login';
import PrivateRoute from './components/route/PrivateRoute';
import PublicRoute from './components/route/PublicRoute';
import ModifyDetails from './components/ModifySchoolDetails/ModifyDetails';


function App() {

    // if user is on root path, redirect to /notifications
     if (window.location.pathname === '/') {
         window.location.href = '/notifications';
     }
    return (
        <Layout>
            <Routes>
                <Route element={<Notifications/>} path="/notifications" exact />
                <Route element={<ModifyDetails/>} path="/ModifyDetails" exact/>
                <Route element={<Users/>} path="/users" exact />
                {/* <PrivateRoute component={Plans} path="/plans" exact /> */}
                <Route element={<Dashboard/>} path="/dashboard" exact />
                <Route element={<Settings/>} path="/Organisation/:orgId" />
                <Route element={<Login/>} path="/login" exact restricted={true} />
            </Routes>
        </Layout>

    );
}
export default App;
