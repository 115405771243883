import React, { useState } from 'react';
import grayUser from '../assets/gray-user.png';
const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    userData:null,
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {

    const initialToken = localStorage.getItem('token');
    const initialUserData = localStorage.getItem('userData');

    const [token, setToken] = useState(initialToken);
    const [userData, setUserData] = useState(JSON.parse(initialUserData));
    const [uProfileImage,setUProfileImage]= useState('')
    const userIsLoggedIn = !!token;

    const loginHandler = (userData) => {

        const { token, ...rest } = userData;
        try {
            setToken(token);
            setUserData(userData);
        } catch (e) {
            console.log(e);
        }
        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(userData));
    }
    const profileUpdateHandler = (profileImage) => {
       // alert('img========'+profileImage);
        setUProfileImage(profileImage);
        userData.thumbImage = profileImage;
        setUserData(userData);
    }
    const logoutHandler = () => {
        setToken(null);
        setUserData(null)
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
    }

    const contextValue = {
        token: token,
        userData:userData,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        setUProfileImage,
        uProfileImage,
        setUserData:setUserData,
        profileUpdate:profileUpdateHandler
    }
    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>

}

export default AuthContext;
