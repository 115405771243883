import { useState } from 'react';
import style from './SchoolLinkAppUsers.module.css';
import Pagination from '../../UI/Pagination/Pagination';
import { GoSearch } from "react-icons/go";
import tableStyle from '../../Style/TableModel.module.css';
import '../../Style/FormStyle.css';
import '../../Style/Pagination.css';
let PageSize = 8;


const IndividualAppUsers = (props) => {
    const [searchInputText, setSearchInputText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const searchTextArray = searchInputText.toLowerCase().trim().split(' ');
    const searchData = props.users.filter(user => {
        return searchTextArray.every(i => user.firstName.toLowerCase().indexOf(i) > -1)
            || searchTextArray.every(i => user.lastName.toLowerCase().indexOf(i) > -1)
            || searchTextArray.every(i => user.email.toLowerCase().indexOf(i) > -1)
    }
    );

    const users = searchData.slice(firstPageIndex, lastPageIndex);

    const searchHandler = (e) => {
        setSearchInputText(e.target.value);
        setCurrentPage(1);
    }

    return (
        <>
            <form method="post" onSubmit={(e) => e.preventDefault()}>
                <div className={'form-group ' + style.hassearch}>
                <GoSearch className={style.searchIcon} />

                    <input
                        value={searchInputText}
                        onChange={searchHandler}
                        type="search"
                        className={style.formcontrol}
                        placeholder="Search..."
                    />

                </div>
            </form>
            <table className={"table table-hover " + style.tableData}>
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">School Link</th>
                        <th scope="col">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => {
                        return (<tr key={user.id}>
                            <td scope="row">
                                <div className={"form-check " + style.inputBtn}>
                                {props.channel.includes("5")!= true ?(
                                    <>
                                   { props.isAllSchoolLinkAppUsersSelected==true?(
                                   <input  checked={props.selectedUserId(users.uuid)} onChange={props.isAllSchoolLinkAppUsersSelected}
                                  className={"form-check-input " + style.checkBtn} type="checkbox" value={user.uuid} />
                                   ):(
                                     <input checked={props.selectedUserId.includes(user.uuid)} onChange={props.onCheckedUser} className={"form-check-input " + style.checkBtn} type="checkbox" value={user.uuid} />)}

                                    </>
                                   )
                                    :
                                    (<input name="PrivateMsg" checked={props.selectedUserId.includes(user.uuid)} onChange={props.onCheckedUser} className={"form-check-input " + style.radioBtn} type="radio" value={user.uuid} />)}
                                </div>
                            </td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td><div className={style.center}><i className="fa fa-check" style={{ color: '#45c096' }}></i></div></td>
                            <td>0000000</td>
                        </tr>)
                    })}
                </tbody>
            </table>
            <div className="modalStyle padd-0">
                <Pagination
                    currentPage={currentPage}
                    totalCount={searchData.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </>
    );
}

export default IndividualAppUsers;
