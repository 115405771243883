import { useState } from 'react';
import style from './EpubIssuesContents.module.css';
import Pagination from '../../UI/Pagination/Pagination';
import tableStyle from '../../Style/TableModel.module.css';
import button from '../../Style/ButtonStyle.module.css';
import dateFormat from 'dateformat';

let PageSize = 8;


const EpubIssuesContents = (props) => {
    const [searchInputText, setSearchInputText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if(props.issues == undefined || props.issues.length==0){
        return null;
    }
    const searchTextArray = searchInputText.toLowerCase().trim().split(' ');
    const searchData = props.issues.filter(issue => {
            return searchTextArray.every(i => issue.issue_name.toLowerCase().indexOf(i) > -1)
        }
    );

    const issues = searchData.slice(firstPageIndex, lastPageIndex);

    const searchHandler = (e) => {
        setSearchInputText(e.target.value);
        setCurrentPage(1);
    }

    return (
        <>
            <div className={tableStyle.buttonArea}>
                <div className="row">
                    <div className={"col-6 " + tableStyle.leftAlign}>
                        <h4 className={tableStyle.tableTitle}>Newsletter Issues</h4>
                        {/*<button type="button" className={"disabled " + button.modalBtn + ' ' + button.whiteButton}>All Issues</button>*/}
                        {/*<button type="button" className={button.modalBtn + ' ' + button.whiteButton + ' ' + button.buttonSpaceLeft}>Newsletter Issues</button>*/}
                        {/*<button type="button" className={button.modalBtn + ' ' + button.whiteButton + ' ' + button.buttonSpaceLeft}>Parent Surveys</button>*/}
                    </div>

                    <div className={"col-6 " + tableStyle.rightAlign + ' ' + tableStyle.modalSearch}>
                        <form method="post" onSubmit={(e) => e.preventDefault()}>
                            <div className={'form-group ' + tableStyle.hassearch}>
                                <i className={"icon icon-search2 " + tableStyle.searchIcon}></i>
                                <input
                                    value={searchInputText}
                                    onChange={searchHandler}
                                    type="search"
                                    className={tableStyle.searchInput}
                                    placeholder="Search..."
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="raw">
                <div className="col-12">


                <table className={"table table-hover " + tableStyle.tableData}>
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Author</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date Added</th>
                    {/*<th scope="col">Date Modified</th>*/}
                    {/*<th scope="col">Last Queued</th>*/}
                    {/*<th scope="col">Action</th>*/}
                </tr>
                </thead>
                <tbody>

                {issues.map((issue) => {
                    return (<tr key={issue.pk_issue_id}>
                        <td scope="row">
                        <div className={"form-check " + tableStyle.inputBtn}>
                                <input name="selectedId" onChange={props.onCheckedissue} className={"form-check-input " + tableStyle.radioBtn} type="radio" value={issue.permalink+'||'+issue.user_first_name+' '+issue.user_last_name+'||'+issue.issue_name} />
                            </div>
                        </td>
                        <td>{issue.issue_name}</td>
                        <td>{issue.user_first_name} {issue.user_last_name}</td>
                        <td>{issue.issue_status}</td>
                        <td>{ dateFormat(issue.issue_add_date, "dd-mm-yyyy HH:mm:ss")}</td>
                        {/*<td></td>*/}
                        {/*<td></td>*/}
                        {/*<td></td>*/}

                    </tr>)
                })}
                </tbody>
            </table>
                </div>
            </div>
            <div style={{ float: 'right' }}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={searchData.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </>
    );
}

export default EpubIssuesContents;
