import { useState, useEffect,useContext } from 'react';
import styles from './Channels.module.css';
import { CHANNELS } from '../../constant-data';

import { ImMobile } from "react-icons/im";
import '../Style/Table-style.css';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import font from '../Style/FontFamily.module.css';
import button from '../Style/ButtonStyle.module.css';
import AuthContext from '../../store/auth-context';
import '../Style/FormStyle.css';
import { parseJToken } from '../../services/notification.services';
import tableStyle from '../Style/TableModel.module.css';
import Modal from 'react-modal';
const Channels = (props) => {
    //console.log(props.channels)
    const [modalIsOpen, setIsOpen] = useState(false);
    const authCtx = useContext(AuthContext);
    const { checkedChannels, resetChannelsHandler,setSelectedChannelsForValidation, onCompose, onComposeChannels } = props;
    const [selectedChannels, setSelectedChannels] = useState([]);

    const arrToken = parseJToken(authCtx.token);
   // console.log('token in channel====',arrToken);
    const channelHandler = (e) => {


        let channels = [...selectedChannels];
        let id = e.target.value;
        if (channels.includes(id))
            channels = channels.filter(ch => ch !== id);
        else
            channels.push(id);

        setSelectedChannels(channels);
        setSelectedChannelsForValidation(channels);
    }

    const openModal = ()=>{
        if(selectedChannels.length == 5){
            setIsOpen(true)
        }else{
            onComposeChannels(selectedChannels);
            onCompose();
        }

    }
    const modalClose = ()=>{
        setIsOpen(false)
    }
    const composeHandler = () => {
        onComposeChannels(selectedChannels);
        onCompose();
    }

    const resetHandler = () => {
        setSelectedChannels([]);
        resetChannelsHandler();
    }
    /*
    useEffect(() => {
        setSelectedChannels(checkedChannels)
    }, [checkedChannels]);
    */
  // console.log("CHANNELS in channel====",CHANNELS)
    return (<div className={styles.channeldropdown}>
        <ul>
            {CHANNELS.map((channel) => {

                let id = `channelId_${channel.id}`;
                if(channel.name=='Push Notification'){
                    return (<li key={channel.id} className={styles.firstLi}>
                        <div className="form-check channel-check">
                            <input onChange={channelHandler} className="form-check-input" checked={selectedChannels.includes(channel.id)} type="checkbox" value={channel.id} id={id} />
                            <label className="form-check-label" htmlFor={id}>
                                <span className={styles.iconArea}><SVGIcon svgIcondata={channel.svgIcon} /> </span> <span className={fontSize.font13 + ' ' + font.fontMedium}>{authCtx.userData.CHANNEL_PUSH_NOTIFICATION=='1' ? channel.name:''}
                                </span>
                            </label>
                        </div>
                    </li>)
                }else if(channel.name!='Push Notification'){
                    return (<li key={channel.id} className={styles.firstLi}>
                        <div className="form-check channel-check">
                            <input onChange={channelHandler} className="form-check-input" checked={selectedChannels.includes(channel.id)} type="checkbox" value={channel.id} id={id} disabled={channel.displayname}/>
                            <label className="form-check-label" htmlFor={id}>
                                <span className={styles.iconArea}><SVGIcon svgIcondata={channel.svgIcon} /> </span> <span className={fontSize.font13 + ' ' + font.fontMedium}>{channel.name}
                                </span>
                            </label>
                        </div>
                    </li>)
                }

            })}
            <li className={styles.buttonstyle}>
                <div className={styles.buttonBody} role="group">
                    <button type="button" onClick={resetHandler} className={button.smBtn + ' ' + button.brownButton}>Reset</button>
                    <button type="button" onClick={openModal} className={button.smBtn + ' ' + button.lightRedButton + ' ' + button.buttonSpaceLeft}>Compose</button>
                </div>
            </li>
        </ul>
        <Modal isOpen={modalIsOpen} className="alertBgContainer" contentLabel="Example Modal" >
            <div className='alertBox'>
                <h4 className={tableStyle.alertTitle}>Alert</h4>
                <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}><b className={font.fontBold}>Confirm if you wish to post this message to (all selected) multiple communication channels,</b> e.g. If you selected SchoolLink notifications & email, then this message will be sent via both channels.</p>
                <div className='bottomArea'>
                    <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                    <button onClick={composeHandler} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>
                </div>
            </div>
        </Modal>
    </div>
    )
}

const SVGIcon = (props) => {
    return <>
        <p style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: props.svgIcondata }}></p>
    </>
}

export default Channels;
