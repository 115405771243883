// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EpubIssuesContents_hassearch__Z6Hdx .EpubIssuesContents_formcontrol__FcuIy {
  padding: 0.5rem;
  width: 100%;
  background-color: #aaaaaa21;
  border-radius: 5px;
  border: 1px solid #aaa;
}

.EpubIssuesContents_hassearch__Z6Hdx .EpubIssuesContents_formcontrolfeedback__PwmrM {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.5rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  left: 92%;
}
.EpubIssuesContents_hassearch__Z6Hdx .EpubIssuesContents_formcontrol__FcuIy:focus {
  border-color: 1px solid #45c096 !important;
  box-shadow: 0 0 0 0.2rem rgba(32, 226, 162, 0.747);
}

.EpubIssuesContents_dangercolor__rxNM5 {
  color: red;
  border: 1px solid red;
  padding: 5px;
  border-radius: 5px;
}
.EpubIssuesContents_label__Kpm8f {
  color: #45c096;
}
.EpubIssuesContents_dropdownstyle__cbnfE {
  border: none;
  border-bottom: 1px solid #495057;
}
`, "",{"version":3,"sources":["webpack://./src/components/NotificationThreadDetail/Editor/EpubIssuesContents.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;EACX,SAAS;AACX;AACA;EACE,0CAA0C;EAC1C,kDAAkD;AACpD;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;EACZ,gCAAgC;AAClC","sourcesContent":[".hassearch .formcontrol {\n  padding: 0.5rem;\n  width: 100%;\n  background-color: #aaaaaa21;\n  border-radius: 5px;\n  border: 1px solid #aaa;\n}\n\n.hassearch .formcontrolfeedback {\n  position: absolute;\n  z-index: 2;\n  display: block;\n  width: 2.375rem;\n  height: 2.375rem;\n  line-height: 2.5rem;\n  text-align: center;\n  pointer-events: none;\n  color: #aaa;\n  left: 92%;\n}\n.hassearch .formcontrol:focus {\n  border-color: 1px solid #45c096 !important;\n  box-shadow: 0 0 0 0.2rem rgba(32, 226, 162, 0.747);\n}\n\n.dangercolor {\n  color: red;\n  border: 1px solid red;\n  padding: 5px;\n  border-radius: 5px;\n}\n.label {\n  color: #45c096;\n}\n.dropdownstyle {\n  border: none;\n  border-bottom: 1px solid #495057;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hassearch": `EpubIssuesContents_hassearch__Z6Hdx`,
	"formcontrol": `EpubIssuesContents_formcontrol__FcuIy`,
	"formcontrolfeedback": `EpubIssuesContents_formcontrolfeedback__PwmrM`,
	"dangercolor": `EpubIssuesContents_dangercolor__rxNM5`,
	"label": `EpubIssuesContents_label__Kpm8f`,
	"dropdownstyle": `EpubIssuesContents_dropdownstyle__cbnfE`
};
export default ___CSS_LOADER_EXPORT___;
