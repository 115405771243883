import React, {useContext, useEffect, useRef, useState, useCallback} from 'react';
import {Dropdown} from 'react-bootstrap';

import NotificationPin from './NotificationPin';
import {IoIosArrowDown} from "react-icons/io";
import style from './NotificationCard.module.css';
import {ImPushpin, ImPencil, ImSearch, ImBin} from "react-icons/im";
import {RiHeart3Line, RiHeart3Fill} from "react-icons/ri";
import fontSize from '../../Style/FontSize.module.css';
import common from '../../Style/common.module.css';
import font from '../../Style/FontFamily.module.css';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {stateToHTML} from "draft-js-export-html";
import ContentBlock, {ContentState, convertFromRaw} from "draft-js";
import ReactDOMServer from "react-dom/server";
import customRenderToHtml from "../Editor/CustomRendererToHtml";
import draftToHtml from "draftjs-to-html";
import ImagesLightBox from "../Editor/ImagesLightBox";
import NotificationCardStyle from "./NotificationCard.module.css";
import AuthContext from "../../../store/auth-context";
import AppContext from "../../../store/AppContext";
import {
    getPinnedMessage,
    updateLastModifiedTimeStampOfNotificationThread
} from "../../../services/notification.services";
import editor from '../../Style/EditorStyle.module.css'
import '../../Style/Table-style.css';
import button from '../../Style/ButtonStyle.module.css';
import tableStyle from '../../Style/TableModel.module.css';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import styles from "../Editor/ComposeNotification.module.css";

const NotificationCard = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalOpen] = useState(false);
    const [preview, setPreview]=useState(false);
    const [displayTitle, setDisplayTitle]=useState("");
    const authCtx = useContext(AuthContext);

    const appCtx = useContext(AppContext);
    console.log('card props', props)
    const galleryBtnRef = useRef();
    const [isLightBoxOpen, setLightBoxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [imagesInThisMessage, setImagesInThisMessage] = useState([]);
    const [isSending, setIsSending] = useState(false);
    let html = "";
    let allImages = [];
    let orgID = authCtx.userData.orgId;

    const {
        id,
        messageBody,
        rawMessageBody,
        title,
        authorId,
        authorName,
        authorImageUrl,
        schoolName,
        isPinned,
        contentType,
        messageCategory,
        isLiked,
        likesCount,
        createdOn,
        lastModified,
        usersGroupId
    } = props.notificationData;

    let i = 0
    let authorImageUrlLocalStore= localStorage.getItem(''+authorId);
    useEffect(()=>{

        setPreview(title.includes("-#DRFT#"));
        let t=title;
        if(t.includes('@@@')){
            let spli=t.split('@@@');
            setDisplayTitle(spli[1]);
        }else{
            setDisplayTitle(title);
        }
    },[]);
    const TagArray = messageCategory?.split(',').map(item => {
        let catName = '';

        if (item == 'ARTICLES' || item == 'NEWSLETTERS' || item == 'CALENDARS' || item == 'SURVEYS' || item == 'EFLASHES' || item == 'ESIGNS') {
            if (item == "ARTICLES") {
                catName = 'Article';
            } else if (item == "NEWSLETTERS") {
                catName = 'Newsletter';
            } else if (item == "SURVEYS") {
                catName = 'Survey';
            } else if (item == "ESIGNS") {
                catName = 'eSign';
            }
            item = catName;
        }
        return <span>{item}</span>
    });

    const signleTagArray = messageCategory?.split(',')
    let firstTag = signleTagArray?.[0]
    let catName = '';

    if (firstTag == 'ARTICLES' || firstTag == 'NEWSLETTERS' || firstTag == 'CALENDARS' || firstTag == 'SURVEYS' || firstTag == 'EFLASHES' || firstTag == 'ESIGNS') {
        if (firstTag == "ARTICLES") {
            catName = 'Article';
        } else if (firstTag == "NEWSLETTERS") {
            catName = 'Newsletter';
        } else if (firstTag == "SURVEYS") {
            catName = 'Survey';
        } else if (firstTag == "ESIGNS") {
            catName = 'eSign';
        }
        firstTag = catName;
    }

    console.log(props.notificationData)
    if (rawMessageBody) {
        let rawMessageBodyObject = JSON.parse(rawMessageBody);


        for (let key in rawMessageBodyObject.entityMap) {
            let e = rawMessageBodyObject.entityMap[key];
            if (e.type == "IMAGES_GALLERY") {
                for (let k in e.data.images) {
                    let image = e.data.images[k];
                    console.log("Image Url", image.url);
                    //setImagesInThisMessage(imagesInThisMessage.push(image.url));
                    allImages.push(image.url);
                }
                ;
            }
        }
        ;
        //setImagesInThisMessage(allImages);
        let contentState = convertFromRaw(rawMessageBodyObject);
        const onGalleryClick = () => {
            //alert("sdfsd");
        }
        let renderingOptions = {
            blockRenderers: {
                atomic: (block) => {
                    let entity = contentState.getEntity(block.getEntityAt(0));
                    let data = entity.getData();
                    let type = entity.getType();
                    let media;
                    media = customRenderToHtml(type, data, onGalleryClick);
                    let htmlized = ReactDOMServer.renderToString(media);
                    return htmlized;
                },
            },
        };
        //let html0 = draftToHtml(rawMessageBodyObject);
        html = stateToHTML(contentState, renderingOptions);
    }


    const dt = new Date(createdOn);
    //console.log(props);
    const edt = new Date(lastModified);
    const currnetDate = new Date();
    const DaysDatediff = Math.floor(Math.abs(currnetDate - edt) / (1000 * 60 * 60 * 24));

    var diff = (currnetDate.getTime() - edt.getTime()) / 1000;
    diff /= (60 * 60);
    const HrDatediff = Math.abs(Math.round(diff));
    var timeDiff = Math.abs(currnetDate.getTime() - edt.getTime());
    var MonthDiff = Math.round(timeDiff / (2e3 * 3600 * 365.25));
    var diffMins = Math.round(((timeDiff % 86400000) % 3600000) / 60000); // minutes
    if (HrDatediff == 0) {
        var diffDate = diffMins + "m"
    } else if (HrDatediff < 24) {
        var diffDate = HrDatediff + "h"

    } else if (DaysDatediff < 31) {
        diffDate = DaysDatediff + "d"
    } else {
        diffDate = MonthDiff + "mn"
    }

    const month = dt.toLocaleString('en-US', {month: 'long'});
    const day = dt.toLocaleString('en-US', {day: '2-digit'});
    const year = dt.getFullYear();

    //const sendAt = `${day + ' ' + month + ' ' + year}`;
    const sendAt = dt.toLocaleString();
    const editAt = edt.toLocaleString();
    const [isPinModalOpen, setIsPinModalOpen] = useState(false);
    const [showAlreadyPinedMessage, setShowAlreadyPinedMessage] = useState(false);
    const handlePinModalClose = () => setIsPinModalOpen(false);
    const handlePinModalShow = () => setIsPinModalOpen(true);

    const openModal = ()=>{
        setIsOpen(true)
    }
    const modalClose = ()=>{
        setIsOpen(false)
    }

    const removeMessage = () => {
        props.removeNotification(id);
        props.setDissableEditor(false);
        setIsOpen(false)
    }
    
    const pinHandler = () => {
        getPinnedMessage(authCtx.userData.orgId, authCtx.token).then(r => {
            appCtx.setPinnedMessage(r.data.data);
            if (r.data.data.length > 0) {
                //debugger;
                setShowAlreadyPinedMessage(true);
            }
            setIsPinModalOpen(true);
        }).catch(err => {
            console.log(err);
        });

    }

    const editopenModal = ()=>{
        setEditModalOpen(true)
    }
    const edirmodalClose = ()=>{
        setEditModalOpen(false)
    }
    const savePinHandler = (messageId) => {
        props.onPinSave(messageId);

    }

    const editMessage = () => {
        props.onEditMessage(props.notificationData);
        setEditModalOpen(false)
    }
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            edirmodalClose();
            modalClose();
        }
      }, [edirmodalClose, modalClose]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);

    const sendMessage=(e)=>{
        e.stopPropagation();
        setIsSending(true);
        props.sendMessage(id).then(r=>{
            if(r.status==200){
                console.log("Message sent");
                setPreview(false);
                props.setDissableEditor(false);

                //usersGroupId
                updateLastModifiedTimeStampOfNotificationThread(usersGroupId,authCtx.token);

                toast.success("A new message created successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success',
                    autoClose: 3000,
                });
                setIsSending(false);

            }
        });
        console.log("send response",);
    }

    const checkPinned = isPinned == true ? {color: 'red', whiteSpace: " pre-wrap",} : {whiteSpace: " pre-wrap",};

    const images = [
        '//placekitten.com/1500/500',
        '//placekitten.com/4000/3000',
        '//placekitten.com/800/1200',
        '//placekitten.com/1500/1500',
    ];

    const messageBodyOnClickHandler = (e) => {
        console.log(e.target.className);
        if (e.target.className === style.ImageGalleryImage || e.target.className === NotificationCardStyle.FourthImageStyle) {
            console.log("Class name matched ");
            //setLightBoxOpen(true);
            galleryBtnRef.current.click();
        }
    }
    if (props.notificationData.flag !=undefined && props.notificationData.flag === 'EDITING') {
        return null;
    }

    return (<> {isPinModalOpen && <NotificationPin show={isPinModalOpen}
                                                   closeHandle={handlePinModalClose}
                                                   showHandle={handlePinModalShow}
                                                   showAlreadyPinedMessage={showAlreadyPinedMessage}
                                                   onSubmit={savePinHandler}
                                                   messageuuid={id}
        />}
            <div className={'overLayColor ' + style.senedmsg} style={{border:(preview?"1px dashed #888470":"")}}>
                <div className={style.updateMessageBox + ' ' + common.rightDetailBox}>
                    <div className={style.schoollogo}><img title={authorName} src={authorImageUrl}/></div>
                    <div className={style.schoolDetails}>
                        <div>
                        <span className={style.sbjdetails}>
                            {!preview && <Dropdown>
                                <Dropdown.Toggle className='boxArrow' variant="outline-secondary" id="dropdown-basic">
                                    <IoIosArrowDown/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {/*<Dropdown.Item href="#">*/}
                                    {/*    <span className={style.iconArea}><ImSearch /></span> Preview*/}
                                    {/*</Dropdown.Item>*/}
                                    <Dropdown.Item onClick={editopenModal} href="#" className={editor.iconArea}>
                                        <span className={editor.editIcon}></span><span>Edit</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" className={editor.iconArea}
                                                   onClick={!isPinned ? pinHandler : null}>
                                        <span className={editor.pinIcon}></span><span>Pin to top</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={openModal} className={editor.iconArea}>
                                        <span className={editor.deleteIcon}></span><span>Remove Message</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> }
                        </span>
                        </div>
                        <p style={{marginRight: "20px"}}
                           className={common.messegeTitle + ' ' + fontSize.smSize + ' ' + font.HelveticaNeue700}>{displayTitle.replace("-#DRFT#","")}</p>
                        <p className={common.autherName + ' ' + fontSize.font13 + ' ' + font.HelveticaNeue400}>
                            <span className={common.dott}>By {authorName} <span>{diffDate}</span> {" "}</span>

                            {TagArray?.length == 1 &&
                                <div><span className={common.singleTag}>{firstTag}</span></div>
                            }
                            {TagArray?.length > 1 &&
                                <div className={common.mainTag}>
                                    <div className={common.singleTag + ' ' + common.arrowIcon}>{firstTag}
                                        <div className={common.multiTag}>{TagArray}</div>
                                    </div>
                                </div>
                            }

                            {isPinned && <span className={common.pinIcon}>
                            <svg style={{width: "16px"}} className={style.previewIcon} viewBox="0 0 48 48"
                                 xmlns="http://www.w3.org/2000/svg"><g transform="matrix(2,0,0,2,0,0)"><path
                                d="M4.949,8.783a1.5,1.5,0,0,0-.3,2.356l8.213,8.213a1.5,1.5,0,0,0,2.356-.3,7.522,7.522,0,0,0,.81-5.54l6.529-4.155a1.5,1.5,0,0,0,.256-2.326L16.97,1.188a1.5,1.5,0,0,0-2.326.255l-4.155,6.53A7.522,7.522,0,0,0,4.949,8.783Z"
                                fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.5"></path><path d="M8.753 15.246L0.75 23.249" fill="none"
                                                                stroke="#000000" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1.5"></path></g></svg>
                        </span>}
                        </p>
                        <p className={common.messageDep2 + ' ' + fontSize.smSize + ' ' + font.HelveticaNeue400}
                           dangerouslySetInnerHTML={{__html: html}} onClick={messageBodyOnClickHandler}></p>
                        <ImagesLightBox ref={galleryBtnRef} images={allImages} isLightBoxOpen={isLightBoxOpen}/>
                        <p className={common.marginNone}>
                        <span>
                        {likesCount > 0 ? (
                            <RiHeart3Fill className={style.heartIcon} color='#ff6d73'/>) : (
                            <RiHeart3Fill className={style.heartIcon} color='#C7C7CC'/>)} <span
                            className={style.likeCount}>{likesCount}</span> {" "}

                        </span>
                            <span className={style.orgName}>{schoolName}</span>
                        </p>

                    </div>

                    <div className="progress" style={{height: "2px", position: "relative", top: "10px"}}>
                        <div className="progress-bar " role="progressbar"
                             style={{width: "65%", backgroundColor: "#49c096"}}
                             aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                </div>
            </div>
            <div>
                {!preview && <p className={style.senddetails + ' ' + font.HelveticaNeue400}>Sent on {sendAt} ( Last
                    edited {editAt} )</p>}
                {preview &&  <div className='bottomArea' style={{margin: '17px 14px 17px 0px'}} >
                        <button onClick={removeMessage} className={button.smBtn + ' '} style={{border:"1px solid #ff6d73", marginRight:"15px", color:"#ff6d73", backgroundColor:"#f4f8fb"}}>Cancel</button>
                        <button onClick={editMessage} style={{marginRight:'0px',border:'1px solid #d1d1d6',backgroundColor:'white',padding:'0px 5px',color:'#9d9d9d',minWidth:'80px',fontSize:"12px"}} className={button.btn + ' ' + button + ' ' + styles.sendButton}>Edit</button>
                        <button disabled={isSending} onClick={sendMessage} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>
                            {isSending &&  <span className={"spinner-border spinner-border-sm "+styles.spinnerForSendingMessage} role="status"
                                                 aria-hidden="true"></span>}
                            Send
                        </button>
                </div>}
            </div>
            <Modal isOpen={modalIsOpen} contentLabel="Example Modal" className="alertBgContainer">
                <div className='alertBox'>
                    <h4 className={tableStyle.alertTitle}>Alert</h4>
                    <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you sure want to remove this notification?</p><p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}> <b className={font.fontBold}>When removed, it will no longer be visible in the SchoolLink app's live newsfeed.</b></p>
                    <div className='bottomArea'>
                        <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                        <button onClick={removeMessage} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Remove post</button>

                    </div>
                </div>
            </Modal>
            <Modal isOpen={editModalIsOpen} className="alertBgContainer" contentLabel="Example Modal" >
                <div className='alertBox'>
                    <h4 className={tableStyle.alertTitle}>Alert</h4>
                    <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you sure want to edit this LIVE notification?</p> <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}><b className={font.fontBold}>When modified all changes will be visible in the SchoolLink app's newsfeed.</b></p>
                    <div className='bottomArea'>
                        <button onClick={edirmodalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                        <button onClick={editMessage} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Edit post</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NotificationCard;
