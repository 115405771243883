import { useState, useEffect,useContext } from 'react';
import { Modal } from 'react-bootstrap';
import tableStyle from '../Style/TableModel.module.css';
import formStyle from '../Style/modalForm.module.css';
import button from '../Style/ButtonStyle.module.css';
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from "../../store/auth-context";
import axios from "axios";

const AddOrganisation = (props) => {
    //console.log(props.onAction)
    const authCtx = useContext(AuthContext);
    const [organisationID, setOrganisationID] = useState(0);
    const [OrganisationUUID, setOrganisationUUID] = useState('');
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [subrub, setSubrub] = useState("");
    const [state, setState] = useState("");
    const [code, setCode] = useState("");
    const [country, setCountry] = useState("");
    const [website, setWebsite] = useState("");
    const [orglogourl, setOrglogourl] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [selectedFileBase64, setSelectedFileBase64] = useState("");


    const http = axios.create({
        baseURL: USERS_API_BASE_URL,
        headers: {
            "Content-type": "application/json",'Authorization':authCtx.token
        }
    });


    const OrganistionAdded = (e) => {
          e.preventDefault();
        //  console.log(name)
        //alert(organisationID);
        if(organisationID==0){
            fetch(`${USERS_API_BASE_URL}/organisation`, {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                'Authorization':authCtx.token
            },
            "body":
                "{\"useruuid\":\""+ authCtx.userData.uuid +"\",\"name\":\"" + name + "\",\"website\":\"" + website + "\",\"phone\":\"" + phone + "\",\"streetAddress\":\"" + address + "\",\"suburb\":\"" + subrub + "\",\"state\":\"" + state + "\",\"postCode\":\"" + code + "\",\"country\":\"" + country + "\",\"email\":\"" + email + "\",\"orgLogo\":\"" + orglogourl + "\"}"

            }).then(res=>res.json()).then(data=>{

                //alert(data.status==302?data.error:data.msg);props.onAction(false)

                if(data.status==302){
                    props.setMessage(data.error)
                }else{
                    props.setMessage(data.msg)
                    if(props.organisationList){
                        props.setOrganisationList([...props.organisationList,{id:data.newOrganisationData.id, name:data.newOrganisationData.name,createDate:data.newOrganisationData.createDate}]);
                    }
                    setOrganisationID(0)
                    setName('');
                    setEmail('');
                    setAddress('');
                    setPhone('');
                    setSubrub('');
                    setState('');
                    setCode('');
                    setCountry('')
                    setWebsite('')
                    props.setOrganisationDetail('');
                    props.onAction(false);
                }
            })
        }else{
            fetch(`${USERS_API_BASE_URL}/organisation/`+organisationID, {
                "method": "PUT",
                "headers": {
                    "Content-Type": "application/json",
                    'Authorization':authCtx.token
                },
                "body":
                "{\"name\":\"" + name + "\",\"website\":\"" + website + "\",\"phone\":\"" + phone + "\",\"streetAddress\":\"" + address + "\",\"suburb\":\"" + subrub + "\",\"state\":\"" + state + "\",\"postCode\":\"" + code + "\",\"country\":\"" + country + "\",\"email\":\"" + email + "\",\"orgLogo\":\"" + orglogourl + "\"}"

            }).then(res=>res.json()).then(data=>{

                //alert(data.status==302?data.error:data.msg);props.onAction(false)

                if(data.status==302){
                    props.setMessage(data.error)
                }else{
                    props.setMessage(data.msg)
                    if(props.organisationList){
                        const index = props.organisationList.findIndex(data => data.id === organisationID),
                        organisationData = [...props.organisationList] // important to create a copy, otherwise you'll modify state outside of setState call
                        organisationData[index] = {id:data.updateOrganisationData.id,uuid:data.updateOrganisationData.uuid, name:data.updateOrganisationData.name,createDate:data.updateOrganisationData.createDate,status:data.updateOrganisationData.status};
                        console.log(organisationData)
                        props.setOrganisationList(organisationData);
                    }
                    //setOrganisationID(0)
                    setName('');
                    setEmail('');
                    setAddress('');
                    setPhone('');
                    setSubrub('');
                    setState('');
                    setCode('');
                    setCountry('')
                    setWebsite('')
                    props.setOrganisationDetail('');
                    props.onAction(false);
                }

            })
        }


    }

    
    
    const onFileUpload = async () => {
     
        // Create an object of formData
        const formData = new FormData();
        
        formData.append("organisationLogoUrl", selectedFileBase64);

        const options = {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization':authCtx.token
            },
          }
          try {
            const response = await fetch(`http://localhost:5000/users/organisation/org-image/${OrganisationUUID}`, options);
            //const response = await fetch(`${USERS_API_BASE_URL}/organisation/org-image/${SelecteduserUUId}`, options);
            //console.log(response)
            const json = await response.json();
            if (response.status !== 201) {
              throw new Error(json.message);
            }
            setOrglogourl(json.userProfilefilePath);
            return json;
          } catch (err) {
            //console.log('Error in upload image', err.message)
            return { error: err.message }
          }
      };
   
    const onFileChange = async (event) =>{
        event.preventDefault();
        let formData = new FormData();
        let file = event.target.files[0];
        setSelectedFile(file);
        formData.append("file", file);
        return http.post("/multiple-upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            //onUploadProgress,
        }).then(function (response) {
            //console.log('res========',response)
            setOrglogourl(response.data.thumbUrl);

            ///processResponse(response);
        });

    }

    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} style={{"width" : "80px","height":"80px"}} alt={image.name} />;
      };
   useEffect(()=>{
            if(props.organisationDetail){
                setOrganisationID(props.organisationDetail.id);
                setOrganisationUUID(props.organisationDetail.uuid);
                setName(props.organisationDetail.name);
                setEmail(props.organisationDetail.email);
                setAddress(props.organisationDetail.streetAddress);
                setPhone(props.organisationDetail.phone);
                setSubrub(props.organisationDetail.suburb);
                setState(props.organisationDetail.state);
                setCode(props.organisationDetail.postCode);
                setCountry(props.organisationDetail.country);
                setWebsite(props.organisationDetail.website);
                setOrglogourl(props.organisationDetail.logoUrl);
            }

   },[props.organisationDetail.id,
     props.organisationDetail.name,
     props.organisationDetail.email,
     props.organisationDetail.streetAddress,
     props.organisationDetail.phone,
     props.organisationDetail.suburb,
     props.organisationDetail.state,
     props.organisationDetail.postCode,
     props.organisationDetail.country,
     props.organisationDetail.website]);

    return (
        <>
            <Modal
                size="md"
                show={props.modalStatus}
                onHide={props.onAction}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className={formStyle.headArea}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h4 className={tableStyle.modalTitle}> Create New / Edit Organisation</h4>
                    </Modal.Title>
                </div>
                <form onSubmit={OrganistionAdded} >
                <div className={formStyle.bodyArea}>

                        <div className="row">
                            <div className="col-ms-12">
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Name" autoFocus title='Name' className={formStyle.formControl} value={name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="email" placeholder="Email" title='Email' className={formStyle.formControl} value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="number" placeholder="Phone" title='Phone' className={formStyle.formControl} value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Street Address" title='Street Address' className={formStyle.formControl} value={address} onChange={e => setAddress(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Subrub" title='Subrub' className={formStyle.formControl} value={subrub} onChange={e => setSubrub(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="State" title='State' className={formStyle.formControl} value={state} onChange={e => setState(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Post Code" title='Post Code' className={formStyle.formControl} value={code} onChange={e => setCode(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Country" title='Country' className={formStyle.formControl} value={country} onChange={e => setCountry(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Website" title='Website' className={formStyle.formControl}     value={website} onChange={e => setWebsite(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                <input type="hidden" placeholder="Imgurl" className={formStyle.formControl}     value={orglogourl}  />
                                        <input type="file" id="org-files" onChange={onFileChange} />
                                        {selectedFile && <ImageThumb image={selectedFile} />}
                                        {orglogourl && !selectedFile && <img src={orglogourl} style={{"width" : "80px","height":"80px"}} alt={orglogourl} />}
                                </div>
                            </div>
                        </div>

                </div>
                <div className={formStyle.FooterArea}>
                    <button type="button" onClick={props.onAction} className={button.btn + ' ' + button.brownButton}>Cancel</button>
                    <button type="submit" className={button.btn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Save</button>
                </div>
                </form>
            </Modal>
        </>
    )
}
export default AddOrganisation;
