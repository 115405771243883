import {useState,useContext,useEffect} from 'react';
import custom from  './customModal.module.css'
import AuthContext from '../../../src/store/auth-context';
import { USERS_API_BASE_URL } from './../../constants';
const AddLinkModal = (props) =>{

    const { show, close, addLinkhandler} = props
    const [itemID, setItemID] = useState(1);
    const [linkTitle, setlinkTitle] = useState('')
    const [Externallink, setExternallink] = useState('')
    const [EmojiIcon, setemojiIcon] = useState('')
    const authCtx = useContext(AuthContext);
    
    let orgUuid=authCtx.userData.orgId;
    let emojiicon = '#ffffff';

    const changeTitle = ({target })=>{
        setlinkTitle(target.value);
    }
    const changeExternalLink = ({target })=>{
        setExternallink(target.value);
    }
    const changeEmojiIcon = ({target })=>{
        setemojiIcon(target.value);
    }

    useEffect(() => {
        //console.log(6)
        //console.log("Values recieved for editing ",props.contactDetail)
        setItemID(props.linkDetail.id);
        setlinkTitle(props.linkDetail.title);
        setExternallink(props.linkDetail.org_link);
        setemojiIcon(props.linkDetail.emojiicon); 
        
    },[props.linkDetail.id,props.linkDetail.title,props.linkDetail.org_link,props.linkDetail.emojiicon]);

    const submitHandler =()=>{
        //console.log(linkTitle)
        if(itemID==0){
            fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/add-link`, {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                    "{\"org_link\":\"" + Externallink + "\",\"orgId\":\"" + orgUuid + "\",\"emojiicon\":\"" + EmojiIcon + "\",\"title\":\"" + linkTitle + "\"}"

            }).then(res=>res.json()).then(data=>{alert(data.status==302?data.error:data.msg);close();setlinkTitle('')
                addLinkhandler(linkTitle,Externallink)
                if(props.OrganisationExternalLink){
                    props.setOrganisationLink([...props.OrganisationExternalLink,{id:data.newLinkData.id,title:linkTitle, org_link:Externallink,emojiicon:EmojiIcon,displayOrder:data.newLinkData.displayOrder,linkStatus:data.newLinkData.linkStatus}]);
                }
            })
        }else{
            fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-link`, {
                "method": "PUT",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                    "{\"org_link\":\"" + Externallink + "\",\"orgId\":\"" + orgUuid + "\",\"emojiicon\":\"" + EmojiIcon + "\",\"title\":\"" + linkTitle + "\",\"id\":\"" + itemID + "\"}"

            }).then(res=>res.json()).then(data=>{alert(data.status==302?data.error:data.msg);close();setlinkTitle('')
                // addLinkhandler(linkTitle,Externallink)
                // if(props.OrganisationExternalLink){
                //     props.setOrganisationLink([...props.OrganisationExternalLink,{id:57, org_link:Externallink,displayOrder:"4"}]);
                // }

                if(props.OrganisationExternalLink){
                    const index = props.OrganisationExternalLink.findIndex(data => data.id === itemID),
                    linkData = [...props.OrganisationExternalLink] // important to create a copy, otherwise you'll modify state outside of setState call
                    linkData[index] = {id:data.updatelinkData.id, org_link:data.updatelinkData.org_link,title:data.updatelinkData.title,emojiicon:data.updatelinkData.emojiicon,linkStatus:data.updatelinkData.linkStatus};
                    //console.log(linkData)
                    props.setOrganisationLink(linkData);
               }
            })
        }

    }

    const deleteHandler =(itemID)=>{
        console.log(itemID)
        
            fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/delete-link`, {
                "method": "PATCH",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                "{\"ORGID\":\"" + orgUuid + "\",\"itemID\":\"" + itemID + "\"}"
    
            }).then(res=>res.json()).then(data=>{alert(data.status==302?data.error:data.msg);close();
                if(props.OrganisationExternalLink){
                    var array = [...props.OrganisationExternalLink]; // make a separate copy of the array
                        //console.log(array)
                        //var index = props.OrganisationContactList.indexOf(itemID)
                        const index = props.OrganisationExternalLink.findIndex(data => data.id === itemID)
                        //console.log(index)
                        if (index !== -1) {
                            array.splice(index, 1);
                            //console.log(array)
                            props.setOrganisationLink(array)
                        }
                }
        
             })

    }
    return(
        <>
        {
            show ?
            <div className={custom.modalContainer}>
                <div className={custom.modalArea}>
                    <div className={custom.modalContent}>
                        <form className={custom.fromStyle}>
                            <div className={custom.groupSide}>
                                <h2 className={custom.modalHead}>ADD ICON</h2>
                                <input type="text" className={custom.fromInput} value={EmojiIcon} placeholder="Click to select" onChange={changeEmojiIcon} />
                            </div>
                            <div className={custom.groupSide}>
                                <h2 className={custom.modalHead}>TITLE</h2>
                                <input type="text" className={custom.fromInput} value={linkTitle} placeholder="Type Here..." onChange={changeTitle} />
                            </div>
                            <div className={custom.groupSide}>
                                <h2 className={custom.modalHead}>Link</h2>
                                <input type="text" className={custom.fromInput} value={Externallink} placeholder="https://example.com.au" onChange={changeExternalLink} />
                            </div>
                            <div className={custom.modalFooter}>
                                <button type="button" className={custom.modalbtn + ' ' + custom.redBtn} onClick={() => deleteHandler(itemID)}>Delete</button>
                                <button type="button" className={custom.modalbtn + ' ' + custom.whiteBtn} onClick={() => close()}>Cancel</button>
                                <button type="button" className={custom.modalbtn + ' ' + custom.blurBtn} onClick={submitHandler}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            : null
        }
        </>
    )
}
export default AddLinkModal;  