// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    background-color: #fafafa;
}
.card-body {
    border: none;
    flex: 1 1 auto;
    padding: 40px 20px;
    background: #f7f8fa;
    box-shadow: 2px 4px 9px 0px #d8d8d8;
}
.card-body .form-group{
    margin-bottom: 20px;
}
.card-body label {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 8px;
    color: #909090;
}
.card-body .form-control {
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #565656;
    background-color: white;
    background-clip: padding-box;
    border: none;
    appearance: none;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-bottom: 1px solid #45c096;
}
.card-body .form-control:focus{
    border: none;
    box-shadow: none;
}
.card-body .btn-primary{
    font-size: 16px;
    padding: 10px 40px;
    margin-top: 20px;
}
.card-body .btn-outline-danger{
    font-size: 16px;
    padding: 10px 40px;
    margin-top: 20px;
}
.card-body .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,mCAAmC;AACvC;AACA;IACI,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,uBAAuB;IACvB,4BAA4B;IAC5B,YAAY;IAEZ,gBAAgB;IAChB,gBAAgB;IAChB,qEAAqE;IACrE,gCAAgC;AACpC;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":["body{\n    background-color: #fafafa;\n}\n.card-body {\n    border: none;\n    flex: 1 1 auto;\n    padding: 40px 20px;\n    background: #f7f8fa;\n    box-shadow: 2px 4px 9px 0px #d8d8d8;\n}\n.card-body .form-group{\n    margin-bottom: 20px;\n}\n.card-body label {\n    display: inline-block;\n    font-size: 14px;\n    margin-bottom: 8px;\n    color: #909090;\n}\n.card-body .form-control {\n    display: block;\n    width: 100%;\n    padding: 10px 10px;\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 1.5;\n    color: #565656;\n    background-color: white;\n    background-clip: padding-box;\n    border: none;\n    -webkit-appearance: none;\n    appearance: none;\n    border-radius: 0;\n    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;\n    border-bottom: 1px solid #45c096;\n}\n.card-body .form-control:focus{\n    border: none;\n    box-shadow: none;\n}\n.card-body .btn-primary{\n    font-size: 16px;\n    padding: 10px 40px;\n    margin-top: 20px;\n}\n.card-body .btn-outline-danger{\n    font-size: 16px;\n    padding: 10px 40px;\n    margin-top: 20px;\n}\n.card-body .form-check {\n    display: block;\n    min-height: 1.5rem;\n    padding-left: 1.5em;\n    margin-bottom: .125rem;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
