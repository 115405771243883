import { useState, useEffect,useContext } from 'react';
import { Modal } from 'react-bootstrap';
import tableStyle from '../Style/TableModel.module.css';
import formStyle from '../Style/modalForm.module.css';
import button from '../Style/ButtonStyle.module.css';
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from "../../store/auth-context";
import Checkbox from "./Checkbox";
import { IoIosEye, IoMdEyeOff } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';


const AddUser = (props) => {
    const authCtx = useContext(AuthContext);
    const [userID, setUserID] = useState(0);
    const [userUUID, setUserUUID] = useState('');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [userType, setUserType] = useState("");
    const [adminType, setAdminType] = useState(false);
    const [userPermission, setUserPermission] = useState([]);
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [usermessage, setUserMessage] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [hasMinimumLength, setHasMinimumLength] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasNumeric, setHasNumeric] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    
    //const [state, setState] = useState("");
    //const [location, setLocation] = useState("");
    //const [country, setCountry] = useState("");
    //console.log('udetail',props.userDetail);
    let permission = props.userDetailPermission;
    console.log('udetailPermission',props.userDetailPermission);
    const SetInternalAdmin = () => {
        setAdminType(!adminType);   
    }
    useEffect(() => {
        // Check password conditions here
        const hasMinLength = password.length >= 7;
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasNum = /[0-9]/.test(password);
        const hasSpecial = /[!@#$%.:]/.test(password);
        setHasMinimumLength(hasMinLength);
        setHasUppercase(hasUpper);
        setHasLowercase(hasLower);
        setHasNumeric(hasNum);
        setHasSpecialChar(hasSpecial);
    },[password]);
    const generatePassword = () => {
        const length = 8;
        const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
        const numericChars = '0123456789';
        const specialChars = '!@#$%.:';
      
        let password = '';
        
        // Generate at least one character of each required type
        password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
        password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
        password += numericChars[Math.floor(Math.random() * numericChars.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];
      
        // Generate remaining characters randomly
        for (let i = 0; i < length - 4; i++) {
          const charset = uppercaseChars + lowercaseChars + numericChars + specialChars;
          const randomIndex = Math.floor(Math.random() * charset.length);
          password += charset[randomIndex];
        }
      
        return password;
      };

    const handleGeneratePassword = () => {
        const generatedPassword = generatePassword();
        //checkPasswordConditions(generatedPassword);
        
        setPassword(generatedPassword);
    };
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const selectUserpermission = (per,checkedVal) =>{
        if(checkedVal==true && userPermission.includes(per)==false){
            setUserPermission([...userPermission,per]);
        }else if(checkedVal==false && userPermission.includes(per)==true){
            var index = userPermission.indexOf(per)
            if (index !== -1) {
                userPermission.splice(index, 1);
                setUserPermission(userPermission);
            }
        } 
    }
    //console.log('admintype===',adminType);
   // console.log('permission===',authCtx.userData);
    useEffect(() => {
        setFirstName(props.userDetail.firstName);
        setUserType(props.userDetail.usertype);
        if(props.userDetail.usertype=='INTERNALADMIN'){
            setAdminType(true);
        }
            setLastName(props.userDetail.lastName);
            setPhone(props.userDetail.phoneNumber);
            setEmail(props.userDetail.email);
            if(props.userDetail.id){
                setUserID(props.userDetail.id);
            }

            setUserUUID(props.userDetail.uuid);
            //console.log('p=======',permission);
            //console.log('pllll=======',permission.length)
            if(permission.length > 0){
                //alert(1);
                permission.map((uper)=>{
                    setUserPermission([...userPermission,uper.fk_module_id]);
                })
            }
            
    }, [permission,props.userDetail.uuid,props.userDetail.firstName,props.userDetail.usertype,props.userDetail.lastName,props.userDetail.phoneNumber,props.userDetail.email,props.userDetail.id])
    const UserAdded = (e) => {
        e.preventDefault();
        //  console.log(name)
       //alert(userPermission.length);
        //alert(userType);
        if(userID==0){
            fetch(`${USERS_API_BASE_URL}/signup`, {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                    "{\"firstName\":\"" + firstName + "\",\"lastName\":\"" + lastName + "\",\"phoneNumber\":\"" + phone + "\",\"password\":\"" + password + "\",\"email\":\"" + email + "\",\"usertype\":\"" + userType + "\",\"orguuid\":\"" + authCtx.userData.orgId + "\" ,\"userpermission\":\"" + userPermission + "\" ,\"userAddedBy\":\"" + authCtx.userData.user_id + "\"  }"

            }).then(res=>res.json()).then(data=>{

                //alert(data.status==302?data.error:data.msg);props.onAction(false)

                if(data.status==302){
                    props.setMessage(data.error)
                }else{
                    props.setMessage(data.msg)
                    if(props.userList){
                        props.setUserList([...props.userList,{id:data.newUserID, firstName:firstName,lastName:lastName,email:email,phoneNumber:phone,usertype:data.newUserType}]);
                    }

                    setFirstName('');
                    setLastName('');
                    setPhone('');
                    setEmail('');
                    setPassword('');
                    props.onAction(false);
                }

            })
        }else{
            fetch(`${USERS_API_BASE_URL}/put-user-profile/`+userUUID, {
                "method": "PUT",
                "headers": {
                    "Content-Type": "application/json",
                    'Authorization':authCtx.token
                },
                "body":
                    "{\"firstName\":\"" + firstName + "\",\"lastName\":\"" + lastName + "\",\"phoneNumber\":\"" + phone + "\",\"email\":\"" + email + "\",\"usertype\":\"" + userType + "\",\"userpermission\":\"" + userPermission + "\"}"

            }).then(res=>res.json()).then(data=>{

                //alert(data.status==302?data.error:data.msg);props.onAction(false)

                if(data.status==302){
                    props.setMessage(data.error)
                }else{
                    props.setMessage(data.msg)
                    if(props.userList){
                        const index = props.userList.findIndex(data => data.id === userID),
                        userData = [...props.userList] // important to create a copy, otherwise you'll modify state outside of setState call
                        userData[index] = {id:userID, firstName:firstName,lastName:lastName,email:email,phoneNumber:phone,usertype:userType};
                        console.log(userData)
                        props.setUserList(userData);
                    }
                    setFirstName('');
                    setLastName('');
                    setPhone('');
                    setEmail('');
                    props.onAction(false);
                }
                // if(props.userList){
                //     props.setUserList([...props.userList,{id:data.newUserID, firstName:firstName,lastName:lastName,email:email,phoneNumber:phone}]);
                // }






            })
        }


    }
    console.log('userSelectedPermission====',userPermission);
    return (
        <>
            <Modal
                size="md"
                show={props.modalStatus}
                onHide={props.onAction}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className={formStyle.headArea}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h4 className={tableStyle.modalTitle}> Create New / Edit User</h4>
                    </Modal.Title>
                </div>
                <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14}}>{usermessage}</div>
                <form onSubmit={UserAdded} >
                <div className={formStyle.bodyArea}>

                        <div className="row">
                            <div className="col-ms-12">
                                {authCtx.userData.user_id=="39" && <div className={formStyle.group}>
                                    <input type="checkbox" checked={authCtx.userData.usertype} placeholder="Internal Admin" value={adminType} onChange={e => SetInternalAdmin(e.target.value)}  /> Internal Admin
                                </div>}
                                
                                <div className={formStyle.group}>
                                    <select autoFocus value={userType} title='Select Usertype'  className={formStyle.formControl} onChange={e => setUserType(e.target.value)}>
                                        <option value={''} className={formStyle.formControl}>--Select Usertype--</option>
                                        <option value="INTERNALADMIN" >Internal Admin</option>
                                        <option value="ADMIN">Power User</option>
                                        <option value="USER">User</option>
                                    </select>
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="First Name" title='First Name' className={formStyle.formControl} value={firstName} onChange={e => setFirstName(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Last Name" title='Last Name' className={formStyle.formControl} value={lastName} onChange={e => setLastName(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="email" placeholder="Email" title='Email' className={formStyle.formControl} value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Phone" title='Phone' className={formStyle.formControl} value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                { userID == 0 ? <div className={formStyle.group + ' ' + formStyle.createPassword}>
                                    <div className={formStyle.passwordBox}>
                                        <input type={showPassword ? 'text' : 'password'} placeholder="Password" title='Password' className={formStyle.formControl} value={password} onChange={e => setPassword(e.target.value)} />
                                        <button type="button" className={formStyle.passwordShow} onClick={handleTogglePassword}>{showPassword ? <IoMdEyeOff /> : <IoIosEye /> }</button>
                                    </div>
                                    <button type="button" className={button.btn + ' ' + button.brownButton + ' ' + formStyle.Generatebtn} onClick={handleGeneratePassword}>Generate Password</button>
                                    <label>
                                        <span className={formStyle.checkArea}>{hasMinimumLength && <BsCheck className={formStyle.passCheck} /> }</span>
                                        {/* <input type="checkbox" checked={hasMinimumLength} disabled /> */}
                                        <span className={formStyle.ex_spm}>Eight characters</span> <span className={formStyle.checkmark}></span>
                                    </label>
                                    <label>
                                        <span className={formStyle.checkArea}>{hasUppercase && <BsCheck className={formStyle.passCheck} /> }</span>
                                        {/* <input type="checkbox" checked={hasUppercase} disabled /> */}
                                     <span className={formStyle.ex_spm}>One uppercase letter</span> <span className={formStyle.checkmark}></span>
                                    </label>
                                    <label>
                                        <span className={formStyle.checkArea}>{hasLowercase && <BsCheck className={formStyle.passCheck} /> }</span>
                                        {/* <input type="checkbox" checked={hasLowercase} disabled /> */}
                                        <span className={formStyle.ex_spm}>One lowercase letter</span> <span className={formStyle.checkmark}></span>
                                    </label>
                                    <label>
                                        <span className={formStyle.checkArea}>{hasNumeric && <BsCheck className={formStyle.passCheck} /> }</span>
                                        {/* <input type="checkbox" checked={hasNumeric} disabled /> */}
                                        <span className={formStyle.ex_spm}>One numeric digit</span> <span className={formStyle.checkmark}></span>
                                    </label>
                                    <label>
                                        <span className={formStyle.checkArea}>{hasSpecialChar && <BsCheck className={formStyle.passCheck} /> }</span>
                                        {/* <input type="checkbox" checked={hasSpecialChar} disabled /> */}
                                        <span className={formStyle.ex_spm}>One special character(!@#$%.:| allowed)</span> <span className={formStyle.checkmark}></span>
                                    </label>
                                </div> : null }
                                {adminType=== true && <div className={formStyle.group}>
                                    {props.moduleList.map((module) => {
                                        //alert(JSON.stringify(props.userDetailPermission).includes(module.id))
                                        return (
                                            <Checkbox 
                                            selectUserpermission={selectUserpermission}
                                            module={module}
                                            userSelectedPermission={props.userDetailPermission}
                                            setIsChecked={setIsChecked}
                                            isChecked={isChecked}
                                            />
                                        )
                                        // return (
                                        //     <div key={module.id}>
                                        //         {JSON.stringify(props.userDetailPermission).includes(module.id)?<input type="checkbox" checked={module.id} placeholder={module.name} onChange={e => selectUserpermission(module.id)}  />:<input type="checkbox" checked={''} placeholder={module.name} onChange={e => selectUserpermission(module.id)}  />  }
                                        //         {module.name}
                                        //     </div>
                                        // )
                                    })}
                                    


                                </div>}

                                
                                


                            </div>
                        </div>

                </div>
                <div className={formStyle.FooterArea}>
                    <button type="button" onClick={props.onAction} className={button.btn + ' ' + button.brownButton}>Cancel</button>
                    <button type="submit" className={button.btn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Save</button>
                </div>
                </form>
            </Modal>
        </>
    )
}
export default AddUser;
