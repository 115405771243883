// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FontSize_lgSize__7dwPV{
    font-size: 22px;
}
.FontSize_mdsize__8kw0Y{
    font-size: 16px;
}
.FontSize_smSize__v7PV8{
    font-size: 14px;
}
.FontSize_xsSize__3Su3r{
    font-size: 12px;
}
.FontSize_lowXsSize__cBTEA{
    font-size: 10px;
}
.FontSize_font13__eVQ2e{
    font-size: 13px;
}
.FontSize_font-11__ZT-Fd{
    font-size: 13px;
}
.FontSize_iconFontSize__GOxGt{
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Style/FontSize.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":[".lgSize{\n    font-size: 22px;\n}\n.mdsize{\n    font-size: 16px;\n}\n.smSize{\n    font-size: 14px;\n}\n.xsSize{\n    font-size: 12px;\n}\n.lowXsSize{\n    font-size: 10px;\n}\n.font13{\n    font-size: 13px;\n}\n.font-11{\n    font-size: 13px;\n}\n.iconFontSize{\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lgSize": `FontSize_lgSize__7dwPV`,
	"mdsize": `FontSize_mdsize__8kw0Y`,
	"smSize": `FontSize_smSize__v7PV8`,
	"xsSize": `FontSize_xsSize__3Su3r`,
	"lowXsSize": `FontSize_lowXsSize__cBTEA`,
	"font13": `FontSize_font13__eVQ2e`,
	"font-11": `FontSize_font-11__ZT-Fd`,
	"iconFontSize": `FontSize_iconFontSize__GOxGt`
};
export default ___CSS_LOADER_EXPORT___;
