import {useState, useEffect, useContext} from 'react';
import {Link, Redirect, useNavigate} from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import CategoryTags from './CategoryTags';
import SchoolAuthenticationcode from './AuthCode';
import Parents from './ParentsDetails';
import AddOrganistaion from './AddOrganistaion';
import User from './users';
import InternalUsers from './internalusers';
import MyAccount from './MyAccount';
import HelpText from './HelpText';
import Plans from './plans';
import Organisation from './organisation';
import style from './OrgSetting.module.css';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import '../Style/Table-style.css';
import { getOrganisationDetails } from '../../services/Organisation.js';
import {useParams} from "react-router-dom";
import AuthContext from "../../store/auth-context";

const OrgSetting = (props) => {


    //console.log(y)
    const authCtx = useContext(AuthContext);
    const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);
    const [OrganisationList, setOrganisationList] = useState([]);
    const [selectedCode, setSelectedCode] = useState('');
    const [key, setKey] = useState('first');


    console.log('authx detaill====',authCtx)

    const navigate = useNavigate();
    const recipientModalHendler = () => {

       setIsRecipientModalOpen((prevState) => !prevState);
    }
    const {cur_orgId} = useParams();
    const [orgId, setOrgId] = useState(cur_orgId);
    const x = window.location.href;
    console.log('url',x)
    let y = x.split('#');
    console.log(y)
    if(y[1].includes('?')){
        let  z = y[1].split('?');
        y[1] = z[0];
        y[2] = z[1];
    }else{
        y[2]='';
    }
    let defaultKey = 'first';
   // let SelectedCode = '';

    useEffect(() => {
        if(y[1]=='users'){
           // alert(6);
           console.log('usertab')
            defaultKey = 'third';
            setKey('third');
            setSelectedCode(y[2])
        }else if(y[1]=='category'){
            // alert(6);
            console.log('usertab')
             defaultKey = 'first';
             setKey('first');
             setSelectedCode('')
         }else if(y[1]=='schoolauthenticationcode'){
            // alert(6);
            console.log('usertab')
             defaultKey = 'Fourth';
             setKey('Fourth');
             setSelectedCode('')
         }else if(y[1]=='helpMessage'){
            // alert(6);
            console.log('usertab')
             defaultKey = 'Fifth';
             setKey('Fifth');
             setSelectedCode('')
         }else if(y[1]=='plans'){
            // alert(6);
            console.log('usertab')
             defaultKey = 'sixth';
             setKey('sixth');
             setSelectedCode('')
         }else if(y[1]=='organisations'){
            // alert(6);
            console.log('usertab')
             defaultKey = 'seventh';
             setKey('seventh');
             setSelectedCode('')
         }else if(y[1]=='myAccount'){
            // alert(6);
            console.log('usertab')
             defaultKey = 'eighth';
             setKey('eighth');
             setSelectedCode('')
         }else if(y[1]=='internalUser'){
            // alert(6);
            console.log('internal user Tab')
             defaultKey = 'eighth';
             setKey('nineth');
             setSelectedCode('')
         }else if(y[1]=='parents'){
            defaultKey = 'second';
            //SelectedCode = y[2];
            console.log('y==========',y[2])
            setSelectedCode(y[2])
            setKey('second')
        }
        getOrganisationDetails(authCtx.token).then((res) => {
            const contactGroupArr = res.data.map(group => group.id);
            setOrganisationList(res);
        })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const selectTab = (eventKey) =>{
        //alert(eventKey);
        if(eventKey=='first'){
            navigate("/Organisation/1#category");
        }else if(eventKey=='second'){
            navigate("/Organisation/1#parents");
        }else if(eventKey=='third'){
            navigate("/Organisation/1#users");
        }else if(eventKey=='Fourth'){
            navigate("/Organisation/1#schoolauthenticationcode");
        }else if(eventKey=='Fifth'){
            navigate("/Organisation/1#helpMessage");
        }else if(eventKey=='sixth'){
            navigate("/Organisation/1#plans");
        }else if(eventKey=='seventh'){
            navigate("/Organisation/1#organisations");
        }else if(eventKey=='eighth'){
            navigate("/Organisation/1#myAccount");
        }else if(eventKey=='nineth'){
            navigate("/Organisation/1#internalUser");
        }else{
            navigate("/Organisation/1#parents");
        }

        setKey(eventKey);
        console.log('eventKey',key)
    }

    console.log("orgSetting",authCtx.userData.userPermission);

    //let orgUuid="71c75ce2-6b85-4664-9a54-a4a155428ce6";
    //let orgUuid="7bce8766-5bd8-4194-b455-ac579dc36587";
    let orgUuid=authCtx.userData.orgId;

    return (
        <div className="">
            {isRecipientModalOpen && <AddOrganistaion
                modalStatus={isRecipientModalOpen}
                onAction={recipientModalHendler}

            />}
            <div className={style.rightbar}>
                <div className={style['page-title']}>
                    <h4 className={[fontSize.lgSize + ' ' + common.pageTitle + ' ' + font.fontMedium]} >Settings</h4>
                    { key=='first' ?
                    <div className={style.settingbutton}>
                        <div className={"dropdown " + style.settingdropdown} >


                        </div>

                        {/* {authCtx.userData.superAdminId == "39" &&
                        {authCtx.userData.superAdminId == "39" &&


                        {/* {authCtx.userData.superAdminId == "39" &&

                        <button id='orgbtn' onClick={recipientModalHendler} className={button.iconBtn + ' ' + button.modalBtn + ' ' + button.redButton}>
                            <i className="icon icon-plus-circle"></i> Create New Organisation</button>} */}
                    </div>
                    : null }

                </div>

                <div className={style.tabs}>
                    <Tabs defaultActiveKey={key} id="uncontrolled-tab-example" className={style.tabsDesign} activeKey={key}
      onSelect={(k) => selectTab(k)}>

                        {(authCtx.userData.usertype=="INTERNALADMIN"  || authCtx.userData.usertype=="OWNER")?'':<Tab tabClassName={style.colorred} eventKey="first" title="Contact Groups">
                            <CategoryTags orgUuid={orgUuid} sCode={selectedCode} setSelectedCode={setSelectedCode} setKey={setKey}/>
                        </Tab>}
                        {(authCtx.userData.usertype=="INTERNALADMIN"  || authCtx.userData.usertype=="OWNER")?'':<Tab eventKey="second" title="App Subscribers" >
                            <Parents orgUuid={orgUuid} sCode={selectedCode} setSelectedCode={setSelectedCode} setKey={setKey}/>
                        </Tab>}
                        <Tab eventKey="third" title="Admin Users">
                             <User orgUuid={orgUuid} sCode={selectedCode} setKey={setKey} setSelectedCode={setSelectedCode}/>
                        </Tab>
                        {authCtx.userData.usertype=="OWNER" && <Tab eventKey="nineth" title="Internal Users">
                             <InternalUsers orgUuid={orgUuid}/>
                        </Tab>}
                        {(authCtx.userData.usertype=="INTERNALADMIN"  || authCtx.userData.usertype=="OWNER")?'':<Tab eventKey="Fourth" title="Verification Codes">
                            <SchoolAuthenticationcode orgUuid={orgUuid} setKey={setKey} setSelectedCode={setSelectedCode}/>
                        </Tab>}


                        {(authCtx.userData.usertype=="INTERNALADMIN"  || authCtx.userData.usertype=="OWNER")?<Tab eventKey="sixth" title="Plans">
                            <Plans orgUuid={orgUuid}/>
                        </Tab>:''}
                        {((authCtx.userData.usertype=="INTERNALADMIN" && JSON.stringify(authCtx.userData.userPermission).includes("2"))  || authCtx.userData.usertype=="OWNER")?<Tab eventKey="seventh" title="Organisations">
                            <Organisation setKey={setKey} setSelectedCode={setSelectedCode}/>
                        </Tab>:''}
                        <Tab eventKey="eighth" title="My Account">
                            <MyAccount orgUuid={orgUuid}/>
                        </Tab>
                        {(authCtx.userData.usertype=="INTERNALADMIN"  || authCtx.userData.usertype=="OWNER")?<Tab eventKey="Fifth" title="Help Message">
                            <HelpText orgUuid={orgUuid}/>
                        </Tab>:''}
                    </Tabs>
                </div>
            </div>

        </div>
    )
}
export default OrgSetting;
