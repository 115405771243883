
import style from './Sidebar.module.css';

const Sidebar = () => {

    return (
    <div className={style.sidebar}>
       <a href="#home"><i className="fa fa-desktop"></i></a>
        <a href="#users"><i className="fa fa-users"></i></a>
        <a href="#services"><i className="fa fa-file-text-o"></i></a>
        <a href="#clients"><i className="fa fa-files-o"></i></a>
        <a href="#download"><i className="fa fa-download"></i></a>
        <a href="#plan"><i className="fa fa-paper-plane"></i></a>
        <a href="#chart"><i className="fa fa-bar-chart"></i></a>
        <a href="#calendar"><i className="fa fa-calendar"></i></a>
        <a href="#ticket"><i className="fa fa-ticket"></i></a>
        <a href="#calendar"><i className="fa fa-comments-o"></i></a>
    </div>);
}

export default Sidebar;