import { useState, useEffect,useContext } from 'react';
import { Modal } from 'react-bootstrap';
import tableStyle from '../Style/TableModel.module.css';
import formStyle from '../Style/modalForm.module.css';
import button from '../Style/ButtonStyle.module.css';
import { USERS_API_BASE_URL } from './../../constants';
import { left } from '@popperjs/core';
import style from './OrgSetting.module.css';
import AuthContext from "../../store/auth-context";
import { getNewToken } from '../../services/notification.services';
import { now } from 'jquery';
function parseJwtToken (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};
let pageSize = 1;
const AddPlan = (props) => {
    const authCtx = useContext(AuthContext);
    const [planID, setPlanID] = useState(0);
    const [checked, setChecked] = useState(false)
    const [planname, setPlanName] = useState("");
    const [description, setDescription] = useState("");
    const [pushNotification, setPushNotification] = useState(false);
    const [editortext, setEditorText] = useState(false);
    const [editEpub, setEditEpub] = useState(false);
    const [editorLink, setEditorLink] = useState(false);
    const [editEmoji, setEditEmoji] = useState(false);
    const [editorAttachment, setEditorAttachment] = useState(false);
    const [editorExternalLink, setEditorExternalLink] = useState(false);

    let orgUuid=authCtx.userData.orgId;
    //console.log(pushNotification)
    useEffect(() => {
                    if(props.planDetail){
                    setPlanID(props.planDetail.id)
                    setPlanName(props.planDetail.name);
                    setDescription(props.planDetail.description);
                    setPushNotification(props.planDetail.CHANNEL_PUSH_NOTIFICATION==0?false:true);
                    setEditorText(props.planDetail.EDITOR_TEXT==0?false:true);
                    setEditEpub(props.planDetail.EDIT_EPUB==0?false:true);
                    setEditorLink(props.planDetail.EDITOR_LINK==0?false:true);
                    setEditEmoji(props.planDetail.EDIT_EMOJI==0?false:true);
                    setEditorAttachment(props.planDetail.EDITOR_ATTACHMENT==0?false:true)
                    setEditorExternalLink(props.planDetail.EDITOR_EXTERNAL_LINK==0?false:true)
                    }
    }, [props.planDetail.name,
        props.planDetail.description,
        props.planDetail.CHANNEL_PUSH_NOTIFICATION,
        props.planDetail.EDITOR_TEXT,
        props.planDetail.EDIT_EPUB,
        props.planDetail.EDITOR_LINK,
        props.planDetail.EDIT_EMOJI,
        props.planDetail.EDITOR_ATTACHMENT,
        props.planDetail.EDITOR_EXTERNAL_LINK,
        props.planDetail.fkPlanId
    ])
    const PlanAdded = (e) => {
        e.preventDefault();
        //  console.log(name)
        //alert(4);
        if(planID==0){
            fetch(`${USERS_API_BASE_URL}/organisation/plan`, {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                   "{\"planname\":\"" + planname + "\",\"description\":\"" + description + "\",\"pushNotification\":\"" + pushNotification + "\",\"editortext\":\"" + editortext + "\",\"editEpub\":\"" + editEpub + "\",\"editorLink\":\"" + editorLink + "\",\"editorExternalLink\":\"" + editorExternalLink + "\",\"editorAttachment\":\"" + editorAttachment + "\",\"editEmoji\":\"" + editEmoji + "\",\"porgid\":\"" + orgUuid + "\"}"
    
            }).then(res=>res.json()).then(data=>{
                
                //alert(data.status==302?data.error:data.msg);props.onAction(false)
    
                if(data.status==302){
                    props.setMessage(data.error)
                }else{
                    props.setMessage(data.msg)
                    if(props.planList){
                        props.setPlanList([...props.planList,{id:data.newPlanData.id, name:data.newPlanData.name,createDate:data.newPlanData.createDate}]);
                    }
                    setPlanID(0)
                    setPlanName('');
                    setDescription('');
                    setPushNotification(false);
                    setEditorText(false);
                    setEditEpub(false);
                    setEditorLink(false);
                    setEditEmoji(false);
                    setEditorAttachment(false)
                    setEditorExternalLink(false)
                    props.setPlanDetail('');
                    props.onAction(false);
                }
                
    
    
    
            
            })
        }else{
            fetch(`${USERS_API_BASE_URL}/organisation/plan/`+planID, {
                "method": "PUT",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                "{\"planname\":\"" + planname + "\",\"description\":\"" + description + "\",\"pushNotification\":\"" + pushNotification + "\",\"editortext\":\"" + editortext + "\",\"editEpub\":\"" + editEpub + "\",\"editorLink\":\"" + editorLink + "\",\"editorExternalLink\":\"" + editorExternalLink + "\",\"editorAttachment\":\"" + editorAttachment + "\",\"editEmoji\":\"" + editEmoji + "\",\"porgid\":\"" + orgUuid + "\"}"
    
            }).then(res=>res.json()).then(data=>{
                
                //alert(data.status==302?data.error:data.msg);props.onAction(false)
    
                if(data.status==302){
                    props.setMessage(data.error)
                }else{
                    props.setMessage(data.msg)
                    if(props.planList){
                        const index = props.planList.findIndex(data => data.id === planID),
                        planData = [...props.planList] // important to create a copy, otherwise you'll modify state outside of setState call
                        planData[index] = {id:data.updatePlanData.id, name:data.updatePlanData.name,createDate:data.updatePlanData.createDate};
                        console.log(planData)
                        props.setPlanList(planData);
                    }
                    setPlanID(0)
                    setPlanName('');
                    setDescription('');
                    setPushNotification(false);
                    setEditorText(false);
                    setEditEpub(false);
                    setEditorLink(false);
                    setEditEmoji(false);
                    setEditorAttachment(false);
                    setEditorExternalLink(false);
                    props.setPlanDetail('');
                    props.onAction(false);
                    let TokenReq =  getNewToken({"refreshToken":authCtx.userData.refreshToken});

                    TokenReq.then(res=> {
                        console.log('gfgfgf',res.data);
                        if(res.data.status==200){

                            authCtx.userData.token = res.data.response.token;
                            authCtx.login(authCtx.userData);
                            console.log("header userData update",(authCtx.userData));
                            let tokenUserData=parseJwtToken(authCtx.userData.token);
                            console.log('updateplantokenData',tokenUserData);
                        }else{
                            //authCtx.logout();
                            //logout(authCtx.token);
                        }

                    }).catch(err=>{
                        console.log(err);
                       // setErrorMsg("Could not get logged in.. Error: "+err)
                            return false;
                        //alert("Could not get logged in.. Error: "+err);
                    });
                }
            
            })
        }
        

    }


    return (
        <>
            <Modal 
                size="md"
                show={props.modalStatus}
                onHide={props.onAction}
                shouldCloseOnOverlayClick={false} 
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className={formStyle.headArea}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h4 className={tableStyle.modalTitle}> Create New Plan</h4>
                    </Modal.Title>
                </div>
                {/* <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14}}>{usermessage}</div> */}
                <form onSubmit={PlanAdded} >
                <div className={formStyle.bodyArea}>
                    
                        <div className="row">
                            <div className="col-ms-12">
                                
                                <div className={formStyle.group}>
                                    <input type="text" placeholder="Name" className={formStyle.formControl} value={planname} onChange={e => setPlanName(e.target.value)} />
                                </div>
                                <div className={formStyle.group}>
                                    <textarea placeholder={description} className={formStyle.formControl}  onChange={e => setDescription(e.target.value)}>{description}</textarea>
                                    
                                </div>
                                <div className={formStyle.group}>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_1`} onClick={e => setPushNotification(!pushNotification)} checked={pushNotification} /><span style={{'padding-left':'5px'}}>PUSH_NOTIFICATION</span></div>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_2`} onClick={e => setEditorText(!editortext)} checked={editortext}/><span style={{'padding-left':'5px'}}>EDITOR_TEXT</span></div>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_3`} onClick={e => setEditEpub(!editEpub)} checked={editEpub}/><span style={{'padding-left':'5px'}}>EDIT_EPUB</span></div>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_4`} onClick={e => setEditorLink(!editorLink)} checked={editorLink}/><span style={{'padding-left':'5px'}}>EDITOR_LINK</span></div>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_5`} onClick={e => setEditEmoji(!editEmoji)} checked={editEmoji}/><span style={{'padding-left':'5px'}}>EDIT_EMOJI</span></div>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_6`} onClick={e => setEditorAttachment(!editorAttachment)} checked={editorAttachment}/><span style={{'padding-left':'5px'}}>EDITOR_ATTACHMENT</span></div>
                                    <div style={{'float':left,'width':'50%'}}><input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" 
          id={`flexSwitchCheckChecked_7`} onClick={e => setEditorExternalLink(!editorExternalLink)} checked={editorExternalLink}/><span style={{'padding-left':'5px'}}>EDITOR_EXTERNAL_LINK</span></div>
                                </div>
                                
                                
                            </div>
                        </div>
                    
                </div>
                <div className={formStyle.FooterArea}>
                    <button type="button" onClick={props.onAction} className={button.btn + ' ' + button.brownButton}>Cancel</button>
                    <button type="submit" className={button.btn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Save</button>  
                </div>
                </form>
            </Modal>
        </>
    );
}

export default AddPlan;