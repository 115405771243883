import {useState, useEffect, useRef, useContext} from 'react';

import {USERS_API_BASE_URL} from '../../constants';
import AuthContext from "../../store/auth-context";
import { getUser } from '../../services/notification.services';
import button from '../Style/ButtonStyle.module.css';
import ChangePassword from './ChangePassword';
import UploadProfile from './UploadProfile';
const MyAccount = (props) => {
   
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [userDetail,setUserDetail] = useState("")
    const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);
    const [isRecipientModalOpenProfile, setIsRecipientModalOpenProfile] = useState(false);
    const [message, setMessage] = useState('');
    const [profileImg, setProfileImg] = useState('');
    console.log('detail',authCtx)

    const recipientModalHendler = () => {

        setIsRecipientModalOpen((prevState) => !prevState);
    }
    const recipientModalHendlerProfile = () => {

        setIsRecipientModalOpenProfile((prevState) => !prevState);
    }
    useEffect(() => {
        getUser(authCtx.userData.uuid,authCtx.token)
            .then((response) => {
                console.log('userRes',response)
                setIsLoading(false);
                setUserDetail(response.data);
                setProfileImg(response.data.user.thumbImage)
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
    }, [authCtx])
    
     console.log(userDetail)
    return (
        <div className="">
        {isRecipientModalOpen && <ChangePassword
            modalStatus={isRecipientModalOpen}
            onAction={recipientModalHendler}
            setMessage={setMessage}
            userDetail={userDetail}
        />}
        {isRecipientModalOpenProfile && <UploadProfile
            modalStatus={isRecipientModalOpenProfile}
            onAction={recipientModalHendlerProfile}
            setMessage={setMessage}
            userDetail={userDetail}
            setProfileImg={setProfileImg}
        />}
        <div className="table-design">
        <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14}}>{message}</div>
            
            {userDetail && 
                <div className="col-sm-12 p-2 ">
                <div className={button.divFlex}>
                    <p>{authCtx.uProfileImage?<img src={authCtx.uProfileImage} alt={authCtx.uProfileImage} /> :<img class="userImg" src="/static/media/gray-user.e844667e.png"></img>}</p>
                    <div>
                        <p>{userDetail.user.firstName} {userDetail.user.lastName}</p>
                        <p>{userDetail.user.email}</p>
                        <p>{userDetail.user.phoneNumber}</p>
                        <button type="submit" onClick={recipientModalHendler} className={button.btn + ' ' + button.blueButton}>Change Password</button>
            <button type="submit" onClick={recipientModalHendlerProfile} className={button.btn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Upload Profile Image</button>
                    </div>
                </div>
                </div>
            }
        </div>
        </div>
    )
}
export default MyAccount
