import React, { useState, useContext, Suspense, useEffect } from 'react';
import {Link, useNavigate,useLocation} from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import style from './Header.module.css';
import logo from '../../../assets/SchoolLink-logo-web.png';
import grayUser from '../../../assets/gray-user.png';
import AuthContext from "../../../store/auth-context";
import {login,logout,getNewToken,getOrganisation,loginepub} from '../../../services/notification.services'
import '../../Style/customDropdown.css'


function parseJwtToken (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};
const SchoolAuthenticationCodeGenerator = React.lazy(() => import('../../SchoolAuthenticationCodeGenerator/SchoolAuthenticationCodeGenerator'));

const Header = (props) => {
    console.log('headerProps',props)
    const authCtx = useContext(AuthContext);
    const [userName,setUserName] = useState();
    const [errorMsg, setErrorMsg]=useState('');
    const [ePublisherMenu , setePublisherMenu] = useState(false)
    const [schoolLinkMenu, setSchoolLinkMenu] = useState(false)
    const [userProfileImage,setUserprofileImage] = useState(authCtx.uProfileImage);
    const [isAuthenticationCodeModalOpen, setIsAuthenticationCodeModalOpen] = useState(false);
    const navigate = useNavigate();
    const authenticationCodeHandler = () => {
        setIsAuthenticationCodeModalOpen(preState => !preState);
    }

    //const {} = useContext()
    const search = useLocation().search;
    const epubtoken = new URLSearchParams(search).get('epubtoken');
    //alert(1);
    //console.log('epubtoken-heaader=======',epubtoken);
    // if(epubtoken!=null){
    //     alert(1);
    //     //return false;
    //         authCtx.logout();
    //         logout(authCtx.token);
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('userData');
    // }

    const logoutHandler = () => {
        //alert(2);
        if(authCtx.userData && authCtx.userData.superAdminId && authCtx.userData.superAdminId=="39"){
            authCtx.logout();
            logout(authCtx.userData.superAdminToken);
            localStorage.removeItem('super-admin-name');
            localStorage.removeItem('super-admin-token');
            localStorage.removeItem('super-admin');
            localStorage.clear();
            navigate('/login');
        }else{
            localStorage.clear();
            authCtx.logout();
            logout(authCtx.token);
            navigate('/login');
            
        }
        // navigate to login page


    }

    const logoutImpersonate = ()=>{
        //authCtx.logout();
        let loginReq =  login({"email":"kanch@austnews.com.au","password":"12345678"});
        loginReq.then(res=> {
            console.log(res)
            let udata = res.data.response;
            console.log("Response ", udata);
            if(udata.token || res.data.response!=''){
                console.log("Token Received ",udata.token);
                let userId=udata.userId;
                let user_Id=udata.user_id;
                let userToken=udata.token;
                let tokenUserData=parseJwtToken(udata.token);
                console.log('tokenData',tokenUserData);
                console.log('currenttime',Date.now() / 1000)
                let orgId=tokenUserData.user.org;
                let uid=tokenUserData.user.id;
                let firstName=udata.firstName;
                let usertype=udata.usertype;
                let lastName=udata.lastName;
                let thumbImage=udata.thumbImage;
                let orgDetail=getOrganisation(orgId,userToken);
                console.log('hitesh',orgDetail)
                orgDetail.then(res=>{
                    if (res.data.status!=400) {
                        let orgName = res.data.name;
                        let orgStatus = res.data.status;
                        let orgWisitiaId = res.data.org_wistia_project_id;
                        let orgLogoUrl = res.data.logoUrl;
                        let channelPushNotification =res.data.CHANNEL_PUSH_NOTIFICATION;
                        let editorAttachment =res.data.EDITOR_ATTACHMENT;
                        let editorExternalLink =res.data.EDITOR_EXTERNAL_LINK;
                        let editorLink =res.data.EDITOR_LINK;
                        let editorText =res.data.EDITOR_TEXT;
                        let editEmoji =res.data.EDIT_EMOJI;
                        let editEpub =res.data.EDIT_EPUB;
                        const userData = {
                            uuid: userId,
                            user_id: user_Id,
                            name: firstName + ' ' + lastName,
                            uid: uid,
                            usertype:usertype,
                            orgName: orgName,
                            orgLogoUrl: orgLogoUrl,
                            token: userToken,
                            refreshToken:udata.refreshToken,
                            orgId:orgId,
                            orgWisitiaID:orgWisitiaId,
                            epubOrgId:res.data.epubOrgId,
                            thumbImage:thumbImage,
                            CHANNEL_PUSH_NOTIFICATION:channelPushNotification,
                            EDITOR_ATTACHMENT:editorAttachment,
                            EDITOR_EXTERNAL_LINK:editorExternalLink,
                            EDITOR_LINK:editorLink,
                            EDITOR_TEXT:editorText,
                            EDIT_EMOJI:editEmoji,
                            EDIT_EPUB:editEpub
                        }
                        authCtx.login(userData);
                        //navigate.replace('/notifications');
                        if(usertype==='OWNER'){
                            localStorage.setItem('super-admin', user_Id);
                            localStorage.setItem('super-admin-token', userToken);
                            localStorage.setItem('super-admin-name', firstName + ' ' + lastName);
                            navigate('/Organisation/1#users');
                            //<Redirect to='/Organisation/1#users'/>
                            //window.location.href='/Organisation/1#users';
                        }else{
                            navigate('/notifications');
                        }

                    } else {
                        alert("Could not get organisation this user belogs to. "+res.data.error);
                        return false;
                    }
                }).catch(err=>{
                    alert("Could not retrieve organisation. Error: "+err);
                });
            }else{
                //alert("Could not get logged in. "+udata.data.error);
                setErrorMsg(res.data.error)
                return false;
            }
        }).catch(err=>{
            //console.log(err);
            setErrorMsg("Could not get logged in.. Error: "+err)
                return false;
            //alert("Could not get logged in.. Error: "+err);
        });
    }
    // const location = useLocation();
    // if (!authCtx.isLoggedIn) {
    //     navigate('/login');
    // }else if (location.pathname === '/') {
    //     navigate('/notifications');
    // }else if(authCtx.userData==null){
    //     window.location.reload();
    // }


    //console.log("header token",authCtx.token);
    //console.log("header userData",(authCtx.userData));
    //console.log("header token",(authCtx.token));

    //const arrToken = parseJwtToken(authCtx.token);
    //console.log("header token array",arrToken);
    //console.log("User Data", authCtx.userData);
   //console.log(res.headers)

    // if(arrToken.exp * 1000 < Date.now()) {
    //    authCtx.logout();
    //     logout(authCtx.token);
    //     setErrorMsg("session timeout");
    //            // return false;
    //     console.log('Token expire');
    //     let TokenReq =  getNewToken({"refreshToken":authCtx.userData.refreshToken});

    //     TokenReq.then(res=> {
    //         console.log('gfgfgf',res.data);
    //         if(res.data.status==200){

    //             authCtx.userData.token = res.data.response.token;
    //             authCtx.login(authCtx.userData);
    //             //console.log("header userData update",(authCtx.userData));
    //         }else{
    //             authCtx.logout();
    //             logout(authCtx.token);
    //         }

    //     }).catch(err=>{
    //         //console.log(err);
    //         setErrorMsg("Could not get logged in.. Error: "+err)
    //             return false;
    //         //alert("Could not get logged in.. Error: "+err);
    //     });

    // }

    useEffect(()=>{
        console.log('img=====',authCtx.uProfileImage);
        //alert(1);
       // alert(authCtx.uProfileImage);
        if(authCtx.userData && authCtx.userData.thumbImage!==null){
            authCtx.setUProfileImage(authCtx.userData.thumbImage); 
        }
        
    },[authCtx.userData])

    return (<> {isAuthenticationCodeModalOpen &&
        <Suspense fallback=''>
            <SchoolAuthenticationCodeGenerator onclick={authenticationCodeHandler} />
        </Suspense>
    }
        <div className={authCtx.userData ? ( authCtx.userData.superAdminName ? ( 'superAdminSelectOrg' ) : '' ) : ''}>
        <header className={'d-flex flex-column flex-md-row align-items-center  box-shadow ' + style.Header}>
        <div className={authCtx.userData ? ( authCtx.userData.superAdminName ? ( 'orgUserArea' ) : '' ) : ''}>
                    {authCtx.userData ? ( authCtx.userData.superAdminName ? (
                        <span>( Impersonating as {authCtx.userData?authCtx.userData.name:''} {authCtx.userData?(authCtx.userData.superAdminName?(") "):''):""}
                        {authCtx.userData?authCtx.userData.orgName:''}</span>

                        ) : '' ) : ''}

            </div>
            <div className={"row " + style.HeaderboxShadow} >
                <div className={'col-md-6 ' + style.logo}>
                    <a href={authCtx.userData && authCtx.userData.user_id!="39"?'/notifications':'/Organisation/1#users'}><img src={logo} alt="SchoolLink" className={style.logoImg}  /></a>

                </div>

                <Dropdown className={'col-md-6 ' + style.rightMenu}>
                <div className={style.menuHead}>
                    {authCtx.userData ? ( authCtx.userData.superAdminName ? (
                        <a className={style.userLogout}  href='#' onClick={()=>logoutImpersonate()}>Go Back</a>
                        ) : '' ) : ''}
                    <Dropdown.Toggle variant="userBtn" id="dropdown-basic"
                    className={authCtx.userData ? ( authCtx.userData.superAdminName ? ( 'usperAdmin' ) : '' ) : ''}
                    >
                    {/* {authCtx.userData?authCtx.userData.name:''} */}

                    {authCtx.userData?(
                        authCtx.userData.superAdminName ?(
                            <span className='userAlign'>
                                <span>
                                    {authCtx.userData.superAdminName}
                                </span> <br/>
                                <span className='otherUser'>( Impersonating as</span>  &nbsp;
                            </span>):''):''}
                        <span className='orgUser'>{authCtx.userData?authCtx.userData.name:''} {authCtx.userData?(authCtx.userData.superAdminName?(")"):''):""}
                         &nbsp;<span className='selectUser'>{authCtx.userData?authCtx.userData.orgName:''}</span>
                         </span>


                        {/* <img className='userImg'
                             src={(authCtx.userData && authCtx.userData.thumbImage!=null ?authCtx.userData.thumbImage:'https://static.myschoollink.app/common/profile-icon-login-head-icon-vector.png')}/> */}

                             <img className='userImg' alt='user image' src={authCtx.uProfileImage} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropBox userArea">

                        <Dropdown.Item
                            className='child-menu'
                            onMouseEnter={()=> setSchoolLinkMenu(true)}
                            onMouseLeave={()=> setSchoolLinkMenu(false)}
                            title='ePublisher config'
                        >
                            <span><i className="fa fa-user"></i></span> ePublisher config <span className='menuArrow'><i className='icon icon-arrow-right3'></i></span>
                            {schoolLinkMenu && (
                                <div className='subMenu'>
                                    <div className='menuBg'>
                                        <Dropdown.Item href="#" title='Manage user accounts'><span><i className="fa fa-users"></i></span> Manage user accounts</Dropdown.Item>
                                        <Dropdown.Item href="#" title='Billing'><span><i className="icon icon-credit-card2"></i></span> Billing</Dropdown.Item>
                                        <Dropdown.Item href="#" title='All Users'><span><i className="icon icon-key2"></i></span> All Users</Dropdown.Item>
                                    </div>
                                </div>
                            )}
                        </Dropdown.Item>

                        <Dropdown.Item
                            className='child-menu'
                            onMouseEnter={()=> setePublisherMenu(true)}
                            onMouseLeave={()=> setePublisherMenu(false)}
                            title='SchoolLink config'
                        >
                            <span><i className="fa fa-users"></i></span> SchoolLink config <span className='menuArrow'><i className='icon icon-arrow-right3'></i></span>
                            {ePublisherMenu && (
                                <div className='subMenu'>
                                    <div className='menuBg'>
                                         <Dropdown.Item href="#" title='My account'><span><i className="fa fa-user"></i></span> My account</Dropdown.Item>
                                        <Dropdown.Item href="#" title='Manage user accounts' as={Link} to="/Organisation/1#users"><span><i className="fa fa-users"></i></span> Account Settings & Users</Dropdown.Item>
                                        <Dropdown.Item href="#" title='Modify School Contact Details' as={Link}  to="/ModifyDetails" ><span><i className="icon icon-phone-plus"></i></span> Modify School Contact Details</Dropdown.Item>
                                         <Dropdown.Item href="#" onClick={authenticationCodeHandler}><span><i className="icon icon-key2"></i></span> App authentication code</Dropdown.Item>
                                        <Dropdown.Item href="#" title='Feature configuration'><span><i className="icon icon-cog2"></i></span> Feature configuration</Dropdown.Item>
                                        <Dropdown.Item href="#" title='Billing'><span><i className="icon icon-credit-card2"></i></span> Billing</Dropdown.Item>
                                    </div>
                                </div>
                            )}
                        </Dropdown.Item>
                        {/* <Dropdown.Item as={Link} to="/users" ><span><i className="fa fa-users"></i></span> Manage User Accounts</Dropdown.Item> */}
                        {/* <Dropdown.Item href="#" ><span><i className="fa fa-users"></i></span> Modify School Contact Details</Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={authenticationCodeHandler} ><span><i className="icon icon-key2"></i></span> App Authentication Code</Dropdown.Item> */}
                        {/* <Dropdown.Item as={Link} to="/Organisation/1#users"><span><i className="icon icon-cog3"></i></span>  Settings & Billing </Dropdown.Item> */}
                        <Dropdown.Item title='Sign Out' onClick={logoutHandler}><span><i className="fa fa-power-off"></i></span> Sign Out</Dropdown.Item>
                    </Dropdown.Menu>
                    </div>
                </Dropdown>

            </div>
        </header>
        </div>
    </>);
}

export default Header;
