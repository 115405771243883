import React, { useState,useEffect,useContext } from "react";
import { render } from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
//import arrayMove1 from "array-move";
import style from './Modify.module.css'
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from '../../../src/store/auth-context';
import { getOrganisationContact,getOrganisationLink,getOrganisationDetailsByID,getOrganisationContactDetail } from '../../services/Organisation.js';




const SortableItem = SortableElement(({ value,indexval,id,phnStatus,checkboxVal,handleClick,editContactHandler,onSortEnd,addNumberModal }) => (

  
   
    <li className={style.dragStyle} tabIndex={indexval} style={{position:'relative'}} >
      <input type="checkbox" className={style.hiddenBtn} onClick={(e) => editContactHandler(e,id)} />
      <span><i className="icon icon-grid2"></i></span> {value} 
      <div className={"form-check form-switch " + style.switchBtn}>
      <input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" defaultChecked={phnStatus}
          onChange={() => handleClick(id,phnStatus)}  id={`flexSwitchCheckChecked_${id}`} checked={phnStatus=='ACTIVE'?true:false}/>
      
    </div>
    {/* <div><span><i className="icon icon-pencil7" onClick={(e) => editContactHandler(e,id)}></i></span></div> */}
    
    </li>
  ));
  
  const SortableList = SortableContainer(({ items,checkedval,handleClick,editContactHandler,onSortEnd,addNumberModal }) => {
    return (
      <ul className={style.dragList}>
        {items.map((value1, index) => (
          <SortableItem key={`item-${value1.org_contact}`} 
          index={value1.displayOrder} 
          indexval={value1.displayOrder} 
          value={value1.title} 
          id={value1.id} 
          phnStatus={value1.phoneStatus} 
          checkboxVal={checkedval} 
          handleClick={handleClick} 
          onSortEnd={onSortEnd}
          editContactHandler={editContactHandler}
          addNumberModal ={addNumberModal} />
        ))}
      </ul>
    );
  });

 
  const SortableComponent = (props) =>{
  
    
    //const [itemsData, setItemData] = useState(props.OrganisationContactList)
    let OrganisationContactList = props.OrganisationContactList;
    const authCtx = useContext(AuthContext);

    let orgUuid=authCtx.userData.orgId;

    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [checked, setChecked] = useState('')
     const handleClick = (itemID,itemStatus) => {
       //console.log("itenid",itemID+'========='+itemStatus)

       fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-contact-status`, {
        "method": "PATCH",
        "headers": {
            "Content-Type": "application/json"
        },
        "body":
            "{\"phnStatus\":\"" + itemStatus + "\",\"itemID\":\"" + itemID + "\"}"

    }).then(res=>res.json()).then(data=>{
      //alert(data.status==302?data.error:data.msg);
        //console.log('hitesh',data.updateorgContactData);
        //setItemData(data.updateorgContactData);
        props.updateMobileContactData(data.updateorgContactData)

        
    })
       //setChecked(!checked)
     } 
    

    const editContactHandler = async (e,itemID) => {
      
      const phoneData = await getOrganisationContactDetail(authCtx.token,orgUuid,itemID)
      //console.log('editdata',phoneData);
      props.setContactDetail({id:phoneData.data.id,title:phoneData.data.title,org_contact:phoneData.data.org_contact,titleColour:phoneData.data.titleColour,displayOrder:phoneData.data.displayOrder,phoneStatus:phoneData.data.phoneStatus})
      //console.log("itenid",itemID)
      //props.ToggleNumberPopup()
    } 
    
    
    
    
     
    useEffect(() => {

        const orgContact =  getOrganisationContact(authCtx.token,orgUuid).then((res) => {
                
            //setItemData(res.data);
            // const allData = res.data.map((mob)=> {
            //     return mob.org_contact
            // } );
            
            // setAddNumber(allData);
        })
        .catch(error => {
            console.log(error);
        })
    }, []); 
   
    const onSwitchAction = () => {
        
      setIsSwitchOn(!isSwitchOn);
    };

   const onSortEnd = ({ oldIndex, newIndex }) => {
       // alert(oldIndex+'=========='+newIndex);

       if(oldIndex!=newIndex){
       console.log(oldIndex+'======---===='+newIndex)
       fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-org-contact-order`, {
        "method": "PATCH",
        "headers": {
            "Content-Type": "application/json"
        },
        "body":
            "{\"oildItemIndex\":\"" + oldIndex + "\",\"newItemIndex\":\"" + newIndex + "\"}"

    }).then(res=>res.json()).then(data=>{
      //alert(data.status==302?data.error:data.msg);
        //console.log('hitesh',data.updateorgContactData);
        //setItemData(data.updateorgContactData);
        props.updateMobileContactData(data.updateorgContactData)

        
    })

      const itemsCopy = OrganisationContactList;
      const oldIndexBackup = itemsCopy[oldIndex];
      itemsCopy[oldIndex] = itemsCopy[newIndex];
      itemsCopy[newIndex] = oldIndexBackup;
  }
  
    //   this.setState(({ items }) => ({
    //     items: itemsCopy
    //   }));
      //setItemData(itemsCopy)
    };
    return(
     //console.log('itemsdetailhh',this.props.itemsdata)
     <SortableList  
      items={OrganisationContactList} 
      onSortEnd={onSortEnd} 
      onSwitchAction={onSwitchAction} 
      handleClick={handleClick} 
      editContactHandler={editContactHandler} 
      checkedval={checked} 
      addNumberModal={props.ToggleNumberPopup} />
    )
  }

  export default SortableComponent;
