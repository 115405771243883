import style from "../../Style/EditorStyle.module.css";

const EpubContentBlock=(props)=>{
    return(
        <div className={style.customContentBlock+" "}  style={{textAlign: "left", border: "0px solid #F7F7F7", borderRadius: "5px", padding: "0px!important",width:"276px",display:'flex',flexDirection:'column'}}>
            {props.inEditMode && <div className={style.rightAlign}><a style={{cursor: "pointer",fontSize:"12px",fontStyle:"italic",color:"rgb(142, 142, 147)"}} onClick={props.deleteAll}>Delete All</a></div>}
            <div className={style.fileAttachView} style={{width:"282.89px",background:'#F1F2F3',borderRadius:'10px',textAlign:'left',position:'relative',margin:'7px 0',}}>
                <div className={style.fileAttachMargin} style={{width:"100%",padding:"9px 5px 8px"}}>
                    <a href={props.link} target="_blank" style={{textDecoration:'none',color:'#8E8E93',fontSize:'12px',lineHeight:'normal',}}>
                        <div style={{display:"flex",flexDirection:"row",width:"auto",padding:"0",alignItems:'center'}} className={style.fileAttachView}>
                            {props.svgIcon}
                            <img src={props.imgIcon} className={style.hideIcon} style={{padding:'12px 0px 11px 13px',width:'30px',marginRight:'4px'}} />
                            {!props.svgIcon && <i className={props.iconClass}></i>}
                            <div style={{padding:"0",}}><p style={{display:'block',color:'#1B1B1B',margin:'0',fontWeight:'600',fontSize:'12px',lineHeight:'normal',}}>{props.titleName}</p>
                                <span style={{color:"#8E8E93",fontSize:"12px",display:'block',marginTop:'3px'}}>{props.authname}</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default EpubContentBlock;
