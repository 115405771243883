
import { MultiSelect } from "react-multi-select-component";
import {useEffect} from "react";


const CategoryTags = (props) => {
    useEffect(() => {
        console.log("props.selectedCategoryTags",props.selectedCategoryTags);
    });

    const { isAllSchoolLinkAppUsersSelected } = props;

    const setSelectedHandler = (data) => {
        props.onChanged(data, props.categoryId);
    }

    let selectedTags;

    const find = props.selectedCategoryTags.find(t => t[props.categoryId])

    if (find !== undefined) {
        selectedTags = find[props.categoryId];
    } else {
        selectedTags = [];
    }
    return (<MultiSelect
        options={props.options}
        tabIndex="0"
        value={selectedTags}
        onChange={setSelectedHandler}
        disabled={isAllSchoolLinkAppUsersSelected}
        labelledBy="Select" />);
}

export default CategoryTags;
