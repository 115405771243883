import { Button } from 'bootstrap';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import {Link, Redirect, useNavigate} from 'react-router-dom';
import { USERS_API_BASE_URL } from '../../constants';
import { addNewTag } from '../../services/notification.services';
import AuthContext from "../../store/auth-context";
import '../Style/Table-style.css';
import fontSize from '../Style/FontSize.module.css';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import common from '../Style/common.module.css';
import tableStyle from '../Style/TableModel.module.css';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
const CategoryTagsItem = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const authCtx = useContext(AuthContext);
    const node = useRef();
    const [isVisible, setState] = useState(false);
    const [Tag111, setNewTag] = useState([]);
    //const [NewTag, setNewTag] = useState([]);
    const navigate = useNavigate();
    const handleClick = ({ target }) => {
        if (node.current.contains(target)) {
            // inside click
            setState(true);
            return;

        } else {
            setState(false);
            return;
        }
        // outside click

    };
    useEffect(() => {

        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const NewTagAdded = (e) => {
        e.preventDefault();
        //alert(Tag111)
        //console.log(Tag111)
        if (Tag111 != '') {
            let body = "{\"name\":\"" + Tag111 + "\",\"fkOrgID\":\"2\",\"description\":\"sdsd\",\"category\":\"" + props.item.id + "\"}";
            addNewTag(body, authCtx.token).then(res => res.json()).then(data => {
                alert(data.status == 302 ? data.error : data.msg);
                setNewTag("");
                props.addCategoryTaghandler(props.item.id, Tag111)
                setState(false)

            });
        }
    }
    const openModal = ()=>{
        setIsOpen(true)
    }
    const modalClose = ()=>{
        setIsOpen(false)
    }
    const handleDeleteCategory = (id) => {
        //if (window.confirm("Are you sure want to delete record?")) {
           //const items = props.item.filter(cat => cat.id !== id);
            fetch(`${USERS_API_BASE_URL}/category/`+id, {
            "method": "DELETE",
            "headers": {
                "Content-Type": "application/json",
                'Authorization': authCtx.token,
            },
            "body":""

            }).then(res=>res.json()).then(data=>{
                // if(data.status==302){
                //     setMessage(data.error)
                // }else{
                //     setMessage(data.msg)
                //     setUserList(items);
                // }

                toast.success(data.status == 302 ? data.error : data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });
                console.log(toast.success)
                //alert(data.status == 302 ? data.error : data.msg);
                props.setCategorytag(data.categoryData);
                setIsOpen(false)
            })
       // }
      };

      const filterByContact = (catname) =>{
        let arr = catname.split("&&")
        //console.log('code==========',code);
        //<Redirect to={"/Organisation/1#parents?"+code} />
        //histy.replace("/Organisation/1#parents?"+code);
        navigate("/Organisation/1#parents");
        props.setKey('second');
        props.setSelectedCode(catname)
        //window.location.href="/Organisation/1#parents?vcode="+code;

    }
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            modalClose();
        }
      }, [modalClose]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);
    return (<tr>
        <td>{props.item.name}</td>
        <td >
            <div className="tooltipContainer" ref={node} onClick={() => setState(isVisible)}>
                <div data-testid="tooltip-placeholder" className='contactTag'>{props.item.tags.length}</div>
                {isVisible && (
                    <div className="tooltipContent"
                        data-testid="tooltip-content"

                    >
                        <span className="arrow"></span>
                        <form className="p-2" >
                            <div className="newTagForm">
                                <span className="tagIcon"><i className="fa fa-tag" aria-hidden="true"></i></span>
                                <input type="text"
                                    className="form-control p-2"
                                    placeholder="New Tag"
                                    value={Tag111}
                                    onChange={e => setNewTag(e.target.value)} />
                                <button type="submit" className="tagSubmit" onClick={NewTagAdded}><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                            </div>
                            {
                                props.item.tags.map((tag) => {
                                    return (
                                        <p>{tag.name}</p>
                                    )
                                })
                            }
                        </form>
                    </div>
                )}
            </div>
        </td>
        <td><a onClick={()=>filterByContact(props.item.name+'&&'+props.item.id)} className='contactTag'>{props.item.UserCount}</a></td>
        <td>-</td>
        <td>Active</td>
        <td>
            <span className='actionStyleButton'><a href='javascript:void(0)' title='Delete' onClick={openModal}><i className="fa fa-trash-o"></i></a></span>
        </td>
        <Modal isOpen={modalIsOpen} className="alertBgContainer" contentLabel="Example Modal" >
            <div className='alertBox'>
                <h4 className={tableStyle.alertTitle}>Alert</h4>
                <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you sure want to delete this  Contact Group?</p>
                <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}> <b className={font.fontBold}>When deleted, it will no longer be visible in the SchoolLink app's Contact group subscriptions.</b></p>
                <div className='bottomArea'>
                    <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                    <button onClick={i => handleDeleteCategory(props.item.id)} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>
                </div>
            </div>
        </Modal>
    </tr>);
}
export default CategoryTagsItem;
