import axios from "axios";
import {USERS_API_BASE_URL} from "../constants";
//const BASE_URL = 'http://apidocs.dev-n-test.com/'; // mock server
const BASE_URL = 'http://api.dev-n-test.com/';
export const getActiveUserCount = (orgId,code) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/${orgId}/${code}/code-active-user`);
}
export const getAuthenticationCode = (orgId,authToken) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/${orgId}/list-all-codes`,{headers:{'Authorization':authToken}});
}
export const getDeactiveAuthenticationCode = (orgId,authToken) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/${orgId}/list-all-deaactive-codes`,{headers:{'Authorization':authToken}});
}
export const generateNewCode = (orgId,authToken) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/${orgId}/generate-code`,{headers:{'Authorization':authToken}});
}

