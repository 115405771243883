import {useContext, useState, useEffect, useRef} from 'react';
import {useNavigate, useLocation, Navigate} from 'react-router-dom';
import logo from '../../assets/SchoolLink-logo-web.png';
import './Login.css';
import AuthContext from '../../store/auth-context';
import {login, logout, getOrganisation, loginepub} from '../../services/notification.services';
import { redirect } from "react-router-dom";
import Loader from "../UI/Loader/Loader";
import {USERS_API_BASE_URL} from "../../constants";
function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

const Login = () => {

    const [email, setEmail] = useState('amy_mcdonald@sink.sendgrid.net');
    const [pass, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const search = useLocation().search;
    const epubtoken = new URLSearchParams(search).get('epubtoken');
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showResetPasswordEmailError, setShowResetPasswordEmailError] = useState(false);
    const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useState('');
    const [showCreateNewPasswordForm, setShowCreateNewPasswordForm] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const emailFieldRef=useRef();
    const otpFieldRef=useRef();
    const [otp, setOtp] = useState('');
    const uuidFieldRef=useRef();
    const passwordFieldRef=useRef();
    const confirmPasswordFieldRef=useRef();

    const [uuid, setUuid] = useState('');
    //alert(1);
    console.log('epubtoken=======', epubtoken);

    useEffect(() => {
        if(authCtx.isLoggedIn){
            navigate('/notifications');
        }
    });

    const loginHandler = (e) => {
        // let user_Id = 39;
        // if(user_Id === 39) {
        //     alert(1);
        //     navigate('/Organisation/1#users');
        //     return false;
        //     // return <Navigate to='/Organisation/1#users'/>
        // }
        e.preventDefault()
        let loginReq = login({"email": email, "password": pass});
        loginReq.then(res => {
            console.log(res)
            let udata = res.data.response;
            console.log("Response ", udata);
            if (udata.token || res.data.response != '') {
                console.log("Token Received ", udata.token);
                let userId = udata.userId;
                let user_Id = udata.user_id;
                let userToken = udata.token;
                let tokenUserData = parseJwt(udata.token);
                console.log('tokenData', tokenUserData);
                console.log('currenttime', Date.now() / 1000)
                let orgId = tokenUserData.user.org;
                let uid = tokenUserData.user.id;
                let firstName = udata.firstName;
                let usertype = udata.usertype;
                let lastName = udata.lastName;
                let thumbImage = udata.thumbImage;
                let userpermission = udata.userPermission;
                let orgDetail = getOrganisation(orgId, userToken);
                console.log('hitesh', orgDetail)
                orgDetail.then(res => {
                    if (res.data.status != 400) {
                        let orgName = res.data.name;
                        let orgStatus = res.data.status;
                        let orgWisitiaId = res.data.org_wistia_project_id;
                        let orgLogoUrl = res.data.logoUrl;
                        let channelPushNotification = res.data.CHANNEL_PUSH_NOTIFICATION;
                        let editorAttachment = res.data.EDITOR_ATTACHMENT;
                        let editorExternalLink = res.data.EDITOR_EXTERNAL_LINK;
                        let editorLink = res.data.EDITOR_LINK;
                        let editorText = res.data.EDITOR_TEXT;
                        let editEmoji = res.data.EDIT_EMOJI;
                        let editEpub = res.data.EDIT_EPUB;
                        const userData = {
                            uuid: userId,
                            user_id: user_Id,
                            name: firstName + ' ' + lastName,
                            uid: uid,
                            usertype: usertype,
                            orgName: orgName,
                            orgLogoUrl: orgLogoUrl,
                            userPermission: userpermission,
                            token: userToken,
                            refreshToken: udata.refreshToken,
                            orgId: orgId,
                            orgWisitiaID: orgWisitiaId,
                            epubOrgId: res.data.epubOrgId,
                            thumbImage: thumbImage,
                            CHANNEL_PUSH_NOTIFICATION: channelPushNotification,
                            EDITOR_ATTACHMENT: editorAttachment,
                            EDITOR_EXTERNAL_LINK: editorExternalLink,
                            EDITOR_LINK: editorLink,
                            EDITOR_TEXT: editorText,
                            EDIT_EMOJI: editEmoji,
                            EDIT_EPUB: editEpub
                        }

                        authCtx.login(userData);
                        authCtx.setUProfileImage(userData.thumbImage)
                        //histort.replace('/notifications');

                        if (usertype === 'OWNER' || usertype === 'INTERNALADMIN') {
                            localStorage.setItem('super-admin', user_Id);
                            localStorage.setItem('super-admin-token', userToken);
                            localStorage.setItem('super-admin-name', firstName + ' ' + lastName);
                            navigate('/Organisation/1#users');
                        } else {
                            navigate('/notifications');
                        }


                    } else {
                        alert("Could not get organisation this user belogs to. " + res.data.error);
                        return false;
                    }
                }).catch(err => {
                    alert("Could not retrieve organisation. Error: " + err);
                });
            } else {
                //alert("Could not get logged in. "+udata.data.error);
                setErrorMsg(res.data.error)
                return false;
            }
        }).catch(err => {
            //console.log(err);
            setErrorMsg("Network Error");
            return false;
            //alert("Could not get logged in.. Error: "+err);
        });
        //console.log("Login completed");
        // return;

        // Redirect to notification area

    }
    // if(epubtoken!=null){
    //     alert(1);
    //     return false;
    //         //authCtx.logout();
    //         logout(authCtx.token);
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('userData');
    // }
    useEffect(async () => {
        if (epubtoken !== null) {
           // alert(4);
            // console.log('object====', epubtoken);
            // authCtx.logout();
            // logout(authCtx.token);
            // localStorage.removeItem('token');
            // localStorage.removeItem('userData');

            let loginReq = loginepub({"epubtoken": epubtoken});
            loginReq.then(res => {
                console.log(res)
                let udata = res.data.response;
                console.log("Response ", udata);
                if (udata.token || res.data.response != '') {
                    console.log("Token Received ", udata.token);
                    let userId = udata.userId;
                    let user_Id = udata.user_id;
                    let userToken = udata.token;
                    let tokenUserData = parseJwt(udata.token);
                    console.log('tokenData', tokenUserData);
                    console.log('currenttime', Date.now() / 1000)
                    let orgId = tokenUserData.user.org;
                    let uid = tokenUserData.user.id;
                    let firstName = udata.firstName;
                    let usertype = udata.usertype;
                    let lastName = udata.lastName;
                    let thumbImage = udata.thumbImage;
                    let orgDetail = getOrganisation(orgId, userToken);
                    console.log('hitesh', orgDetail)
                    orgDetail.then(res => {
                        if (res.data.status != 400) {
                            let orgName = res.data.name;
                            let orgStatus = res.data.status;
                            let orgWisitiaId = res.data.org_wistia_project_id;
                            let orgLogoUrl = res.data.logoUrl;
                            let channelPushNotification = res.data.CHANNEL_PUSH_NOTIFICATION;
                            let editorAttachment = res.data.EDITOR_ATTACHMENT;
                            let editorExternalLink = res.data.EDITOR_EXTERNAL_LINK;
                            let editorLink = res.data.EDITOR_LINK;
                            let editorText = res.data.EDITOR_TEXT;
                            let editEmoji = res.data.EDIT_EMOJI;
                            let editEpub = res.data.EDIT_EPUB;
                            const userData = {
                                uuid: userId,
                                user_id: user_Id,
                                name: firstName + ' ' + lastName,
                                uid: uid,
                                usertype: usertype,
                                orgName: orgName,
                                orgLogoUrl: orgLogoUrl,
                                token: userToken,
                                refreshToken: udata.refreshToken,
                                orgId: orgId,
                                orgWisitiaID: orgWisitiaId,
                                epubOrgId: res.data.epubOrgId,
                                thumbImage: thumbImage,
                                CHANNEL_PUSH_NOTIFICATION: channelPushNotification,
                                EDITOR_ATTACHMENT: editorAttachment,
                                EDITOR_EXTERNAL_LINK: editorExternalLink,
                                EDITOR_LINK: editorLink,
                                EDITOR_TEXT: editorText,
                                EDIT_EMOJI: editEmoji,
                                EDIT_EPUB: editEpub
                            }
                            authCtx.login(userData);
                            authCtx.setUProfileImage(userData.thumbImage);
                            //histort.replace('/notifications');
                            //alert(user_Id);
                            if(user_Id === 39) {
                               // alert(1);
                                navigate('/Organisation/1#users');


                                return <Navigate to='/Organisation/1#users'/>
                                ///return false;
                            } else {
                                //alert(2);
                                return <Navigate to='/notifications'  />
                                //navigate('/notifications');
                                //return false;
                            }

                        } else {
                            alert("Could not get organisation this user belogs to. " + res.data.error);
                            return false;
                        }
                    }).catch(err => {
                        alert("Could not retrieve organisation. Error: " + err);
                    });
                } else {
                    //alert("Could not get logged in. "+udata.data.error);
                    setErrorMsg(res.data.error)
                    return false;
                }
            }).catch(err => {
                //console.log(err);
                setErrorMsg("Could not get logged in.. Error: " + err)
                return false;
                //alert("Could not get logged in.. Error: "+err);
            });
        }
    }, [epubtoken])


    function resetPasswordHandler(e) {

        e.preventDefault();
        setPasswordResetErrorMessage('');

        if(emailFieldRef.current.value === ''){
            setPasswordResetErrorMessage("Please enter email address");
            setShowResetPasswordEmailError(true);
            return false;
        }

        setShowResetPasswordEmailError(true);

        // code to make post request to API /reset-password
        setShowLoading(true);
        fetch(USERS_API_BASE_URL+'/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: emailFieldRef.current.value}),
        })
            .then(response => response.json())
            // check status code of response
            .then(data => {
                setShowLoading(false);
                if(data.status === 400){
                    setPasswordResetErrorMessage(data.msg);
                    setShowResetPasswordEmailError(true);
                    return false;
                }else{
                    setShowLogin(false);
                    setShowResetPassword(false);
                    setShowSuccessMessage(true);
                    setUuid(data.userId);

                }
                console.log('Success:', data);
            })
            .catch((error) => {
                setShowLoading(false);
                console.error('Error:', error);
            }
        );
    }

    function toggleResetPassword() {
        setShowResetPassword(showResetPassword => !showResetPassword);
        setShowLogin(showLogin => !showLogin);
        setShowSuccessMessage(false);
        setShowResetPasswordEmailError(false)
    }

    function toggleLogin() {
        setShowResetPassword(false);
        setShowLogin(true);
        setShowSuccessMessage(false);
    }

    function proceedToResetPassword() {
        setShowLoading(true);
        fetch(USERS_API_BASE_URL+'/reset-password/verify-access-code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({verificationCode: otpFieldRef.current.value, uuid: uuid}),
        })
            .then(response => response.json())
            // check status code of response
            .then(data => {
                setShowLoading(false);
                if(data.status === 400){
                    setPasswordResetErrorMessage(data.error);
                    setShowResetPasswordEmailError(true);
                    return false;

                }else{
                    //setShowLogin(true);
                    setShowResetPassword(false);
                    setShowSuccessMessage(false);
                    setPasswordResetErrorMessage('');
                    setShowResetPasswordEmailError(false);
                    setShowCreateNewPasswordForm(true);
                    setUuid(data.userDetail.uuid);
                    setOtp(otpFieldRef.current.value);
                }
                console.log('Success:', data);
            })
            .catch((error) => {
                    setShowLoading(false);
                    console.error('Error:', error);
                }
            );
    }

    function createNewPasswordHandler(e) {
            e.preventDefault();
            const passwordValue = passwordFieldRef.current.value;
            const confirmPasswordValue = confirmPasswordFieldRef.current.value;
            // if password is the combination of characters and numbers and minimum 8 characters long and special characters and atleast one capital letter
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if(passwordValue === ''){
                setPasswordResetErrorMessage("Please enter password");
                setShowResetPasswordEmailError(true);
                return false;
            }
            if(confirmPasswordValue === ''){
                setPasswordResetErrorMessage("Please enter confirm password");
                setShowResetPasswordEmailError(true);
                return false;
            }
            if(passwordValue !== confirmPasswordValue){
                setPasswordResetErrorMessage("Passwords do not match");
                setShowResetPasswordEmailError(true);
                return false;
            }
            if(!passwordRegex.test(passwordValue)){
                setPasswordResetErrorMessage("Password must be minimum 8 characters long, contain at least one capital letter, one number and one special character");
                setShowResetPasswordEmailError(true);
                return false;
            }


            setShowLoading(true);
            fetch(USERS_API_BASE_URL+'/reset-password-create-new-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: email, verificationCode: otp, uuid: uuid, password: passwordValue, confirmPassword: confirmPasswordValue}),
            })
                .then(response => response.json())
                // check status code of response
                .then(data => {
                    setShowLoading(false);
                    if(data.status === 400){
                        setPasswordResetErrorMessage(data.msg);
                        setShowResetPasswordEmailError(true);
                        return false;
                    }else{
                        setShowLogin(true);
                        setShowResetPassword(false);
                        setShowSuccessMessage(false);
                        setPasswordResetErrorMessage('');
                        setShowResetPasswordEmailError(false);
                        alert("Password reset successfully");
                    }
                    console.log('Success:', data);
                })
                .catch((error) => {
                        setShowLoading(false);
                        console.error('Error:', error);
                    }
                );
    }

    return (
        <div className="row" style={{justifyContent: 'center', alignItems: 'center'}}>
            <div className="col-md-6 col-lg-4">
                <div className="card card-body">
                    {showLogin && <form method="post" onSubmit={loginHandler}>
                        <div className="text-center pb-4">
                            <img style={{width: "70%"}} src={logo} className="rounded" alt="logo"/>
                        </div>
                        <div style={{"color": 'red', fontSize: "12"}}>{errorMsg}</div>
                        <div className="form-group">
                            <label htmlFor="Email">Email</label>
                            <input type="email" className="form-control" defaultValue={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Password">Password</label>
                            <a style={{float: "right", color: "#398aff", fontSize: "14px"}} href="#" onClick={toggleResetPassword} >Forgot
                                Password?</a>
                            <input type="password" className="form-control" defaultValue={pass}
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="col-12">
                            <div style={{marginTop: "20px"}} className="form-check pull-left">
                                <label className="form-check-label pull-left">
                                    <input type="checkbox" className="form-check-input"
                                           onChange={e => setRememberMe(e.target.checked ? "ON" : "")}/> Remember
                                    me</label>
                            </div>
                            <button style={{marginTop: "7px;"}} type="submit"
                                    className="pull-right bg-gradient-theme-left border-0 btn btn-primary btn-lg btn-block">Login
                            </button>
                        </div>

                    </form>}
                    {/* Reset Password Form with email field and success message */}

                    {showResetPassword && <form method="post" onSubmit={resetPasswordHandler}>
                        <div className="text-center pb-4">
                            <img style={{width: "70%"}} src={logo} className="rounded" alt="logo"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Email">Email*</label>
                            <input type="email" ref={emailFieldRef} className="form-control" defaultValue={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                            {
                                showResetPasswordEmailError && <span className="alrt alert-danger" style={{color:"#f00",fontSize:'14px'}}>{passwordResetErrorMessage}</span>
                                }
                        </div>
                        <div className="col-12">
                            <button style={{marginTop: "7px;"}} className="pull-left btn btn-outline-danger btn-lg btn-block" onClick={toggleResetPassword}>Cancel</button>
                            <button style={{marginTop: "7px;"}} type="submit"
                                    className="pull-right bg-gradient-theme-left border-0 btn btn-primary btn-lg btn-block">Reset
                                Password
                            </button>
                        </div>
                    </form>
                    }
                    {/* Success Message after reset password link sent to email provided */}
                    {showSuccessMessage && <div>
                        <div className="text-center pb-4">
                            <img style={{width: "70%"}} src={logo} className="rounded" alt="logo"/>
                        </div>
                        <div className="" role="alert">
                            <p>One time password has been sent to your email. Please enter the OTP.</p>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" defaultValue="" ref={otpFieldRef} name="otp" id="otp"/>
                            <input type="hidden" className='' ref={uuidFieldRef} name='uuid' id='uuid' />
                            {
                                showResetPasswordEmailError && <span className="alrt alert-danger" style={{color:"#f00",fontSize:'14px'}}>{passwordResetErrorMessage}</span>
                            }
                        </div>

                        <button onClick={toggleLogin} style={{marginTop: "7px;"}} type="submit"
                                className="pull-left bg-gradient-theme-left border-0 btn btn-outline-danger btn-lg btn-block">Cancel
                        </button>
                        <button onClick={proceedToResetPassword} style={{marginTop: "7px;"}} type="submit"
                                className="pull-right bg-gradient-theme-left border-0 btn btn-primary btn-lg btn-block" >Reset Password
                        </button>
                    </div>
                    }


                    {showCreateNewPasswordForm && <form method="post" onSubmit={createNewPasswordHandler}>
                        <div className="text-center pb-4">
                            <img style={{width: "70%"}} src={logo} className="rounded" alt="logo"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Email">Password*</label>
                            <input type="password" className={'form-control'} defaultValue="" name="passwordValue" required ref={passwordFieldRef} />
                            <label htmlFor="Email">Confirm Password*</label>
                            <input type="password" className={'form-control'} defaultValue="" name="confirmPasswordValue" required ref={confirmPasswordFieldRef}/>
                        </div>

                        {
                            showResetPasswordEmailError && <span className="alrt alert-danger" style={{color:"#f00",fontSize:'14px'}}>{passwordResetErrorMessage}</span>
                        }

                        <div className="col-12">
                            <button style={{marginTop: "7px;"}} className="pull-left btn btn-outline-danger btn-lg btn-block" onClick={toggleLogin}>Cancel</button>
                            <button style={{marginTop: "7px;"}} type="submit"
                                    className="pull-right bg-gradient-theme-left border-0 btn btn-primary btn-lg btn-block">Reset
                                Password
                            </button>
                        </div>
                    </form>
                    }


                    {showLoading && <Loader/>}
                </div>
            </div>
        </div>

    );
}

export default Login;
