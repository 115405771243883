import {useState, useEffect, useContext, useCallback} from 'react';
import {Link, Redirect, useNavigate} from 'react-router-dom';
import style from './CategoryTages.module.css';//import Pagination from '../../UI/Pagination/Pagination';
import { appUsers,fetchAppUsers,disableParentCode,enableParentCode,fetchAppUsersByCode,fetchAppUsersByCatID,fetchParents } from '../../services/notification.services';
import AuthContext from "../../store/auth-context";
import '../Style/Table-style.css';
import fontSize from '../Style/FontSize.module.css';
import Pagination from '../UI/Pagination/Pagination';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import common from '../Style/common.module.css';
import tableStyle from '../Style/TableModel.module.css';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
let pageSize = 10;
const ParentsDetails = (props) => {
    console.log('===p===',props);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState(false)
    const authCtx = useContext(AuthContext);
    const orgUuid = authCtx.userData.orgId;
    const [organisationCodes, setOrganisationCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(pageSize);

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;



    const navigate = useNavigate();
    ///const [codeval, setCodeVal] = useState(props.sCode);
    let codeVal = props.sCode;
    let groupID = 0;
    //console.log('======',codeVal.indexOf("&&"))
    if(codeVal!='' && isNaN(codeVal)==true){
       let codeArr = codeVal.split("&&");
       console.log("codeAr",codeArr)
       const groupName = codeArr[0];
       groupID = codeArr[1];
       codeVal = groupName;
       console.log("groupIDName",groupID,groupName)
    }
    //console.log('first',codeVal)
    const showHandler = (e) => {
        setPageSize(+e.target.value);
        setCurrentPage(1);
    }
    //console.log('filtercode',codeVal);
    const deactiveNotificationHandler = (uid) => {
        setIsOpen(true);
        setStatus(uid)
        console.log(uid)
    }
    const modalClose = ()=>{
        setIsOpen(false)
    }

    const disableParent = (uid,ucode, codeUserStatus) => {
        //if (window.confirm("Are you want to disable user?")) {
            if(uid){
            const userPostData = {
                        uid: uid,
                        ucode: ucode,
                        orgUuid: orgUuid,
             }
            disableParentCode(userPostData,authCtx.token)
            .then((response) => {
                setIsLoading(false);
                console.log('codeList afetr disable',response.data)
                fetchParents(orgUuid,authCtx.token).then((response) => {
                    setIsLoading(false);
                    setOrganisationCodes(response.data.userList);
                    console.log(organisationCodes)
                    toast.success("User Deactivated successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: 'success'
                    });
                    setIsOpen(false)
                })
                .catch((err) => {
                    alert(err);
                    console.error(err);
                    setIsLoading(false);
                });
               //setOrganisationCodes(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
            }
        //}

    }

    const enableParent = (uid,ucode) => {

        //if (window.confirm("Are you want to enable user?")) {
            if(uid){
            const userPostData = {
                        uid: uid,
                        ucode: ucode,
                        orgUuid: orgUuid,
             }
            enableParentCode(userPostData,authCtx.token)
            .then((response) => {
                setIsLoading(false);
                console.log('codeList afetr disable',response.data)
                fetchParents(orgUuid,authCtx.token).then((response) => {
                    setIsLoading(false);
                    setOrganisationCodes(response.data.userList);
                    toast.success("User Activated successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: 'success'
                    });
                    setIsOpen(false)
                })
                .catch((err) => {
                    alert(err);
                    console.error(err);
                    setIsLoading(false);
                });
               //setOrganisationCodes(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
            }
        //}

    }

    const goBackParent = (codeVal) => {
        if(isNaN(codeVal)==true){
            navigate("/Organisation/1#category");
            props.setSelectedCode('');
            props.setKey('first');
        }else{
           navigate("/Organisation/1#parents");
            props.setSelectedCode('');
            props.setKey('Fourth');
        }

    }
    const searchHandler = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    }
    useEffect(() => {
        if(codeVal=='' && authCtx.userData.usertype=='ADMIN'){
            fetchParents(orgUuid,authCtx.token)
       // appUsers( authCtx.token)

            .then((response) => {
                setIsLoading(false);
                setOrganisationCodes(response.data.userList);
            }).catch((err) => {
                //alert(err);
                console.error(err);
                setIsLoading(false);
            });
        }else if(isNaN(codeVal)==true){
            fetchAppUsersByCatID(orgUuid,groupID,authCtx.token)
            //fetchParents(orgUuid,authCtx.token)
       // appUsers( authCtx.token)

            .then((response) => {
                console.log('users=======',response.data);
                setIsLoading(false);
                setOrganisationCodes(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
        }else{
            //alert(1);
            fetchAppUsersByCode(orgUuid,codeVal,authCtx.token)
       // appUsers( authCtx.token)

            .then((response) => {
                setIsLoading(false);
                setOrganisationCodes(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
        }

    }, [codeVal,orgUuid]);
    const totalCount = organisationCodes.length || 0;
    const searchTextArray = searchText.toLowerCase().trim().split(' ');
    const searchParentData = organisationCodes.filter(parentdata => {
        return searchTextArray.every(i => parentdata.email.toLowerCase().indexOf(i) > -1)
        }
    );
    const parentData = searchParentData.slice(firstPageIndex, lastPageIndex);
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            modalClose();
        }
      }, [modalClose]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);
    //const parentData = organisationCodes.slice(firstPageIndex, lastPageIndex);
    return (
        <>
            <div className={style.creatUserArea}>
                    {/* <h4 className={[fontSize.lgSize + ' ' + common.pageTitle + ' ' + font.fontMedium]} >Settings</h4> */}
                    {/* <div className={style.settingbutton}>

                        {authCtx.userData.user_id!="39" && <button onClick={recipientModalHendler} className={button.iconBtn + ' ' + button.modalBtn + ' ' + button.redButton}>
                            <i className="icon icon-plus-circle"></i> Create New User</button>}
                    </div> */}
                    <div style={{marginTop:'20px',marginBottom:'5px',display:'flex',justifyContent:'flex-end'}}>
                        <div className={'form-group ' + tableStyle.hassearch}>
                        <label for="SearchInput" className='hidenText'>Search</label>
                        <input value={searchText} onChange={searchHandler} className={tableStyle.searchInput} type="search" placeholder="Search..." />
                        <i className={"icon icon-search2 " + tableStyle.searchIcon}></i>
                        </div>
                    </div>
            </div>
            <div className="table-design">
                {codeVal && <div style={{paddingLeft:"10px",color:"#45C096"}}>Parents link to {isNaN(codeVal)==true?'Contact Group':'code'} : {codeVal}<a className={style.backBtn}  href='#' onClick={()=>goBackParent(codeVal)}>Go Back</a></div>}
            <table className={"table table-hover " + style.categorytable}>
                <thead className={style.heading}>
                    <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Verification Code</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody className={style.tablebody}>

                    {parentData.map((user) => {

const dt = new Date(user.createDate);



const month = dt.toLocaleString('en-US', { month: 'short' });
const day = dt.toLocaleString('en-US', { day: '2-digit' });
const year = dt.getFullYear();
const createdDate = `${day + ' ' + month + ' ' + year}`;
                        return (
                            <tr key={user.id} className={user.codeUserStatus=='INACTIVE' ? 'deActiveRow' : ''}>


                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.code}</td>
                                <td>{createdDate}</td>
                                <td>{user.codeUserStatus=='ACTIVE'?<p style={{margin:'0'}}><i className="fa fa-check" style={{ color: '#45c096' }}></i><span style={{color: '#45c096',marginLeft: '4px'}}>Active</span></p>:<p style={{margin:'0'}}><i className="fa fa-check" style={{ color: '#FF6D73' }}></i><span style={{color: '#FF6D73',marginLeft: '4px'}}>Inactive</span></p>}</td>
                                <td>0000000</td>
                                <td>
                                    {user.codeUserStatus=='ACTIVE' &&
                                        <div className={"form-check form-switch " + style.switchBtn}>
                                            <input className={"form-check-input " + style.switchInput} type="checkbox" role="switch"
                                                onClick={i=>deactiveNotificationHandler(user)} checked={user.codeUserStatus=='ACTIVE'?true:false} />
                                        </div>
                                    }
                                    {user.codeUserStatus=='INACTIVE' &&
                                        <div className={"form-check form-switch " + style.switchBtn}>
                                        <input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" onClick={i=>deactiveNotificationHandler(user)}  />

                                    </div>
                                    }
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
            <div className={"row justify-content-between " + tableStyle.listShow}>
                {totalCount > pageSize && <div className="col-4">
                    <label className={tableStyle.listLabel}>
                        Show
                        <select className={"form-select " + tableStyle.listSelect} value={PageSize} onChange={showHandler}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>}

                <div className="col-4">
                    <div style={{ float: 'right' }}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={searchParentData.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>

            </div>
            </div>
            {modalIsOpen && <Modal status={status} isOpen={modalIsOpen} className="alertBgContainer" contentLabel="Example Modal" >
                <div className='alertBox'>
                    <h4 className={tableStyle.alertTitle}>Alert</h4>
                    <div>
                     <div>
                        <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you want to
                        {status.codeUserStatus=='ACTIVE'?(<b className={font.fontBold}> Deactivate </b>):(<b className={font.fontBold}> Activate </b>)}this user?</p>
                        <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>This will cause user disappear from the SchoolLink app.</p>
                        <div className='bottomArea'>
                            <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                            {status.codeUserStatus=='ACTIVE'?(
                            <button onClick={i=>disableParent(status.uuid, status.code)} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>):(<button onClick={i=>enableParent(status.uuid,status.code)} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>)}
                        </div>
                    </div>
                    </div>
                </div>
            </Modal>
            }
        </>
    );
}

export default ParentsDetails;
