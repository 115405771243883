import { useRef, useState,useContext } from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { createPinMessage } from '../../../services/notification.services';
import { toast } from 'react-toastify';
import fontSize from '../../Style/FontSize.module.css';
import common from '../../Style/common.module.css';
import font from '../../Style/FontFamily.module.css';
import tableStyle from '../../Style/TableModel.module.css';
import AuthContext from "../../../store/auth-context";
import button from '../../Style/ButtonStyle.module.css';
import AppContext from "../../../store/AppContext";
import '../../Style/FormStyle.css'

const NotificationPin = (props) => {
    const authCtx = useContext(AuthContext);
    const appCtx = useContext(AppContext);
    const pinDaysRef = useRef();
    const [isSaving, setIsSeving] = useState(false);
    const [hasUserConfirmed, setUserConfirmed]=useState(false);
    const [isConfirmationNeeded,setConfirmationNeeded]=useState(props.showAlreadyPinedMessage);
    const [showConfirmationPopup,setShowConfirmationPopup]=useState(props.showAlreadyPinedMessage);
    let orgID = authCtx.userData.orgId;
    let userId = authCtx.userData.uuid;

    const checkIfOtherMessagePinned=()=>{
        setConfirmationNeeded(true);
        return;
    }

    const savePinHandler = (e) => {
        e.preventDefault();
        checkIfOtherMessagePinned();
        if(isConfirmationNeeded && !hasUserConfirmed){
            // Show confirmation popup
            return false;
        }
        setIsSeving(true);

        const postData = {
            messageUUID: props.messageuuid,
            orgUUID: orgID,
            days: pinDaysRef.current.value,
            userUUid:userId
        };
        createPinMessage(postData,authCtx.token).then((response) => {

            setIsSeving(false);
            console.log(response.data);
            props.closeHandle();
            props.onSubmit(props.messageuuid);
            toast.success(response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: 'success'
            });
        }).catch((error) => {
            setIsSeving(false);
            console.log(error);
        });
        //props.onSubmit(pinDaysRef.current.value);
    }


    function confirmHandler() {
        setUserConfirmed(true);
        setShowConfirmationPopup(false);
        setConfirmationNeeded(false);
    }

    return (
            <Modal
                //size="sm"
                centered
                show={props.show}
                onHide={props.closeHandle}
                aria-labelledby="example-modal-sizes-title-sm"

            >
                <div className={tableStyle.pinArea}>
                {!showConfirmationPopup && <div className={tableStyle.pinalertbody + ' ' + tableStyle.areaPadding}>
                    <h4 className={tableStyle.alertTitle}>Pin to top</h4>
                    <button onClick={props.closeHandle} className={tableStyle.closeModal} type="button"><i class="fa fa-times"></i></button>
                    <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>This notification will stay pinned to the top of your recipient's newsfeed until the expiry of selected time frame.</p>
                    <form onSubmit={savePinHandler} className={tableStyle.pinfrom}>
                        <Row>
                            <Col md={12}>
                                <div className="form-group">
                                    <label className={tableStyle.pinlabel}  style={{ color: 'green' }}>Pin deactivated in</label>
                                    <select className={tableStyle.pinselect} ref={pinDaysRef}>
                                        <option value="1">1 day</option>
                                        <option value="2">2 days</option>
                                        <option value="3">3 days</option>
                                        <option value="4">4 days</option>
                                        <option value="5">5 days</option>
                                    </select>
                                    <button type="submit" className={tableStyle.pinApply} disabled={isSaving}> {!isSaving ? 'Apply' : '    '}
                                        {isSaving && (<>Applying...</>)}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>}


                {showConfirmationPopup && <div>
                    <div className={tableStyle.pinalertbody + ' ' + tableStyle.areaPadding}>
                        <h4 className={tableStyle.alertTitle}>Pin to top</h4>
                        <button onClick={props.closeHandle} className={tableStyle.closeModal} type="button"><i
                            className="fa fa-times"></i></button>
                        <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>It is found that you
                            have already pinned message in your organisation. If you pin this message, previously pinned
                            message would get unpinned and this message would pinned.</p>

                    </div>
                    <div className={tableStyle.alertbody + ' ' + tableStyle.areaPadding + ' ' + tableStyle.bodySize} style={{paddingTop:'0'}}>
                        <strong>Message:</strong>
                        <div dangerouslySetInnerHTML={{__html: appCtx.pinnedMessage[0].messageBody}} />
                    </div>
                    <div className={tableStyle.pinalertbody + ' ' + tableStyle.areaPadding} style={{textAlign:'right',bottom:'0'}}>
                        <button onClick={props.closeHandle} type="button" className={button.modalMdBtn + ' ' + button.whiteButton}> Cancel</button>
                        <button type="submit" onClick={confirmHandler} className={button.modalMdBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft} > Ok</button>
                    </div>
                </div>}

                </div>
            </Modal>
    )
}
export default NotificationPin;
