import Modal from 'react-bootstrap/Modal';
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import styles from "./Editor/ComposeNotification.module.css";
import NotificationRecipientPreview from "../ContactGroup/NotificationRecipientPreview";

export default function SelectedAppUsersForGroup(props) {

    /*const usersProps=props.users.filter(u=>u!=null);
    const USERS=usersProps.map(u=>{
       return {name:u.firstName+' '+u.lastName,email:u.email}
    });
    console.log("USERS",USERS);
    const [name, setName] = useState('');
    const [foundUsers, setFoundUsers] = useState(USERS);

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = USERS.filter((user) => {
                return user.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFoundUsers(results);
        } else {
            setFoundUsers(USERS);
        }
        setName(keyword);
    };
    const SelectedCategoriesTagsView = (props) => {
        let selectedcategoryiestagsView = props.currentSelectedGroup.usergroupcategory.map(c => {
            let category = c.name;
            let tags = c.usergrouptag.map(t => {
                return <span style={{ fontSize: "10px", marginLeft: "2px", fontWeight: "500" }} className="badge rounded-pill bg-secondary">{t.name}</span>
            });
            return <div className="rounded"><label style={{ fontSize: "12px", fontWeight: "600" }}>{category}</label> {tags}</div>
        });
        return  <div className={styles.selectedcategoryiestagsViewOpen}>{selectedcategoryiestagsView}</div>;
    }*/

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span style={{fontSize:"28px", color:'#424242'}}>List of recipients attached to this contact group</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {props.recipients!=null && <NotificationRecipientPreview
                    previewOnlyView={true}
                    data={props.recipients}
                />}

            </Modal.Body>

        </Modal>
    );
}
