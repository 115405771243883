import { useState, useEffect, useRef } from 'react';
import NotificationThreadListItem from './NotificationThreadListItem';
import Channels from '../NotificationThreadListHeader/Channels';
import ChannelFilter from '../NotificationThreadListHeader/ChannelFilter';
import { Col } from 'react-bootstrap';
import '../NotificationDashboard/Notifications1.css';
import { ImArrowDown2, ImArrowUp2 } from "react-icons/im";
import { FiFilter, FiMoreVertical } from "react-icons/fi";
import { FaEllipsisV } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import button from '../Style/ButtonStyle.module.css';
import utilStyle from '../Style/UtilStyles.css';
import channelStyle from '../NotificationThreadListHeader/Channels.module.css';
import font from '../Style/FontFamily.module.css';
import DeactivateNotificationThread from '../NotificationThreadListHeader/DeactivateNotificationThread';
import jQuery from "jquery";
import InfiniteScroll from "react-infinite-scroll-component";
import { GrSearch } from 'react-icons/gr';
import { CgClose } from 'react-icons/cg';
import searchGif from '../../assets/loading-33.gif';

let PageSize = 30;

const useOutsideClick = (ref, callback) => {

    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            //callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    },[]);
};

const NotificationThreadList = (props) => {

    //console.log("NotificationThreadList props", props);
    const {
        notifications,
        selectedContactGroup,
        selectedChannels,
        resetNotificationBox,
        onComposeChannels,
        onSelectedNotification,
        resetChannelsHandler,
        sortingNotificationHandler,
        contactGroupsOrder,
        selectedChannelFilter,
        applyChannelFilterHandler,
        resetChannelFilterHandler,
        deactivateNotificationHandler,
        loadMoreOnScroll,
        loadNotificationThreads,
        currentPage,
        hasMore
    } = props;

    jQuery(document).on('click touched', function (e) {
        if (jQuery(e.target).parents().hasClass("channelBox")) {
            e.stopPropagation();
        } else if (jQuery(e.target).parents().hasClass("selectfilter")) {
            e.stopPropagation();
        } else if (jQuery(e.target).hasClass("emojionearea")) {
            e.stopPropagation();
        } else if (jQuery(e.target).parents().hasClass("emojionearea")) {
            e.stopPropagation();
        } else {
            setSsShowChannels(false);
            setIsChannelFilter(false);
            if (window.emoj && window.emoj[0]) {
                //window.emoj[0].emojioneArea.hidePicker();
            }
        }
    });

    const ref = useRef();
    const [isShowChannels, setSsShowChannels] = useState(false);
    const [isChannelFilter, setIsChannelFilter] = useState(false); // For opening channel filter dropdown
    const [isDeactivateNotificationOpne, setIsDeactivateNotificationOpne] = useState(false);
    const [isDeactivateNotificationFilter, isSetDeactivateNotificationFilter] = useState(false);
    const [selectedChannelsForValidation, setSelectedChannelsForValidation] = useState([]);
    const [searchFilter, setSearchFilter] = useState(false);
    const [searchBoxHeight, setSearchBoxHeight] = useState(0);
    const [filterBoxHeight, setFilterBoxHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(57);

    const openFilterHandler =()=>{
        setSearchFilter(true)
    }
    const closeFilterHandler=()=>{
        setSearchFilter(false)
    }
    const clearFilterNotifaction =()=>{
        resetChannelFilterHandler()
    }

    //const [currentPage, setCurrentPage] = useState(1);
    // const firstPageIndex = (currentPage - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;

    //console.log('page index',currentPage,firstPageIndex,lastPageIndex)
    //const [filteredNotification, setFilteredNotification] = useState([])

    useOutsideClick(ref, () => {
        if (isShowChannels) setSsShowChannels(false);
        if (isChannelFilter) setIsChannelFilter(false);
        if (isDeactivateNotificationOpne) setIsDeactivateNotificationOpne(false);
    });

    const Spinner = () =>{
        return (<div className='post loading'>
            <h5>Loading..</h5>
        </div>)
    }

    const filterNotificationByChannel = () => {
        setIsChannelFilter(isPoen => !isPoen);
    }

    const selectChannelHandler = () => {
        console.log("selectedChannelsForValidation", selectedChannelsForValidation);
        if (selectedChannelsForValidation.length == 0) {
            alert("Please select channel(s) to send notification");
            return;
        }
        props.clearRecipientsSelection();
        setSelectedChannelsForValidation([]);
        setSsShowChannels(preState => !preState);
        //resetNotificationBox();
    }
    const selectNotificationHandler = (id, contactGroupStatus, channels,title) => {
        //debugger;
        console.log("changing channels ", channels);
        onSelectedNotification(id, contactGroupStatus, channels,title);
    }

    // For filtering  deactive notification
    const deactivateNotificationFilterHandler = () => {
        setIsDeactivateNotificationOpne(prevState => !prevState);
        isSetDeactivateNotificationFilter(preState => !preState);
    }

   let filteredNotification = notifications;

    const fetchMoreData = () => {
        loadMoreOnScroll(currentPage + 1);
    }

    // text field change handler
    const onSearchHandler = (e) => {
        let value = e.target.value;
        props.applySearchText(value.trim());
    }

    if (isDeactivateNotificationFilter)
        filteredNotification = notifications.filter((notification) => notification.contactGroupStatus === 'INACTIVE');

    if (selectedChannelFilter.length > 0) {

        if (selectedChannelFilter.includes('99')) {

            let onlyDeactivatedNoti = notifications.filter((notification) => notification.contactGroupStatus === 'INACTIVE');

            if (selectedChannelFilter.length === 1)
                filteredNotification = onlyDeactivatedNoti;
            else
                filteredNotification = onlyDeactivatedNoti.filter((notification) => {
                    const channels = notification.channels.split(',');
                    return selectedChannelFilter.some(r => channels.includes(r));
                });

        } else {
            filteredNotification = notifications.filter((notification) => {
                const channels = notification.channels.split(',');
                return selectedChannelFilter.some(r => channels.includes(r));
            });
        }
    }

    const selectedFiltersString= (selectedChannelFilter.includes("1") ?"Push Notification":'' )+(selectedChannelFilter.includes("2") ? ", Email Notification":'') + (selectedChannelFilter.includes("99") ? ", Inactive Notification Thread":'') ;
    const reducedHeighOfScroll = (selectedChannelFilter.length>0 ? 50 : 0) + (searchFilter ? 50 : 0) + 57;


    return (

         <>
         <div className={selectedChannelFilter.length>0 ? 'filterHeightss' : ''}>
            <div className={searchFilter ? 'sarchHeightss' : ''}>
                <div className="container messageHead">
                    <div className="row" >
                        {/* <div  style={{
                padding: '10px', width: '50%', background: '#fefefe',
                    borderBottom: '1px solid #fafafa'
                }}> */}
                        <Col xs={8} md={8}>
                            <div className="channelBox">
                                <button onClick={() => {setSsShowChannels(!isShowChannels); setIsChannelFilter(false)}} className={button.btn + ' ' + button.blueButton + ' '}>Select Channels <IoIosArrowDown size='22px' /></button>
                                {isShowChannels && (
                                    <div ref={ref}><Channels
                                        onComposeChannels={onComposeChannels}
                                        onCompose={selectChannelHandler}
                                        checkedChannels={selectedChannels}
                                        setSelectedChannelsForValidation={setSelectedChannelsForValidation}
                                        resetChannelsHandler={resetChannelsHandler}
                                    /></div>)}
                            </div>

                        </Col>
                        {/* </div> */}
                        <Col xs={4} md={4} className={button.flex}>
                            <div className="chatbox">
                                <div className="selectfilter" style={{"position":"relative","right":"-12px"}}>
                                    <button onClick={() => {setIsChannelFilter(!isChannelFilter); setSsShowChannels(false);} } className={button.iconType}><span className={button.hideBtnText}>Select Channel</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={button.filtericon} style={{ width: '22px' }}><g transform="matrix(2,0,0,2,0,0)"><path d="M21.831 2.808A1.5 1.5 0 0 0 20.5 2h-16a1.5 1.5 0 0 0-1.244 2.339L9 13.293V21a1 1 0 0 0 1.372.929l5-2A1 1 0 0 0 16 19v-5.707l5.729-8.933a1.5 1.5 0 0 0 .102-1.552zm-7.673 9.652A1 1 0 0 0 14 13v5.323l-3 1.2V13a1 1 0 0 0-.158-.54L5.415 4h14.17z"></path></g></svg></button>
                                    {isChannelFilter && (
                                        <div ref={ref} style={{ display: 'inline-block' }}>
                                            <ChannelFilter
                                                applyChannelFilterHandler={applyChannelFilterHandler}
                                                selectedChannelFilter={selectedChannelFilter}
                                                filterNotificationByChannel={filterNotificationByChannel}
                                                resetChannelFilterHandler={resetChannelFilterHandler}
                                                deactivateNotificationFilterHandler={deactivateNotificationFilterHandler}
                                                isDeactivateNotificationOpne={isDeactivateNotificationOpne}
                                            />
                                        </div>
                                    )}
                                    <button className={button.iconType}>
                                        <span className={button.hideBtnText}>Shot Listing</span>
                                        {/* {contactGroupsOrder && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={button.filtericon} style={{ width: '18px', transform: 'rotate(180deg)' }}><g transform="matrix(2,0,0,2,0,0)"><path d="M23.42,11.76,13.77.8A2.67,2.67,0,0,0,12,0a2.67,2.67,0,0,0-1.77.8l-9.65,11A1.41,1.41,0,1,0,2.7,13.63l7.45-8.46a.25.25,0,0,1,.28-.07.25.25,0,0,1,.16.23V22.59a1.41,1.41,0,1,0,2.82,0V5.33a.25.25,0,0,1,.16-.23.25.25,0,0,1,.28.07l7.45,8.46a1.41,1.41,0,1,0,2.12-1.87Z"></path></g></svg>}
                                        {!contactGroupsOrder && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={button.filtericon} style={{ width: '18px' }}><g transform="matrix(2,0,0,2,0,0)"><path d="M23.42,11.76,13.77.8A2.67,2.67,0,0,0,12,0a2.67,2.67,0,0,0-1.77.8l-9.65,11A1.41,1.41,0,1,0,2.7,13.63l7.45-8.46a.25.25,0,0,1,.28-.07.25.25,0,0,1,.16.23V22.59a1.41,1.41,0,1,0,2.82,0V5.33a.25.25,0,0,1,.16-.23.25.25,0,0,1,.28.07l7.45,8.46a1.41,1.41,0,1,0,2.12-1.87Z"></path></g></svg>} */}
                                        {searchFilter && <CgClose className={button.closeFilterSearch} onClick={closeFilterHandler} />}
                                        {!searchFilter && <GrSearch onClick={openFilterHandler} className={button.filtericon + ' ' + button.searchFilter} />}
                                    </button>

                                    {/*
                                    <button onClick={() => setIsDeactivateNotificationOpne(!isDeactivateNotificationOpne)} className={button.iconType}><svg className={button.filtericon} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(4.8,0,0,4.8,0,0)"><path d="M3.750 8.750 A1.250 1.250 0 1 0 6.250 8.750 A1.250 1.250 0 1 0 3.750 8.750 Z" fill="#555" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path><path d="M3.750 5.000 A1.250 1.250 0 1 0 6.250 5.000 A1.250 1.250 0 1 0 3.750 5.000 Z" fill="#555" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path><path d="M3.750 1.250 A1.250 1.250 0 1 0 6.250 1.250 A1.250 1.250 0 1 0 3.750 1.250 Z" fill="#555" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path></g></svg></button>
                                    {isDeactivateNotificationOpne && (
                                        <div ref={ref} style={{ display: 'inline-block' }}>
                                            <DeactivateNotificationThread
                                                deactivateNotificationFilterHandler={deactivateNotificationFilterHandler}
                                            />
                                        </div>
                                    )}
                                    */}
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
                {selectedChannelFilter.length>0 &&
                    <div className={button.searchNotification}>
                        <div className={button.filterTitle}>
                            <p><strong>Filters:</strong> {selectedFiltersString}</p>
                            <button type="button" className={button.closeFilter} onClick={clearFilterNotifaction}>Clear</button>
                        </div>
                    </div>
                }
                {searchFilter &&
                    <div className={button.searchNotification}>
                        <div className={button.searchArea}>

                            {/* <label for="filterSearch">Search</label> */}
                            <input disabled={props.isSearching} type="search" id='filterSearch' placeholder='Auto Search' onChange={onSearchHandler} />
                            {/* add class if isSearching is true */}
                           {/* <button className={button.btnFilter}>
                                gif animation if props.isSearching is true
                                {props.isSearching?<img src={searchGif} />:''}
                                <GrSearch className={button.filtericon + ' ' + button.searchFilter+' '+(props.isSearching?'opacityAnim':'')} />
                            </button>*/}
                        </div>
                    </div>
                }

                <div className="messageNoti" id="threadsContainer" style={{'height':'calc(100% - '+(reducedHeighOfScroll)+'px)','overflowY':'scroll','position':'relative'}}>

                    <InfiniteScroll
                        dataLength={filteredNotification.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        //loader={filteredNotification.length>0 && <Spinner/>}
                        //loader={<div className="" style={{"display":"block", "position":"absolute","top":"0","bottom":"0","left":"0","right":"0","zIndex":"99","background":"rgba(124,124,124,0.18)"}} role="status">Loading..</div>}
                        loader={false}
                        scrollableTarget="threadsContainer"
                        overflowY={true}
                        endMessage={<span className={'noMoreData'}>No more data</span>}

                    >

                    {filteredNotification.map((message) => {
                        //console.log("Message Thread Data ", message);
                        //debugger;
                        let selectedChannelsArray = message.channels.split(",");
                        selectedChannelsArray = selectedChannelsArray.filter(item => item.trim().length > 0);
                        let selectedChannels = selectedChannelsArray.join(",");
                        message.channels = selectedChannels;

                            return (
                            <NotificationThreadListItem
                                onClick={selectNotificationHandler.bind(null, message.usersGroupId, message.contactGroupStatus, message.channels,message.title)}
                                contactGroup={message.usersGroupId}
                                key={message.id}
                                messageId={message.id}
                                messageAuthorId={message.authorId}
                                authorImageUrl={message.authorImageUrl}
                                messageTitle={message.title}
                                messageBody={message.messageBody.replace(/<[^>]+>/g, '')}
                                authorName={message.authorName}
                                lastModified={message.lastModified}
                                messageType='Message'
                                selectedContactGroup={selectedContactGroup}
                                channels={message.channels}
                                contactGroupStatus={message.contactGroupStatus}
                                onSelectDeactivateNotification={deactivateNotificationHandler}
                            />
                            )
                    })}
                     </InfiniteScroll>

                </div>
            </div>
            </div>
        </>
        );
}

export default NotificationThreadList;
