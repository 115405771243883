import { useState } from 'react';
import style from './SchoolAuthenticationCodeGenerator.module.css'
import tableStyle from '../Style/TableModel.module.css';
const AuthenticationCodeSearchForm = (props) => {

    const [searchInputText, setSearchInputText] = useState('');

    const searchInputHandler = (e) => {

        setSearchInputText(e.target.value);
        props.onSearch(e.target.value);
    } 
    return (
        <div className={'form-group ' + tableStyle.hassearch}>
            <label for="SearchInput" className='hidenText'>Search</label>
            <input value={searchInputText} onChange={searchInputHandler} className={tableStyle.searchInput} type="search" placeholder="Search..." />
                    <i className={"icon icon-search2 " + tableStyle.searchIcon}></i>
        </div>
    );
}

export default AuthenticationCodeSearchForm;

