import { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import IndividualAppUsers from './Recipient/IndividualAppUsers';
import SchoolLinkAppUsers from './Recipient/SchoolLinkAppUsers';
import EmailOnlySubscribers from './Recipient/EmailOnlySubscribers';
import style from '../NotificationThreadDetail/NotificationRecipient.module.css';
import Loader from '../UI/Loader/Loader';
import { toast } from 'react-toastify';
import React from "react";

import {
    updateContactGoup,
    getCategoryTags,
    appUsers,
    fetchEmailOnlySubscribers,
    fetchAppUsers,
    getSelectUsersForGroup,
    getSelectedRecipientData
} from '../../services/notification.services';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import '../Style/Table-style.css';
import tableStyle from '../Style/TableModel.module.css';
import { MdOutlineMarkunreadMailbox } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { FaUserPlus } from "react-icons/fa";
import AuthContext from "../../store/auth-context";
import NotificationRecipientPreview from "./NotificationRecipientPreview";
//const orgUuid = '7bce8766-5bd8-4194-b455-ac579dc36587';
const NotificationRecipient = (props) => {
   // console.log('firstprops',props)
    const authCtx = useContext(AuthContext);
    //console.log('emailOnly=====',authCtx.userData);
    const orgUuid = authCtx.userData.orgId;
    const epubOrgId = authCtx.userData.epubOrgId;
    /**Sate for SchoolLink app users **/
    const [categories, setCategories] = useState([]);
    /**  Sate for Select individual app users **/
    const [users, setUsers] = useState([]);
    /**State for Email only subscribers **/
    const [emailOnlySubscribers, setEmailOnlySubscribers] = useState([]);
    /** sate for  checked indivisual user user id **/
    const [selectedUserId, setSelectedUserId] = useState([]);
    /** State for  selected SchoolLink app users **/
    const [selectedCategoryTags, setSelectedCategoryTags] = useState([]);
    /* state for selected email Only Subscribers */
    const [selectedEmailOnlySubscribers, setSelectedEmailOnlySubscribers] = useState([]);
    const [isOpen,setOpen]=useState(props.modalStatus);
    const [isNext,setIsNext]=useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [currentTab, setCurrentTab] = useState(props.channels.includes("5") ? 3 : 1);
    const [searchText, setSearchText] = useState('');
    const [previewedRecipientDataTemp, setPreviewedRecipientDataTemp] = useState([]);
    const tabChangeHandler = (id) => {
        setCurrentTab(id);
    }


    const getUserCheckedValueHandler = (event) => {
        //alert(3);
        const value = event.target.value;
        let selected = [...selectedUserId];
        let find = selected.indexOf(value)

        if (find > -1)
            selected.splice(find, 1)
        else
            selected.push(value)
        setSelectedUserId(selected)
        console.log('selected',selected);
        props.setSelectedUsers(selected);
    }
    /* select  SchoolLink app users data */
    const setSelectedTagsHandlers = (selectedTagsData, cateId) => {

        const newTagsObj = {
            [cateId]: selectedTagsData
        };

        const categoryTag = [...selectedCategoryTags];

        const index = categoryTag.findIndex(e => e[cateId]);

        if (index > -1) {
            categoryTag[index][cateId] = selectedTagsData
        } else {
            categoryTag.push(newTagsObj);
        }
        setSelectedCategoryTags(categoryTag);
        props.setSelectedCategoryTags(categoryTag);
    }

    /** select email Only subscribers **/
    const emailOnlySubscribersChangeHandler = (selectedTags, categoryId) => {

        const newTagsObj = {
            [categoryId]: selectedTags
        };
        const selectedAppData = [...selectedEmailOnlySubscribers];
        const index = selectedAppData.findIndex(e => e[categoryId]);
        if (index > -1) {
            selectedAppData[index][categoryId] = selectedTags;
        } else {
            selectedAppData.push(newTagsObj);
        }
        setSelectedEmailOnlySubscribers(selectedAppData);
        props.setselectedEmailOnlySubscribers(selectedAppData);
    }

    // Save recipient details //
    const saveRecipientHandler = () => {

        if (props.isAllSchoolLinkAppUsersSelected ||
            props.isAllEmailOnlySubscribersSelected ||
            selectedUserId.length > 0 ||
            selectedCategoryTags.length ||
            selectedEmailOnlySubscribers.length > 0) {

            const contactGroupObj = {
                name: "Test",
                selectedUsers: selectedUserId,
                selectedTags: [],
                selectedCategoriesAndTags: [],
                createdBy: '1',
                emailOnlySubscribers: selectedEmailOnlySubscribers,
                isAllSchoolLinkAppUsersSelected:props.isAllSchoolLinkAppUsersSelected,
                isAllEmailOnlySubscribersSelected:props.isAllEmailOnlySubscribersSelected,
            };

            for (let group of selectedCategoryTags) {

                for (let gr in group) {
                    //console.log(gr)
                    const catname = categories.find(cate => cate.id == gr);
                    // console.log(catname)
                    let temp = {
                        selectedCategory: {
                            "categoryID": catname.id,
                            "name": catname.name,
                            "fkOrgID": orgUuid,
                            "catuuid": catname.uuid
                        },
                        "selectedTags": group[gr].map((tag) => ({
                            tagId: tag.value,
                            name: tag.label,
                            fkOrgID: orgUuid,
                            tagcategoryId: catname.id,
                            taguuid: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
                            useruuid: '497f6eca-6276-4993-bfeb-53cbbbba6f08'
                        })),
                    };
                    contactGroupObj.selectedCategoriesAndTags.push(temp);
                }
            }
            selectedCategoryTags.forEach(r => {
                for (let key in r) {
                    r[key].map(v => contactGroupObj.selectedTags.push(v.value));
                }
            });
           // console.log('groupData',contactGroupObj);
            //return false;
            //props.setSelectedCategoryTags(contactGroupObj.selectedCategoriesAndTags);
            props.onSave(contactGroupObj);
            props.onAction();
        }
        else
            alert('Please select data')
    }


    const [selectedFilterParams, setSelectedFilterParams] = useState(null);

    const convertDataFormatToGetRecipientsAndMoveToNextScreen = () => {
        if (props.isAllSchoolLinkAppUsersSelected ||
            props.isAllEmailOnlySubscribersSelected ||
            selectedUserId.length > 0 ||
            selectedCategoryTags.length ||
            selectedEmailOnlySubscribers.length > 0) {

            const contactGroupObj = {
                name: "Test",
                selectedUsers: selectedUserId,
                selectedTags: [],
                selectedCategoriesAndTags: [],
                createdBy: '1',
                emailOnlySubscribers: selectedEmailOnlySubscribers,
                isAllSchoolLinkAppUsersSelected: props.isAllSchoolLinkAppUsersSelected,
                isAllEmailOnlySubscribersSelected: props.isAllEmailOnlySubscribersSelected
            };

            for (let group of selectedCategoryTags) {

                for (let gr in group) {
                    //console.log(gr)
                    const catname = categories.find(cate => cate.id == gr);
                    // console.log(catname)
                    let temp = {
                        selectedCategory: {
                            "categoryID": catname.id,
                            "name": catname.name,
                            "fkOrgID": orgUuid,
                            "catuuid": catname.uuid
                        },
                        "selectedTags": group[gr].map((tag) => ({
                            tagId: tag.value,
                            name: tag.label,
                            fkOrgID: orgUuid,
                            tagcategoryId: catname.id,
                            taguuid: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
                            useruuid: '497f6eca-6276-4993-bfeb-53cbbbba6f08'
                        })),
                    };
                    contactGroupObj.selectedCategoriesAndTags.push(temp);
                }
            }
            selectedCategoryTags.forEach(r => {
                for (let key in r) {
                    r[key].map(v => contactGroupObj.selectedTags.push(v.value));
                }
            });
            // console.log('groupData',contactGroupObj);
            //return false;
            //covertedDataToBeUsedToSendReqForRecipientsPreview=contactGroupObj;
            //console.log('covertedDataToBeUsedToSendReqForRecipientsPreview',covertedDataToBeUsedToSendReqForRecipientsPreview)
            contactGroupObj.epubOrgId=epubOrgId;
            contactGroupObj.fkOrgID=orgUuid;
            setSelectedFilterParams(contactGroupObj);
            setIsNext(true);
        }
    }

    // For updation recipient group
    const UpdateRecipientHandler = () => {
        const groupId = props.isRecipientUpdate;
        if (selectedUserId.length > 0 || selectedCategoryTags.length || props.isRecipientUpdate) {

            props.setPreviewedRecipientData(previewedRecipientDataTemp);
            const finalData = {
                name: "Test",
                selectedUsers: selectedUserId,
                selectedTags: [],
                selectedCategoriesAndTags: [],
                createdBy: '1',
                emailOnlySubscribers: selectedEmailOnlySubscribers,
                selectedChanels:(Array.isArray(props.channels)?props.channels.join(','):props.channels),
                isAllEmailOnlySubscribersSelected:""+props.isAllEmailOnlySubscribersSelected+"",
                isAllSchoolLinkAppUsersSelected:""+props.isAllSchoolLinkAppUsersSelected+"",
                previewedRecipientData:previewedRecipientDataTemp,
            };
            for (let group of selectedCategoryTags) {

                for (let gr in group) {
                    console.log('group=====',gr)
                    console.log('grouptag=====',group[gr]);
                    const catname = categories.find(cate => cate.id == gr);
                    console.log('groupTag===',group[gr])
                    let temp = {
                        selectedCategory: {
                            "categoryID": catname.id,
                            "name": catname.name,
                            "fkOrgID": orgUuid,
                            "catuuid": catname.uuid
                        },
                        "selectedTags": group[gr].map((tag) => ({

                            tagId: tag.value,
                            name: tag.label,
                            fkOrgID: orgUuid,
                            tagcategoryId: catname.id,
                            taguuid: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
                            useruuid: '497f6eca-6276-4993-bfeb-53cbbbba6f08'
                        })),

                    };
                    finalData.selectedCategoriesAndTags.push(temp);
                }
            }
            selectedCategoryTags.forEach(r => {
                for (let key in r) {
                    r[key].map(v => finalData.selectedTags.push(v.value));
                }
            });
            setIsSaving(true);
            updateContactGoup(finalData, groupId, authCtx.token).then((resp) => {
                setIsSaving(false);

                getSelectUsersForGroup(groupId, authCtx.token)
                    .then(response => {
                        props.setSelectUsersForGroup(response.data);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('Err:', error);
                    });

                toast.success("Contact groups updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });

                setOpen(false);

            }).catch((error) => {
                setIsSaving(false);
                alert(error);
            });
        }
        else
            alert('You have to select at least one recipient or subscriber');
    }

    let currentComponent;

    if (currentTab === 1)
        currentComponent = <SchoolLinkAppUsers
            categories={categories}
            users={users}
            onSelectTag={setSelectedTagsHandlers}
            selectedCategoryTags={selectedCategoryTags}
            isAllSchoolLinkAppUsersSelected={props.isAllSchoolLinkAppUsersSelected}
            setIsAllSchoolLinkAppUsersSelected={props.setIsAllSchoolLinkAppUsersSelected}
        />
    else if (currentTab === 2)
        currentComponent = <EmailOnlySubscribers
            appData={emailOnlySubscribers}
            selectedEmailOnlySubscribers={selectedEmailOnlySubscribers}
            onChange={emailOnlySubscribersChangeHandler}
            isAllEmailOnlySubscribersSelected={props.isAllEmailOnlySubscribersSelected}
            setIsAllEmailOnlySubscribersSelected={props.setIsAllEmailOnlySubscribersSelected}
            selectedChannels={props.channels}
        />
    else {
        currentComponent = <IndividualAppUsers
            onCheckedUser={getUserCheckedValueHandler}
            users={users}
            channel={props.channels}
            selectedUserId={selectedUserId}
            isAllSchoolLinkAppUsersSelected={props.isAllSchoolLinkAppUsersSelected}
            setIsAllSchoolLinkAppUsersSelected={props.setIsAllSchoolLinkAppUsersSelected}
        />
    }
    /* Fetch Select contact groups data */
    useEffect(() => {
        console.log("Props in Notification Recipient", props);
        //alert(2);
        const orgId = 12;
        const fetchData = async () => {
            const categoryResponse = await getCategoryTags(orgUuid,authCtx.token);
            const emailOnlySubscribers = await fetchEmailOnlySubscribers(epubOrgId,authCtx.token);
            //const appUser = await appUsers(authCtx.token);
            const appUser = await fetchAppUsers(orgUuid,authCtx.token);

            if (props.isRecipientUpdate!=false) {
                getSelectedRecipientData(props.isRecipientUpdate, authCtx.token)
                    .then((response) => {
                        if (response.data.isAllSchoolLinkAppUsersSelected == "true") {
                            props.setIsAllSchoolLinkAppUsersSelected(true);
                        } else {
                            props.setIsAllSchoolLinkAppUsersSelected(false);
                        }
                        if (response.data.isAllEmailOnlySubscribersSelected == "true") {
                            props.setIsAllEmailOnlySubscribersSelected(true);
                        } else {
                            props.setIsAllEmailOnlySubscribersSelected(false);
                        }
                    }).catch((error) => {
                    console.log(error);
                })
            }

            //const appUser = await fetchParents(orgUuid,authCtx.token);
            console.log('app users====',appUser);

            setCategories(categoryResponse.data);
            setEmailOnlySubscribers(emailOnlySubscribers.data);
            setUsers(appUser.data.userList);


            setIsLoading(false);
        }
        try {
            fetchData();
        } catch {
            setIsLoading(false);
            console.log('something went wrong...');
        }

    }, []);

    useEffect(() => {
        //alert(3);
        const selectesUsers = (props.selectedUsers) ? props.selectedUsers : [];
        const selectedCategoriesTags = (props.selectedCategoriesTags) ? props.selectedCategoriesTags : [];
        const selectedEmailOnlySubscribers = (props.selectedEmailOnlySubscribers) ? props.selectedEmailOnlySubscribers : [];
        setSelectedCategoryTags(selectedCategoriesTags);
        setSelectedEmailOnlySubscribers(selectedEmailOnlySubscribers);
        setSelectedUserId(selectesUsers);
        //props.setIsAllSchoolLinkAppUsersSelected(props.isAllSchoolLinkAppUsersSelected);
        //props.setIsAllEmailOnlySubscribersSelected(props.isAllEmailOnlySubscribersSelected);

    }, [props.selectedUsers, props.selectedCategoriesTags, props.selectedEmailOnlySubscribers]);


    return (
        <>
            {isLoading && <Loader />}
            <Modal
                size="xl"
                show={isOpen}
                className="xlgModelSize"
                centered={true}
                onHide={props.onAction}
                aria-labelledby="example-modal-sizes-title-lg"

            >
                { !isNext? <>
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg" className={style.modalTitle}>
                        <h4 className={tableStyle.modalTitle}>Select contact groups</h4>
                    </Modal.Title>

                    <div>
                        {/* <button type="button" className={button.modalBtn + ' ' + button.borderButton}>Campaign</button> */}
                        <button type="button" onClick={props.onAction} className={button.modalBtn + ' ' + button.borderButton + ' ' + button.buttonSpaceLeft}>Cancel</button>
                        {/*<button type="button" style={{ border: "1px solid" }} disabled={isSaving} onClick={props.isRecipientUpdate ? UpdateRecipientHandler : saveRecipientHandler} className={button.modalBtn + ' ' + button.whiteButton + ' ' + button.buttonSpaceLeft}>
                            {isSaving ? <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</> : 'Save'}
                        </button>*/}

                        <button type="button" style={{ border: "1px solid" }} disabled={isSaving} onClick={()=>{ convertDataFormatToGetRecipientsAndMoveToNextScreen();}} className={button.modalBtn + ' ' + button.whiteButton + ' ' + button.buttonSpaceLeft}>
                            {isSaving ? <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</> : 'Next'}
                        </button>

                    </div>

                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={'col-md-5 ' + style.leftGroup}>
                            <div className={style['group-list']}>
                                {(props.channels.includes("5") != true) && <>
                                    <button type="button" onClick={() => { tabChangeHandler(1) }} className={currentTab === 1 ? style.active : ''}>
                                        <div className={style.displayinline}>
                                            <div className={style.groupIcon}>
                                                <ImMobile className={style.icon} />
                                            </div>
                                            <div className={style.groupDetail}>
                                                <h5>SchoolLink app users</h5>
                                                <p>
                                                    Select your preferred contact groups from the parents who have downloaded the app. You can communicate to these groups via multiple channels.</p>
                                            </div>
                                        </div>

                                    </button>

                                    <button type="button" onClick={() => { tabChangeHandler(2) }} className={currentTab === 2 ? style.active : ''}>
                                        <div className={style.displayinline}>
                                            <div className={style.groupIcon}>
                                                <MdOutlineMarkunreadMailbox className={style.icon} />
                                            </div>
                                            <div className={style.groupDetail}>
                                                <h5>Email only subscribers</h5>
                                                <p>
                                                    These are your traditional subscribers from ePublisher, typically uploaded via CSV or web form. Can be used only for email communications.</p>
                                            </div>
                                        </div>
                                    </button>
                                </>
                                }
                            {props.isAllSchoolLinkAppUsersSelected===false?(
                                <button type="button" onClick={() => { tabChangeHandler(3) }} className={currentTab === 3 ? style.active : ''} >
                                    <div className={style.displayinline}>
                                        <div className={style.groupIcon}>
                                            <FaUserPlus className={style.icon} />
                                        </div>
                                        <div className={style.groupDetail}>
                                            <h5>Select individual app users</h5>
                                            <p >
                                                In the addition to the contact groups, if you wish to include specific app users in the notification, you can select them here.</p>
                                        </div>
                                    </div>
                                </button>):null}
                            </div>
                        </div>
                        <div className={'col-md-7 ' + style.rightGroup}>
                            <div className={style.rightSide}>{currentComponent}</div>
                        </div>
                    </div>
                </Modal.Body>
            </>:
                    <NotificationRecipientPreview
                        onAction={props.onAction}
                        saveRecipientHandler={saveRecipientHandler}
                        UpdateRecipientHandler={UpdateRecipientHandler}
                        isSaving={isSaving}
                        isNext={isNext}
                        setIsNext={setIsNext}
                        isOpen={isOpen}
                        setRecipientsCount={props.setRecipientsCount}
                        selectedParameters={selectedFilterParams}
                        isRecipientUpdate={props.isRecipientUpdate}
                        orgUuid={orgUuid}
                        setPreviewedRecipientData={props.setPreviewedRecipientData}
                        setPreviewedRecipientDataTemp={setPreviewedRecipientDataTemp}
                    />
                }

                {/*<pre>{JSON.stringify(previewedRecipientDataTemp, null, 2)}</pre>*/}
            </Modal>


            {/* next screen*/}



        </>
    );
}

export default NotificationRecipient;
