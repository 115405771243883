import React, {useState, useEffect, useRef, useContext, forwardRef, useImperativeHandle} from 'react';
import NotificationCardList from './NotificationsList/NotificationCardList'
import styles from './Editor/ComposeNotification.module.css';
import NotificationRecipient from '../ContactGroup/NotificationRecipient';
import RichTextEditorToolbar from './Editor/RichTextEditorToolbar';
import button from '../Style/ButtonStyle.module.css';
import AuthContext from '../../store/auth-context';
import {
    createNewNotification,
    createContactGoup,
    editNotificationService,
    getSelectedRecipientData,
    getUsersDetailBGgroup,
    addMessageReadStatus,
    sendMessage
} from '../../services/notification.services';
import { toast } from 'react-toastify';
import RichTextMessageEditor from './Editor/RichTextMessageEditor'
import FileUpload from './Editor/FileUpload'
import jQuery from "jquery";
import { CHANNELS } from "../../constant-data";
import common from "../Style/common.module.css";
import fontSize from "../Style/FontSize.module.css";
import font from "../Style/FontFamily.module.css";
import EditorStyle from "../Style/EditorStyle.module.css";
import SelectedAppUsersForGroup from "./SelectedAppUsersForGroup";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {FaPenSquare} from 'react-icons/fa';
import {ImAddressBook} from "react-icons/im";

window.contentBlocksType = {
    "IMAGES_GALLERY": 1,
    "VIDEOS": 2,
    "PDF_ATTACHMENTS": 3,
    "IMAGE_ATTACHMENTS": 4,
    "AUDIO_ATTACHMENTS": 5,
    "PDF_TO_IMAGE_ATTACHMENTS": 6
}

const SVGIcon = (props) => {
    return <>
        <span className={common.channelIconEdit} dangerouslySetInnerHTML={{ __html: props.svgIcondata }}></span>
    </>
}



const NotificationThread = forwardRef((props, ref) => {

    const RecipientsFocus = useRef(null);

  useEffect(() => {
    if (RecipientsFocus.current) {
        RecipientsFocus.current.focus();
    }
  }, []);

    const authCtx = useContext(AuthContext);
    const orgUuid = authCtx.userData.orgId;
    const epubOrg = authCtx.userData.epubOrgId;
    const { channels, isCardOpen, onSelectedNotificationUUID, emailOnlySubscribers } = props;
    const [notificationSubject, setNotificationSubject] = useState('');
    const [activeEditor, setActiveEditor] = useState('RICH_TEXT');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [rawMessage, setRawMessage] = useState('');
    const [messageToEdit, setMessageToEdit] = useState(false);
    const [recipientsCount, setRecipientsCount] = useState(0);
    const [createRecipient, setCreateRecipient] = useState({
        selectedUsers: [],
        selectedTags: [],
        selectedChanels: '',
        name: '',
        createdBy: '',
        emailOnlySubscribers:[]
    });
    const getRawMessage = () => {
        return rawMessage
    };
    const [isCreatingRecipient, setIsCreatingRecipient] = useState(false);
    const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [dissableEditor, setDissableEditor] = useState(false);
    const [currentContentLengthType, setCurrentContentLengthType] = useState(0);
    const [currentSubjectLenght, setCurrentSubjectLenght] = useState(0);
    const [isMessageInputFocused, setMessageInputFocused] = useState(false);
    const [isSubjectInputFocused, setSubjectInputFocused] = useState(false);
    const [isSendButtonEnabled, setSendButtonEnabled] = useState(true);
    const [isselectedcategoryiestagsViewOpen, setSelectedcategoryiestagsViewOpen] = useState(false);
    const [moreTextBlockEditorState, setmoreTextBlockEditorState]=useState();
    const [composeNotification, setComposeNotification] = useState(
        {
            id: '',
            displayOrder: 1,
            priority: 'NORMAL',
            contentType: 'TEXT',
            messageCategory:'',
            messageBody: '',
            rawMessageBody: '',
            lastModified: '',
            createdOn: '',
            status: 'ACTIVE',
            categoryId: '337f5e5d-288b-40d5-be14-901cc3acacc0',
            authorId: authCtx.userData.uuid,
            orgId: orgUuid,
            epubOrgID:epubOrg,
            starsRating: 4,
            readStatus: 0,
            readOn: '',
            authorImageUrl: (authCtx.userData.thumbImage?authCtx.userData.thumbImage :''),
            title: '',
            authorName: authCtx.userData.name,
            messageType: 'MESSAGE',
            isPasswordProtected: 'NO',
            schoolName: "Austnews2",
            isFavorite: 'NO',
            likesCount: 0,
            isLiked: false,
            isPinned: false,
            schoolImage: authCtx.userData.orgLogoUrl,
            usersGroupId: null
        }
    );
    // For contact group Edit
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCategoryTags, setSelectedCategoryTags] = useState([]);
    const [selectedEmailOnlySubscribers, setselectedEmailOnlySubscribers] = useState([]);
    const [isAllSchoolLinkAppUsersSelected, setIsAllSchoolLinkAppUsersSelected] = useState(false);
    const [isAllEmailOnlySubscribersSelected, setIsAllEmailOnlySubscribersSelected] = useState(false);
    const [previewedRecipientData, setPreviewedRecipientData] = useState([]);


    // First message of group notification
    const groupFirstNotificationSubject = props.selectedNtification.slice(0, 1);
    const richtextEditor = useRef();
    const refMoreTextBlockEditor=useRef();

    useEffect(() => {
        setSendButtonEnabled(true);
        // console.log("User Data", authCtx.userData);
        // console.log("channels", props.channels);
        window.emoj=false;
        window.emoj = jQuery("#message-composition-wrapper").emojioneArea({
            standalone: true,
            hideSource: true,
            autocomplete: false,
            recentEmojis: false
        });
        window.emoj[0].emojioneArea.off("emojibtn.click");
        window.emoj[0].emojioneArea.on("emojibtn.click", function (btn, event) {
            let emo = window.emoj[0].emojioneArea.getText();
            //console.log(emo);
            richtextEditor.current.insertEditorText(emo);
            //setNotificationMessage(emo);
        });

        const updateComposeNotification = {
            ...composeNotification,
            usersGroupId: onSelectedNotificationUUID,
            emailOnlySubscribers: emailOnlySubscribers
        };
        setComposeNotification(updateComposeNotification);
        //richtextEditor.current.insertAttachment("test");
        setDissableEditor(false);
        console.log("Props on NotificationThread.js", props);
        //props.selectUsersForGroup.previewedRecipientData
        /*if(props.selectUsersForGroup.previewedRecipientData!=null) {
            setPreviewedRecipientData(props.selectUsersForGroup.previewedRecipientData);
        }else{
            setPreviewedRecipientData([]);
        }*/
    }, [onSelectedNotificationUUID, emailOnlySubscribers,props.selectUsersForGroup,previewedRecipientData]);

    const notificationSubjectHandler = (e) => {
        setNotificationSubject(e.target.value);
        setCurrentSubjectLenght(e.target.value.length);
        if (e.target.value.length > 55) {
            setSendButtonEnabled(false);
        } else {
            setSendButtonEnabled(true);
        }
    }
    const notificationMessageHandler = (e) => {
        setNotificationMessage(e.target.value)
    }

    const setCurrentContentLengthTypeHandler = (val) => {
        console.log("setCurrentContentLengthTypeHandler", val);
        setCurrentContentLengthType(val);
    }

    const getCurrentContentLength = () => {
        return currentContentLengthType;
    }

    const filesHandlerCallback = (files) => {
        //console.log('Uploaded Files ',files);
        if (window.UPLOAD_FILES == "IMAGES_GALLERY") {
            richtextEditor.current.insertImages(files);
        }
        if (window.UPLOAD_FILES == "VIDEO") {
            richtextEditor.current.insertVideosCustomComponent(files);
        }
        if (window.UPLOAD_FILES == "PDF_ATTACHMENTS") {
            richtextEditor.current.insertPDFAttachment(files);
        }
        if (window.UPLOAD_FILES == "IMAGE_ATTACHMENTS") {
            richtextEditor.current.insertImageAttachmentCustomComponent(files);
        }
        if (window.UPLOAD_FILES == "PDF_TO_IMAGES_ATTACHMENTS") {
            richtextEditor.current.insertPdfToImageAttachmentCustomComponent(files);
        }
        if (window.UPLOAD_FILES == "AUDIO_ATTACHMENTS") {
            richtextEditor.current.insertAudioAttachmentCustomComponent(files);
        }
    }

    const updatenotificationMessage = (message) => {
        // console.log("Message set = " + message);
        setNotificationMessage(message);
    }
    const sendNewMessage=(messageUuid)=>{
        return sendMessage(messageUuid, authCtx.token);
    }

    const isBlankMessage = (rawMessage) => {
        let messageBlocks= JSON.parse(rawMessage).blocks??[];
        let isBlank = true;

        for (let i = 0; i < messageBlocks.length; i++) {
            if (messageBlocks[i].type!="atomic" && messageBlocks[i].text.trim().length > 0) {
                isBlank = false;
                break;
            }
            if (messageBlocks[i].type==="atomic") {
                isBlank = false;
                break;
            }
        }
        return isBlank;
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        let rewmsgobject = JSON.parse(rawMessage);
        let contentTypeArr = [];
        let contentTypeIdArr = [];
        let contentTypeStr = '';
        let contentTypeIdStr = '';
        if (isBlankMessage(rawMessage)) {
            alert("Message is blank.");
            return false;
        }
        if (rewmsgobject.entityMap) {
            for (let key in rewmsgobject.entityMap) {
                let e = rewmsgobject.entityMap[key];
                if (e.type == 'ARTICLES' || e.type == 'NEWSLETTERS' || e.type == 'CALENDARS' || e.type == 'SURVEYS' || e.type == 'EFLASHES' || e.type == 'ESIGNS') {
                    contentTypeArr.push(e.type);
                    if (e.type == "ARTICLES") {
                        contentTypeIdArr.push('fd5c3807-135a-4b49-a6d7-7ad6c49c8761');
                    } else if (e.type == "NEWSLETTERS") {
                        contentTypeIdArr.push('09cb889b-3cb0-4ede-98e7-a9f3a797bcd5');
                    } else if (e.type == "SURVEYS") {
                        contentTypeIdArr.push('effac112-cd2d-44ac-a163-2e55971d07e2');
                    } else if (e.type == "ESIGNS") {
                        contentTypeIdArr.push('56cb5860-bc8e-49f7-b0c4-d94b160e4eb0');
                    }
                }

            }
            contentTypeStr = contentTypeArr.join(',');
            contentTypeIdStr = contentTypeIdArr.join(',');
        }

        if (currentSubjectLenght > 55 || currentContentLengthType > 240) {
            alert("Length of subject or message body exceeded.");
            return false;
        }
        if (isCreatingRecipient) {

            if (props.channels.length == 0) {
                alert('Please select Channels');
                return;
            }
            if (notificationSubject == null || notificationSubject.length == 0) {
                alert('Subject is required.');
                return;
            }
            setIsSending(true);

            const updateGroupRecipient = {...createRecipient, selectedChanels: props.channels.join(','),name:notificationSubject,previewedRecipientData:previewedRecipientData}


            createContactGoup(updateGroupRecipient, authCtx.token).then(async (resp) => {

                //check if it is private message
                let pvtRecipient='';
                if (props.channels.includes('5') && !notificationSubject.includes('@@@')) {
                    let userDetails = await getUsersDetailBGgroup(resp.data.groupID,authCtx.token);
                    pvtRecipient=userDetails.data.groupUser[0].username+"@@@";
                }
                let draft='-#DRFT#';
                if(notificationSubject.includes('-#DRFT#')){
                    draft='';
                }

                const emailOnlySubscribers = resp.data?.groupDetail?.emailOnlySubscribers;
                const updateComposeNotification = {
                    ...composeNotification,
                    title: pvtRecipient+notificationSubject + draft,
                    messageBody: notificationMessage,
                    messageCategory: contentTypeStr,
                    categoryId: contentTypeIdStr,
                    usersGroupId: resp.data.groupID,
                    schoolName: authCtx.userData.orgName,
                    rawMessageBody: rawMessage,
                    selectedRecipient: createRecipient,
                    emailOnlySubscribers: emailOnlySubscribers
                };
                //setComposeNotification(updateComposeNotification);
                console.log('create notification data', updateComposeNotification);
                createNewNotification(updateComposeNotification, authCtx.token).then(res => {

                    /*toast.success("A new message created successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: 'dark'
                    });*/
                    setDissableEditor(true);
                    let messagesCreated = res.data[0];
                    messagesCreated = {
                        ...messagesCreated,
                        channels: Array.isArray(props.channels) ? props.channels.join(",") : props.channels,
                        contactGroupStatus: "ACTIVE",
                        emailOnlySubscribers: emailOnlySubscribers
                    }
                    props.addNotificationsToList(messagesCreated, isCreatingRecipient);

                    setIsSending(false);
                    setNotificationSubject('');
                    setNotificationMessage('');
                    setIsCreatingRecipient(false);
                    if (richtextEditor.current) {
                        richtextEditor.current.reInitializeForEdit('', '');
                    }
                    setCreateRecipient({});
                }).catch((error) => {
                    alert(error);
                    setIsSending(false);
                });

            }).catch((error) => {
                alert(error);
                setIsSending(false);
            });

        } else if (messageToEdit != false) {


            //props.updateNotificationFlagAtUuid(messageToEdit.id, '');
            props.updateNotificationAtUuid(messageToEdit.id, notificationMessage, rawMessage);

            setIsSending(true);
            const updateComposeNotification = {
                ...messageToEdit,
                title: groupFirstNotificationSubject[0]?.title,
                messageBody: notificationMessage,
                selectedRecipient: createRecipient,
                rawMessageBody: rawMessage,
            };
            editNotificationService(messageToEdit.id, updateComposeNotification, authCtx.token).then(res => {
                toast.success("Message updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });
                let messagesCreated = res.data;
                setIsSending(false);
                setNotificationMessage('');
                setRawMessage('');
                setMessageToEdit(false);
                if (richtextEditor.current) {
                    richtextEditor.current.reInitializeForEdit('', '');
                }

            }).catch((error) => {
                alert(error);
                setIsSending(false);
            });
        } else {
            if (composeNotification.usersGroupId === null) {
                alert('Please select your recipients prior to sending.');
                return;
            }
            setIsSending(true);

            //check if it is private message

            let pvtRecipient='';
            let draft='-#DRFT#';
            if (props.channels.includes('5') && !props.selectedGroupTitle.includes('@@@')) {
                let userDetails = await getUsersDetailBGgroup(composeNotification.usersGroupId,authCtx.token);
               pvtRecipient=userDetails.data.groupUser[0].username+"@@@";
            }
            if(props.selectedGroupTitle.includes('-#DRFT#')){
                draft='';
            }
            const updateComposeNotification = {
                ...composeNotification,
                title: pvtRecipient+props.selectedGroupTitle + draft,
                messageBody: notificationMessage,
                messageCategory: contentTypeStr,
                categoryId: contentTypeIdStr,
                schoolName: authCtx.userData.orgName,
                rawMessageBody: rawMessage,
            };
            //setComposeNotification(updateComposeNotification);
            createNewNotification(updateComposeNotification, authCtx.token).then(res => {
                /*toast.success("A new message created successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'dark'
                });*/
                setDissableEditor(true);
                const usersGroupId = res.data[0].usersGroupId;
                let messagesCreated = res.data[0];
                messagesCreated = {
                    ...messagesCreated,
                    channels: Array.isArray(props.channels) ? props.channels.join(",") : props.channels,
                    contactGroupStatus: "ACTIVE"
                }
                props.addNotificationsToList(messagesCreated, isCreatingRecipient);
                setIsSending(false);
                setNotificationMessage('');
                if (richtextEditor.current) {
                    richtextEditor.current.reInitializeForEdit('', '');
                }
            }).catch((error) => {
                alert(error);
                setIsSending(false);
            });
        }
    }
    // open/cloase Recipient modal
    const recipientModalHendler = () => {
        //alert(1);
        //console.log(props.onSelectedNotificationUUID+'====='+props.currentSelectedGroup.uuid);
        if (!isRecipientModalOpen && props.onSelectedNotificationUUID) {

            getSelectedRecipientData(props.onSelectedNotificationUUID, authCtx.token)
                .then((response) => {
                    console.log('getRecipientData',response.data);
                    const sliceCatTags = [];
                    const categoriesTags = response.data.usergroupcategory;

                    if (categoriesTags.length > 0) {

                        categoriesTags.forEach(catTags => {

                            const temp = {
                                [catTags.categoryID]: catTags.usergrouptag.map(t => ({ label: t.name, value: t.tagId }))
                            }
                            sliceCatTags.push(temp);
                        });
                    }
                    const selectedusersStr = response.data.selectedUsers;
                    //console.log('selectedUserStr',selectedusersStr);
                    const updateSelectedUsers = !selectedusersStr ? [] : selectedusersStr.split(",");
                    setSelectedUsers(updateSelectedUsers);
                    setSelectedCategoryTags(sliceCatTags);
                    setselectedEmailOnlySubscribers(response.data.emailOnlySubscribers);
                    if(response.data.isAllSchoolLinkAppUsersSelected=="true"){
                        setIsAllSchoolLinkAppUsersSelected(true);
                    }
                    if(response.data.isAllEmailOnlySubscribersSelected=="true"){
                        setIsAllEmailOnlySubscribersSelected(true);
                    }
                    //setIsAllEmailOnlySubscribersSelected(response.data.isAllEmailOnlySubscribersSelected);

                }).catch((error) => {
                    console.log(error);
                })
        }else{
            //setSelectedUsers([]);
        }
        setIsRecipientModalOpen((prevState) => !prevState);
    }
    // set the contact group uuid on the create notification object
    const getContactGroupHandler = (contactGroupId) => {
        console.log('finalDatatoSave=====',contactGroupId);
        setCreateRecipient(contactGroupId);
        setIsCreatingRecipient(true);
        props.resetNotificationBox();
    }
    const showEmojiPicker = () => {
        //alert("Yet to implement.");
        window.emoj[0].emojioneArea.showPicker();
    }
    const getMessageContent = () => {
        return notificationMessage;
    }
    // on create pin message
    const savePinMessageHandler = (messageId) => {

        props.onCreatePinMessage(messageId);
    }



    //Active Editor
    let currentEditor = null;
    if (props.channels.includes("3")) {
        currentEditor = <textarea onChange={notificationMessageHandler} value={notificationMessage}
            className={'form-control pl-2 my-0 ' + styles.inputbox}
            placeholder="Type your message here..." />;
    } else if (activeEditor === 'RICH_TEXT') {
        currentEditor = <RichTextMessageEditor
            notificationMessage={notificationMessage}
            rawMessage={rawMessage}
            updatenotificationMessage={updatenotificationMessage}
            setRawMessage={setRawMessage}
            getRawMessage={getRawMessage}
            setCurrentContentLengthType={setCurrentContentLengthTypeHandler}
            ref={richtextEditor}
            setSubjectInputFocused={setSubjectInputFocused}
            setMessageInputFocused={setMessageInputFocused}
            setSendButtonEnabled={setSendButtonEnabled}
            refMoreTextBlockEditor={refMoreTextBlockEditor}
            setmoreTextBlockEditorState={setmoreTextBlockEditorState}
            getMessageContent={getMessageContent} />;
    } else {
        currentEditor = <label>Please select Editor</label>;
    }


    const editMessage = (messageObject) => {
        setMessageToEdit(messageObject);
        props.updateNotificationFlagAtUuid(messageObject.id,'EDITING');
        //debugger;
        if (richtextEditor.current) {
            richtextEditor.current.reInitializeForEdit(messageObject.messageBody, messageObject.rawMessageBody);
        }
        setNotificationMessage(messageObject.messageBody);
        setRawMessage(messageObject.rawMessageBody);
        setIsEditing(true);
        props.setIsEditing(true);
        setDissableEditor(false);
    }

    const cancelEditing=(e)=>{
        if (e) {
            e.preventDefault();
        }
        if (richtextEditor.current) {
            richtextEditor.current.reInitializeForEdit('', '');
            setNotificationMessage('');
            setRawMessage('');
            if(props.selectedNtification.length==0){
                setNotificationSubject('');
            }
        }
        setIsEditing(false);
        props.setIsEditing(false);
    }
    const getCurrentContentLengthType = () => {
        return currentContentLengthType;
    }

    function onActionNotificationRecipient() {
        console.log("onActionNotificationRecipient triggered");
    }
    const ShowRecipients = () => {
        return (props.currentSelectedGroup ? <NotificationRecipient
            modalStatus={true}
            onAction={onActionNotificationRecipient}
            selectedUsers={props.currentSelectedGroup.selectedUsers}
            selectedCategoriesTags={props.currentSelectedGroup.usergroupcategory}
            selectedEmailOnlySubscribers={props.currentSelectedGroup.emailOnlySubscribers}
            isRecipientUpdate={props.currentSelectedGroup.uuid}
            channels={props.channels}
            setRecipientsCount={setRecipientsCount}
            isAllSchoolLinkAppUsersSelected={isAllSchoolLinkAppUsersSelected}
            isAllEmailOnlySubscribersSelected={isAllEmailOnlySubscribersSelected}
            setIsAllSchoolLinkAppUsersSelected={setIsAllSchoolLinkAppUsersSelected}
            setIsAllEmailOnlySubscribersSelected={setIsAllEmailOnlySubscribersSelected}
            setPreviewedRecipientData={setPreviewedRecipientData}
            previewedRecipientData={previewedRecipientData}
        /> : null);
    }
    //const [isRecipientModalOpen,]
    const [showSelectedUsersForGroup, setShowSelectedUsersForGroup]=useState(false);
    const openModelForGroupEdit = (gid) => {
        jQuery('#editgroupbtn-' + gid).trigger('click');
    }

    const [isRecipientModalOpenForEdit, setisRecipientModalOpenForEdit] = useState(false);

    useImperativeHandle(ref, () => ({

        clearEditor() {
            cancelEditing();
        },
        clearRecipientsSelection() {
            setSelectedUsers([]);
            setSelectedCategoryTags([]);
            setselectedEmailOnlySubscribers([]);
            setIsAllSchoolLinkAppUsersSelected(false);
            setIsAllEmailOnlySubscribersSelected(false);
            setIsCreatingRecipient(false);
            setRecipientsCount(0);
            setPreviewedRecipientData([]);
            richtextEditor.current.reInitializeForEdit('', '');
            setNotificationSubject('');
            props.setSelectUsersForGroup({previewedRecipientData:[],total:0});
        },
        setPreviewedRecipientData(data) {
            setPreviewedRecipientData(data);
        }

    }),[]);

    const removeNotification=(id, shouldRemoveGroup)=>{
        props.onRemoveNotification(id,shouldRemoveGroup);
    }
    console.log("isCreatingRecipient",isCreatingRecipient)
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {CHANNELS.name}
        </Tooltip>
      );


    return (
        <div className={common.rightContainer}>


            {isRecipientModalOpen && <NotificationRecipient
                modalStatus={isRecipientModalOpen}
                onAction={recipientModalHendler}
                onSave={getContactGroupHandler}
                channels={props.channels}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                setSelectUsersForGroup={props.setSelectUsersForGroup}
                selectedCategoriesTags={selectedCategoryTags}
                selectedEmailOnlySubscribers={selectedEmailOnlySubscribers}
                isAllSchoolLinkAppUsersSelected={isAllSchoolLinkAppUsersSelected}
                isAllEmailOnlySubscribersSelected={isAllEmailOnlySubscribersSelected}
                setRecipientsCount={setRecipientsCount}
                setSelectedCategoryTags={setSelectedCategoryTags}
                setselectedEmailOnlySubscribers={setselectedEmailOnlySubscribers}
                setIsAllSchoolLinkAppUsersSelected={setIsAllSchoolLinkAppUsersSelected}
                setIsAllEmailOnlySubscribersSelected={setIsAllEmailOnlySubscribersSelected}
                isRecipientUpdate={props.onSelectedNotificationUUID ? props.onSelectedNotificationUUID : false}
                setPreviewedRecipientData={setPreviewedRecipientData}
                previewedRecipientData={previewedRecipientData}
            />}


            {isRecipientModalOpenForEdit && <ShowRecipients />}

            {onSelectedNotificationUUID &&
                <div className={styles.ComposeNotificationHeader + ' ' + props.contactGroupStatus + ' container '+common.firstBlock} title={props.onSelectedNotificationUUID }>
                    <div className="row" style={{height:"100%"}}>
                        <div className={"col-8 " + styles.SelectedUserGroupDetails} style={{ display: "block" }}>
                            <strong className={common.threadHeaderTitle}>{props.selectedGroupTitle}</strong>
                        </div>



                        <div className="col-4 d-flex flex-row" style={{ display: "block", alignItems:"center",justifyContent:"flex-end",paddingRight:"24px"}}>
                            {/*<button className="btn btn-outline-primary" onClick={()=>setisRecipientModalOpenForEdit(true)}>Edit</button>*/}

                            {showSelectedUsersForGroup && <SelectedAppUsersForGroup currentSelectedGroup={props.currentSelectedGroup} users={previewedRecipientData.length} recipients={props.selectUsersForGroup.previewedRecipientData} show={showSelectedUsersForGroup} onHide={() => setShowSelectedUsersForGroup(false)} />}

                            <div className="btn-group btn-group-sm" role="group">

                                <button onClick={()=>setShowSelectedUsersForGroup(true)} style={{ }} type="button" className="btn btn-secondary ">Recipients: {previewedRecipientData.length} </button>

                                {props.channels.includes("5") != true || true?(
                                    <button style={{ }} className="btn btn-secondary " style={{borderLeft:"1px solid #9f9f9f"}} type="button" onClick={recipientModalHendler}>
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                                             className={common.messageIcon}>
                                            <g transform="matrix(2,0,0,2,0,0)">
                                                <defs></defs>
                                                <path strokeWidth="2px" style={{ strokeLinecap: 'unset' }} className="a"
                                                      d="M9.164 16.178a6.729 6.729 0 0 1 0-9.515"></path>
                                                <path
                                                    d="M9.165 6.663 11.327 4.5 13 6.173l-2.162 2.163zm-.001 9.516 1.577-1.577 2.163 2.162-1.577 1.577z"></path>
                                                <path style={{ strokeLinecap: 'unset' }} className="a"
                                                      d="M17 12.5v10H3V.5h18v12h-4zm4 3v3m0 2v3"></path>
                                            </g>
                                        </svg>*/}
                                        <ImAddressBook style={{height:"20px"}}/>

                                    </button>):null}
                            </div>

                        </div>



                        {/*<div className="col-12" style={{padding:"27px 7px"}}>
                                <div className="progress" style={{height:"5px"}}>
                                    <div className="progress-bar " role="progressbar" style={{width:"25%", backgroundColor:"#49c096"}}
                                         aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>*/}
                    </div>
                </div>



            }
            <div className={common.editorInner+' '+common.secondBlock}>
                {/*
                <span style={{ color: "red", marginRight: '10px' }}>
                <i className="fa fa-refresh "></i>
                </span>
                */}
                {!onSelectedNotificationUUID && channels.length > 0 && (
                    <div style={{ padding: '9px 17px 20px' }}>
                        {(isCreatingRecipient==true)?
                            <button ref={RecipientsFocus} style={{color:'#FFFFFF'}} className={styles.Selectrecipientbtn} onClick={recipientModalHendler}>
                            Recipients selected {recipientsCount>0?`(${recipientsCount})`:''}{" "}
                            <span className={EditorStyle.recipientIcon}>
                                <i title={'Edit selection'} className="icon icon-pencil5"></i>
                            </span>
                        </button>:
                            <button ref={RecipientsFocus} className={styles.Selectrecipientbtn} onClick={recipientModalHendler}>
                                Select Your Recipients{" "}
                                <span className={EditorStyle.recipientIcon}>
                                <i className="icon icon-plus-circle"></i>
                            </span>
                            </button>
                        }
                    </div>)
                }


                {isCardOpen && <NotificationCardList
                    selectedNotificationData={props.selectedNtification}
                    removeNotification={removeNotification}
                    onSavePinMessage={savePinMessageHandler}
                    onEditMessage={editMessage}
                    sendMessage={sendNewMessage}
                    setDissableEditor={setDissableEditor}
                    contactGroupStatus={props.contactGroupStatus}
                />}
                {!isCardOpen && <div style={{ height: "auto" }}></div>}
            </div>

            {/* Border to separate list and editor */}

            <form className={"form-group basic-textarea " + props.contactGroupStatus + ' ' + EditorStyle.editorView + ' ' + common.editorbody+' '+common.thirdBlock}
                style={{ display: (props.contactGroupStatus == "INACTIVE") ? "none" : "" }} onSubmit={submitHandler}>
                <div style={{ borderBottom: "1px solid rgb(244 248 251)", width: "536px", marginLeft: "15px", boxShadow: "0 -1px 1px rgba(108, 107, 107, 0.23)", zIndex: "99" }}></div>

                {previewedRecipientData.length <=0 && <div style={{backgroundColor:'rgb(251 251 251 / 50%)', height:'100%', width:'100%', position:'absolute', zIndex:'2', backdropFilter:'blur(2px)'}}>
                    <span style={{ position: "relative",top: "80px", left: "180px", color: "#f00a", border:"1px solid #cccccc", borderRadius:"5px", padding:"7px",background:"#ffffff", boxShadow:"1px 1px 3px #ccc", cursor:"pointer"}} className={""} onClick={recipientModalHendler}>Please select your recipients.</span>
                </div>}

                {dissableEditor && <div style={{backgroundColor:'rgb(251 251 251 / 50%)', height:'100%', width:'100%', position:'absolute', zIndex:'2', backdropFilter:'blur(2px)'}}>
                    <span style={{ position: "relative",top: "80px", left: "180px", color: "#f00a"}}></span>
                </div>}

                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div id="selectedChannelsInComposer" className='aaa'
                                style={{
                                    position: "",
                                    marginTop: "0",
                                    padding: '10px 5px',
                                    top: '10px',
                                    float: "left"
                                }}>
                                {CHANNELS.map((channel) => {
                                    return channels.includes(channel.id) &&
                                    <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 400 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>
                                          {channel.name}
                                        </Tooltip>
                                      )}
                                    >
                                  <span><SVGIcon svgIcondata={channel.svgIcon} /> </span>
                                </OverlayTrigger>



                                })
                                }
                            </div>
                        </div>
                        <div className="col-9" style={{"paddingRight":"25px"}}>
                            {isSubjectInputFocused &&
                                <div className={styles.ContentLengthDispaly}>
                                    {currentSubjectLenght > 55 && <span className={styles.invaliderror}>Character limit(max 65) exceeded for subject line.</span>}
                                    {currentSubjectLenght <= 55 && <span>Characters remaining for subject: {(55 - currentSubjectLenght)}/55</span>}
                                </div>
                            }
                            {isMessageInputFocused &&
                                <div className={styles.ContentLengthDispaly}>
                                    {currentContentLengthType > 240 && <span className={styles.invaliderror}>Character limit(max 240) exceeded for message body.</span>}
                                    {currentContentLengthType <= 240 && <span>Characters remaining for message body: {(240 - currentContentLengthType)}/240</span>}
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/*Editor Area starts from here*/}
                <div className={styles.composemsgbox}>
                    {!onSelectedNotificationUUID && (
                        <><input onFocus={() => { setSubjectInputFocused(true); setMessageInputFocused(false) }}
                            onChange={notificationSubjectHandler}
                            type="text"
                            className={'form-control  pl-2 my-0 ' + styles.inputbox + ' ' + styles.subjectLine}
                            placeholder="Type your subject here..."
                            value={notificationSubject.replace("-#DRFT#","")}
                                 id="message-subject-line"
                        />
                            <div className={styles.inputline}></div>
                        </>
                    )}

                    {currentEditor}
                    <FileUpload filesHandlerCallback={filesHandlerCallback} />

                </div>
                {/*Editor Area ends from here*/}


                {/*Bottom buttons area starts*/}
                <div className={styles.features}>

                    <div className={styles.bottomBorder}>
                        <RichTextEditorToolbar
                            setActiveEditor={setActiveEditor}
                            showEmojiPicker={showEmojiPicker}
                            richtextEditor={richtextEditor}
                            channels={props.channels}
                            getCurrentContentLength={getCurrentContentLength}
                            moreTextBlockEditorState={moreTextBlockEditorState}
                        />
                        {(currentContentLengthType > 0) && <button onClick={cancelEditing} style={{marginRight:'10px',border:'1px solid #d1d1d6',backgroundColor:'transparent',padding:'5px 10px',color:'#d1d1d6'}} className={button.btn + ' ' + button + ' ' + styles.sendButton} > Cancel</button>  }
                        <button type="submit"
                            disabled={(!notificationMessage.trim().length > 0) || isSending}
                            className={button.btn + ' ' + button.blueButton + ' ' + styles.sendButton}
                            style={{ position: " inherit", float: 'right' }}>

                            {!isSending ? 'Preview' : ''}
                            {isSending && <> <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Preview...</>
                            }
                        </button>
                    </div>
                </div>
                {/*Bottom buttons area ends*/}
            </form>

            <div className={common.fourthBlock} style={{ fontSize: "12px", textAlign: "center", margin: "6px 0", color: "#888888" }}>All Rights Reserved {new Date().getFullYear()}
            </div>
        </div>
    );
})

export default NotificationThread;
