import {MultiSelect} from "react-multi-select-component";

const EmailOnlySubscriberTags = (props) => {

    const { isAllEmailOnlySubscribersSelected } = props;

    const { pkCategoryID, TagitemsId, Tagitems, onSelectTags, selectedEmailOnlySubscribers } = props;
    const options = TagitemsId.map((tag, i) => ({ label: Tagitems[i], value: tag }));

    const setSelectedHandler = (tagId) => {

        onSelectTags(tagId, pkCategoryID);
    }
    let selectedTags;
    const find = selectedEmailOnlySubscribers.find(t => t[pkCategoryID])

    if (find !== undefined) {
        selectedTags = find[pkCategoryID];
    } else {
        selectedTags = [];
    }
    return (
        <MultiSelect
            options={options}
            value={selectedTags}
            labelledBy="labelledBy"
            onChange={setSelectedHandler}
            disabled={isAllEmailOnlySubscribersSelected}
        />
    );
}

export default EmailOnlySubscriberTags;
