import {useState, useEffect, useContext} from 'react';
import AuthenticationCodeList from './AuthCodeList';
import AuthenticationCodeSearchForm from '../SchoolAuthenticationCodeGenerator/AuthenticationCodeSearchForm';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Loader from '../UI/Loader/Loader';
import style from '../SchoolAuthenticationCodeGenerator/SchoolAuthenticationCodeGenerator.module.css'
import { getAuthenticationCode, generateNewCode,getDeactiveAuthenticationCode } from '../../services/SchoolAuthenticationCodeGenerator.services';
import { toast } from 'react-toastify';
import AuthContext from "../../store/auth-context";
import button from '../Style/ButtonStyle.module.css';
import tableStyle from '../Style/TableModel.module.css';

//const orgUuid = '7bce8766-5bd8-4194-b455-ac579dc36587';

const SchoolAuthenticationCodeGenerator = (props) => {

    const [isGeneratingCode, setIsGeneratingCode] = useState(false);
    const [organisationCodes, setOrganisationCodes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showTab, setShowTab] = useState(true);
    const authCtx = useContext(AuthContext);
    const orgUuid=authCtx.userData.orgId;
    useEffect(() => {
        if(orgUuid!=null){
            getAuthenticationCode(orgUuid)
            .then((response) => {
                setIsLoading(false);
                setOrganisationCodes(response.data);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
        }
       
    }, [orgUuid]);

    const getDeActiveGenerateCode = () => {
        getDeactiveAuthenticationCode(orgUuid,authCtx.token)
            .then((response) => {
                setIsLoading(false);
                //console.log(response.data);
                // response.data.map(async (val,index)=>{
                //     //console.log(val);
                //     const  ActiveUser = await getActiveUserCount(orgUuid,val.code)
                //     //console.log(ActiveUser.data[index]);
                //     response.data[index].useractive = ActiveUser.data.activeUser;
                //     //response.data['index']['activeuser'] = ActiveUser.data.activeUser
                // })
                //console.log(response.data);
                setOrganisationCodes(response.data);
                setShowTab(false);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
    }


    const getActiveCodeList = () => {
        getAuthenticationCode(orgUuid,authCtx.token)
            .then((response) => {
                setIsLoading(false);
                //console.log(response.data);
                // response.data.map(async (val,index)=>{
                //     //console.log(val);
                //     const  ActiveUser = await getActiveUserCount(orgUuid,val.code)
                //     //console.log(ActiveUser.data[index]);
                //     response.data[index].useractive = ActiveUser.data.activeUser;
                //     //response.data['index']['activeuser'] = ActiveUser.data.activeUser
                // })
                //console.log(response.data);
                setOrganisationCodes(response.data);
                setShowTab(true);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
    }
    const generateNewCodeHandler = () => {

        setIsGeneratingCode(true);

        generateNewCode(orgUuid)
            .then((response) => {
                const generatedCode = response.data;
                const newCode = {
                    id: generatedCode.id,
                    code: generatedCode.code,
                    createDate: generatedCode.createDate,
                    codeStatus: generatedCode.codeStatus,
                }
                const copyOrganisationCodes = [...organisationCodes];
                copyOrganisationCodes.unshift(newCode);
                setOrganisationCodes(copyOrganisationCodes);
                setIsGeneratingCode(false);

                toast.success("A new code generated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });

            })
            .catch((err) => {
                console.error(err);
                setIsGeneratingCode(false);
            });
    }

    const searchHandler = (searchInputText) => {
        setSearchText(searchInputText)
    }
    //let filterdCodes = '';
    //if(organisationCodes.length>0){
        const filterdCodes = organisationCodes.filter((c) => {
            if (searchText)
                return (c.code+'').includes(searchText);
            return true;
        });
    //}
   

    return (<>
        <div
            size="xl"
            show={true}
            aria-labelledby="example-modal-sizes-title-lg"
            onHide={props.onclick}>

            <Modal.Body>
                <div className={"row " + style.margin}>
                    <div className={'col-md-6 ' + button.tabBox}>
                    <button type="button" onClick={generateNewCodeHandler} className={button.modalBtn + ' ' + button.redButton + ' ' + button.iconBtn} disabled={isGeneratingCode} >
                            {!isGeneratingCode ? <> <i className="icon icon-unlocked"></i> Generate New </> : ''
                            }
                            {isGeneratingCode && <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Generating...
                            </>
                            }
                        </button>
                        {/* {!showTab && <ul className={button.tabButton}>
                                <li><button onClick={()=>{getActiveCodeList()}} >Active Code</button></li>
                                <li className={button.tabActive}><button onClick={()=>{getDeActiveGenerateCode()}} >DeActive Code</button></li>
                            </ul>
                        }
                        {showTab && <ul className={button.tabButton}>
                                <li  className={button.tabActive}><button onClick={()=>{getActiveCodeList()}}>Active Code</button></li>
                                <li><button onClick={()=>{getDeActiveGenerateCode()}} >DeActive Code</button></li>
                            </ul>
                        } */}
                        </div>

                        <div className='col-md-6'>
                    <div className="search-box" style={{marginBottom:'0'}}>
                        <AuthenticationCodeSearchForm onSearch={searchHandler} />
                    </div>
                    </div>
                </div>
                {isLoading && <Loader />}
                <AuthenticationCodeList organisationCodes={filterdCodes} setOrganisationCodes={setOrganisationCodes} setKey={props.setKey} setSelectedCode={props.setSelectedCode} showTab={showTab}/>

            </Modal.Body>
        </div>
    </>
    )

}

export default SchoolAuthenticationCodeGenerator;
