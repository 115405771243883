import { useState, useRef,useContext, useCallback, useEffect } from 'react';
import {Link, Redirect, useNavigate} from 'react-router-dom';
import tableStyle from '../Style/TableModel.module.css';
import button from '../Style/ButtonStyle.module.css';
import AuthContext from "../../store/auth-context";
import {disableVerificationCode,enableVerificationCode } from '../../services/notification.services';
import { getAuthenticationCode, generateNewCode,getActiveUserCount,getDeactiveAuthenticationCode } from '../../services/SchoolAuthenticationCodeGenerator.services';
import { toast } from 'react-toastify';
import '../Style/Table-style.css';
import fontSize from '../Style/FontSize.module.css';
import font from '../Style/FontFamily.module.css';
import common from '../Style/common.module.css';
import Modal from 'react-modal';
const AuthenticationCode = (props) => {
    console.log('codelist===',props);
    const [modalIsOpen, setIsOpen] = useState(false);
    const authCtx = useContext(AuthContext);
    const [isCopied, setIsCopied] = useState(false);
    const codeRef = useRef(null);
    const orgUuid = authCtx.userData.orgId;
    const navigate = useNavigate();
    const copyCodeHandler = () => {

        // console.log('clicked', props); return false;

        const codeText = props.code;
        navigator.clipboard.writeText(codeText).then(function () {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 600);
        },
            function (err) {
                console.error('Async: Could not copy text: ', err);
            });
    }

    const openModal = (uid) => {
        setIsOpen(true);
    }
    const modalClose = ()=>{
        setIsOpen(false)
    }

    const disableVerifyCode = (code) =>{
        //if (window.confirm("Do you want to Deactivate verification code?")) {
            if(code){
            const userPostData = {
                        ucode: code,
                        orgUuid: orgUuid,
             }
            disableVerificationCode(userPostData,authCtx.token)
            .then((response) => {
                //setIsLoading(false);
                //console.log('codeList afetr disable',response.data)
                getAuthenticationCode(orgUuid,authCtx.token)
            .then((response) => {
                //setIsLoading(false);
                //console.log(response.data);
                // response.data.map(async (val,index)=>{
                //     //console.log(val);
                //     const  ActiveUser = await getActiveUserCount(orgUuid,val.code)
                //     //console.log(ActiveUser.data[index]);
                //     response.data[index].useractive = ActiveUser.data.activeUser;
                //     //response.data['index']['activeuser'] = ActiveUser.data.activeUser
                // })
                //console.log(response.data);
                props.setOrganisationCodes(response.data);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                //setIsLoading(false);
            });
                toast.success("Verification code Deactivated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });
                setIsOpen(false)
               //setOrganisationCodes(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                //setIsLoading(false);
            });
            }
       // }
    }

    const enableVerifyCode = (code) =>{
        //if (window.confirm("Do you want to Activate verification code?")) {
            if(code){
            const userPostData = {
                        ucode: code,
                        orgUuid: orgUuid,
             }
            enableVerificationCode(userPostData,authCtx.token)
            .then((response) => {
                //setIsLoading(false);
                //console.log('codeList afetr disable',response.data)
                getDeactiveAuthenticationCode(orgUuid,authCtx.token)
            .then((response) => {
                //setIsLoading(false);
                //console.log(response.data);
                // response.data.map(async (val,index)=>{
                //     //console.log(val);
                //     const  ActiveUser = await getActiveUserCount(orgUuid,val.code)
                //     //console.log(ActiveUser.data[index]);
                //     response.data[index].useractive = ActiveUser.data.activeUser;
                //     //response.data['index']['activeuser'] = ActiveUser.data.activeUser
                // })
                //console.log(response.data);
                props.setOrganisationCodes(response.data);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                //setIsLoading(false);
            });
                toast.success("Verification code Activated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });
                setIsOpen(false)
               //setOrganisationCodes(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                //setIsLoading(false);
            });
            }
       // }
    }

    const filterByCode = (code) =>{
        //console.log('code==========',code);
        //<Redirect to={"/Organisation/1#parents?"+code} />
        //histy.replace("/Organisation/1#parents?"+code);
        navigate("/Organisation/1#parents?"+code);
        props.setKey('second');
        props.setSelectedCode(code)
        //window.location.href="/Organisation/1#parents?vcode="+code;

    }

    const dt = new Date(props.createdOn);
    //const dt1 = new Date(props.createdOn);
    //console.log("codeDate",dt1.getDate());
    let getCodeDateVal = dt.getDate();
    let getCodeYearVal = dt.getFullYear();
    let getCodeMonthVal = dt.getMonth();


    const month = dt.toLocaleString('en-US', { month: 'short' });
    const day = dt.toLocaleString('en-US', { day: '2-digit' });
    const year = dt.getFullYear();
    const createdOn = `${day + ' ' + month + ' ' + year}`;

    let curentDate = new Date();
    //console.log("currentDate",curentDate.getDate());
    let getCurrentDateVal = curentDate.getDate();
    let getCurrentYearVal = curentDate.getFullYear();
    let getCurrentMonthVal = curentDate.getMonth();
    let newCodeClass = '';
    if(getCodeDateVal==getCurrentDateVal && getCodeYearVal==getCurrentYearVal && getCodeMonthVal==getCurrentMonthVal){
        newCodeClass = 'active';
    }
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            modalClose();
        }
      }, [modalClose]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);
    console.log(newCodeClass)
    return (
        <tr className={newCodeClass}>
            <td>
                <button ref={codeRef} type="button" className={"list-btn whiteButton " + tableStyle.codeNo}>
                    {props.code}
                </button>
            </td>
            <td></td>
            <td>{createdOn}</td>
            <td><a onClick={()=>filterByCode(props.code)} className='contactTag'>{props.usersCount>0?props.usersCount:0}</a></td>
            <td>
                {isCopied && <button type="button" className={"copyButton " + button.actionBtn + ' ' + button.yellowButton}>Copied!</button>}
                {props.showTab && !isCopied ? <button type="button" onClick={copyCodeHandler} className={"copyButton " + button.actionBtn + ' ' + button.yellowButton}>
                    <i className="icon icon-copy3"></i> Copy
                </button> : null}
                {!props.showTab && !isCopied ? <button type="button" disabled onClick={copyCodeHandler} className={"copyButton " + button.actionBtn}>
                    <i className="icon icon-copy3"></i> Copy
                </button> : null}
            </td>
            <td>
                <div className="form-check form-switch ">
                    {props.codeStatus == 'INACTIVE' &&
                    <input className="form-check-input" type="checkbox" role="switch" onClick={e=>openModal(props.code)} />}
                    {props.codeStatus == 'ACTIVE' &&
                    <input className="form-check-input" type="checkbox" role="switch" onClick={e=>openModal(props.code)} checked={disableVerifyCode=='ACTIVE'?false:true}/>}
                </div>
                {/* {!props.showTab && <div className="form-check form-switch ">
                    <input className="form-check-input" type="checkbox" role="switch" onClick={e=>enableVerifyCode(props.code)} />
                </div>
                }
                {props.showTab && <div className="form-check form-switch ">
                    <input className="form-check-input" type="checkbox" role="switch" onClick={e=>disableVerifyCode(props.code)} checked={disableVerifyCode=='ACTIVE'?false:true}/>
                </div>
                } */}
            </td>

            {modalIsOpen && <Modal isOpen={modalIsOpen} className="alertBgContainer" contentLabel="Example Modal" >
                <div className='alertBox'>
                    <h4 className={tableStyle.alertTitle}>Alert</h4>
                    <div>
                    {props.codeStatus == 'INACTIVE' &&
                    <div>
                         <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you want to <b className={font.fontBold}>enable this authentication code?</b></p>
                        <div className='bottomArea'>
                            <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                            <button onClick={e=>enableVerifyCode(props.code)} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>
                        </div>
                    </div>
                    }
                    {props.codeStatus == 'ACTIVE' &&
                    <div>
                       <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you want to <b className={font.fontBold}>disable this authentication code?</b></p> <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>This will force all users who are currently using this code to access the SchoolLink app to be signed out immediately.</p>
                        <div className='bottomArea'>
                            <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                            <button onClick={e=>disableVerifyCode(props.code)} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>
                        </div>
                    </div>
                    }


                    </div>
                </div>
            </Modal>
            }

        </tr>
    )
}

export default AuthenticationCode;
