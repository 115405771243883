import {Button, Overlay, Popover, Stack} from "react-bootstrap";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Editor} from 'react-draft-wysiwyg';
import {stateToHTML} from 'draft-js-export-html';
import style from "./ComposeNotification.module.css";
import moreTextBlockStyle from './MoreTextBlock.module.css'
import editor from "../../Style/EditorStyle.module.css";
import button from "../../Style/ButtonStyle.module.css";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertFromRaw, convertToRaw, EditorState, Modifier} from "draft-js";
const MoreTextBlock=forwardRef((props,ref)=>{
    const [show, setShow] = useState(props.show);
    const [target, setTarget] = useState(document.getElementById("MoreTextBlockShowButton"));
    const [editorState, setEditorState]=useState(props.moreTextBlockEditorState?props.moreTextBlockEditorState:null);
    const [plainTextLength, setPlainTextLength]=useState(0);
    //const ref = useRef(props.ref);
    const handleClick = (event,v1) => {
        console.log(event);
        let l = props.getCurrentContentLength();
        console.log("content length",l);
        setEditorState(EditorState.createEmpty());
        if(window.moretextToBeEdited){
            const contentBlock = htmlToDraft(window.moretextToBeEdited);
            //const contentBlock = htmlToDraft("Hello World!");
            let contentState1 = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState1))
        }
        setShow(!show);
        setTarget(event.target);
    };

    const onEditorStateChange=(editorState)=>{

        setPlainTextLength(editorState.getCurrentContent().getPlainText().replace('\n','').trim().length);
        setEditorState(editorState);

    }

    const insertInMainEditorAsHTML=()=>{
        let contentState = editorState.getCurrentContent();
        let html = stateToHTML(contentState, {});
        let rawState= convertToRaw(contentState);
        console.log("MoreTextBLock - Saving html ",html);
        props.addMoreTextInEditor({html:html,raw:rawState});
        setShow(false);
        setEditorState(false);
    }

    const cleanWordHtml=(str)=>{
        {

            str = str.replace(/<o:p>\s*<\/o:p>/g, "") ;
            str = str.replace(/<o:p>.*?<\/o:p>/g, "&nbsp;") ;
            str = str.replace( /\s*mso-[^:]+:[^;"]+;?/gi, "" ) ;
            str = str.replace( /\s*MARGIN: 0cm 0cm 0pt\s*;/gi, "" ) ;
            str = str.replace( /\s*MARGIN: 0cm 0cm 0pt\s*"/gi, "\"" ) ;
            str = str.replace( /\s*TEXT-INDENT: 0cm\s*;/gi, "" ) ;
            str = str.replace( /\s*TEXT-INDENT: 0cm\s*"/gi, "\"" ) ;
            str = str.replace( /\s*TEXT-ALIGN: [^\s;]+;?"/gi, "\"" ) ;
            str = str.replace( /\s*PAGE-BREAK-BEFORE: [^\s;]+;?"/gi, "\"" ) ;
            str = str.replace( /\s*FONT-VARIANT: [^\s;]+;?"/gi, "\"" ) ;
            str = str.replace( /\s*tab-stops:[^;"]*;?/gi, "" ) ;
            str = str.replace( /\s*tab-stops:[^"]*/gi, "" ) ;
            str = str.replace( /\s*face="[^"]*"/gi, "" ) ;
            str = str.replace( /\s*face=[^ >]*/gi, "" ) ;
            str = str.replace( /\s*FONT-FAMILY:[^;"]*;?/gi, "" ) ;
            str = str.replace(/<(\w[^>]*) class=([^ |>]*)([^>]*)/gi, "<$1$3") ;
            str = str.replace( /<(\w[^>]*) style="([^\"]*)"([^>]*)/gi, "<$1$3" ) ;
            str = str.replace( /\s*style="\s*"/gi, '' ) ;
            str = str.replace( /<SPAN\s*[^>]*>\s*&nbsp;\s*<\/SPAN>/gi, '&nbsp;' ) ;
            str = str.replace( /<SPAN\s*[^>]*><\/SPAN>/gi, '' ) ;
            str = str.replace(/<(\w[^>]*) lang=([^ |>]*)([^>]*)/gi, "<$1$3") ;
            str = str.replace( /<SPAN\s*>(.*?)<\/SPAN>/gi, '$1' ) ;
            str = str.replace( /<FONT\s*>(.*?)<\/FONT>/gi, '$1' ) ;
            str = str.replace(/<\\?\?xml[^>]*>/gi, "") ;
            str = str.replace(/<\/?\w+:[^>]*>/gi, "") ;
            str = str.replace( /<H\d>\s*<\/H\d>/gi, '' ) ;
            str = str.replace( /<H1([^>]*)>/gi, '' ) ;
            str = str.replace( /<H2([^>]*)>/gi, '' ) ;
            str = str.replace( /<H3([^>]*)>/gi, '' ) ;
            str = str.replace( /<H4([^>]*)>/gi, '' ) ;
            str = str.replace( /<H5([^>]*)>/gi, '' ) ;
            str = str.replace( /<H6([^>]*)>/gi, '' ) ;
            str = str.replace( /<\/H\d>/gi, '<br>' ) ; //remove this to take out breaks where Heading tags were
            str = str.replace( /<(U|I|STRIKE)>&nbsp;<\/\1>/g, '&nbsp;' ) ;
            str = str.replace( /<(B|b)>&nbsp;<\/\b|B>/g, '' ) ;
            str = str.replace( /<([^\s>]+)[^>]*>\s*<\/\1>/g, '' ) ;
            str = str.replace( /<([^\s>]+)[^>]*>\s*<\/\1>/g, '' ) ;
            str = str.replace( /<([^\s>]+)[^>]*>\s*<\/\1>/g, '' ) ;
//some RegEx code for the picky browsers
            var re = new RegExp("(<P)([^>]*>.*?)(<\/P>)","gi") ;
            str = str.replace( re, "<div$2</div>" ) ;
            var re2 = new RegExp("(<font|<FONT)([^*>]*>.*?)(<\/FONT>|<\/font>)","gi") ;
            str = str.replace( re2, "<div$2</div>") ;
            str = str.replace( /size|SIZE = ([\d]{1})/g, '' ) ;
            return str ;
        }
    }

    const cleanPaste = (html) =>{
        // Run the standard YUI cleanHTML method
       // html = cleanWordHtml(html);

        // Remove additional MS Word content
        html = html.replace(/<(\/)*(\\?xml:|meta|link|html|body|head|span|font|del|ins|st1:|[ovwxp]:)((.|\s)*?)>/gi, ''); // Unwanted tags
        html = html.replace(/(class|style|type|start)=("(.*?)"|(\w*))/gi, ''); // Unwanted sttributes
        html = html.replace(/<style(.*?)style>/gi, '');   // Style tags
        html = html.replace(/<script(.*?)script>/gi, ''); // Script tags
        html = html.replace(/<!--(.*?)-->/gi, '');        // HTML comments

        return html;
    }
    const _handlePastedText = (text, html, editorState) => {
        const selection = editorState.getSelection();
        const cleanedHtml=cleanPaste(html);
        const contentBlock = htmlToDraft(cleanedHtml);
        const pastedBlocks = ContentState.createFromBlockArray(contentBlock).getBlockMap();
        const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            pastedBlocks,
        );
        const newEditorState = EditorState.push(editorState, newState, "insert-fragment");
        //this.handleChange(newEditorState);
        setEditorState(newEditorState);
        console.log("pasting done....");
        return "handled";
    };
    useImperativeHandle(ref, () => ({
        openMoreTextBlockEditor(text) {
            console.log("test now lskdf");
            setShow(true);
            setTarget(document.getElementById("MoreTextBlockShowButton"));
        }
    }),[editorState]);

    return (
        <div ref={ref} style={{display:'flex'}}>
            <button id="MoreTextBlockShowButton" onClick={handleClick} type="button" className={style.iconclass} title="Add More Text">
                <span className={editor.fileTextSvgIcon}></span>
            </button>
            <Overlay
                show={show}
                target={target}
                placement="top-start"
                container={ref}
                containerPadding={20}
                rootClose={true}
                onHide={handleClick}
            >
                <Popover id="popover-contained" style={{borderRadius:'10px'}} >
                    <Popover.Body style={{padding:"10px",borderRadius:'10px', width:"510px"}} >
                        { props.getCurrentContentLength() > 0 ? <Editor
                           editorState={editorState}
                           onEditorStateChange={onEditorStateChange}
                           handlePastedText={_handlePastedText}
                           editorClassName={moreTextBlockStyle.CustomStyle}
                            placeholder="Insert your additional text here..."
                           toolbar={{
                               options: ['inline', 'list', 'link'],
                               inline: {
                                   options: ['bold', 'italic', 'underline'],
                                   bold: {className: 'bordered-option-classname'},
                                   italic: {className: 'bordered-option-classname'},
                                   underline: {className: 'bordered-option-classname'},
                                   inDropdown: false,
                               },
                               list: {
                                   inDropdown: false,
                                   className: undefined,
                                   component: undefined,
                                   dropdownClassName: undefined,
                                   options: ['unordered', 'ordered'],

                               },
                               link: {
                                   inDropdown: false,
                                   className: undefined,
                                   component: undefined,
                                   popupClassName: undefined,
                                   dropdownClassName: undefined,
                                   showOpenOptionOnHover: true,
                                   defaultTargetOption: '_self',
                                   options: ['link', 'unlink'],
                                   linkCallback: undefined
                               },
                               blockType: {
                                   className: 'bordered-option-classname',
                               },
                               fontSize: {
                                   className: 'bordered-option-classname',
                               },
                               fontFamily: {
                                   className: 'bordered-option-classname',
                               },
                           }}

                       />: <div style={{padding:"10px",borderRadius:'10px',textAlign:'center', color:"#e13838"}}>First add text to the primary text box. Use this for additional text. </div>}
                        <div style={{padding:"10px",borderTop:"1px solid #ccc"}}>
                            <Stack direction="horizontal" className="justify-content-md-end" gap={1}>
                                <Button  size="sm" variant="secondary" onClick={handleClick}>Cancel</Button>
                                { props.getCurrentContentLength() > 0? <Button disabled={plainTextLength<1}  size="sm" variant="primary" onClick={insertInMainEditorAsHTML}>Save</Button>:null}
                            </Stack>
                        </div>
                    </Popover.Body>

                </Popover>
            </Overlay>
        </div>
    );

});

export default MoreTextBlock;
