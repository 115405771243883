import {useState, useEffect, useMemo, forwardRef, useImperativeHandle, useRef} from 'react';
import ReactDOMServer from 'react-dom/server';
import {
    SelectionState,
    EditorState,
    convertToRaw,
    ContentState,
    Modifier,
    convertFromRaw,
    AtomicBlockUtils,
    Entity
} from "draft-js";
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateToHTML} from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import jQuery from "jquery";
import style from '../../Style/EditorStyle.module.css';
import '../../Style/RichEditor.css'
import customRenderToHtml from './CustomRendererToHtml';
import EpubContentBlock from "./EpubContentRenderingBlock";
import moreTextBlockStyle from './MoreTextBlock.module.css';
import MoreTextBlockRender from "./MoreTextBlockRender";
import axios from "axios";
import EditAudioPlayer from './EditAudioPlayer';
import NotificationCardStyle from "../NotificationsList/NotificationCard.module.css";
import pdfIcon from '../../../assets/admin-pdf-icon.jpg'
import imgIcon from '../../../assets/admin-img-icon.jpg'
style.attchView = undefined;
const RichTextMessageEditor = (props, ref) => {

    const editorRef = useRef();
    const refMoreTextBlockEditor = useRef();
    const editorStateRef = useRef();
    const [imageProcessing, setImagesProcess]=useState(false);
    const contentBlock = htmlToDraft(props.notificationMessage);
    //const contentBlock = htmlToDraft("Hello World!");
    let contentState1 = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    if (props.getRawMessage().trim().length>0) {
        //console.log('rawMessage = ',props.rawMessage);
        //console.log('rawMessageObject = ',JSON.parse(props.rawMessage));
        contentState1 = convertFromRaw(JSON.parse(props.rawMessage));
    }
    const [state, setState] = useState(() => EditorState.createWithContent(contentState1));
    const [contentLength, setContentLength]=useState(0);
    let localState = state;

    const moreTextBlockEditorState=(contenState)=>{
        props.setmoreTextBlockEditorState(contenState);
    }

    const onEditorStateChange = (editorState) => {
        setState(editorState);
        let contentState = editorState.getCurrentContent();
        let contentLength = contentState.getPlainText().replace('\n','').trim().length;

        props.setCurrentContentLengthType(contentLength);
        if(contentLength>240){
            props.setSendButtonEnabled(false);
        }else{
            props.setSendButtonEnabled(true);
        }
        let rawDraftContentState = convertToRaw(contentState);
        let options = {
            blockRenderers: {
                atomic: (block) => {
                    let entity = contentState.getEntity(block.getEntityAt(0));
                    let data = entity.getData();
                    let type = entity.getType();
                    let media;
                    media = customRenderToHtml(type, data);
                    let htmlized = ReactDOMServer.renderToString(media);
                    return htmlized;
                },
            },
        };
        //let html = draftToHtml(rawDraftContentState);
        let html = stateToHTML(contentState, options);
        props.updatenotificationMessage(html);
        let s = JSON.stringify(rawDraftContentState);

        props.setRawMessage(s);
    };

    function reInitializeForEdit(messageBody,rawMessageBody){
        // if(rawMessageBody.trim.length>0){
        //     console.log("Initialigng with Raw content",rawMessageBody);
        //     let contentState = convertFromRaw(JSON.parse(rawMessageBody));
        //     let editorState=EditorState.createWithContent(contentState)
        //     setState(editorState);
        // }else{
        //     let contentBlock = htmlToDraft(messageBody);
        //     let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //     let editorState=EditorState.createWithContent(contentState);
        //     setState(editorState);
        // }
        if(rawMessageBody.length==0){
            setState(EditorState.createEmpty());
        }else {
            let contentState = convertFromRaw(JSON.parse(rawMessageBody));
            let editorState = EditorState.createWithContent(contentState)
            setState(editorState);
        }
    }

    function updateEditorContent(contentStateWithEntity, editorState) {
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity},
            "create-entity"
        );

        let newState = AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            " "
        );

        setState(newState);
        Entity.mergeData(entityKey);
        //console.log("entityKey",entityKey);
        return entityKey;
    }

    const addImagesComponent = (images) => {


        let arrayOfThumbNails=[];
        setImagesProcess(true);
        let checkInterval = setInterval(()=>{
            let count=0;
            for (const k in images) {
                let image=images[k];
                console.log("Checking url ",image.thumbUrl);
                fetch(image.thumbUrl,{ mode: 'no-cors'})
                    .then(response=> {
                        console.log("File response is ok, stopping setInterval");
                        //
                        if (arrayOfThumbNails.filter(e => e.thumbUrl === image.thumbUrl).length > 0) {
                            console.log("already fetched");
                        }else {
                            arrayOfThumbNails.push({thumbUrl: image.thumbUrl, data: response});
                        }
                    })
                    .catch((error)=> {
                        console.log("File is not on server ",error);
                    });
            }
            console.log("arrayOfThumbNails", arrayOfThumbNails);
            if(arrayOfThumbNails.length===images.length){
                console.log("thumbnails are equal to images passed. Now creating block in editor");

                const editorState = state;
                const contentState = editorState.getCurrentContent();
                const contentStateWithEntity = contentState.createEntity(
                    "IMAGES_GALLERY",
                    "IMMUTABLE",
                    {images: images}
                );
                let entityKey=updateEditorContent(contentStateWithEntity, editorState);
                console.log("Stopping polling loop");
                clearInterval(checkInterval);
                setImagesProcess(false);
                arrayOfThumbNails=[];
            }
        },5000);





    }

    const addVideosCustomComponent=(videos)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "VIDEOS",
            "IMMUTABLE",
            {videos: videos}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }
    const addYouTubeComponent=(youtube)=>{
        let youtubes= [youtube];
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "YOUTUBE",
            "IMMUTABLE",
            {youtube: youtubes}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addPdfAttachmentCustomComponent=(attachments)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "PDF_ATTACHMENTS",
            "IMMUTABLE",
            {pdfAttachments: attachments}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addImageAttachmentCustomComponent=(images)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "IMAGE_ATTACHMENTS",
            "IMMUTABLE",
            {imageAttachments: images}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addAudioAttachmentCustomComponent=(audios)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "AUDIO_ATTACHMENTS",
            "IMMUTABLE",
            {audioAttachments: audios}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }


    const addPdfToImageAttachmentCustomComponent=(pdfs)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "PDF_TO_IMAGES_ATTACHMENTS",
            "IMMUTABLE",
            {pdfsToImagesAttachments: pdfs}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addExternalLinkComponent=(links)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "EXTERNAL_LINKS",
            "IMMUTABLE",
            {externalLinks: links}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addArticlesComponent=(articles)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "ARTICLES",
            "IMMUTABLE",
            {articles: articles}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addNewslettersComponent=(newsletters)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "NEWSLETTERS",
            "IMMUTABLE",
            {newsletters: newsletters}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }



    const addCalendarsComponent=(calendars)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "CALENDARS",
            "IMMUTABLE",
            {calendars: calendars}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addSurveysComponent=(surveys)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "SURVEYS",
            "IMMUTABLE",
            {surveys: surveys}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addEFlashesComponent=(eflashes)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "EFLASHES",
            "IMMUTABLE",
            {eflashes: eflashes}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }

    const addESignsComponent=(esigns)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "ESIGNS",
            "IMMUTABLE",
            {esigns: esigns}
        );
        updateEditorContent(contentStateWithEntity, editorState);
    }
    const addMoreTextBLockComponent=(data)=>{
        const editorState = state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "MORETEXT",
            "MUTABLE",
            {moretext: data.html,raw:data.raw}
        );
        if (window.moretextToBeEdited) {
            //debugger;
            let entityKey=window.moretextToBeEdited_entityKey;
            contentState.replaceEntityData(entityKey,{moretext: data.html});
            setState(EditorState.forceSelection(state, state.getSelection()));
        } else {
            updateEditorContent(contentStateWithEntity, editorState);
        }
    }

    const addEpubContentComponent=(list, type)=>{
        switch (type){
            case "article": addArticlesComponent(list); break;
            case "newsletter": addNewslettersComponent(list); break;
            case "survey": addSurveysComponent(list); break;
            case "esign": addESignsComponent(list); break;
            default: console.log("Type did not match ",type);
        }
    }

    const removeBlock = (editorState) => {
        //console.log("Removing block");
    }
    const insertCharacter = (characterToInsert, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();
        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            characterToInsert
        );
        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        return newEditorState;
    }
    useImperativeHandle(ref, () => ({
        insertEditorText(text) {
            if (text.length > 0) {
                let newEditorState = insertCharacter(text, state);
                setState(newEditorState);
            }
        },
        insertImages(images){
            if(images.length>0){
                addImagesComponent(images);
            }
        },
        insertAttachment(attachment) {
            if (attachment) {
                console.log("inserting attachement");
            }
        },
        insertYouTubeVideo(youtubeVideoID){
            if(youtubeVideoID!=false){
                addYouTubeComponent(youtubeVideoID);
            }
        },
        insertVideosCustomComponent(videos){
            addVideosCustomComponent(videos);
        },
        insertPDFAttachment(pdfs){
            addPdfAttachmentCustomComponent(pdfs);
        },
        insertImageAttachmentCustomComponent(images){
            addImageAttachmentCustomComponent(images);
        },
        insertAudioAttachmentCustomComponent(audios){
            addAudioAttachmentCustomComponent(audios);
        },
        insertPdfToImageAttachmentCustomComponent(pdfs){
            addPdfToImageAttachmentCustomComponent(pdfs);
        },
        insertExternalLinkComponent(links){
            addExternalLinkComponent(links);
        },
        insertArticlesComponent(articles){
            addArticlesComponent(articles);
        },
        insertNewslettersComponent(newsletters){
            addNewslettersComponent(newsletters);
        },
        insertCalendarsComponent(calendars){
            addCalendarsComponent(calendars);
        },
        insertSurveysComponent(surveys){
            addSurveysComponent(surveys);
        },
        insertEFlashesComponent(eflashes){
            addEFlashesComponent(eflashes);
        },
        insertESignsComponent(esignes){
            addESignsComponent(esignes);
        },
        insertEpubContentComponent(list,type){
            addEpubContentComponent(list,type);
        },
        insertMoreTextBLockComponent(data){
            addMoreTextBLockComponent(data);
        },
        reInitializeForEdit(messageBody,rawMessageBody){
            reInitializeForEdit(messageBody,rawMessageBody);
        }


    }), [state]);


    const removeBlockByKey = (contentState, blockKey) => {
        const afterKey = contentState.getKeyAfter(blockKey);
        const afterBlock = contentState.getBlockForKey(afterKey);
        let targetRange;

        // Only if the following block the last with no text then the whole block
        // should be removed. Otherwise the block should be reduced to an unstyled block
        // without any characters.
        if (afterBlock &&
            afterBlock.getType() === 'unstyled' &&
            afterBlock.getLength() === 0 &&
            afterBlock === contentState.getBlockMap().last()) {
            targetRange = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: 0,
                focusKey: afterKey,
                focusOffset: 0,
            });
        } else {
            targetRange = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: 0,
                focusKey: blockKey,
                focusOffset: 1,
            });
        }

        // change the blocktype and remove the characterList entry with the block
        const newContentState = Modifier.setBlockType(
            contentState,
            targetRange,
            'unstyled'
        );
        return Modifier.removeRange(newContentState, targetRange, 'backward');
    }

    const replaceBlockContentByKey = (contentState, blockKey,data) => {
        const afterKey = contentState.getKeyAfter(blockKey);
        const afterBlock = contentState.getBlockForKey(afterKey);
        let targetRange;

        // Only if the following block the last with no text then the whole block
        // should be removed. Otherwise the block should be reduced to an unstyled block
        // without any characters.
        if (afterBlock &&
            afterBlock.getType() === 'unstyled' &&
            afterBlock.getLength() === 0 &&
            afterBlock === contentState.getBlockMap().last()) {
            targetRange = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: 0,
                focusKey: afterKey,
                focusOffset: 0,
            });
        } else {
            targetRange = new SelectionState({
                anchorKey: blockKey,
                anchorOffset: 0,
                focusKey: blockKey,
                focusOffset: 1,
            });
        }

        // change the blocktype and remove the characterList entry with the block
        const newContentState = Modifier.setBlockType(
            contentState,
            targetRange,
            'unstyled'
        );
        return Modifier.removeRange(newContentState, targetRange, 'backward');
    }


    const Image = props => {
        const onClickHandler = (e) => {

            console.log(props.editorState);
            console.log("clicked", e.target);
        }
        if (!!props.src) {
            return (
                <div style={{ border: "1px solid #fcfcfc", margin: "3px", borderRadius: "5px",width: props.signleImage ?"100%":"130px",height: "130px",overflow: "hidden", position: "relative"}}>
                    <span className={style.removeFile}><span className="fa fa-close"></span></span>
                    <img alt="Loading ..." src={props.src} key={props.name}/>
                </div>
            );
        }
        return null;
    };

    const Video=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc",margin: "5px",borderRadius: "5px",width: "350px",height: "150px",overflow: "hidden",position: "relative"}}>
                    <span className={style.removeFile}><span className="fa fa-close"></span></span>
                    <video width="320" height="240" controls poster={props.thumbUrl}>
                        <source src={props.src} type="video/mp4"/>
                    </video>
                </div>
            );
        }
        return null;
    }
    const Youtube=props=>{
        if (!!props.src) {
            return  <iframe width="420" height="315" src={"https://www.youtube.com/embed/"+props.src}></iframe>
        }
        return null;
    }
    const PdfAttachment=props=>{

        if (!!props.src) {

            return (
                <div>
                    <a href={props.src} download target="_blank" style={{textDecoration:'none',color:'#8E8E93',fontSize:'12px',lineHeight:'normal',}}>
                        <div style={{display:"flex",flexDirection:"row",width:"auto",padding:"0",alignItems:'center'}} className={style.fileAttachView}>
                            <span className={style.removeFile}><span className="fa fa-close"></span></span>
                            <img src={pdfIcon} className={style.hideIcon} style={{padding:'12px 0px 11px 13px',width:'30px',marginRight:'4px'}} />
                            <i class="icon icon-file-pdf"></i>
                            <div style={{padding:"0",}}>
                                <p style={{display:'block',color:'#1B1B1B',margin:'0',fontWeight:'600',fontSize:'12px',lineHeight:'normal',}}>{props.name}</p>
                                <span style={{color:"#8E8E93",fontSize:"12px",textDecoration:'none',display:'block',marginTop:'3px'}}>File Download</span>
                            </div>
                        </div>
                    </a>
                </div>
            );
        }
        return null;
    }


    const ImageAttachment=props=>{

        if (!!props.src) {
            return (
                <div>
                    <a href={props.src} download target="_blank" style={{textDecoration:'none',color:'#8E8E93',fontSize:'12px',lineHeight:'normal',}}>
                        <div style={{display:"flex",flexDirection:"row",width:"auto",padding:"0",alignItems:'center'}} className={style.fileAttachView}>
                        <span className={style.removeFile}><span className="fa fa-close"></span></span>
                        <img src={imgIcon} className={style.hideIcon} style={{padding:'12px 0px 11px 13px',width:'30px',marginRight:'4px'}} />
                        <i class="icon icon-images2"></i>
                        <div style={{padding:"0",}}>
                            <p style={{display:'block',color:'#1B1B1B',margin:'0',fontWeight:'600',fontSize:'12px',lineHeight:'normal',}}>{props.name}</p>
                            <span style={{color:"#8E8E93",fontSize:"12px",textDecoration:'none',display:'block',marginTop:'3px'}}>File Download</span>
                        </div>
                        </div>
                    </a>
                </div>
            );
        }
        return null;
    }

    const AudioAttachment=props=>{

        if (!!props.src) {

            return <EditAudioPlayer rawData={props}  />

        }
        return null;
    }

    const PdfToImageAttachment=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc", margin: "5px", borderRadius: "5px", width: "100%", height: "150px", overflow: "hidden", position: "relative"}}>
                    <span className={style.removeFile}><span className="fa fa-close"></span></span>
                    <h3>PDF to Image </h3>
                    <a href={props.src}>{props.name}</a>
                </div>
            );
        }
        return null;
    }
    const ExternalLink=props=>{
        if (!!props.url) {
            return (
                <div>
                    <span className={style.removeFile}><span className="fa fa-close"></span></span>
                    <div className={style.linkImg}>
                        <img style={{width:"100%"}} src={props.image}/>
                    </div>
                    <div className={style.linkTitle}><h3>{props.title}</h3></div>
                    <div className={style.linkText}><p>{props.description}</p></div>
                    <a className={style.link} href={props.url}>{props.url}</a>
                </div>
            );
        }
        return null;
    }


    const Article=props=>{
        if (!!props.src) {
            let infoParts=props.src.split('||');
            return (
                <div style={{width:"342px",padding:"9px 5px 8px"}}>
                    <a href={infoParts[0]}>
                        <div style={{display:"flex",flexDirection:"row",width:"auto",padding:"0"}} className={style.fileAttachView}>
                            <i style={{padding:"12px 0px 11px 13px"}} className="icon icon-file-text2"></i>
                            <div style={{padding:"0", width:"200px"}}><p>{infoParts[2]}</p>
                                <span style={{color:"#8E8E93",fontSize:"12px"}}>{infoParts[1]}</span></div>

                        </div>
                    </a>
                </div>
            );
        }
        return null;
    }

    const EpubContent=props=>{
        if (!!props.src) {
            let infoParts=props.src.split('||');
            return (
                <div>
                    <a href={infoParts[0]}>
                        <div className={style.fileAttachView}>
                            <i className="icon icon-file-pdf"></i>
                            <p>{infoParts[2]}</p>
                            <span>{infoParts[1]}</span>
                        </div>
                    </a>
                </div>
            );
        }
        return null;
    }

    const Newsletter=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc", margin: "5px", borderRadius: "5px", width: "150px", height: "150px", overflow: "hidden", position: "relative"}}>
                    <i className="fa fa-close" style={{position: "absolute", right: "5px", top: "5px", textShadow: "#000000b0 0px 0px 0px", color: "#000", cursor: "pointer"}}></i>
                    Newsletter
                    <a href={props.src}>{props.name}</a>
                </div>
            );
        }
        return null;
    }

    const Calendar=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc", margin: "5px", borderRadius: "5px", width: "150px", height: "150px", overflow: "hidden", position: "relative"}}>
                    <i className="fa fa-close" style={{position: "absolute", right: "5px", top: "5px", textShadow: "#000000b0 0px 0px 0px", color: "#000", cursor: "pointer"}}></i>
                    Calendar
                    <a href={props.src}>{props.name}</a>
                </div>
            );
        }
        return null;
    }

    const Survey=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc", margin: "5px", borderRadius: "5px", width: "150px", height: "150px", overflow: "hidden", position: "relative"}}>
                    <i className="fa fa-close" style={{position: "absolute", right: "5px", top: "5px", textShadow: "#000000b0 0px 0px 0px", color: "#000", cursor: "pointer"}}></i>
                    Survey
                    <a href={props.src}>{props.name}</a>
                </div>
            );
        }
        return null;
    }

    const EFlash=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc", margin: "5px", borderRadius: "5px", width: "150px", height: "150px", overflow: "hidden", position: "relative"}}>
                    <i className="fa fa-close" style={{position: "absolute", right: "5px", top: "5px", textShadow: "#000000b0 0px 0px 0px", color: "#000", cursor: "pointer"}}></i>
                    Eflash
                    <a href={props.src}>{props.name}</a>
                </div>
            );
        }
        return null;
    }

    const ESign=props=>{
        if (!!props.src) {
            return (
                <div style={{border: "1px solid #fcfcfc", margin: "5px", borderRadius: "5px", width: "150px", height: "150px", overflow: "hidden", position: "relative"}}>
                    <i className="fa fa-close" style={{position: "absolute", right: "5px", top: "5px", textShadow: "#000000b0 0px 0px 0px", color: "#000", cursor: "pointer"}}></i>
                    eSign
                    <a href={props.src}>{props.name}</a>
                </div>
            );
        }
        return null;
    }

    const Media = props => {

        const entity = props.contentState.getEntity(props.block.getEntityAt(0));
        const entityKey=props.block.getEntityAt(0);
        const data = entity.getData();
        const type = entity.getType();
        const blockKey = props.block.getKey();
        let media=null;

        const deleteAll = () => {

            let newContentState = removeBlockByKey(state.getCurrentContent(), blockKey);
            let newEditorState = EditorState.push(
                state,
                newContentState,
                "remove-range"
            );
            setState(newEditorState);
        }
        const editMoreTextBlcok=(e)=>{
            console.log("Edit button clicked....");
            moreTextBlockEditorState(props.contentState);
            let optionsToRender={
                blockRenderers: {
                    atomic: (block) => {
                        let entity = props.contentState.getEntity(block.getEntityAt(0));
                        let data = entity.getData();
                        let type = entity.getType();
                        let media;
                        media = <div>{data.moretext}</div>;
                        let htmlized = ReactDOMServer.renderToString(media);
                        return htmlized;
                    },
                },
            }

            let html=stateToHTML(props.contentState, optionsToRender);
            //console.log("HTML of the text to be edited ",data.moretext);
            window.moretextToBeEdited=data.moretext;
            window.moretextToBeEdited_entityKey=entityKey;
            //let editorstate=EditorState.createWithContent(props.contentState);
            document.getElementById("MoreTextBlockShowButton").click(e,"test");
            // props.refMoreTextBlockEditor.current.openMoreTextBlockEditor("test asld flksdf ");
        }

        if (type === "IMAGES_GALLERY") {
            let remaining = data.images.length - 4;
            let width = data.images.length === 1 ? "278px!important" : "";
            let height = data.images.length === 1 ? "278px!important" : "";
            let singleImageContainer = data.images.length === 1 ? style.singleImageContainer : "";
            let renderedList = null;
            if (data.images.length === 1) {
                renderedList = data.images.map((e) => {
                    return <div style={{width: '278px', height: '160px', marginLeft: "0px", marginTop: "0px"}}><img
                        className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                });
            } else if (data.images.length === 2){

                renderedList = data.images.map((e) => {
                    return <div style={{marginLeft: "0px", marginTop: "0px"}}><img
                        className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                });
            } else if (data.images.length === 3) {
                renderedList = data.images.map((e, index) => {

                    if (index<2) {
                        if (index < 1) {
                            return <div style={{marginLeft: "0px", marginTop: "0px"}}><img
                                className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                        } else {
                            return <div style={{position: "relative", marginLeft: "0px", marginTop: "0px"}}>
                                {  <span
                                        className={NotificationCardStyle.FourthImageStyle}><span className={NotificationCardStyle.plusIocn}>+</span>{"1"}</span>}
                                <img
                                    className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                        }
                    }
                });
            } else {
                renderedList = data.images.map((e, index) => {
                    if (index<4) {
                        if (index < 3) {
                            return <div style={{marginLeft: "0px", marginTop: "0px"}}><img
                                className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                        } else {
                            return <div style={{position: "relative", marginLeft: "0px", marginTop: "0px"}}>
                                {remaining > 0 && <span
                                    className={NotificationCardStyle.FourthImageStyle}><span className={NotificationCardStyle.plusIocn}>+</span>{remaining + ""}</span>}
                                <img
                                    className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                        }
                    }
                });
            }

            media = (
                <div className={style.editArea+' images-gallery '+style.customContentBlock} style={{width:"290px"}}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div style={{width: width, height: height}} className={style.imageStyleView + ' ' + singleImageContainer}
                 >{renderedList}</div>
                </div>
            );
        }
        if (type === "VIDEOS") {
            let renderedList = data.videos.map((e) => {
                if(e.embededCode){
                    e=e.embededCode;
                }
                e=e.replace("640","260");
                e=e.replace("360","180");
                return <div dangerouslySetInnerHTML={{__html: e}}></div>
            })

            media = (
                <div  className={style.editArea+' videos '+style.customContentBlock}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div className={style.videoStyleView}>{renderedList}</div>
                </div>
            );
        }
        if (type === "YOUTUBE") {
            media = (
                <div  className={style.editArea+' youtube '+style.customContentBlock}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div className={style.youtubeStyleView}>
                        <Youtube src={data.youtube} />
                    </div>
                </div>
            );
        }
        if (type === "PDF_ATTACHMENTS") {

            let renderedList = data.pdfAttachments.map((e) => {
                //console.log(e);
                return <PdfAttachment editorState={state} src={e.url} thumbUrl={e.thumbUrl} key={e.name} name={e.name}/>
            })

            media = (
                <div  className={style.editArea+' pdf-attachment '+style.customContentBlock}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div className={style.fileAttachView}>{renderedList}</div>
                </div>
            );
        }

        if (type === "IMAGE_ATTACHMENTS") {
            let renderedList = data.imageAttachments.map((e) => {
                return <ImageAttachment editorState={state} src={e.url} thumbUrl={e.thumbUrl} key={e.name} name={e.name}/>
            })

            media = (
                <div  className={style.editArea+' image-attachment '+style.customContentBlock}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div className={style.fileAttachView}>{renderedList}</div>
                </div>
            );
        }

        if (type === "AUDIO_ATTACHMENTS") {

            let renderedList = data.audioAttachments.map((e) => {
                return <AudioAttachment editorState={state} src={e.url} thumbUrl={e.thumbUrl} key={e.name} name={e.name}/>
            })

            media = (
                <div  className={style.customContentBlock+" audio-attachment"}>
                    <div className={style.rightAlign}><a style={{cursor: "pointer"}} className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div style={{display: 'flex', flexWrap: 'wrap', position: "relative"}}>{renderedList}</div>
                </div>
            );
        }
        if (type === "PDF_TO_IMAGES_ATTACHMENTS") {
            let renderedList = data.pdfsToImagesAttachments.map((e) => {
                return <PdfToImageAttachment editorState={state} src={e.url} thumbUrl={e.thumbUrl} key={e.name} name={e.name}/>
            })

            media = (
                <div  className={style.editArea+' pdftoimage '+style.customContentBlock}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div style={{display: 'flex', flexWrap: 'wrap', position: "relative"}}>{renderedList}</div>
                </div>
            );
        }

        if (type === "EXTERNAL_LINKS") {
            let renderedList = data.externalLinks.map((e) => {
                return <ExternalLink editorState={state} url={e.url} image={e.image} key={e.title.length} title={e.title} description={e.description}/>
            })

            media = (
                <div   className={style.editArea+' externallinks '+style.customContentBlock}>
                    <div className={style.rightAlign}><a className={style.deleteBtn} onClick={deleteAll}>Delete All</a></div>
                    <div className={style.externalLinksView}>{renderedList}</div>
                </div>
            );
        }

        if (type === "ARTICLES") {
            media = data.articles.map((e) => {
                let values=e.split("||");
                return <EpubContentBlock inEditMode={true} link={values[0]} titleName={values[2]} authname={values[1]} iconClass="icon icon-file-text2" deleteAll={deleteAll}/>
            });
        }
        if (type === "NEWSLETTERS") {
            media = data.newsletters.map((e) => {
                let values=e.split("||");
                return <EpubContentBlock inEditMode={true} link={values[0]} titleName={values[2]} authname={values[1]} iconClass="icon icon-stack" deleteAll={deleteAll}/>
            });
        }
        if (type === "CALENDARS") {
            let renderedList = data.calendars.map((e) => {
                return <Calendar editorState={state} src={e.url} thumbUrl={e.thumbUrl} key={e.name} name={e.name}/>
            })

            media = (
                <div className={style.customContentBlock+' calendars'}  style={{textAlign: "left", border: "1px solid #F7F7F7", borderRadius: "5px", padding: "5px"}}>
                    <a style={{cursor: "pointer"}} onClick={deleteAll}>Delete All</a>
                    <div style={{display: 'flex', flexWrap: 'wrap', position: "relative"}}>{renderedList}</div>
                </div>
            );
        }
        if (type === "SURVEYS") {
            media = data.surveys.map((e) => {
                let values=e.split("||");
                return <EpubContentBlock inEditMode={true} link={values[0]} titleName={values[2]} authname={values[1]} iconClass="icon icon-clipboard2" deleteAll={deleteAll}/>
            });
        }

        if (type === "EFLASHES") {
            let renderedList = data.eflashes.map((e) => {
                return <EFlash editorState={state} src={e.url} thumbUrl={e.thumbUrl} key={e.name} name={e.name}/>
            })

            media = (
                <div className={style.customContentBlock+" eflash"}  style={{textAlign: "left", border: "1px solid #F7F7F7", borderRadius: "5px", padding: "5px"}}>
                    <a style={{cursor: "pointer"}} onClick={deleteAll}>Delete All</a>
                    <div style={{display: 'flex', flexWrap: 'wrap', position: "relative"}}>{renderedList}</div>
                </div>
            );
        }

        if (type === "ESIGNS") {
            media = data.esigns.map((e) => {
                let values=e.split("||");
                let svgIcon= <svg style={{width:"28px",margin:"12px",height:"23px"}} className="svg-inline--fa fa-signature fa-w-20" aria-hidden="true" focusable="false"
                                  data-prefix="fas" data-icon="signature" role="img" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 640 512" data-fa-i2svg="">
                    <path fill="#49c096"
                          d="M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z"></path>
                </svg>;
                return <EpubContentBlock inEditMode={true} link={values[0]} titleName={values[2]} authname={values[1]} iconClass="icon icon-pen5" svgIcon={svgIcon} deleteAll={deleteAll}/>
            });
        }
        if (type === "MORETEXT") {
            //console.log("MoreTextBlock - Rendering more text after save ",data.moretext);
            media =<div className={moreTextBlockStyle.outerContainer}>
                <div className={moreTextBlockStyle.editButtonContainer}>
                    <a className={""+moreTextBlockStyle.edit_button} onClick={editMoreTextBlcok}><i className="fa fa-edit"></i></a>
                </div>
                <div dangerouslySetInnerHTML={{__html: data.moretext}}></div>
            </div>
        }

        return media;
    };
    // const editMoreTextBlock=(e,text)=>{
    //     e.stopPropagation();
    //     console.log("editMoreTextBlock ",text);
    // }
    const mediaBlockRenderer = (block) => {
        if (block.getType() === "atomic") {
            return {
                component: Media,
                editable: false
            };
        }
        return null;
    };

    const _getLengthOfSelectedText = () => {
        const currentSelection = state.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = state.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            console.log(currentSelection)
            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                };
            }
        }

        return length;
    }

    const MAX_LENGTH=240;
    const _handleBeforeInput = () => {

        const currentContent = state.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = _getLengthOfSelectedText();
        let msg=document.getElementById("max-length");
        if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
            //msg.style.display="block";
            //return 'handled';
        }else{
            //msg.style.display="none";
        }
    }

    const _handlePastedText = (text, html, editorState) => {
        const selection = editorState.getSelection();
        const pastedBlocks = ContentState.createFromText(text).blockMap;
        const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            pastedBlocks,
        );
        const newEditorState = EditorState.push(editorState, newState, "insert-fragment");
        //this.handleChange(newEditorState);
        setState(newEditorState);
        console.log("pasting done....");
        return "handled";
    };

    return (
        <div className={style.richtextEdit}>
            {/*<div style={{display:"none"}} id="max-length" className="alert alert-danger" role="alert">*/}
            {/*    Sorry, you've exceeded your limit of 240 characters.*/}
            {/*</div>*/}
            <Editor

                editorState={state}
                onFocus={()=>{props.setMessageInputFocused(true);props.setSubjectInputFocused(false);}}
                handleBeforeInput={_handleBeforeInput}
                //handlePastedText={_handlePastedText}
                wrapperClassName="richtext-editor-wrapper"
                editorClassName="richtext-editor"
                className={style.aaa}
                onEditorStateChange={onEditorStateChange}
                blockRendererFn={mediaBlockRenderer}
                ref={editorRef}
                toolbar={{
                    options: ['inline', 'list', 'link'],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                        bold: {className: 'bordered-option-classname'},
                        italic: {className: 'bordered-option-classname'},
                        underline: {className: 'bordered-option-classname'},
                        inDropdown: false,
                    },
                    list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered'],

                    },
                    link: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: '_self',
                        options: ['link', 'unlink'],
                        linkCallback: undefined
                    },
                    blockType: {
                        className: 'bordered-option-classname',
                    },
                    fontSize: {
                        className: 'bordered-option-classname',
                    },
                    fontFamily: {
                        className: 'bordered-option-classname',
                    },
                }}
            />
            {imageProcessing && <div>
                <div style={{fontSize:"14px",color:"#c9c9c9",display:"inline-block"}}>Processing images, Please wait.</div>
                <div className="spinner-grow" role="status" style={{marginLeft:"10px",width:"12px",height:"12px",verticalAlign:"middle"}}>
                    <span className="sr-only"></span>
                </div>
            </div>}
            <div style={{display:"none"}}>
                <button onClick={addVideosCustomComponent}>Add Vidoes</button>
                <button onClick={addPdfAttachmentCustomComponent}>Add PDF</button>
                <button onClick={addImageAttachmentCustomComponent}>Add Image</button>
                <button onClick={addAudioAttachmentCustomComponent}>Add Audio</button>
                <button onClick={addPdfToImageAttachmentCustomComponent}>Add Pdf to Image</button>
                <button onClick={addExternalLinkComponent}>Add External Links</button>
                <button onClick={addArticlesComponent}>Add Articles</button>
                <button onClick={addNewslettersComponent}>Add Newsletters</button>
                <button onClick={addCalendarsComponent}>Add Calendars</button>
                <button onClick={addSurveysComponent}>Add Surveys</button>
                <button onClick={addEFlashesComponent}>Add EFlash</button>
                <button onClick={addESignsComponent}>Add eSign</button>

            </div>
        </div>


    );
}

export default forwardRef(RichTextMessageEditor);
