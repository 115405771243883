import { useState } from 'react';
import style from './SchoolLinkAppUsers.module.css';
import Pagination from '../../UI/Pagination/Pagination';
import CategoryTags from './CategoryTags';
import { GoSearch } from "react-icons/go";
import '../../Style/FormStyle.css';
import '../../Style/Pagination.css';

let PageSize = 6;
const SchoolLinkAppUsers = (props) => {
    console.log('Selected App User',props);
    const { categories, isAllSchoolLinkAppUsersSelected, setIsAllSchoolLinkAppUsersSelected } = props;

    const [searchInputText, setSearchInputText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const schoolLinkAppUsersHandler = (e) => {

        setSearchInputText(e.target.value);
        setCurrentPage(1);
    }
    const selectAllHandler = () =>{
        if(isAllSchoolLinkAppUsersSelected==true){
            setIsAllSchoolLinkAppUsersSelected(false);
        }else{
            setIsAllSchoolLinkAppUsersSelected(true);
        }
    }
    const searchTextArray = searchInputText.toLowerCase().trim().split(' ');

    const categoriesWithTags=categories.filter(c=>c.tags.length>0);
    const searchData = categoriesWithTags.filter((category) => {
        return searchTextArray.every(i => category.name.toLowerCase().indexOf(i) > -1)
    });

    const sliceData = searchData.slice(firstPageIndex, lastPageIndex);

    return (<>
            <div className={'form-group ' + style.titleGroup}>
                <label className={style.label} >Select users from specific contact groups</label>
                <br/>
            </div>

        <form>
            <div className={'form-group ' + style.hassearch}>
                <GoSearch className={style.searchIcon} />
                <input
                    onChange={schoolLinkAppUsersHandler}
                    type="text"
                    className={style.formcontrol}
                    placeholder="Search..."
                />
            </div>
        </form>
            <br/>
        <form>
            <div className='form-group'>
                <div className={'form-check ' + style.allGroup}>
                    <input className={'form-check-input ' + style.groupInput} type="checkbox"
                        onChange={() => selectAllHandler()}
                        checked={isAllSchoolLinkAppUsersSelected} />
                    <label className={'form-check-label ' + style.groupLabel} htmlFor="flexCheckDefault">
                        Select All app users ( {props.users.length} )
                    </label>
                </div>
            </div>
            <div>

                {sliceData.map((category, i) => {
                    const tagsArr = category.tags.map(tag => {
                        return {
                            label: tag.name,
                            value: tag.id
                        }
                    })

                    return (
                        <div className={'form-group ' + style.selectGroup} key={category.id}>
                            {isAllSchoolLinkAppUsersSelected=="true"?(
                            <label className={style.inputLabel} style={{color:"#8E8E93"}}>{category.name}</label>):(<label className={style.inputLabel}>{category.name}</label>)}
                            {category.tags.length > 0 &&
                                <CategoryTags
                                    categoryId={category.id}
                                    tagsArr={category.tags}
                                    options={tagsArr}
                                    tabIndex="0"
                                    value={props.selectedTags}
                                    onChanged={props.onSelectTag}
                                    labelledBy="Select"
                                    selectedCategoryTags={props.selectedCategoryTags}
                                    isAllSchoolLinkAppUsersSelected={isAllSchoolLinkAppUsersSelected}
                                />}

                        </div>
                    );
                })}
            </div>
        </form>
        <div className="modalStyle padd-0">
            <Pagination
                currentPage={currentPage}
                totalCount={searchData.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    </>
    );
}

export default SchoolLinkAppUsers;
