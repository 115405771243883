import React, { useContext,useEffect, useState } from 'react';
import NotificationRecipient from '../ContactGroup/NotificationRecipient';
import { getSelectedRecipientData, deactivateNotificationThread,fetchAppUsers, getUsersDetailBGgroup} from '../../services/notification.services';
import { CHANNELS } from '../../constant-data';
import { ImAddressBook, ImCommand } from "react-icons/im";
import { FaRegChartBar, FaLock } from "react-icons/fa";
import style from './NotificationListItem.module.css';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import font from '../Style/FontFamily.module.css';
import { toast } from 'react-toastify';
import ConfirmAlertModal from '../UI/ConfirmAlertModal/ConfirmAlertModal';
import AuthContext from "../../store/auth-context";
import lockIcon from "../../assets/streamline-icon-lock-square-1@48x48.svg"
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import $ from 'jquery';
import {USERS_API_BASE_URL } from '../../constants';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Statistics
    </Tooltip>
  );
const NotificationThreadListItem = (props) => {
    const[PrivatemessgeUser,SetPrivatemessgeUser]= useState([]);
    const[subjectLine, setSubjectLine]=useState('');
    const[authImage,setAuthImage]=useState("");
    const authCtx = useContext(AuthContext);
    const { contactGroupStatus,
        selectedContactGroup,
        onSelectDeactivateNotification,
        contactGroup

    } = props;

    useEffect(()=>{

       /*let getMessageAuthorId= localStorage.getItem(''+props.messageAuthorId);

       if(getMessageAuthorId==null){

            fetch(`${USERS_API_BASE_URL}/get-user-image/`+props.messageAuthorId, {
                method: 'GET',
                headers: {'Authorization': authCtx.token}
            }).then(response=>{
                return response.json();

            }).then(jsonData=>{

                if(!(jsonData.userImage.thumbImage)){
                    localStorage.setItem(''+props.messageAuthorId, jsonData.userImage.thumbImage);
                setAuthImage(jsonData.userImage.thumbImage)
                }
                else{
                    localStorage.setItem(''+props.messageAuthorId, "https://static.myschoollink.app/common/profile-icon-login-head-icon-vector.png");

                }

            });

        }
        else{
            setAuthImage(getMessageAuthorId);
        }*/

        setAuthImage(props.authorImageUrl);

        if (props.channels.includes("5") == true) {

            let splited = props.messageTitle.split('@@@');
            let sub= splited.length>1?splited[1]:props.messageTitle;
            sub=sub.replace('-#DRFT#','');
            setSubjectLine(sub);
            SetPrivatemessgeUser(splited[0]);
        }
    },[]);


    const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCategoryTags, setSelectedCategoryTags] = useState([]);
    const [selectedEmailOnlySubscribers, setselectedEmailOnlySubscribers] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [isSending, setIsSending] = useState(false);


    const dt = new Date(props.lastModified);

    const month = dt.toLocaleString('en-US', { month: 'numeric' });
    const day = dt.toLocaleString('en-US', { day: '2-digit' });
    const year = dt.getFullYear();
    const editedAt = `${day + '/' + month + '/' + year}`;
    const hours = dt.getHours();
    const minutes = dt.getMinutes();

    var today = new Date();
    //var Christmas = new Date("2012-12-25");
    var diffMs = (today-dt); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
   if(diffDays>0){
    diffHrs = diffDays+' Days'
   }
    else if(diffHrs>0){
        diffHrs = diffHrs+' hours'
    }else{
        diffHrs = diffMins+' minutes'
    }


    const activeStyle = `${(contactGroup === selectedContactGroup) ? style.active : ''}`;
    const filterActive = `${(contactGroup === selectedContactGroup) ? 'filterAction' : ''}`;

    const deactiveNotificationStle = contactGroupStatus === 'INACTIVE' ? style.deactiveNotification : '';

    const recipientModalHendler = (e) => {

        e.stopPropagation();

        if (!isRecipientModalOpen) {
            getSelectedRecipientData(contactGroup, authCtx.token)
                .then((response) => {

                    const sliceCatTags = [];
                    const categoriesTags = response.data.usergroupcategory;

                    if (categoriesTags.length > 0) {

                        categoriesTags.forEach(catTags => {

                            const temp = {
                                [catTags.categoryID]: catTags.usergrouptag.map(t => ({ label: t.name, value: t.tagId }))
                            }
                            sliceCatTags.push(temp);
                        });
                    }
                    const selectedusersStr = response.data.selectedUsers;
                    const updateSelectedUsers = !selectedusersStr ? [] : selectedusersStr.split(",");
                    setSelectedUsers(updateSelectedUsers);
                    setSelectedCategoryTags(sliceCatTags);
                    setselectedEmailOnlySubscribers(response.data.emailOnlySubscribers);

                }).catch((error) => {
                    console.log(error);
                })
        }
        setIsRecipientModalOpen((prevState) => !prevState);
    }

    /** For deactivate  notificationThread (start here. . .)   **/

    const deactiveNotificationHandler = (e) => {
        e.stopPropagation();
        setShowAlert(true);
    }
    const onConfirmHandler = () => {
        setIsSending(true);
        deactivateNotificationThread(contactGroup, { "action": "INACTIVE" }, authCtx.token)
            .then((res) => {
                if (res.status) {
                    setIsSending(false);
                    setShowAlert(false);
                    onSelectDeactivateNotification(contactGroup);
                    toast.success("Notification thread deactivated successfully ", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: 'success'
                    });
                } else {
                    setIsSending(false);
                }
            })
            .catch((err) => {
                setIsSending(false);
                console.log(err);
            });
    }
    const onCancelHandler = () => {
        setShowAlert(false);
    }
    /*** End Here ***/

    // For channels
    let channelsArr = [];
    if (props.channels) {
        channelsArr = props.channels.split(',');
    }
    let message = props.messageBody.toString().length > 110 ? props.messageBody.toString().substring(0, 110 - 3) + " ..." : props.messageBody.toString();

   // console.log("message", props);

    return (<>
        {isRecipientModalOpen && <NotificationRecipient
            modalStatus={isRecipientModalOpen}
            onAction={recipientModalHendler}
            selectedUsers={selectedUsers}
            selectedCategoriesTags={selectedCategoryTags}
            selectedEmailOnlySubscribers={selectedEmailOnlySubscribers}
            isRecipientUpdate={props.contactGroup}
            channels={props.channels}
        />}

        {showAlert && <ConfirmAlertModal
            show={showAlert}
            onHide={onCancelHandler}
            isSending={isSending}
            onConfirm={onConfirmHandler}
        />}

        <div key={Math.random + props.messageId} style={{ position: "relative" }}
            className={style.messageNoti + ' ' + common.messageEffect + ' ' + activeStyle + ' ' + filterActive + ' ' + (contactGroupStatus === 'INACTIVE' ? style.inactiveMessageThread : '')}
            onClick={(props.contactGroup !== props.selectedContactGroup) ? props.onClick : null}>

            {(contactGroupStatus == 'INACTIVE') && <div className={common.deactiveitem}></div>}
            <div className={style.List} >
                <div className={style.messageList}>
                    <div className={style.userimage}>
                        <img src={props.authorImageUrl}/>
                    </div>
                    <div className={style.Messagearea}>
                        <div className={style.MessageSub}>

                            <p className={common.messegeTitle + ' ' + fontSize.smSize + ' ' + font.HelveticaNeue700} title={selectedContactGroup}>
                            {props.channels.includes("5") != true?(
                                <span className={common.msgDes+' '+style.truncateTitleOfNotification}>{props.messageTitle.replace("-#DRFT#","")}</span>):( <span className={common.msgDes}>Direct Message to {PrivatemessgeUser}</span>)}
                                <span className={style.rightFilter + ' ' + common.hoverEffect + ' ' + style.activeEffect} style={{padding:"0"}}>


                                    {props.channels.includes("5") != true?(
                                    <button >
                                    <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 50, hide: 400 }}
                                            overlay={renderTooltip}
                                            >
                                        <span className={style.statesSvgIcon} ></span>
                                        </OverlayTrigger>
                                        <span className={common.hideBtnText}>Statistics</span>
                                        </button>
                                    ):null }


                                    <button onClick={deactiveNotificationHandler} >
                                        {contactGroupStatus == 'INACTIVE' && <span className={style.lockedSvgIcon} ></span>}
                                        {contactGroupStatus != 'INACTIVE' && <span className={style.unlockedSvgIcon} ></span>}
                                        <span className={common.hideBtnText}>DeactiveNotification</span>
                                         {/*<i style={{color:"rgb(155 157 160)",verticalAlign:"bottom",fontSize:"18px"}}  className="icon icon-lock2"></i>*/}
                                    </button>
                                    {/*{props.channels.includes("5") != true?(
                                    <button onClick={recipientModalHendler} id={"editgroupbtn-"+selectedContactGroup}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={common.messageIcon}><g transform="matrix(2,0,0,2,0,0)"><defs></defs><path strokeWidth="2px" style={{ strokeLinecap: 'unset' }} className="a" d="M9.164 16.178a6.729 6.729 0 0 1 0-9.515"></path><path d="M9.165 6.663 11.327 4.5 13 6.173l-2.162 2.163zm-.001 9.516 1.577-1.577 2.163 2.162-1.577 1.577z" ></path><path style={{ strokeLinecap: 'unset' }} className="a" d="M17 12.5v10H3V.5h18v12h-4zm4 3v3m0 2v3"></path></g></svg>
                                    </button>):null
                                    }*/}
                                </span>

                            </p>
                            <p className={common.autherName + ' ' + fontSize.font13 + ' ' + font.HelveticaNeue400}>
                                <span className={common.dott}>By {props.authorName} <span>{diffHrs}</span>{" "}</span>
                                {props.channels.includes("5") == true?(<span className={common.orgTag + ' ' + fontSize.lowXsSize}style={{background:'#0b5ed7'}}>Private Message</span>
                           ):(<span className={common.orgTag + ' ' + fontSize.lowXsSize}>{props.messageType}</span>
                           )}
                            </p>
                            {props.channels.includes("5") != true?(
                            <p className={common.threadLatestMessage + ' ' + fontSize.font13 + ' ' + font.HelveticaNeue400 + ""}>
                                {message}

                                { }
                            </p>):(<p className={common.threadLatestMessage + ' ' + fontSize.font13 + ' ' + font.HelveticaNeue400 + ""}>
                                {subjectLine}

                                { }
                            </p>)}
                            <p className={common.messageDetail + ' ' + fontSize.font13 + ' ' + font.HelveticaNeue400 + ' ' + style.activeEffect}>

                                {channelsArr.map(channl => <SVGIcon key={Math.random() + "-icon-" + props.messageId} svgIcondata={CHANNELS.find(i => i.id == channl).svgIcon} />)}


                                <span className={style.messageDate}> Edited {hours + ':' + minutes + ' - ' + editedAt}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const SVGIcon = (props) => {
    return <>
        <p className="iconclass" dangerouslySetInnerHTML={{ __html: props.svgIcondata }}></p>
    </>
}

export default NotificationThreadListItem;
