import React, { useContext,useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import fontSize from '../../Style/FontSize.module.css';
import common from '../../Style/common.module.css';
import font from '../../Style/FontFamily.module.css';
import tableStyle from '../../Style/TableModel.module.css';
import button from '../../Style/ButtonStyle.module.css';


const ConfirmAlertModal = (props) => {

    const { show, onConfirm, onHide, isSending } = props;
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            onHide();
        }
      }, [onHide]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);
    return (
        <Modal
            backdrop="static"
            keyboard={false}
            centered
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm"
        >
            <div className={tableStyle.alertbody}>
                <h4 className={tableStyle.alertTitle}>Warning!</h4>
                <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you want to <b className={font.fontBold}>deactivate</b> this notificationThread?</p>
                <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}><strong className={font.fontBold}>This will cause all posts contained in this thread to disappear from SchoolLink app's timeline(on parents phones).</strong>
                    <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}><b className={font.fontBold} style={{color:'#FF0000',marginTop:'8px', display:'inline-block'}}>This action is permanent.</b> </p>
                </p>
            </div>
            <div className={tableStyle.alertFooter}>
                <button onClick={onHide} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>

                <button onClick={onConfirm} disabled={isSending} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>
                    {isSending && (<>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Deactivating...</>
                    )}
                    {!isSending && 'Proceed'}
                </button>
            </div>
        </Modal>
    )
}

export default ConfirmAlertModal;