// This is local file
//module.exports = {
/**
 * Constant for pointing http://api.dev-n-test.com/messages
 */
//MESSAGES_API_BASE_URL : 'https://api-dev.myschoollink.app/messages', // Base url for development server
/**
 * Constant for pointing http://api.dev-n-test.com/users
 */

//USERS_API_BASE_URL : 'https://api-dev.myschoollink.app/users' // Base url for development server
// USERS_API_BASE_URL : 'http://localhost:5000/users' // Base url for development server
if (window.location.hostname === "admin.myschoollink.app") {
     module.exports = {
          MESSAGES_API_BASE_URL: 'https://api.myschoollink.app/messages', // Base url for development server
          USERS_API_BASE_URL: 'https://api.myschoollink.app/users', // Base url for development server
          EPUBLISHER_API:'https://api-dev.myschoollink.app/epub'
     }
}else if (window.location.hostname === "admin-stage.myschoollink.app") {
     module.exports = {
          MESSAGES_API_BASE_URL: 'https://api-stage.myschoollink.app/messages', // Base url for development server
          USERS_API_BASE_URL: 'https://api-stage.myschoollink.app/users', // Base url for development server
          EPUBLISHER_API:'https://api-stage.myschoollink.app/epub'
     }
}else if (window.location.hostname === "admin-dev.myschoollink.app") {
     module.exports = {
          MESSAGES_API_BASE_URL: 'https://api-dev.myschoollink.app/messages', // Base url for development server
          USERS_API_BASE_URL: 'https://api-dev.myschoollink.app/users', // Base url for development server
          EPUBLISHER_API:'https://api-dev.myschoollink.app/epub'
     }
} else if (navigator.userAgent.includes('Intel Mac OS 1')) {
     module.exports = {
          //MESSAGES_API_BASE_URL: 'http://localhost:5050/messages', // Base url for development server
          //MESSAGES_API_BASE_URL: 'https://api-dev.myschoollink.app/messages', // Base url for development server
          USERS_API_BASE_URL: 'http://localhost:5001/users', // Base url for development server
          // EPUBLISHER_API:'https://api-dev.myschoollink.app/epub'
          //USERS_API_BASE_URL: 'https://api-dev.myschoollink.app/users', // Base url for development server
          //EPUBLISHER_API:'https://api-dev.myschoollink.app/epub'
          //EPUBLISHER_API:'http://localhost:4000'

          MESSAGES_API_BASE_URL: 'https://api.myschoollink.app/messages', // Base url for development server
          //USERS_API_BASE_URL: 'https://api.myschoollink.app/users', // Base url for development server
          EPUBLISHER_API:'https://api-dev.myschoollink.app/epub'
     }
}else {
     module.exports = {
          MESSAGES_API_BASE_URL: 'https://api-dev.myschoollink.app/messages', // Base url for development server
          //MESSAGES_API_BASE_URL: 'http://localhost:5050/messages',
          USERS_API_BASE_URL: 'https://api-dev.myschoollink.app/users', // Base url for development server
          //MESSAGES_API_BASE_URL: 'http://localhost:5050/messages', // Base url for development server
          //USERS_API_BASE_URL: 'http://localhost:5000/users', // Base url for development server
          EPUBLISHER_API:'https://api-dev.myschoollink.app/epub'
     }

}

