import { useState, useEffect,useContext } from 'react';
import { Modal } from 'react-bootstrap';
import tableStyle from '../Style/TableModel.module.css';
import formStyle from '../Style/modalForm.module.css';
import button from '../Style/ButtonStyle.module.css';
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from "../../store/auth-context";
import axios from "axios";
const UploadProfile = (props) => {
    const authCtx = useContext(AuthContext);
    const [userID, setUserID] = useState(0);
    const [usermessage, setUserMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState("");
    const [profileImageUrl, setProfileImageurl] = useState("");

    const http = axios.create({
        baseURL: USERS_API_BASE_URL,
        headers: {
            "Content-type": "application/json",'Authorization':authCtx.token
        }
    });
    const ProfileImageUpdate = (e) => {
        e.preventDefault();
        
        fetch(`${USERS_API_BASE_URL}/update-profile-image`, {
            "method": "PATCH",
            "headers": {
                "Content-Type": "application/json",
                'Authorization':authCtx.token
            },
            "body":
               "{\"profileImage\":\"" + profileImageUrl + "\",\"userID\":\"" + userID + "\"}"

        }).then(res=>res.json()).then(data=>{
            
            //alert(data.status==302?data.error:data.msg);props.onAction(false)

            if(data.status==400){
                setUserMessage(data.error)
            }else{
                props.setMessage(data.msg)
                authCtx.userData.thumbImage = data.profileImageUrl;
                console.log('after Image Update',authCtx.userData);
                authCtx.profileUpdate(data.profileImageUrl);
                authCtx.login(authCtx.userData);
                setUserID(0)
                props.onAction(false);
            }
        
        })

    }

    const onProfileFileChange = async (event) =>{
        event.preventDefault();
        let formData = new FormData();
        let file = event.target.files[0];
        setSelectedFile(file);
        formData.append("file", file);
        return await http.post("/profile-image-upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            //onUploadProgress,
        }).then(function (response) {
            //console.log('profileImageUpload===',response.data);
            authCtx.setUProfileImage(response.data.thumbUrl);
            localStorage.setItem(authCtx.userData.uuid, response.data.thumbUrl);
            authCtx.userData.thumbImage = response.data.thumbUrl;
            setProfileImageurl(response.data.thumbUrl);
            //alert(response.data.thumbUrl)
            props.setProfileImg(response.data.thumbUrl)           
            // authCtx.profileUpdate(response.data.thumbUrl);
            ///processResponse(response);
        });

    }
    useEffect(()=>{
        setUserID(props.userDetail.user.uuid);
    },[props.userDetail])
    //console.log('useridhitesh',userID);
    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} style={{"width" : "80px","height":"80px"}} alt={image.name} />;
      };
    return (
        <>
            <Modal
                size="md"
                show={props.modalStatus}
                onHide={props.onAction}
                shouldCloseOnOverlayClick={false} 
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <div className={formStyle.headArea}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h4 className={tableStyle.modalTitle}> Upload Profile</h4>
                    </Modal.Title>
                </div>
                <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14,"color":"red","margin-left":"15px"}}>{usermessage}</div>
                <form onSubmit={ProfileImageUpdate} >
                <div className={formStyle.bodyArea}>
                    
                        <div className="row">
                            <div className="col-ms-12">
                                
                                <div className={formStyle.group}>
                                <input type="file" id="org-files" onChange={onProfileFileChange} />
                                        {selectedFile && <ImageThumb image={selectedFile} />}
                                        {profileImageUrl && !selectedFile && <img src={profileImageUrl} style={{"width" : "80px","height":"80px"}} alt={profileImageUrl} />}
                                </div>
                                
                                
                            </div>
                        </div>
                    
                </div>
                <div className={formStyle.FooterArea}>
                    <button type="button" onClick={props.onAction} className={button.btn + ' ' + button.brownButton}>Cancel</button>
                    <button type="submit" className={button.btn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Save</button>  
                </div>
                </form>
            </Modal>
        </>
    )
}
export default UploadProfile;