import React, { useState,useEffect,useContext } from "react";
import { render } from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
//import arrayMove1 from "array-move";
import style from './Modify.module.css'
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from '../../../src/store/auth-context';
import { getOrganisationContact,getOrganisationLink,getOrganisationDetailsByID,getOrganisationLinkDetail } from '../../services/Organisation.js';




const SortableItem = SortableElement(({ value,indexval,id,phnStatus,checkboxVal,handleClick,editLinkHandler,onSortEnd  }) => (
   
    <li className={style.dragStyle} tabIndex={indexval} style={{position:'relative'}}>
      <input type="checkbox" className={style.hiddenBtn} onClick={() => editLinkHandler(id)} />
      <span><i className="icon icon-grid2"></i></span> {value}
    <div className={"form-check form-switch " + style.switchBtn}>
      <input className={"form-check-input " + style.switchInput} type="checkbox" role="switch" defaultChecked={phnStatus}
          onChange={() => handleClick(id,phnStatus)}  id={`flexSwitchCheckChecked_${id}`} checked={phnStatus=='ACTIVE'?true:false}/>
      {/* <div><span><i className="icon icon-pencil7" onChange={() => handleDeleteClick(id)}></i></span></div> */}
    </div>
    </li>

  ));
  
  const SortableList = SortableContainer(({ items,checkedval,handleClick,editLinkHandler,onSortEnd }) => {
    return (
      <ul className={style.dragList}>
        {items.map((value1, index) => (
          <SortableItem key={`item-${value1.org_link}`} 
          index={value1.displayOrder} 
          indexval={value1.displayOrder} 
          value={value1.title}
          id={value1.id} 
          phnStatus={value1.linkStatus} 
          checkboxVal={checkedval} 
          handleClick={handleClick} 
          onSortEnd={onSortEnd}
          editLinkHandler={editLinkHandler} />
        ))}
      </ul>
    );
  });

 
  const SortableLinkComponent = (props) =>{
  
    let OrganisationExternalLink = props.OrganisationExternalLink;
    //const [itemsData, setItemData] = useState([])
    const authCtx = useContext(AuthContext);

    let orgUuid=authCtx.userData.orgId;
    

    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [checked, setChecked] = useState('')
     const handleClick = (linkID,itemStatus) => {
       console.log("itenid",linkID+'========='+itemStatus)

       fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-link-status`, {
        "method": "PATCH",
        "headers": {
            "Content-Type": "application/json"
        },
        "body":
            "{\"phnStatus\":\"" + itemStatus + "\",\"itemID\":\"" + linkID + "\"}"

    }).then(res=>res.json()).then(data=>{
      //alert(data.status==302?data.error:data.msg);
        //console.log('hitesh',data.updateorgContactData);
        //setItemData(data.updateorgContactData);
        props.updateMobileLinkData(data.updateorgLinkData)

        
    })
       //setChecked(!checked)
     } 
    //console.log(itemsData)

    const handleDeleteClick = (itemID) => {
        console.log("itenid",itemID)
  
        fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/delete-link-data`, {
         "method": "PATCH",
         "headers": {
             "Content-Type": "application/json"
         },
         "body":
             "{\"ORGID\":\"" + orgUuid + "\",\"itemID\":\"" + itemID + "\"}"
  
     }).then(res=>res.json()).then(data=>{
       //alert(data.status==302?data.error:data.msg);
         //console.log('hitesh',data.updateorgContactData);
         //setItemData(data.updateorgContactData);
         props.updateMobileLinkData(data.updateorgLinkData)
  
         
     })
        //setChecked(!checked)
    } 

    const editLinkHandler = async (itemID) => {
      //console.log(itemID)
     const linkeData = await getOrganisationLinkDetail(authCtx.token,orgUuid,itemID)
      //console.log('editdata',phoneData);
      props.setLinkDetail({id:linkeData.data.id,title:linkeData.data.title,org_link:linkeData.data.org_link,emojiicon:linkeData.data.emojiicon,displayOrder:linkeData.data.displayOrder,linkStatus:linkeData.data.linkStatus})
      //console.log("itenid",itemID)
      props.ToggleLinkPopup()
    } 
     
    useEffect(() => {

        const orgContact =  getOrganisationLink(authCtx.token,orgUuid).then((res) => {
                
            //setItemData(res.data);
            // const allData = res.data.map((mob)=> {
            //     return mob.org_contact
            // } );
            
            // setAddNumber(allData);
        })
        .catch(error => {
            console.log(error);
        })
    }, []); 
    const onSwitchAction = () => {
        
        setIsSwitchOn(!isSwitchOn);
      };
  
   const onSortEnd = ({ oldIndex, newIndex }) => {
       // alert(oldIndex+'=========='+newIndex);
       if(oldIndex!=newIndex){
       console.log(oldIndex+'======---===='+newIndex)
       fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-org-link-order`, {
        "method": "PATCH",
        "headers": {
            "Content-Type": "application/json"
        },
        "body":
            "{\"oildItemIndex\":\"" + oldIndex + "\",\"newItemIndex\":\"" + newIndex + "\"}"

    }).then(res=>res.json()).then(data=>{
        //alert(data.status==302?data.error:data.msg);
        //console.log('hitesh',data.updateorgContactData);
        //setItemData(data.updateorgContactData);
        props.updateMobileLinkData(data.updateorgLinkData)

        
    })

      const itemsCopy = OrganisationExternalLink;
      const oldIndexBackup = itemsCopy[oldIndex];
      itemsCopy[oldIndex] = itemsCopy[newIndex];
      itemsCopy[newIndex] = oldIndexBackup;
    }
    //   this.setState(({ items }) => ({
    //     items: itemsCopy
    //   }));
      //setItemData(itemsCopy)
    };
    return(
     //console.log('itemsdetailhh',this.props.itemsdata)
     <SortableList items={OrganisationExternalLink} onSortEnd={onSortEnd} onSwitchAction={onSwitchAction} handleClick={handleClick} editLinkHandler={editLinkHandler} checkedval={checked}/>
    )
  }

  export default SortableLinkComponent;