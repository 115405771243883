import React, { useState,useEffect,useContext } from 'react';
import ReactDOM from "react-dom";
import AddNumberModal from './AddNumberModal';
import AddLinkModal from './AddLinkModal';
import SortableComponent from './SortableComponent';
import SortableLinkComponent from './SortableLinkComponent';
import style from './Modify.module.css';
import custom from  './customModal.module.css';
import font from '../Style/FontFamily.module.css';
import { List, arrayMove } from 'react-movable';
import { USERS_API_BASE_URL } from './../../constants';
import AuthContext from '../../../src/store/auth-context';
import { getOrganisationContact,getOrganisationLink,getOrganisationDetailsByID } from '../../services/Organisation.js';
import { nodeName } from 'jquery';





const ModifyDetails = (props) =>{

    const [addNumber, setAddNumber] = useState([])
    const [link, setLink] = useState([])
    const [mobileNumberModal, setmobileNumberModal] = useState(false)
    const [externalLinkModal, setexternalLinkModal] = useState(false)
    const [OrganisationContactList, setOrganisationContactList] = useState([]);
    const [OrganisationExternalLink, setOrganisationLink] = useState([]);
    const [OrganisationDataByID, setOrganisationData] = useState('');
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const [contactDetail, setContactDetail] = useState({id:'',title:'',org_contact:'',titleColour:'',displayOrder:'',phoneStatus:''});
    const [linkDetail, setLinkDetail] = useState({id:'',title:'',org_link:'',emojiicon:'',displayOrder:'',linkStatus:''});

    const [disabled, setDisabled] = useState(true);
    const [hiddenBtn, setHidden] = useState(true);


    const [itemsData, setItemData] = useState([])


    const [StrretAddress, setStreetAddress] = useState('')
    const [Subrub, setSubrub] = useState('')
    const [orgState, setorgState] = useState('')
    const [Postcode, setPostcode] = useState('')
    const [Country, setCountry] = useState('')

    const [message, setMessage] = useState('')

    function handleEditClick() {
        setDisabled(!disabled);
        setHidden(!hiddenBtn)
    }

    const callSetContactDetail=(data)=>{
        //console.log("Data Recevied ",data);
        setmobileNumberModal(!mobileNumberModal)
        ToggleNumberPopup();
        setContactDetail(data);
        setmobileNumberModal(!mobileNumberModal)
    }

    const callSetLinkDetail=(data)=>{
        //console.log("Data Recevied ",data);
        setexternalLinkModal(false);
        setLinkDetail(data);
        setexternalLinkModal(true);
    }
    
    // const onSortEnd = ({ oldIndex, newIndex }) => {
    //     const itemsCopy = this.state.items.slice();
    //     const oldIndexBackup = itemsCopy[oldIndex];
    //     itemsCopy[oldIndex] = itemsCopy[newIndex];
    //     itemsCopy[newIndex] = oldIndexBackup;
    
    //     this.setState(({ items }) => ({
    //       items: itemsCopy
    //     }));
    //   };
    

    const authCtx = useContext(AuthContext);

    let orgUuid=authCtx.userData.orgId;

    // const changeSwitchHandel =()=>{
    //     setIsSwitchOn(!isSwitchOn)
    // }

    const changeStreetAddress = ({target })=>{
        setStreetAddress(target.value);
    }
    const changeSubrub = ({target })=>{
        setSubrub(target.value);
    }
    const changeState = ({target })=>{
        setorgState(target.value);
    }
    const changePostcode = ({target })=>{
        setPostcode(target.value);
    }
    const changeCountry = ({target })=>{
        setCountry(target.value);
    }

    const updateHandler =()=>{
        
        fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-org-address`, {
            "method": "PATCH",
            "headers": {
                "Content-Type": "application/json"
            },
            "body":
                "{\"streetAddress\":\"" + StrretAddress + "\",\"suburb\":\"" + Subrub + "\",\"state\":\"" + orgState + "\",\"postCode\":\"" + Postcode + "\",\"country\":\"" + Country +"\"}"

        }).then(res=>res.json()).then(data=>{
            //alert(data.status==302?data.error:data.msg);
            if(data.status==302){
                setMessage(data.error)
            }else{
                setMessage(data.msg)
            }
            
            setOrganisationData(data.updateorgDetail)
            setDisabled(!disabled);
            setHidden(!hiddenBtn);
        })

    }
    const updateMobileContactData =(conatactData) => {
        setOrganisationContactList(conatactData);
    }
    const updateMobileLinkData =(linkData) => {
        setOrganisationLink(linkData);
    }
    const addnumberhandler =(mobileNumber,titleColour,title) =>{
       // console.log('addNumber Show',mobileNumber)
        setAddNumber([...addNumber, mobileNumber])   
        const allDataContact =  [...OrganisationContactList, {id:57, org_contact:mobileNumber,displayOrder:"4",title:title,titleColour:titleColour,phoneStatus:'ACTIVE'}];
        //console.log(allData)
        setOrganisationContactList(allDataContact);
       
        
    }
    const addLinkhandler =(linktitle,externalLink) =>{
        setLink([...link, externalLink])

        const allData =  [...OrganisationExternalLink, {id:57, org_link:externalLink,displayOrder:"4",linkStatus:'ACTIVE'}];
        console.log(allData)
        setOrganisationLink(allData);
    }
    //console.log(addNumber.findIndex)
      
    useEffect(() => {
          //console.log(5)
          
          //console.log('ItemData',contactDetail);

            const orgContact =  getOrganisationContact(authCtx.token,orgUuid).then((res) => {
            
            setOrganisationContactList(res.data);
            const allData = res.data.map((mob)=> {
                return mob.org_contact
            } );
            
            setAddNumber(allData);
        })
        const orgLinkData =  getOrganisationLink(authCtx.token,orgUuid).then((reslink) => {
            
            setOrganisationLink(reslink.data);
            //console.log(reslink.data)
            const allDataLink = reslink.data.map((orglink)=> {
                  return  orglink.org_link;
                //link.push(orglink.org_link)
               //addLinkhandler(orglink.org_link)
                //setAddNumber([...addNumber, mob.org_contact])  
               
            } );
            setLink(allDataLink)
        })

        const getorgData =   getOrganisationDetailsByID(authCtx.token,orgUuid).then((resorgdata) => {
        setOrganisationData(resorgdata.data)

        setStreetAddress(resorgdata.data.streetAddress);
        setSubrub(resorgdata.data.suburb)
        setorgState(resorgdata.data.state);
        setPostcode(resorgdata.data.postCode);
        setCountry(resorgdata.data.country);
            
           // return resorgdata;
         })
         

        
            .catch(error => {
                console.log(error);
            })
    }, []);
   
    
   console.log(OrganisationDataByID);
    
    

    

    const ToggleNumberPopup =() =>{
        setmobileNumberModal(!mobileNumberModal)
    }
    const ToggleLinkPopup =() =>{
        setexternalLinkModal(!externalLinkModal)
    }

    const onSwitchAction = () => {
        
      setIsSwitchOn(!isSwitchOn);
    };


    return(
        <div className={style.pageCOntainer}>
            <h4 className={style.PageTitle + ' ' + font.fontMedium}>Modify school contact details</h4>
            <div className={style.boxFlex}>
                <div className={style.detailBox}>
                    <div className={style.editDetailBox}>
                        <div className={style.editArea}>
                            <h2 className={style.boxTitle + ' ' + font.fontBold}>Edit school address </h2>
                            <button type="button" className={style.editButton} onClick={handleEditClick}><i className="icon icon-pencil7"></i></button>
                        </div>
                        <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14}}>{message}</div>
                        <form>
                            <div className={style.fromGroup}>
                                <label className={style.fromLabel}>Street</label>
                                <input type="text" className={style.formInput} disabled={disabled} value={StrretAddress} onChange={changeStreetAddress}/>
                            </div>
                            <div className={style.fromGroup}>
                                <label className={style.fromLabel}>City/Suburb</label>
                                <input type="text" disabled={disabled} className={style.formInput} value={Subrub} onChange={changeSubrub} />
                            </div>
                            <div className={style.fromGroup}>
                                <label className={style.fromLabel}>State/Territory/Province</label>
                                <input type="text" disabled={disabled} className={style.formInput} value={orgState} onChange={changeState}/>
                            </div>
                            <div className={style.fromGroup}>
                                <label className={style.fromLabel}>Post/Zip code</label>
                                <input type="text" disabled={disabled} className={style.formInput} value={Postcode} onChange={changePostcode}/>
                            </div>
                            <div className={style.fromGroup}>
                                <label className={style.fromLabel}>Country</label>
                                <input type="text" disabled={disabled} className={style.formInput} value={Country} onChange={changeCountry}/>
                            </div>
                            <div className={style.fromGroup}>
                                <button type="button" hidden={hiddenBtn} onClick={updateHandler} className={custom.modalbtn + ' ' + custom.blurBtn + ' ' + style.btnSpace}>Update</button>
                                <button type="button" hidden={hiddenBtn} onClick={handleEditClick} className={custom.modalbtn + ' ' + custom.whiteBtn  + ' ' + style.btnSpace}>Cancle</button>
                            </div>
                        </form>
                    </div>
                    <div className={style.addNumberBox}>
                        <h2 className={style.boxTitle + ' ' + font.fontBold}>Add/Remove phone numbers</h2>
                        <p>You can add useful phone numbers here, allowing parents to conveniently dial out directly from the SchoolLink app. Click on a field to modify/delete.</p>
                        <div className={style.addRow}>
                            <button type="button" className={style.AddRowButton} onClick={() => ToggleNumberPopup()}>Add +</button>
                            <AddNumberModal 
                             show={mobileNumberModal} 
                             close={ToggleNumberPopup} 
                             OrganisationContactList={OrganisationContactList} 
                             setOrganisationContactList={setOrganisationContactList} 
                             addnumberhandler={addnumberhandler} 
                             setContactDetail={setContactDetail} 
                             contactDetail={contactDetail}
                             title={contactDetail.title} />
                            {/* <ul className={style.dragList} >
                                {OrganisationContactList.map(number =><li draggable="true"><span><i className="icon icon-grid2"></i></span> {number.org_contact}</li>)}
                            </ul> */}
                            <SortableComponent 
                            OrganisationContactList={OrganisationContactList} 
                            ToggleNumberPopup={ToggleNumberPopup} 
                            setOrganisationContactList={setOrganisationContactList} 
                            updateMobileContactData={updateMobileContactData} 
                            setContactDetail={callSetContactDetail} />
                            {/* <List 
                                values={addNumber}
                                onChange={({ oldIndex, newIndex }) =>
                                setAddNumber(arrayMove(addNumber, oldIndex, newIndex))
                                }
                                renderList={({ children, props }) => <ul className={style.dragList} {...props}>{children}</ul>}
                                renderItem={({ value, props }) => <li className={style.dragLi} {...props} ><span><i className="icon icon-grid2" ></i></span> {value} </li>}
                            /> */}
                        </div>
                    </div>
                    <div className={style.addNumberBox}>
                        <h2 className={style.boxTitle + ' ' + font.fontBold}>Create links to external pages</h2>
                        <p>This allows parents to access useful web pages Such as Uniform shop, Tuck shop, Extra Curricular pages etc.,</p>
                        <div className={style.addRow}>
                            <button type="button" className={style.AddRowButton} onClick={() => ToggleLinkPopup()}>Add +</button>
                            <AddLinkModal 
                            show={externalLinkModal} 
                            close={ToggleLinkPopup} 
                            addLinkhandler={addLinkhandler} 
                            linkDetail={linkDetail}
                            OrganisationExternalLink={OrganisationExternalLink}
                            setOrganisationLink={setOrganisationLink} />
                            {/* <ul className={style.dragList}>
                                {link.map(links =><li><span><i className="icon icon-grid2"></i></span> {links}</li>)}
                            </ul> */}
                            {/* <List
                                values={link}
                                onChange={({ oldIndex, newIndex }) =>
                                setLink(arrayMove(link, oldIndex, newIndex))
                                }
                                renderList={({ children, props }) => <ul className={style.dragList} {...props}>{children}</ul>}
                                renderItem={({ value, props }) => <li className={style.dragLi} {...props}><span><i className="icon icon-grid2"></i></span> {value}</li>}
                            /> */}
                            <SortableLinkComponent 
                            OrganisationExternalLink={OrganisationExternalLink} 
                            setOrganisationLink={setOrganisationLink}  
                            updateMobileLinkData={updateMobileLinkData}
                            ToggleLinkPopup = {ToggleLinkPopup}
                            setLinkDetail = {callSetLinkDetail}/>
                        </div>
                    </div>
                </div>
                <div className={style.previewbOX}>
                    <h2 className={style.boxTitle + ' ' + font.fontBold}>{OrganisationDataByID.name} </h2>
                    <div className={style.viewDetail}>
                        <div className={style.fromGroup}>
                            <label className={style.fromLabel}>Street</label>
                            <p className={style.formInput}>{OrganisationDataByID.streetAddress}</p>
                        </div>
                        <div className={style.fromGroup}>
                            <label className={style.fromLabel}>City/Suburb</label>
                            <p className={style.formInput}>{OrganisationDataByID.suburb}</p>
                        </div>
                        <div className={style.fromGroup}>
                            <label className={style.fromLabel}>State/Territory/Province</label>
                            <p className={style.formInput}>{OrganisationDataByID.state}</p>
                        </div>
                        <div className={style.fromGroup}>
                            <label className={style.fromLabel}>Post/Zip code</label>
                            <p className={style.formInput}>{OrganisationDataByID.postCode}</p>
                        </div>
                        <div className={style.fromGroup}>
                            <label className={style.fromLabel}>Country</label>
                            <p className={style.formInput}>{OrganisationDataByID.country}</p>
                        </div>
                        <div className={style.schoolnumberList}>
                            <ul>
                                {/* <li>
                                    <span className={style.grayBackground}>Reception</span>
                                    <p>+61 7 1234 5678</p>
                                </li>
                                <li><span className={style.purpleBackground}>Report Absentees</span><p>+61 7 1234 5678</p></li> */}
                                {OrganisationContactList.map(contact =>
                                   
                                   contact.phoneStatus=='ACTIVE'?<li><span className={contact.titleColour}>{contact.title}</span><p>{contact.org_contact}</p></li>:''
                                         
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className={style.detailLink}>
                        <p>Access School Resources</p>
                        <ul>
                               
                               
                                 {OrganisationExternalLink.map(links =>
                                 
                                 links.linkStatus=='ACTIVE'?<li> <a href={links.org_link} style={{'text-decoration':'none'}} target='blank'>{links.title}</a></li>:''
                                 
                                 
                                 )}
                             
                                
                             
                             
                                 
                             
                            
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModifyDetails;   