import { useState, useEffect } from 'react';
import styles from './Channels.module.css';
import { CHANNELS_FILTER } from '../../constant-data';

import { ImMobile } from "react-icons/im";
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import font from '../Style/FontFamily.module.css';
import button from '../Style/ButtonStyle.module.css';
import '../Style/FormStyle.css'
const ChannelFilter = (props) => {
    const channelName = CHANNELS_FILTER.name
    const { selectedChannelFilter,
        applyChannelFilterHandler,
        filterNotificationByChannel,
        resetChannelFilterHandler,
        setFilterNotifaction
    } = props;

    const [selectedChannels, setSelectedChannels] = useState([]);

    const channelHandler = (e) => {
        let channels = [...selectedChannels];
        let id = e.target.value;
        if (channels.includes(id))
            channels = channels.filter(ch => ch !== id);
        else
            channels.push(id);

        setSelectedChannels(channels);
        applyChannelFilterHandler(channels);
        filterNotificationByChannel();
    }
    const applyFilterHandler = () => {
        applyChannelFilterHandler(selectedChannels);
        setFilterNotifaction(true)
        filterNotificationByChannel();
    }
    const resetFilterHandler = () => {
        resetChannelFilterHandler();
        filterNotificationByChannel();
    }
    useEffect(() => {
        setSelectedChannels(selectedChannelFilter)

    }, [selectedChannelFilter]);

    return (<div className={styles.channeldropdown + ' ' + styles.filterDrop}>
        <ul>
            {CHANNELS_FILTER
                .filter(ch=>{return ch.id!='3' & ch.id!='4' & ch.id!='5' ;})
                .map((channel) => {
                let id = `channelId_${channel.id}`;
                return (<li key={channel.id} className={styles.firstLi}>
                    <div className="form-check channel-check">
                        <input onChange={channelHandler} checked={selectedChannels.includes(channel.id)} className="form-check-input" type="checkbox" value={channel.id} id={id} disabled={channel.displayname}/>
                        <label className="form-check-label" htmlFor={id}>
                            <span className={styles.iconArea}><SVGIcon svgIcondata={channel.svgIcon} /> </span> <span className={fontSize.font13 + ' ' + font.fontMedium}>{channel.name}</span>
                        </label>
                    </div>
                </li>)
            })}
            {/*
            <li onClick={props.deactivateNotificationFilterHandler} className={styles.firstLi}>
                <div className="form-check channel-check">
                    <input onChange={props.deactivateNotificationFilterHandler(!props.isDeactivateNotificationOpne)} checked={props.isDeactivateNotificationOpne} className="form-check-input" type="checkbox" id="deactivationcheckbox" />
                    <label className="form-check-label" htmlFor="deactivationcheckbox">
                        <span className={styles.iconArea}><i className="icon icon-minus-circle undefined"></i></span> <span className={fontSize.font13 + ' ' + font.fontMedium}>Deactive Notification Thread</span>
                    </label>
                </div>
            </li>
            */}
            {/*<li className={styles.buttonstyle}>
                <div className={styles.buttonBody} role="group">
                    <button type="button" onClick={resetFilterHandler} className={button.smBtn + ' ' + button.brownButton}>Reset</button>
                    <button type="button" onClick={applyFilterHandler} className={button.smBtn + ' ' + button.lightRedButton + ' ' + button.buttonSpaceLeft}>Apply</button>
                </div>
            </li>*/}

        </ul>
    </div>)
}

const SVGIcon = (props) => {
    return <>
        <p style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: props.svgIcondata }}></p>
    </>
}

export default ChannelFilter;
