import { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';

import style from '../NotificationThreadDetail/NotificationRecipient.module.css';
import Loader from '../UI/Loader/Loader';
import React from "react";

import {
    updateContactGoup,
    getCategoryTags,
    appUsers,
    fetchEmailOnlySubscribers,
    fetchAppUsers,
    getSelectUsersForGroup,
    getSelectedRecipientData, createNewNotification, previewSelectedRecipients
} from '../../services/notification.services';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import '../Style/Table-style.css';
import tableStyle from '../Style/TableModel.module.css';
import { MdOutlineMarkunreadMailbox } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { FaUserPlus } from "react-icons/fa";
import AuthContext from "../../store/auth-context";
import { BiSort } from 'react-icons/bi';
import {
    Column,
    Table,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
    FilterFns, createColumnHelper,
} from '@tanstack/react-table'
//const orgUuid = '7bce8766-5bd8-4194-b455-ac579dc36587';
import {matchSorter} from 'match-sorter'
function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

const NotificationRecipientPreview = (props) => {
    const authCtx = useContext(AuthContext);


    const [data, setData] = React.useState([]);
    const rerender = React.useReducer(() => ({}), {})[1];
    const [sorting, setSorting] = React.useState([])
    const [isLoading, setIsLoading] = useState(false);
    const columnHelper = createColumnHelper();

    const columns = React.useMemo(
        () => [
            columnHelper.accessor('name', {
                cell: info => info.getValue(),
                footer: info => info.column.id,
                header:'Name',
            }),,
            columnHelper.accessor('email', {
                cell: info => info.getValue(),
                footer: info => info.column.id,
                header:'Email',
            }),
            columnHelper.accessor('source', {
                cell: info => info.getValue(),
                footer: info => info.column.id,
                header:'Source',
            }),
        ],
        []
    )

    const [columnFilters, setColumnFilters] = React.useState(
        []
    )


    const [globalFilter, setGlobalFilter] = React.useState('')


    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            sorting,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        //globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    const preViewSelectedRecipients = () => {
        console.log("Request Data ",props.selectedParameters);
        setIsLoading(true);
        previewSelectedRecipients(props.selectedParameters, authCtx.token).then(res => {
            console.log("Data Reciveid for preview",res);
            let rdata = res.data.data.map((item) => {
                return {
                    name: item.firstName+' '+item.lastName,
                    email: item.email,
                    source: item.tagName==='ePubSubscriber'?'ePublisher':'App User'
                }
            });
            setData(rdata);
            props.setRecipientsCount(rdata.length);
            props.setPreviewedRecipientData(rdata);
            props.setPreviewedRecipientDataTemp(rdata);

            setIsLoading(false);
        });
    }

    useEffect(() => {
        if(props.previewOnlyView){
            setData(props.data);
        }else {
            preViewSelectedRecipients();
        }
    },[]);

    function saveChanges() {
        console.log("Save Changes Called", props.isRecipientUpdate);
        return props.isRecipientUpdate ? props.UpdateRecipientHandler() : props.saveRecipientHandler();
    }

    return (
        <>
            {isLoading && <Loader />}
            {!props.previewOnlyView && <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg" className={style.modalTitle}>
                        <h4 className={tableStyle.modalTitle}>Confirm Selected Recipients</h4>
                    </Modal.Title>

                    <div>
                        {/* <button type="button" className={button.modalBtn + ' ' + button.borderButton}>Campaign</button> */}
                        <button type="button" onClick={props.onAction} className={button.modalBtn + ' ' + button.borderButton + ' ' + button.buttonSpaceLeft}>Cancel</button>
                        <button type="button" onClick={()=>{props.setIsNext(false)}} className={button.modalBtn + ' ' + button.borderButton + ' ' + button.buttonSpaceLeft}>Back</button>
                        {/*<button type="button" style={{ border: "1px solid" }} disabled={isSaving} onClick={props.isRecipientUpdate ? UpdateRecipientHandler : saveRecipientHandler} className={button.modalBtn + ' ' + button.whiteButton + ' ' + button.buttonSpaceLeft}>
                            {isSaving ? <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</> : 'Save'}
                        </button>*/}

                        <button type="button" style={{ border: "1px solid" }} disabled={props.isSaving} onClick={()=>saveChanges()} className={button.modalBtn + ' ' + button.whiteButton + ' ' + button.buttonSpaceLeft}>
                            {props.isSaving ? <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</> : 'Save'}
                        </button>

                    </div>

                </Modal.Header>}
                <Modal.Body>
                    <div className="row table-design" style={{width:'auto',marginLeft:'0',marginRight:'0'}}>
                        <div className={'col'} style={{padding:'0 15px'}}>
                            <p style={{color:'#FF6D73', fontSize:'16px'}}>This is the final list of recipients being selected, based on your criteria.</p>
                            <table style={{width:'100%'}}>
                                <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header =>
                                            <th key={header.id}>

                                            <div className='listHead'>
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: <BiSort size="14px" style={{marginLeft:'0'}} />,
                                                        desc: <BiSort size="14px" style={{marginLeft:'0'}} />,
                                                    }[header.column.getIsSorted()] ?? null}
                                                </div>
                                                {header.column.getCanFilter() ? (
                                                    <div>
                                                        <Filter column={header.column} table={table} />
                                                    </div>
                                                ) : null}
                                                </div>
                                            </th>
                                        )}
                                    </tr>
                                ))}
                                </thead>
                                <tbody>
                                {table.getRowModel().rows.map(row => {
                                    return (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => {

                                                return (
                                                    <td key={cell.id} style={{borderBottom:'1px solid #ddd',fontSize:'14px'}}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <div className="h-2" />
                            <div className="flex items-center gap-2">


                            </div>

                        </div>
                    </div>

                    <div className={'row'} style={{margin:'15px'}}>
                        <div className={'col'}>
                            <div className='listShow'>
                                <span className="flex items-center gap-1">

                                    <strong>
                                        {table.getState().pagination.pageIndex + 1} of{' '}
                                        {table.getPageCount()}
                                    </strong>
                                    </span>
                                <span className="flex items-center gap-1">
                                    &nbsp;&nbsp;| Go to page:
                                    <input
                                        type="number" style={{width:'50px'}}
                                        defaultValue={table.getState().pagination.pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            table.setPageIndex(page)
                                        }}
                                        className="border p-1 rounded "
                                    />
                                    </span>
                                <select className="border form-select" style={{height:'33px'}}
                                    value={table.getState().pagination.pageSize}
                                    onChange={e => {
                                        table.setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={'col'}>
                            <div className='listButton'>
                                <span>{table.getRowModel().rows.length} Rows</span>
                                <button
                                    className="border rounded p-1 m-1"
                                    onClick={() => table.setPageIndex(0)}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    {'<<'}
                                </button>
                                <button
                                    className="border rounded p-1 m-1"
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    {'<'}
                                </button>
                                <button
                                    className="border rounded p-1 m-1"
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    {'>'}
                                </button>
                                <button
                                    className="border rounded p-1 m-1"
                                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                    disabled={!table.getCanNextPage()}
                                >
                                    {'>>'}
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>

        </>
    );
}

function Filter({
                    column,
                    table,
                }) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()
    return (
        <input
            type="text"
            value={(columnFilterValue ?? '') }
            onChange={e => column.setFilterValue(e.target.value)}
            placeholder={`Search...`}
            className="w-36 border rounded"
        />
    )
}

export default NotificationRecipientPreview;
