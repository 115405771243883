import {useState,useContext,useEffect, useCallback} from 'react';

//import OtpInput from 'react-otp-input';
import custom from  './customModal.module.css'
import AuthContext from '../../../src/store/auth-context';
import { USERS_API_BASE_URL } from './../../constants';
const AddNumberModal = (props) =>{
    console.log("On load component",props);
    const { show, close, addnumberhandler} = props

    const [itemID, setItemID] = useState(0);
    const [title, settitle] = useState(props.title);
    const [mobileNumber, setMobileNumber] = useState('');
    const [titleColour, settitleColour] = useState('custom.titleColorBg');
    const [colorModal, setColorModal] = useState(false);

    const authCtx = useContext(AuthContext);

    let orgUuid=authCtx.userData.orgId;
    let contactdetail = props.contactDetail;
    //let titleColour = '#ffffff';

    const colorModalHandle = ()=>{
        setColorModal(!colorModal)
    }

    const numberChangeHandle =({target})=>{
        //console.log(target.value)
        setMobileNumber(target.value)
    }

    const changeTitle = ({target })=>{
        settitle(target.value);
    }

    const getClassName = (clsName) =>{
        //alert(1)
        settitleColour(clsName)
        setColorModal(!colorModal)
    }

    //console.log('dsfsdf',contactdetail)

    useEffect(() => {
        //console.log(6)
        //console.log("Values recieved for editing ",props.contactDetail)
        setItemID(props.contactDetail.id);
        settitle(props.contactDetail.title);
        setMobileNumber(props.contactDetail.org_contact);
        settitleColour(props.contactDetail.titleColour);

    },[props.contactDetail.id,props.contactDetail.title,props.contactDetail.org_contact,props.contactDetail.titleColour]);

    const submitHandler =()=>{
        //console.log(titleColour)
        if(itemID==0){
            fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/add-contact`, {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                    "{\"org_contact\":\"" + mobileNumber + "\",\"orgId\":\"" + orgUuid + "\",\"titleColour\":\"" + titleColour + "\",\"title\":\"" + title + "\"}"

            }).then(res=>res.json()).then(data=>{alert(data.status==302?data.error:data.msg);close();settitle('')
                addnumberhandler(mobileNumber,titleColour,title);
                if(props.OrganisationContactList){
                    props.setOrganisationContactList([...props.OrganisationContactList,{id:data.newContactData.id, org_contact:mobileNumber,title:title,titleColour:titleColour,displayorder:data.newContactData.displayorder,phoneStatus:data.newContactData.phoneStatus}]);
                }

             })
        }else{
            fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/update-contact`, {
                "method": "PUT",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                    "{\"org_contact\":\"" + mobileNumber + "\",\"orgId\":\"" + orgUuid + "\",\"titleColour\":\"" + titleColour + "\",\"title\":\"" + title + "\",\"id\":\"" + itemID + "\"}"

            }).then(res=>res.json()).then(data=>{alert(data.status==302?data.error:data.msg);close();settitle('')
                //addnumberhandler(mobileNumber,titleColour,title);
               if(props.OrganisationContactList){
                    const index = props.OrganisationContactList.findIndex(data => data.id === itemID),
                    contactData = [...props.OrganisationContactList] // important to create a copy, otherwise you'll modify state outside of setState call
                    contactData[index] = {id:data.updatecontactData.id, org_contact:data.updatecontactData.org_contact,title:data.updatecontactData.title,titleColour:data.updatecontactData.titleColour,phoneStatus:data.updatecontactData.phoneStatus};
                    console.log(contactData)
                    props.setOrganisationContactList(contactData);
               }

             })
        }




    }

    const deleteHandler =(itemID)=>{
        console.log(itemID)

            fetch(`${USERS_API_BASE_URL}/organisation/`+orgUuid+`/delete-contact`, {
                "method": "PATCH",
                "headers": {
                    "Content-Type": "application/json"
                },
                "body":
                "{\"ORGID\":\"" + orgUuid + "\",\"itemID\":\"" + itemID + "\"}"

            }).then(res=>res.json()).then(data=>{alert(data.status==302?data.error:data.msg);close();
                if(props.OrganisationContactList){
                    var array = [...props.OrganisationContactList]; // make a separate copy of the array
                        //console.log(array)
                        //var index = props.OrganisationContactList.indexOf(itemID)
                        const index = props.OrganisationContactList.findIndex(data => data.id === itemID)
                        //console.log(index)
                        if (index !== -1) {
                            array.splice(index, 1);
                            //console.log(array)
                            props.setOrganisationContactList(array)
                        }
                }

             })

    }

    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            close();
        }
      }, [close]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);

    return(
        <>
        {
            show ?
            <div className={custom.modalContainer}>
                <div className={custom.modalArea}>
                    <div className={custom.modalContent}>
                        <form>
                            <div className={custom.groupSide}>
                                <h2 className={custom.modalHead}>TITLE</h2>
                                <div className={custom.underLine}>
                                    <input type="text" className={custom.fromInput} value={title} placeholder="Type Here..." onChange={changeTitle} />
                                </div>
                                <div className={custom.colorPicker}>
                                    <button type="button" onClick={colorModalHandle} className={titleColour} style={{width:'30px',height:'30px'}}><span></span></button>
                                </div>
                                {colorModal && (
                                    <div className={custom.selectColorBox}>
                                    <div className={custom.pickerHead}>
                                        <button type="button" onClick={colorModalHandle} className={custom.colorBtn + ' ' + custom.blackclrBtn}>Close</button>
                                        <button type="button" className={custom.colorBtn + ' ' + custom.blurclrBtn}>Save</button>
                                    </div>
                                    <div className={custom.colorCode}>
                                        <p className={custom.selectOpt}>SELECT A TITLE BACKGROUND</p>
                                        <ul>
                                            <li onClick={() => getClassName(custom.whiteBackground)}>
                                                <span className={custom.whiteBackground} ></span>
                                                <p>White background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.grayBackground)}>
                                                <span className={custom.grayBackground}></span>
                                                <p>Grey background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.brownBackground)}>
                                                <span className={custom.brownBackground}></span>
                                                <p>Brown background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.orngeBackground)}>
                                                <span className={custom.orngeBackground}></span>
                                                <p>Orange background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.yellowBackground)}>
                                                <span className={custom.yellowBackground}></span>
                                                <p>Yellow background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.greenBackground)}>
                                                <span className={custom.greenBackground}></span>
                                                <p>Green background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.blueBackground)}>
                                                <span className={custom.blueBackground}></span>
                                                <p>Blue background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.purpleBackground)}>
                                                <span className={custom.purpleBackground}></span>
                                                <p>Purple background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.pinkBackground)}>
                                                <span className={custom.pinkBackground}></span>
                                                <p>Pink background</p>
                                            </li>
                                            <li onClick={() => getClassName(custom.redBackground)}>
                                                <span className={custom.redBackground}></span>
                                                <p>Red background</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                )}

                            </div>
                            <div className={custom.numberFeed}>
                                <h2 className={custom.modalHead}>ADD PHONE NUMBER</h2>
                                <span>Area code + 8 digit number</span>
                                <div className={custom.numberArea}>
                                {/*<OtpInput
                                    value={mobileNumber}
                                    onChange={numberChangeHandle}
                                    numInputs={10}
                                    className={custom.numberInput}
                                    separator={<span> </span>}
                                />*/}
                                    <input type="tel" value={mobileNumber}  onChange={numberChangeHandle} />
                                </div>
                            </div>
                            <div className={custom.modalFooter}>
                                <button type="button" className={custom.modalbtn + ' ' + custom.redBtn} onClick={() => deleteHandler(itemID)}>Delete</button>
                                <button type="button" className={custom.modalbtn + ' ' + custom.whiteBtn} onClick={() => close()}>Cancel</button>
                                <button type="button" className={custom.modalbtn + ' ' + custom.blurBtn} onClick={submitHandler}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            : null
        }
        </>
    )
}
export default AddNumberModal;
