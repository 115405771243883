import { useEffect, useRef } from 'react';
import NotificationCard from './NotificationCard';
import style from './NotificationCard.module.css';

const NotificationCardList = (props) => {
  
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        console.log("Total messages : ",props.selectedNotificationData.length);
        scrollToBottom()
    }, [props.selectedNotificationData]);

    const addMessageToPin = (messageId) => {
        props.onSavePinMessage(messageId);
    }

    const removeNotification=(id)=>{
        if(props.selectedNotificationData.length==1){
            if(window.confirm("CAUTION: You're deleting the last remaining post of this thread. If this post is deleted, the parent thread will also be deleted. \n" +
                "This action is permanent. Are you sure?")){
                props.removeNotification(id,true);
            }
        }else {
            props.removeNotification(id);
        }
    }
    return (<div className={style.comopsoedrop + ' ' + props.contactGroupStatus} style={{height:(props.contactGroupStatus=="INACTIVE")?"auto":""}}>

        <div className={style.editBox}>
            {props.selectedNotificationData.map(notification => <NotificationCard
                key={notification.id}
                notificationData={notification}
                removeNotification={removeNotification}
                onPinSave={addMessageToPin}
                sendMessage={props.sendMessage}
                setDissableEditor={props.setDissableEditor}
                onEditMessage={props.onEditMessage}
            />)}
            <div ref={messagesEndRef} />
        </div>

    </div>
    );
}
export default NotificationCardList;
