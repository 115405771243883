import React, { useState, useEffect, useRef } from "react";
import style from "../../Style/EditorStyle.module.css";
import '../../Style/FormStyle.css';
import $ from 'jquery'
import audioThm from '../../../assets/audio-img.jpg'
function EditAudioPlayer (props){

  $(function() {
    // for each audioquote component, if there is more than one in the same page

    $('.audioBox').each(function(e){

      const $this = $(this);
      const $togglePlay = $this.find('.playPauseButton');
      const player = $this.find('audio').get(0);
      const progressBar = $this.find('.progresshead');

      let isPaused = true
      $togglePlay.on('click', function(e){
        var audios = document.getElementsByTagName('audio');
        for(var i = 0, len = audios.length; i < len;i++){
            if(audios[i] != e.target){
                audios[i].pause();
                $('.controlContainer').removeClass('is-playing');
            }
        }
        if(isPaused){
          player.play();
          $this.find('.controlContainer').addClass('is-playing');
          // $this.find('.playPauseButton').removeClass('fa-play');
          // $this.find('.playPauseButton').addClass('fa-pause');
          isPaused = false
        } else {
          player.pause();
          isPaused = true;
          $this.find('.controlContainer').removeClass('is-playing');
          // $this.find('.playPauseButton').addClass('fa-play');
          // $this.find('.playPauseButton').removeClass('fa-pause');
        }
      });

      // when the audio finish, reset all
      player.addEventListener("ended", function() {
        $('.controlContainer').removeClass('is-playing');
        // $('.playPauseButton').removeClass('playing');
        // reset to zero the progress
        progressBar.css('width', '0%');
        // time at zero
        player.currentTime = 0;
      }, true);

      // set total duration of the video
      player.addEventListener('canplaythrough', function(){
        // insert total duration into the page
        const totalLength = calculateTotalValue(player.duration);
        $this.find('.duration').html(totalLength);
      }, false);


      // calculate total length of the audio
      function calculateTotalValue(length) {
        const minutes = Math.floor(length / 60);
        const seconds_int = length - minutes * 60;
        if(seconds_int < 1){
          seconds_int = "0"+seconds_int;
        }
        const seconds_str = seconds_int.toString();
        const seconds = seconds_str.substr(0, 2);
        const time = minutes + ':' + seconds;
        return time;
      }

      // Update the progress bar
      function updateProgressBar() {
        // Work out how much of the media has played via the duration and currentTime parameters
        const percentage = Math.floor((100 / player.duration) * player.currentTime);
        // Update the progress bar's value
        progressBar.css('width', percentage+'%');
        // Update the progress bar's text
        const currentTime = calculateCurrentValue(player.currentTime);
        $this.find(".playhead").html(currentTime);
      }

      function calculateCurrentValue(currentTime) {
        let current_hour = parseInt(currentTime / 3600) % 24,
            current_minute = parseInt(currentTime / 60) % 60,
            current_seconds_long = currentTime % 60,
            current_seconds = current_seconds_long.toFixed(),
            current_time = (current_minute < 10 ? "" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
        return current_time;
      }

      // Add a listener for the timeupdate event so we can update the progress bar
      player.addEventListener('timeupdate', updateProgressBar, false);


      $this.find('.progresstrack').on('click', function(e){
        if (player.src) {
          const percent = e.offsetX / this.offsetWidth;
          player.currentTime = percent * player.duration;
          // update progress bar
          progressBar.css('width', Math.floor(percent / 100)+'%');
        }
      });

    });
  });

      return (
            <div className={"audioBox " + style.audioComponent}>
                <div className={style.audioContainer}>
                    <div className={style.audioBox}>
                        <div className={style.audioImage}>
                            <img className={style.albumCover} src={audioThm} />
                        </div>
                        <div className={style.audioDetail}>
                            <p className={style.audioTitle}>{props.rawData.name}</p>
                            <p className={style.authorName}>Digital Marketing - By Setup Cast</p>
                        </div>
                    </div>
                    <div className={"controlContainer " + style.boxContainer}>
                    <audio className="audioSrc" src={props.rawData.src}>
                      <p>Your browser does not support the audio element</p>
                    </audio>
                    <div className={"playPauseButton " + style.toggleBtn}></div>

                      <div className={"progress "  + style.progressMain}>
                        <span className={"progresstrack "  + style.proFront}></span>
                        <span className={"progresshead " + style.proBack}></span>
                      </div>

                        <div className={style.timeContainer}><p className="playhead">0:00</p><span>/</span><p className="duration">0:00</p></div>
                  </div>
                </div>
            </div>)
}

export default EditAudioPlayer;
