import {useState, useEffect, useMemo, useRef, useContext} from 'react';
import axios from "axios";
import {USERS_API_BASE_URL} from "../../../constants"
import data from "bootstrap/js/src/dom/data";
import AuthContext from "../../../store/auth-context";


const UploadFiles = (props) => {
    let selectedFilesList = false;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progressInfos, setProgressInfos] = useState({val: []});
    const [message, setMessage] = useState([]);
    const [fileInfos, setFileInfos] = useState([]);
    const progressInfosRef = useRef(null);
    const authCtx = useContext(AuthContext);
    let cancelUpload = false;
    let acceptType='';
    var filesInfoArray=[];
    const http = axios.create({
        baseURL: USERS_API_BASE_URL,
        headers: {
            "Content-type": "application/json",'Authorization':authCtx.token
        }
    });
    const uploadService = (file, onUploadProgress, processResponse) => {
        if (file.type === "image/jpeg" || file.type === "audio/mpeg" && file.size < "500000000" || file.type === "application/pdf") {
            let formData = new FormData();
            formData.append("file", file);
            return http.post("/multiple-upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress,
            }).then(function (response) {
                processResponse(response);
            });
        } else {
            alert("Maximum file size allowed is 50MB.");
        }
    };

    const getFilesService = () => {
        return http.get("/files");
    };


    const selectFiles = (event) => {

        event.stopPropagation();
        console.log("file type ",event.target.accept);
        setSelectedFiles(event.target.files);
        selectedFilesList = event.target.files;
        setProgressInfos({val: []});
        uploadFiles();
    };


    const uploadFiles = (e) => {
        if (e) e.preventDefault();
        //Initialize variables to default
        setSelectedFiles([]);
        setProgressInfos({val: []});
        setFileInfos([]);
        setMessage([]);
        filesInfoArray=[];

        const files = Array.from(selectedFilesList);
        let _progressInfos = files.map(file => ({percentage: 0, fileName: file.name}));

        //setFileInfos(files.map(file => ({fileName: file.name,thumbUrl:'',url:''})));

        progressInfosRef.current = {
            val: _progressInfos,
        }

        const uploadPromises = files.map(async (file, i) => await upload(i, file));
        let imagesUrls = [];

        fileInfos.map((file, index) => {
            //imagesUrls=[...imagesUrls,{ name: file.url, url: file.thumbUrl}];
        });

        //console.log('imagesUrls',imagesUrls);


        Promise.all(uploadPromises)
            .then((res) => {

            }).then((fileInfos) => {
            //setFileInfos(files.data);
            //console.log("filesInfoArray ",filesInfoArray);
            props.filesHandlerCallback(filesInfoArray);

            setSelectedFiles([]);
            setProgressInfos({val: []});
            setFileInfos([]);
            setMessage([]);
            filesInfoArray=[];

        });
        setMessage([]);
    };

    const resizeImage = (file) => {
        return new Promise((resolve) => {
            let img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                let width = img.width;
                let height = img.height;
                if (width > 2000) {
                    height = Math.round((height *= 2000 / width));
                    width = 2000;
                }
                const elem = document.createElement("canvas");
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                    resolve(blob);
                }, "image/jpeg", 0.8);
            };
        });
    }

    const upload = async (idx, file) => {
        if (cancelUpload) return;
        let _progressInfos = [...progressInfosRef.current.val];
        let _fileInfos = [...fileInfos];

        if (file.type === "image/jpeg") {
            await resizeImage(file).then((blob) => {

                const newFile = new File([blob], file.name, {
                    type: "image/jpeg",
                    lastModified: Date.now(),
                });
                file = newFile;
            });
        }

        return uploadService(file, (event) => {
            _progressInfos[idx].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            setProgressInfos({val: _progressInfos});
        }, (response) => {
            //_fileInfos[idx].thumbUrl=response.data.thumbUrl;
            if (response.data.error) {
                console.log("Error while uploading file " + response.data.error);
                return;
            }
            setFileInfos((preFileInfos) => {
                return [...preFileInfos, {
                    idx: idx,
                    file: file,
                    fileID: response.data.fileID,
                    url: response.data.url,
                    thumbUrl: response.data.thumbUrl
                }]
            });
            //console.log("File info updating ");
            //console.log(fileInfos);
            filesInfoArray = [...filesInfoArray, {
                name: file.name,
                fileID: response.data.fileID,
                url: response.data.url,
                thumbUrl: response.data.thumbUrl
            }];
        })
            .then(() => {
                setMessage((prevMessage) => ([
                    ...prevMessage,
                    "Uploaded the file successfully: " + file.name,

                ]));

            })
            .catch((e) => {
                console.log(e);
                _progressInfos[idx].percentage = 0;
                setProgressInfos({val: _progressInfos});

                setMessage((prevMessage) => ([
                    ...prevMessage,
                    "Could not upload the file: " + file.name,
                ]));
            });
    };


    useEffect(() => {
        /*getFilesService().then((response) => {
            setFileInfos(response.data);
        });*/


    }, []);

    switch (window.UPLOAD_FILES){
        case "IMAGES_GALLERY": acceptType='image/*';break;
        case "VIDEO": acceptType="video/mp4,video/x-m4v,video/*";break;
        case "PDF_ATTACHMENTS": acceptType="application/pdf";break;
        case "IMAGE_ATTACHMENTS": acceptType="image/*";break;
        case "AUDIO_ATTACHMENTS": acceptType=".mp3,audio/*";break;
        case "PDF_TO_IMAGES_ATTACHMENTS": acceptType="application/pdf";break;
        default: acceptType='';
    }

    return (
        <div style={{border: "0px", borderColor: "#444", borderStyle: "solid"}}>
            { fileInfos.length > 0 &&
            <div className="card">
                <div style={{display:"none"}} className="card-header">List of Files <a onClick={() => {
                    cancelUpload = true;
                }}>Cancel</a></div>
                <ul style={{display:"none"}} className="list-group list-group-flush">
                    {fileInfos &&
                    fileInfos.map((file, index) => (
                        <li className="list-group-item uploaded-files-detail" key={index}>
                            <a href={file.url}>{file.thumbUrl}</a>
                        </li>
                    ))}
                </ul>
            </div>
        }
            {progressInfos && progressInfos.val.length > 0 &&
            progressInfos.val.map((progressInfo, index) => (
                <div className="mb-2" key={index}>
                    <span style={{fontSize:"14px", color:"rgb(136, 136, 136)"}}>{progressInfo.fileName}</span>
                    <div className="progress" style={{height:"5px",marginTop:"10px"}}>
                        <div
                            className="progress-bar progress-bar-info"
                            role="progressbar"
                            aria-valuenow={progressInfo.percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{width: progressInfo.percentage + "%", backgroundColor:"#49c096"}}
                        >
                            {/*{progressInfo.percentage}%*/}
                        </div>
                    </div>
                </div>
            ))}

            <div className="row my-3" style={{display: "none"}}>
                <div className="col-8">
                    <label className="btn btn-default p-0">
                        <input type="file" id="selected_files_images" accept="image/*" multiple onChange={selectFiles}/>
                        <input type="file" id="selected_files_videos" accept="video/mp4,video/x-m4v,video/*" multiple onChange={selectFiles}/>
                        <input type="file" id="selected_files_pdfs" accept="application/pdf" multiple onChange={selectFiles}/>
                        <input type="file" id="selected_files_audios" accept=".mp3,audio/*" multiple onChange={selectFiles}/>
                        <input type="file" id="selected_files" accept="*" multiple onChange={selectFiles}/>
                    </label>
                </div>

                <div className="col-4">
                    <button id="upload-button"
                            className="btn btn-success btn-sm"
                            disabled={!selectedFiles}
                            onClick={uploadFiles}
                    >
                        Upload
                    </button>
                </div>
            </div>

            {message.length > 0 && (
                <div className="alert alert-secondary" role="alert" style={{display: "none"}}>
                    <ul>
                        {message.map((item, i) => {
                            return <li key={i}>{item}</li>;
                        })}
                    </ul>
                </div>
            )}


        </div>
    );
}
export default UploadFiles;
