import { useState } from 'react';
import style from './SchoolLinkAppUsers.module.css';
import Pagination from '../../UI/Pagination/Pagination';
import EmailOnlySubscriberTags from './EmailOnlySubscriberTags';
import { GoSearch } from "react-icons/go";
import '../../Style/FormStyle.css';
import '../../Style/Pagination.css';

let PageSize = 6;

const EmailOnlySubscribers = (props) => {
    const { appData, onChange, selectedEmailOnlySubscribers, isAllEmailOnlySubscribersSelected, selectedChannels,setIsAllEmailOnlySubscribersSelected } = props;

    const [searchInputText, setSearchInputText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    const searchTextArray = searchInputText.toLowerCase().trim().split(' ');

    const searchData = appData.filter((category) => {
        return searchTextArray.every(i => category.CategoryName.toLowerCase().indexOf(i) > -1)
    });

    const sliceData = searchData.slice(firstPageIndex, lastPageIndex);


    const emailOnlySubscribersSearchHandler = (e) => {

        setSearchInputText(e.target.value);
        setCurrentPage(1);

    }
    return (<>
    {(selectedChannels.includes("2")===true) ?(
    <>
            <div className={'form-group ' + style.titleGroup}>
                <label className={style.label} >Select subscribers for email</label><br/>
            </div>
        <form>
            <div className={'form-group ' + style.hassearch}>
                <GoSearch className={style.searchIcon} />
                <input
                    onChange={emailOnlySubscribersSearchHandler}
                    type="text"
                    className={style.formcontrol}
                    placeholder="Search..."
                />
            </div>
        </form>
            <br/>
        <form>
            {/*<div className='form-group'>
                <div className={'form-check ' + style.allGroup}>
                    <input className={'form-check-input ' + style.groupInput}
                        type="checkbox"
                        onChange={() => setIsAllEmailOnlySubscribersSelected(preState => !preState)}
                        checked={isAllEmailOnlySubscribersSelected}
                    />
                    <label className={'form-check-label ' + style.groupLabel} htmlFor="flexCheckDefault">
                        Select All app users
                    </label>
                </div>
            </div>*/}




            {
                sliceData.map((data) => (
                    <div className={'form-group ' + style.selectGroup} key={data.pkCategoryID}>
                        <label className={style.inputLabel}>{data.CategoryName}</label>
                        <EmailOnlySubscriberTags
                            TagitemsId={data.TagitemsId}
                            Tagitems={data.Tagitems}
                            pkCategoryID={data.pkCategoryID}
                            onSelectTags={onChange}
                            selectedEmailOnlySubscribers={selectedEmailOnlySubscribers}
                            isAllEmailOnlySubscribersSelected={isAllEmailOnlySubscribersSelected}
                        />
                    </div>
                ))
            }
        </form>
        <div className="modalStyle padd-0">
            <Pagination
                currentPage={currentPage}
                totalCount={searchData.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
        </>):(<div className={'form-group ' + style.titleGroup}>
                <label className={style.label} >If you want send messages to Email Subscribers then Please select Email Notification from Channels.</label><br/>
            </div>)
}
    </>
    );
}

export default EmailOnlySubscribers;
