import {useState, useEffect, useRef, useContext} from 'react';
import { getHelpText } from '../../services/Help.services';
import {USERS_API_BASE_URL} from '../../constants';
import AuthContext from "../../store/auth-context";
import { ContentState, convertToRaw,EditorState
,SelectionState,
Modifier,
convertFromRaw,
AtomicBlockUtils,
Entity } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import ReactDOMServer from 'react-dom/server';
import customRenderToHtml from '../NotificationThreadDetail/Editor/CustomRendererToHtml';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import button from '../Style/ButtonStyle.module.css';
import {stateToHTML} from 'draft-js-export-html';
import '../Style/Table-style.css';
import { toast } from 'react-toastify';

// import { convertToHTML } from 'draft-convert';
const HelpText = (props) => {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
      );
      const [convertedContent, setConvertedContent] = useState(null);

      useEffect(() => {
       
      }, [editorState]);

     
    useEffect(() => {
        getHelpText(authCtx.token)
            .then((response) => {
                setIsLoading(false);
               // setConvertedContent(response.data.helpText);
               
                const contentBlock = htmlToDraft(JSON.parse(response.data.helpText).html);
                let contentState1 = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                //contentState1 = convertFromRaw(JSON.parse(response.data.helpText).rawDraftContentState);
                
                let editorState = EditorState.createWithContent(contentState1)
                
                setEditorState(editorState)
               
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
    }, [])
    const UpdateHelpText = (e) => {
        e.preventDefault();
         let html = stateToHTML(editorState.getCurrentContent());
        let contentState = editorState.getCurrentContent();
        let rawDraftContentState = convertToRaw(contentState);
      
        let helpData ={html:html,rawDraftContentState:rawDraftContentState}
       // console.log("helpData",helpData)
       // setConvertedContent(helpData);
       
        if (helpData != '') {
            let ConvertedText =  JSON.stringify(helpData)
            fetch(USERS_API_BASE_URL+"/help", {
                "method": "PUT",
                "headers": {
                    "Content-Type": "application/json","Authorization":authCtx.token
                },
                "body":
                ConvertedText

            })
                .then((response) => {
                    if (response.status == 200) {
                        toast.success("Message Updated.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            type: 'success'
                        });
                    }
                })

        }
    }
 

    return (
        <div className="table-design">
            <div className='helpEditor'>
        
                <Editor
                style={{    border: '1px solid #dee2e6',
                    borderRadius: '5px',
                    padding: '5px',
                    margin: '10px 0',}}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                        options: ['inline', 'list', 'link'],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                            bold: {className: 'bordered-option-classname'},
                            italic: {className: 'bordered-option-classname'},
                            underline: {className: 'bordered-option-classname'},
                            inDropdown: false,
                        },
                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered'],

                        },
                        link: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            dropdownClassName: undefined,
                            showOpenOptionOnHover: true,
                            defaultTargetOption: '_self',
                            options: ['link', 'unlink'],
                            linkCallback: undefined
                        },
                        blockType: {
                            className: 'bordered-option-classname',
                        },
                        fontSize: {
                            className: 'bordered-option-classname',
                        },
                        fontFamily: {
                            className: 'bordered-option-classname',
                        },
                    }}
                />
                </div>
                <input type="button" value="Update" className={button.btn + ' ' + button.blueButton} onClick={UpdateHelpText} />
        </div>
    )
}
export default HelpText

