// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoreTextBlock_CustomStyle__IoIdx{
    min-height: 200px!important;
    max-height: 400px;
    overflow: scroll!important;
    overflow-y: auto!important;
    overflow-x: hidden!important;
    height: auto!important;
    margin-top: auto;
}

.MoreTextBlock_outerContainer__sbhYU{
    /*border: 1px solid #ccca;*/
    /*box-shadow: 0px 0px 4px #ccc;*/
    font-family: -apple-system, BlinkMacSystemFont, Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif!important;
    font-size: 14px;
    color: #1d1d1f;
    line-height: 1.7;
    letter-spacing: 0em;
    position: relative;
    padding-top: 13px;
    border-bottom: 1px solid #ededed;
    /*width: 272px;*/
}
.MoreTextBlock_edit_button__04xlr{
    position: relative;
    float: right;
    opacity: 0;
}
.MoreTextBlock_editButtonContainer__oJuIj{
    border-bottom: 1px solid #ededed;
    position: relative;
    height: 0px;
    width: 100%;
    margin-bottom: 10px;
}
.MoreTextBlock_outerContainer__sbhYU p{
    margin: 0;
}
.MoreTextBlock_outerContainer__sbhYU:hover .MoreTextBlock_edit_button__04xlr{
    opacity: 1;
    transition: opacity;
    transition-duration: 1s;
}
.MoreTextBlock_renderedOuterContainer__ax2x5{
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    margin: 10px 0;
    padding: 10px 0;

}
`, "",{"version":3,"sources":["webpack://./src/components/NotificationThreadDetail/Editor/MoreTextBlock.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,0BAA0B;IAC1B,0BAA0B;IAC1B,4BAA4B;IAC5B,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,gCAAgC;IAChC,sHAAsH;IACtH,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,gCAAgC;IAChC,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd;AACA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,SAAS;AACb;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,gCAAgC;IAChC,6BAA6B;IAC7B,cAAc;IACd,eAAe;;AAEnB","sourcesContent":[".CustomStyle{\n    min-height: 200px!important;\n    max-height: 400px;\n    overflow: scroll!important;\n    overflow-y: auto!important;\n    overflow-x: hidden!important;\n    height: auto!important;\n    margin-top: auto;\n}\n\n.outerContainer{\n    /*border: 1px solid #ccca;*/\n    /*box-shadow: 0px 0px 4px #ccc;*/\n    font-family: -apple-system, BlinkMacSystemFont, Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif!important;\n    font-size: 14px;\n    color: #1d1d1f;\n    line-height: 1.7;\n    letter-spacing: 0em;\n    position: relative;\n    padding-top: 13px;\n    border-bottom: 1px solid #ededed;\n    /*width: 272px;*/\n}\n.edit_button{\n    position: relative;\n    float: right;\n    opacity: 0;\n}\n.editButtonContainer{\n    border-bottom: 1px solid #ededed;\n    position: relative;\n    height: 0px;\n    width: 100%;\n    margin-bottom: 10px;\n}\n.outerContainer p{\n    margin: 0;\n}\n.outerContainer:hover .edit_button{\n    opacity: 1;\n    transition: opacity;\n    transition-duration: 1s;\n}\n.renderedOuterContainer{\n    border-bottom: 1px solid #ededed;\n    border-top: 1px solid #ededed;\n    margin: 10px 0;\n    padding: 10px 0;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomStyle": `MoreTextBlock_CustomStyle__IoIdx`,
	"outerContainer": `MoreTextBlock_outerContainer__sbhYU`,
	"edit_button": `MoreTextBlock_edit_button__04xlr`,
	"editButtonContainer": `MoreTextBlock_editButtonContainer__oJuIj`,
	"renderedOuterContainer": `MoreTextBlock_renderedOuterContainer__ax2x5`
};
export default ___CSS_LOADER_EXPORT___;
