
import styles from './Channels.module.css';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import font from '../Style/FontFamily.module.css';

const DeactivateNotificationThread = (props) => {

    const { deactivateNotificationFilterHandler } = props;
    return (
        <div className={styles.channeldropdown + ' ' + styles.deactivateBtn}>
            <ul>
                <li onClick={deactivateNotificationFilterHandler} className={styles.firstLi}>
                    <i className={'icon icon-minus-circle ' + common.iconArea}></i>
                    <span className={fontSize.font13 + ' ' + font.fontMedium}>&nbsp;Deactive Notification Thread</span>
                </li>
            </ul>
        </div>
    )
}

export default DeactivateNotificationThread;
