import { useContext } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import layoutStyles from './Layout.module.css';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../../../store/auth-context';
import { ToastContainer, Slide } from 'react-toastify';


const Layout = (props) => {

    const authCtx = useContext(AuthContext);

    return (
        <div className="d-flex flex-column">
            <div style={{zIndex:1000}} className={layoutStyles.headerContainer}>
            <ToastContainer
                transition={Slide}
                hideProgressBar={true}
            />
            {authCtx.isLoggedIn && (
                <>
                    <Header />
                    {/*<Sidebar />*/}
                </>
            )}
            </div>
            <main className={layoutStyles.mainContainer} >
                {props.children}
            </main>
            {/* {authCtx.isLoggedIn && <Footer />} */}

        </div>
    )
}

export default Layout;
