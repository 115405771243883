import {forwardRef, useState} from "react";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
const ImageLightBox=forwardRef((props,ref)=>{
    const [isLightBoxOpen, setLightBoxOpen]=useState(props.isLightBoxOpen);
    const [photoIndex,setPhotoIndex]=useState(0);
    if(props.images==undefined || props.images.length==0) return null;
    return (
        <>
            <button style={{display:"none"}} ref={ref} className="buttonToOpenLightbox btn btn-outline-secondary" type="button" onClick={() => setLightBoxOpen(true)}>
                View Images
            </button>
            {isLightBoxOpen && (
                <Lightbox
                    mainSrc={props.images[photoIndex]}
                    nextSrc={props.images[(photoIndex + 1) % props.images.length]}
                    prevSrc={props.images[(photoIndex + props.images.length - 1) % props.images.length]}
                    onCloseRequest={() => setLightBoxOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex ((photoIndex + props.images.length - 1) % props.images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % props.images.length)
                    }
                />
            )}
        </>
    )
});

export default ImageLightBox;
