import React from 'react';
import { usePagination, DOTS } from '../../../hooks/use-pagination';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <nav aria-label="Page navigation" style={{ background: "#fff", float: "right", height: "inherit" }}>

            <ul className="pagination ">
                <li className={`page-item ${(currentPage === 1) ? 'disabled' : ''}`} key="p-1">
                    <button onClick={onPrevious} type="button" className="page-link" aria-label="Previous">
                        <MdKeyboardArrowLeft size="20px" />
                    </button>
                </li>

                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return <li className="pagination-item dots">&#8230;</li>;
                    }

                    return (
                        <li key={pageNumber} className={'page-item ' + (pageNumber === currentPage ? 'active' : '')} onClick={() => onPageChange(pageNumber)}>
                            <button type="button" className="page-link">{pageNumber}</button>
                        </li>
                    );
                })}

                <li className={`page-item ${(currentPage === lastPage) ? 'disabled' : ''}`} key="p-n">
                    <button onClick={onNext} type="button" className="page-link" aria-label="Next">
                        <MdKeyboardArrowRight size="20px" />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;