import axios from "axios";
import {USERS_API_BASE_URL} from "../constants";
//const BASE_URL = 'http://api.dev-n-test.com/'; // Base url for development server
//const LOCAL_BASE_URL = 'http://localhost:5050/';  // Base url for local server
//const BASE_URL = 'http://apidocs.dev-n-test.com/'; Base url for mock server

/** For getting contact group ids **/
export const getOrganisationDetails = (authToken) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/list`,{headers:{'Authorization':authToken}});
}

export const getOrganisationDetailsByID = (authToken,orguuid) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orguuid,{headers:{'Authorization':authToken}});
}

export const getOrganisationContact = (authToken,orgID) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orgID+`/org-contacts`,{headers:{'Authorization':authToken}});
}
export const getOrganisationContactDetail = (authToken,orgID,cid) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orgID+`/org-contacts-detail/`+cid,{headers:{'Authorization':authToken}});
}
export const getOrganisationLinkDetail = (authToken,orgID,cid) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orgID+`/org-link-detail/`+cid,{headers:{'Authorization':authToken}});
}
export const getOrganisationLink = (authToken,orgID) => {
    return axios.get(`${USERS_API_BASE_URL}/organisation/`+orgID+`/org-link`,{headers:{'Authorization':authToken}});
}
