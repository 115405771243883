// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FontFamily_fontBlack__jdWze{
    font-family: "Roboto-Black";
}
.FontFamily_fontBold__g0zMY{
    font-family: "Roboto-Bold";
}
.FontFamily_fontRegular__bBEVo{
    font-family: "Roboto-Regular";
}
.FontFamily_fontMedium__sDvt0{
    font-family: "Roboto-Medium";
}
.FontFamily_fontLight__bapAy{
    font-family: "Roboto-Light";
}

/* .HelveticaNeue100{
    font-family: "HelveticaNeue-Thin";
} */
.FontFamily_HelveticaNeue300__K64XV{
    font-family: "Helvetica-Light";
}
.FontFamily_HelveticaNeue400__V3aKy{
    font-family: "Helvetica-Regular";
}
.FontFamily_HelveticaNeue500__pt1GP{
    font-family: "Helvetica-Medium";
}
.FontFamily_HelveticaNeue700__bTutO{
    font-family: "Helvetica-Bold";
}`, "",{"version":3,"sources":["webpack://./src/components/Style/FontFamily.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,6BAA6B;AACjC;AACA;IACI,4BAA4B;AAChC;AACA;IACI,2BAA2B;AAC/B;;AAEA;;GAEG;AACH;IACI,8BAA8B;AAClC;AACA;IACI,gCAAgC;AACpC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,6BAA6B;AACjC","sourcesContent":[".fontBlack{\n    font-family: \"Roboto-Black\";\n}\n.fontBold{\n    font-family: \"Roboto-Bold\";\n}\n.fontRegular{\n    font-family: \"Roboto-Regular\";\n}\n.fontMedium{\n    font-family: \"Roboto-Medium\";\n}\n.fontLight{\n    font-family: \"Roboto-Light\";\n}\n\n/* .HelveticaNeue100{\n    font-family: \"HelveticaNeue-Thin\";\n} */\n.HelveticaNeue300{\n    font-family: \"Helvetica-Light\";\n}\n.HelveticaNeue400{\n    font-family: \"Helvetica-Regular\";\n}\n.HelveticaNeue500{\n    font-family: \"Helvetica-Medium\";\n}\n.HelveticaNeue700{\n    font-family: \"Helvetica-Bold\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontBlack": `FontFamily_fontBlack__jdWze`,
	"fontBold": `FontFamily_fontBold__g0zMY`,
	"fontRegular": `FontFamily_fontRegular__bBEVo`,
	"fontMedium": `FontFamily_fontMedium__sDvt0`,
	"fontLight": `FontFamily_fontLight__bapAy`,
	"HelveticaNeue300": `FontFamily_HelveticaNeue300__K64XV`,
	"HelveticaNeue400": `FontFamily_HelveticaNeue400__V3aKy`,
	"HelveticaNeue500": `FontFamily_HelveticaNeue500__pt1GP`,
	"HelveticaNeue700": `FontFamily_HelveticaNeue700__bTutO`
};
export default ___CSS_LOADER_EXPORT___;
