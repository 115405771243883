
import React,{useEffect, useState} from 'react'; 

function Checkbox(props) {  
    const [moduleDetail,setModuleDetail] = useState(props.module);
    //const [userPermission,setUserPermission] = useState(props.userPermission);
    const [isChecked, setIsChecked] = useState(JSON.stringify(props.userSelectedPermission).includes(moduleDetail.id)?true:false)
    const selectModule = () => {
        setIsChecked(!isChecked);
        props.selectUserpermission(moduleDetail.id,!isChecked);
    }
    // useEffect(()=>{
    //     console.log('moduleDetail===',moduleDetail);
    //     if(JSON.stringify(props.userSelectedPermission).includes(moduleDetail.id)==true){
    //         props.setIsChecked(!props.isChecked);
    //     }
    // },[props.userSelectedPermission]);
   
  return ( 
    <>
        {<input type="checkbox" checked={isChecked} id={moduleDetail.id} placeholder={moduleDetail.name} onChange={e => selectModule()}  />  }
        {moduleDetail.name}
    </>  
  ); 
  
}; 

export default Checkbox;