import React, {useState, useEffect, Fragment, useMemo, useContext, useRef, useCallback } from 'react';
import NotificationThreadList from '../NotificationThreadList/NotificationThreadList';
import NotificationThread from '../NotificationThreadDetail/NotificationThread';
import Loader from '../UI/Loader/Loader';
import {
    getContactGroupIds,
    notificationList,
    getNotificationByContactGroupUUID,
    removeMessage,
    getSelectedRecipientData, getSelectUsersForGroup, deleteNotificationThread, getNotificationThreads
} from '../../services/notification.services';
import AuthContext from '../../../src/store/auth-context';
import '../../../node_modules/emojionearea/dist/emojionearea.min.css';
import style from './Notifications.module.css';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import font from '../Style/FontFamily.module.css';
import '../Style/Table-style.css';
import button from '../Style/ButtonStyle.module.css';
import tableStyle from '../Style/TableModel.module.css';
import $ from 'jquery'
import dateFormat from 'dateformat';
import {useNavigate} from "react-router-dom";
import Modal from 'react-modal';
import {USERS_API_BASE_URL} from "../../constants";
//const orgUuid = '7bce8766-5bd8-4194-b455-ac579dc36587';
let PageSize = 60;
const Notifications = () => {

    // contact group state
    const [modalIsOpen, setIsOpen] = useState(false);
    const [contactGroups, setContactGroups] = useState([]);
    const [contactGroupsLatestMessage, setcontactGroupsLatestMessage] = useState([]);
    const [isContactGroupsDescendingOrder, setIsContactGroupsDescendingOrder] = useState(true);

    // Notification listing area data state
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState([]);
    const [selectedNotificationUUID, setSelectedNotificationUUID] = useState(null); // Selected contact Group Id
    const [activatedNotification, setActivatedNotification] = useState({});
    const [isNotificationCardOpen, setIsNotificationCardOpen] = useState(false);
    const [selectedChannels, setSelectedChannel] = useState([]); // For selected channels state for compose notification
    const [selectedChannelFilter, setSelectedChannelFilter] = useState([]);// Checked channel for filtering
    const [currentSelectedGroup, setCurrentSelectedGroup] = useState(false);
    const [selectUsersForGroup, setSelectUsersForGroup] = useState({previewedRecipientData:[],total:0});
    const [selectedGroupTitle, setSelectedGroupTitle] = useState(false);
    const composeNotificationRef = useRef();
    const [notificationThreadSearchText, setNotificationThreadSearchText] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    // map of userIds with their images
    const [userImages, setUserImages] = useState(new Map());
    var emoji = require("emojionearea");
    const selectEmoji = (button) => {

    }



    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    //const orgUuid=authCtx.userData.orgId;
    // For getting groups ids


    const [currentPage, setCurrentPage] = useState(0);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    useEffect(() => {
        setHasMore(true);
        if (!authCtx.isLoggedIn) {
            navigate('/login');
            return;
        }
        loadNotificationThreads(0,'');

    }, []);

    const loadMoreOnScroll = (pno) => {
        //alert("Loading page no "+pno);
        setCurrentPage(pno);
        getNotificationThreads('',notificationThreadSearchText,authCtx.token,pno).then((res) => {
            console.log('recevied data ', res.data.length);
            if (res.data.length == 0) {
                setHasMore(false);
            }else{
                setHasMore(true);
            }
            const selectGroupArr = res.data.map(group => group.uuid);
            fetchLatestNotificationsForThreads(selectGroupArr, res);
        }).catch(error => {
                console.log(error);
                setIsLoading(false);
        });

    }

    // set userImage with key in userImages map
    const setUserImage = (key, value) => {
        setUserImages(new Map(userImages.set(key, value)));
        console.log("==== userImages",userImages);
    }
    // get userImage with key in userImages map
    const getUserImage = (key) => {
        return userImages.get(key);
    }

    // check if user key exists in userImages map
    const isUserImageExists = (key) => {
        return userImages.has(key);
    }
    // update userImage in message
    const getAuthorLatestImageUrl = async (message) => {
        if (userImages.has(message.authorId)==false) {
            console.log("userImages",userImages);
            console.log("fetching image for user id ",message.authorId);
            const response = await fetch(`${USERS_API_BASE_URL}/get-user-image/` + message.authorId, {
                method: 'GET',
                headers: {'Authorization': authCtx.token}
            });
            const jsonData= await response.json();
            if (!(jsonData.userImage.thumbImage)) {
                    //localStorage.setItem('' + props.messageAuthorId, jsonData.userImage.thumbImage);
                    setUserImage(message.authorId,jsonData.userImage.thumbImage)
               } else {
                    //localStorage.setItem('' + props.messageAuthorId, "https://static.myschoollink.app/common/profile-icon-login-head-icon-vector.png");
                    setUserImage(message.authorId,"https://static.myschoollink.app/common/profile-icon-login-head-icon-vector.png");
            }

            console.log("userImages2",userImages);
        }
        return getUserImage(message.authorId);
    }

    function fetchLatestNotificationsForThreads_bk(selectGroupArr, res) {
        notificationList(selectGroupArr, authCtx.token).then(function (response) {
            let arr1 = response.data;
            const notificationWithChannel = arr1.map(async msg => {
                console.log("before getAuthorLatestImageUrl");
                // resolve promise of getAuthorLatestImageUrl
                /*let authorImageUrlPromise = getAuthorLatestImageUrl(msg);*/
               // let authorImageUrl = await authorImageUrlPromise;
                console.log("after getAuthorLatestImageUrl");
                const foundChannel = res.data.find((grp) => grp.uuid === msg.usersGroupId);
                if (msg.usersGroupId === foundChannel.uuid)
                    return {
                        ...msg, channels: foundChannel.selectedChanel,
                        contactGroupStatus: foundChannel.status,
                        groupIntId: foundChannel.id,
                        emailOnlySubscribers: foundChannel.emailOnlySubscribers
                    };
            })
            //console.log('notificationWithChannel', notificationWithChannel.map((msg) => msg.id));
            // resolve promise of notificationWithChannel
            /*Promise.all(notificationWithChannel).then((notificationWithChannel) => {
                console.log('notificationWithChannel', notificationWithChannel);
                setNotifications([...notifications, ...notificationWithChannel]);
                //setSelectedNotification([]);
                //setIsNotificationCardOpen(false);
                //setSelectedNotificationUUID(null);
                setIsLoading(false);
            });*/
            setNotifications([...notifications ,...notificationWithChannel]);
            //setSelectedNotification([]);
            //setIsNotificationCardOpen(false);
            //setSelectedNotificationUUID(null);
            setIsLoading(false);
        }).catch(function (error) {
            console.error(error);
            setIsLoading(false);
        });
    }


    function fetchLatestNotificationsForThreads(selectGroupArr, res) {
        console.log("notifications 0",notifications);
        notificationList(selectGroupArr, authCtx.token).then(function (response) {
            let arr1 = response.data;
            const notificationWithChannel = arr1.map((msg) => {
                const foundChannel = res.data.find((grp) => grp.uuid === msg.usersGroupId);

                if (msg.usersGroupId === foundChannel.uuid)
                    return {
                        ...msg, channels: foundChannel.selectedChanel,
                        contactGroupStatus: foundChannel.status,
                        groupIntId: foundChannel.id,
                        emailOnlySubscribers: foundChannel.emailOnlySubscribers
                    };
            })
            //console.log('notificationWithChannel', notificationWithChannel.map((msg) => msg.id));
            setNotifications([...notifications ,...notificationWithChannel]);
            //setSelectedNotification([]);
            //setIsNotificationCardOpen(false);
            //setSelectedNotificationUUID(null);
            setIsLoading(false);
        }).catch(function (error) {
            console.error(error);
            setIsLoading(false);
        });
    }

    const clearNotifications = () => {
        setNotifications([]);
    }

    const applySearchText = (searchText) => {
        console.log('searchText', searchText);
        setHasMore(true);
        clearRecipientsSelection(searchTimeout);
        // max lenght of search text is 50
        if (searchText.length > 50) {
            console.log("Max length of search text is 50");
            return;
        }
        clearNotifications();
        setNotificationThreadSearchText(searchText);
        if (searchText != '' & searchText.length > 2) {
            clearTimeout(searchTimeout);
            let tout= setTimeout(() => {
                setIsSearching(true);
                loadNotificationThreads(0,searchText);
            }
            , 1000);
            setSearchTimeout(tout);
        }else if(searchText == ''){
            setNotifications([]);
            loadNotificationThreads(0,'');
        }
    }

    const loadNotificationThreads = (pageNumber,searchText) => {
        console.log("notifications 4",notifications);
        getNotificationThreads('',searchText,authCtx.token,pageNumber).then((res) => {
            setIsSearching(false);
            setContactGroups(res.data);
            setIsLoading(true);
            const selectGroupArr = res.data.map(group => group.uuid);
            fetchLatestNotificationsForThreads(selectGroupArr, res);

        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }

    const  clearRecipientsSelection=()=>
    {  //clearing the selected recipients
        if(composeNotificationRef.current) {
            composeNotificationRef.current.clearRecipientsSelection();
        }
    }
    /** For notification selection **/
    const modalClose = ()=>{
        setIsOpen(false)
    }
    const leavePost = ()=>{
        if(composeNotificationRef.current) {
            composeNotificationRef.current.clearEditor();
            setIsOpen(false)
        }
    }
    const selectedNotificationHandler = (contactGroupUUID, contactGroupStatus, channels,title) => {
        window.moretextToBeEdited=false;
        if(isEditing){
            //setIsOpen(true)
            //return
            if(!window.confirm("You will lose the data you editing, are you sure to leave.")) {
                return;
            }
        }

        if(composeNotificationRef.current) {
            composeNotificationRef.current.clearEditor();
        }
        setIsLoading(true);
        setSelectedChannel(channels);

        getNotificationByContactGroupUUID(contactGroupUUID, authCtx.token)
            .then(response => {

                const activeNotification = notifications.find((notification) => notification.usersGroupId === contactGroupUUID);
                setIsLoading(false);
                const finalData=response.data.filter((item)=>!item.title.includes('#DRFT#'));

                setSelectedNotification(finalData);
                setIsNotificationCardOpen(true);
                setSelectedNotificationUUID(contactGroupUUID);
                setSelectedGroupTitle(title);
                //   setSelectedChannel(channels);
                setActivatedNotification(activeNotification);
                setIsLoading(true);
                getSelectedRecipientData(contactGroupUUID, authCtx.token)
                    .then(response => {
                        console.log('SelectedRecipient',response);
                        // response = JSON.parse(response);
                        setCurrentSelectedGroup(response.data);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('Err:', error);
                    });

                getSelectUsersForGroup(contactGroupUUID, authCtx.token)
                    .then(response => {

                        composeNotificationRef.current.setPreviewedRecipientData(response.data.previewedRecipientData==null?[]:response.data.previewedRecipientData);
                        setSelectUsersForGroup(response.data);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('Err:', error);
                    });



            }).catch(error => {
                setIsLoading(false);
                console.error('Err:', error);
            })



    }
    const createNotificationHandler = (data, isCreatingRecipient) => {
        //console.log('debug1========',isCreatingRecipient)
        const notificationsSelected = [...selectedNotification];
        const Notifications = [...notifications];
        //console.log('debug2========',Notifications)
        //console.log('debug5========',data)
        if (isCreatingRecipient) {

            Notifications.unshift(data);
            setSelectedNotification([data]);
            setNotifications(Notifications);
            setSelectedNotificationUUID(data.usersGroupId);
            setIsNotificationCardOpen(true);
            setActivatedNotification(data);
        } else {

            const index = Notifications.findIndex((n) => n.usersGroupId === data.usersGroupId);
           // console.log('index value======',index)
            Notifications[index] = data;
            notificationsSelected.push(data);
            Notifications.sort(
                (objA, objB) => new Date(objB.lastModified).getTime() - new Date(objA.lastModified).getTime(),
              );
            setNotifications(Notifications);
            setSelectedNotification(notificationsSelected);
            setSelectedNotificationUUID(data.usersGroupId);
            setIsNotificationCardOpen(true);
        }

        //setSelectedChannel([]); // For testing ...

    }
    // For reseting the selected state
    const resetNotificationBox = () => {
        setSelectedNotificationUUID(null);
        setIsNotificationCardOpen(false);
        setActivatedNotification({});
    }
    // For reseting selected channels
    const resetChannelsHandler = () => {
        setSelectedChannel([]);
    }
    // Remove notification

    const removeNotification = (id,shouldRemoveGroup) => {
        const updatedNoti = selectedNotification.filter((msg) => msg.id !== id);
        setSelectedNotification(updatedNoti);
        removeMessage(id,authCtx.token).then((res) => {
            console.log(res);
            if(shouldRemoveGroup){
                let filteredNotifications = notifications.filter((notification)=>notification.usersGroupId!=selectedNotificationUUID);
                setNotifications(filteredNotifications);
                deleteNotificationThread(selectedNotificationUUID,authCtx.token);
                resetNotificationBox();
                setSelectedChannel([]);
            }
        }).catch((err) => {
            console.log(err)
        });
    }


    const updateNotificationAtUuid = (id, messageBody, rawMessageBody) => {

        //const updatedNoti = selectedNotification.filter((msg) => msg.id !== id);
        const updatedMessages = selectedNotification.map((msg) => msg.id === id ? { ...msg, messageBody: messageBody, rawMessageBody: rawMessageBody, flag: '' } : msg);
        setSelectedNotification(updatedMessages);
    }

    const updateNotificationFlagAtUuid = (id, flag) => {

        //const updatedNoti = selectedNotification.filter((msg) => msg.id !== id);
        const updatedMessages = selectedNotification.map((msg) => msg.id === id ? { ...msg, flag: flag} : msg);
        setSelectedNotification(updatedMessages);
    }

    // For updating message pinned status === true
    const addMessageToPin = (messageId) => {

        const updatePinndMessage = selectedNotification.map((msg) => msg.id === messageId ? { ...msg, isPinned: true } : msg);
        setSelectedNotification(updatePinndMessage);
    }
    // Selected Channels for compose notification
    const composeChannelsHandler = (arrChannelIds) => {
        setSelectedChannel(arrChannelIds);

        resetNotificationBox();
    }

    // For sorting contact group message
    const sortingNotificationHandler = () => {

        const messages = [...notifications];

        if (isContactGroupsDescendingOrder)
            messages.sort((a, b) => {
                return a.groupIntId - b.groupIntId;
            })
        else
            messages.sort((a, b) => {
                return b.groupIntId - a.groupIntId;
            });
        setIsContactGroupsDescendingOrder(order => !order);
        setNotifications(messages);
        //resetNotificationBox();
        $(function() {
            $( ".filterAction" )[0].scrollIntoView();
        });
    }

    // Apply Channel filter Handler
    const applyChannelFilterHandler = (selectedFilterChannels) => {

        setSelectedChannelFilter(selectedFilterChannels);
        //resetNotificationBox();
    }
    // For resetting checked channel fiter
    const resetChannelFilterHandler = () => {
        setSelectedChannelFilter([]);
    }

    // Deactivate notification thread Handler
    const deactivateNotificationThreadHandler = (contactGroup) => {

        const messages = notifications.map((msg) => msg.usersGroupId === contactGroup ? { ...msg, contactGroupStatus: 'INACTIVE' } : msg);
        setNotifications(messages);
        setActivatedNotification({ ...activatedNotification, contactGroupStatus: 'INACTIVE' });

    }
    console.log('activatedNotification ' + activatedNotification)
    return (<Fragment> {isLoading && <Loader />}
        <div className={style.rightbar + ' ' + common.screenContainer}>
            {/* <div className={common.pageHeading}>
                <a onClick={resetNotificationBox}><h4 className={[fontSize.lgSize + ' ' + common.pageTitle + ' ' + font.fontMedium]} onClick={resetNotificationBox}>Manage notifications</h4></a>

            </div> */}

                <div className={"col-xl-4 col-md-6 col-xs-6 " + common.leftSection}>
                    <div className={style.Messagebox}>
                        <NotificationThreadList
                            notifications={notifications}
                            loadMoreOnScroll={loadMoreOnScroll}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            selectedContactGroup={selectedNotificationUUID}
                            onSelectedNotification={selectedNotificationHandler}
                            selectedChannels={selectedChannels}
                            onComposeChannels={composeChannelsHandler}
                            resetChannelsHandler={resetChannelsHandler}
                            resetNotificationBox={resetNotificationBox}
                            sortingNotificationHandler={sortingNotificationHandler}
                            contactGroupsOrder={isContactGroupsDescendingOrder}
                            selectedChannelFilter={selectedChannelFilter}
                            applyChannelFilterHandler={applyChannelFilterHandler}
                            resetChannelFilterHandler={resetChannelFilterHandler}
                            deactivateNotificationHandler={deactivateNotificationThreadHandler}
                            setSelectUsersForGroup={setSelectUsersForGroup}
                            clearRecipientsSelection={clearRecipientsSelection}
                            notificationThreadSearchText={notificationThreadSearchText}
                            applySearchText={applySearchText}
                            isSearching={isSearching}
                            hasMore={hasMore}
                        />
                        {/* <div style={{ backgroundColor: 'transparent', float: "left", height: "19px", width: "453px" }}>&nbsp;</div>
                        <div style={{ backgroundColor: 'transparent', float: "right", height: "28px", width: "9px",  }}>&nbsp;</div> */}
                    </div>
                </div>

                {selectedChannels.length > 0 && <div className={"col-xl-5 col-md-7 col-xs-6 " + common.rightSection}>
                    <div className={style.Sendmessagebox + ' ' + common.eritorcontainer}>
                        {<NotificationThread
                            ref={composeNotificationRef}
                            selectedNtification={selectedNotification}
                            contactGroupStatus={activatedNotification.contactGroupStatus}
                            addNotificationsToList={createNotificationHandler}
                            loadNotificationThreads={loadNotificationThreads}
                            onSelectedNotificationUUID={selectedNotificationUUID}
                            isCardOpen={isNotificationCardOpen}
                            resetNotificationBox={resetNotificationBox}
                            onRemoveNotification={removeNotification}
                            onCreatePinMessage={addMessageToPin}
                            updateNotificationAtUuid={updateNotificationAtUuid}
                            channels={selectedChannels}
                            emailOnlySubscribers={activatedNotification.emailOnlySubscribers}
                            currentSelectedGroup={currentSelectedGroup}
                            selectUsersForGroup={selectUsersForGroup}
                            setSelectUsersForGroup={setSelectUsersForGroup}
                            selectedGroupTitle={selectedGroupTitle}
                            setIsEditing={setIsEditing}
                            updateNotificationFlagAtUuid={updateNotificationFlagAtUuid}
                        />}

                    </div>
                </div>}

        </div>
        <Modal isOpen={modalIsOpen} className="alertBgContainer" contentLabel="Example Modal" >
            <div className='alertBox'>
                <h4 className={tableStyle.alertTitle}>Alert</h4>
                <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>You will lose the data you editing, are you sure to leave.</p>
                {/* <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}><b className={font.fontBold}>When modified all changes will be visible in the SchoolLink app's newsfeed.</b></p> */}
                <div className='bottomArea'>
                    <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                    <button onClick={leavePost} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>leave post</button>
                </div>
            </div>
        </Modal>
    </Fragment>
    );
}
export default Notifications;
