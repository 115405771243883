import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, createBrowserRouter, RouterProvider} from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './fonts/icomoon.css'
import App from './App';
//import reportWebVitals from './reportWebVitals';

import { AuthContextProvider } from './store/auth-context';
import {AppContextProvider} from "./store/AppContext";
import Notifications from "./components/NotificationDashboard/Notifications";




ReactDOM.render(
  <React.StrictMode>

      <AuthContextProvider>
          <AppContextProvider>
              <BrowserRouter>
              <App/>
                </BrowserRouter>
          </AppContextProvider>
      </AuthContextProvider>

  </React.StrictMode>,
  document.getElementById('root')
);
//reportWebVitals();
