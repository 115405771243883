// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalStyle .pagination {
    padding: 0 15px 0 0;
}
.padd-0 .pagination{
    padding: 0;
}
.modalStyle .pagination .page-item {
    padding: 0;
    margin: 15px 5px;
}
.modalStyle .pagination .page-item button {
    background: #555555;
    color: #fff;
    font-family: "Roboto-Regular";
    border-radius: 50%;
    line-height: normal;
    border: none;
    width: 32px;
    height: 32px;
    font-size: 15px;
    text-align: center;
    padding: 0;
}
.modalStyle .pagination .page-item.active button, .modalStyle .pagination .page-item button:hover {
    background: #1c7cd5;
    color: #fff;
}
.modalStyle .pagination .page-item button:focus-within{
    border: 1px solid #000;
}
/* .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #45c096;
    border-color: #45c096;
}
.page-link:hover {
    z-index: 2;
    color: #fff;
    background-color: #45c096;
    border-color: #45c096;
}
.page-link {
    color: #6c757d;
} */`, "",{"version":3,"sources":["webpack://./src/components/Style/Pagination.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,sBAAsB;AAC1B;AACA;;;;;;;;;;;;;;GAcG","sourcesContent":[".modalStyle .pagination {\n    padding: 0 15px 0 0;\n}\n.padd-0 .pagination{\n    padding: 0;\n}\n.modalStyle .pagination .page-item {\n    padding: 0;\n    margin: 15px 5px;\n}\n.modalStyle .pagination .page-item button {\n    background: #555555;\n    color: #fff;\n    font-family: \"Roboto-Regular\";\n    border-radius: 50%;\n    line-height: normal;\n    border: none;\n    width: 32px;\n    height: 32px;\n    font-size: 15px;\n    text-align: center;\n    padding: 0;\n}\n.modalStyle .pagination .page-item.active button, .modalStyle .pagination .page-item button:hover {\n    background: #1c7cd5;\n    color: #fff;\n}\n.modalStyle .pagination .page-item button:focus-within{\n    border: 1px solid #000;\n}\n/* .page-item.active .page-link {\n    z-index: 3;\n    color: #fff;\n    background-color: #45c096;\n    border-color: #45c096;\n}\n.page-link:hover {\n    z-index: 2;\n    color: #fff;\n    background-color: #45c096;\n    border-color: #45c096;\n}\n.page-link {\n    color: #6c757d;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
