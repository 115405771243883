import { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

const PrivateRoute = ({ component: Component,userTabActive, ...rest }) => {

    const authCtx = useContext(AuthContext);

    return (
        <Route {...rest} render={props => (
            authCtx.isLoggedIn ?
                <Component {...props}  />
                : <Navigate to="/login" />
        )} />
    );
};

export default PrivateRoute;
