import {useState, useEffect, useContext, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import style from './OrgSetting.module.css';
//import Pagination from '../../UI/Pagination/Pagination';
import { appUsers,appUsersAdmin,getModuleList,internalUsersAdmin } from '../../services/notification.services';
import '../Style/Table-style.css';
import Pagination from '../UI/Pagination/Pagination';
import AuthContext from "../../store/auth-context";
import tableStyle from '../Style/TableModel.module.css';
import AddUser from './AddUser';
import fontSize from '../Style/FontSize.module.css';
import common from '../Style/common.module.css';
import button from '../Style/ButtonStyle.module.css';
import font from '../Style/FontFamily.module.css';
import { Tabs, Tab } from 'react-bootstrap';

import CategoryTags from './CategoryTags';
import SchoolAuthenticationcode from './AuthCode';
import Parents from './ParentsDetails';
import AddOrganistaion from './AddOrganistaion';
import HelpText from './HelpText';
import { GoSearch } from "react-icons/go";
import { USERS_API_BASE_URL } from './../../constants';
import {getOrganisation} from '../../services/notification.services';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to Impersonate
    </Tooltip>
  );
function parseJwtUsers (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

let pageSize = 10;
const InternalUsers = (props) => {
    //console.log(window.location.href)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const authCtx = useContext(AuthContext);
    const [userList, setUserList] = useState([]);
    const [moduleList, setModuleList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(pageSize);

    const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false);

    const [message, setMessage] = useState('');

    const [userDetail, setUserDetail] = useState('');
    const [userDetailPermission, setUserDetailPermission] = useState([]);

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const navigate = useNavigate();
    let orgUuid=authCtx.userData.orgId;
    const showHandler = (e) => {
        setPageSize(+e.target.value);
        setCurrentPage(1);
    }

    const recipientModalHendler = () => {

        setIsRecipientModalOpen((prevState) => !prevState);
        setUserDetail('');
    }
    // const searchHandler = (searchInputText) => {
    //     setSearchText(searchInputText)
    // }
    // const handleDelete = (id) => {
    //     alert(id)
    //     if (window.confirm("Delete the item?")) {
    //     setPopup({
    //       show: true,
    //       id,
    //     });
    // }
    //   };

    const openAlertPop = (id) => {
        setIsOpen(true);
        setStatus(id)
    }
    const modalClose = ()=>{
        setIsOpen(false)
    }


      const handleDelete = (id) => {
        //if (window.confirm("Are you sure want to delete record?")) {
            const items = userList.filter(user => user.id !== id);
            fetch(`${USERS_API_BASE_URL}/deleteUser/`+id, {
            "method": "PUT",
            "headers": {
                "Content-Type": "application/json",
                'Authorization':authCtx.token
            },
            "body":""

            }).then(res=>res.json()).then(data=>{
                if(data.status==302){
                    //setMessage(data.error)
                }else{
                    //setMessage(data.msg)
                    setUserList(items);
                }
                toast.success(data.status == 302 ? data.error : data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: 'success'
                });
                setIsOpen(false)


            })
       // }
      };

      const handleEdit = (id) => {
        recipientModalHendler();
        fetch(`${USERS_API_BASE_URL}/user-detail/`+id, {
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                'Authorization':authCtx.token
            }

        }).then(res=>res.json()).then(data=>{
            if(data.status==302){
                setMessage(data.error)
            }else{
                console.log('userDetail',data.user)
                setUserDetail(data.user);
                console.log('userDetailPermission',data.userPermission)
                setUserDetailPermission(data.userPermission);
            }


        })
      }

      // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    //   const handleDeleteFalse = () => {
    //     setPopup({
    //       show: false,
    //       id: null,
    //     });
    //   };
    const userSwitchHandler = (uuid) => {
        //alert(1);
        fetch(`${USERS_API_BASE_URL}/switch-user/`+uuid, {
            "method": "GET",
            "headers": {
                "Content-Type": "application/json"
            }

        }).then(res=>res.json()).then(data=>{
            if(data.status==302){
                setMessage(data.error)
            }else{
                let udata = data.response;
                if(udata.token || data.response!=''){
                    console.log("Token Received ",udata.token);
                    let superAdmin= localStorage.getItem('super-admin');
                    let superAdminName= localStorage.getItem('super-admin-name');
                    let superAdminToken= localStorage.getItem('super-admin-token');
                    let userId=udata.userId;
                    let user_Id=udata.user_id;
                    let userToken=udata.token;
                    let tokenUserData=parseJwtUsers(udata.token);
                    console.log('tokenData',tokenUserData);
                    console.log('currenttime',Date.now() / 1000)
                    let orgId=tokenUserData.user.org;
                    let uid=tokenUserData.user.id;
                    let firstName=udata.firstName;
                    let usertype=udata.usertype;
                    let lastName=udata.lastName;
                    let thumbImage=udata.thumbImage;
                    let orgDetail=getOrganisation(orgId,userToken);
                    console.log('hitesh',orgDetail)
                    orgDetail.then(res=>{
                        if (res.data.status!=400) {
                            let orgName = res.data.name;
                            let orgStatus = res.data.status;
                            let orgWisitiaId = res.data.org_wistia_project_id;
                            let orgLogoUrl = res.data.logoUrl;
                            let channelPushNotification =res.data.CHANNEL_PUSH_NOTIFICATION;
                            let editorAttachment =res.data.EDITOR_ATTACHMENT;
                            let editorExternalLink =res.data.EDITOR_EXTERNAL_LINK;
                            let editorLink =res.data.EDITOR_LINK;
                            let editorText =res.data.EDITOR_TEXT;
                            let editEmoji =res.data.EDIT_EMOJI;
                            let editEpub =res.data.EDIT_EPUB;
                            const userData = {
                                uuid: userId,
                                superAdminId:superAdmin,
                                superAdminName:superAdminName,
                                superAdminToken:superAdminToken,
                                user_id: user_Id,
                                name: firstName + ' ' + lastName,
                                uid: uid,
                                usertype:usertype,
                                orgName: orgName,
                                orgLogoUrl: orgLogoUrl,
                                token: userToken,
                                refreshToken:udata.refreshToken,
                                orgId:orgId,
                                epubOrgId:res.data.epubOrgId,
                                orgWisitiaID:orgWisitiaId,
                                thumbImage:thumbImage,
                                CHANNEL_PUSH_NOTIFICATION:channelPushNotification,
                                EDITOR_ATTACHMENT:editorAttachment,
                                EDITOR_EXTERNAL_LINK:editorExternalLink,
                                EDITOR_LINK:editorLink,
                                EDITOR_TEXT:editorText,
                                EDIT_EMOJI:editEmoji,
                                EDIT_EPUB:editEpub
                            }
                            authCtx.login(userData);
                            console.log('SwitchUser',userData);
                            navigate('/notifications');
                        } else {
                            alert("Could not get organisation this user belogs to. "+data.error);
                            return false;
                        }
                    }).catch(err=>{
                        alert("Could not retrieve organisation. Error: "+err);
                    });
                }else{
                    //alert("Could not get logged in. "+udata.data.error);
                    setMessage(data.error)
                    return false;
                }
            }
        })
    }

    const searchHandler = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    }
    useEffect(() => {
        getModuleList(authCtx.token).then((resp) => {
            setModuleList(resp.data.moduleList);
        }).catch((err) => {
            console.error(err);
        })
        if(authCtx.userData.user_id=="39"){
           //alert(1);
            internalUsersAdmin(authCtx.token)
            .then((response) => {

                setIsLoading(false);
                setUserList(response.data.userList);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
        }
        // else{
        //     appUsers(authCtx.token)
        //     .then((response) => {

        //         setIsLoading(false);
        //         console.log('superadmin',response.data.userList);
        //         setUserList(response.data.userList);
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //         setIsLoading(false);
        //     });
        // }

    }, [authCtx.userData]);
    const totalCount = userList.length;
    // const searchTextArray = searchText.toLowerCase().trim().split(' ');
    // const searchData = userList.filter(tagdata => {
    //     return searchTextArray.every(i => tagdata.name.toLowerCase().indexOf(i) > -1)
    //     }
    // );
    //const userData = userList.slice(firstPageIndex, lastPageIndex);

    const searchTextArray = searchText.toLowerCase().trim().split(' ');
    const searchData = userList.filter(userdata => {
        return searchTextArray.every(i => userdata.email.toLowerCase().indexOf(i) > -1)
        }
    );

    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            modalClose();
        }
      }, [modalClose]);
     
      useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);
     
        return () => {
          document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
      }, [handleEscKey]);


    const userData = searchData.slice(firstPageIndex, lastPageIndex);
    return (
        <>
            <div className="">
            {isRecipientModalOpen && <AddUser
                modalStatus={isRecipientModalOpen}
                onAction={recipientModalHendler}
                setUserList={setUserList}
                userList={userList}
                setMessage={setMessage}
                userDetail={userDetail}
                moduleList={moduleList}
                userDetailPermission={userDetailPermission}


            />}
            <div className={style.creatUserArea}>
                    {/* <h4 className={[fontSize.lgSize + ' ' + common.pageTitle + ' ' + font.fontMedium]} >Settings</h4> */}
                    <div className={style.settingbutton}>

                        {/* {authCtx.userData.user_id!="39" && <button onClick={recipientModalHendler} className={button.iconBtn + ' ' + button.modalBtn + ' ' + button.redButton}>
                            <i className="icon icon-plus-circle"></i> Create New User</button>} */}
                        <button onClick={recipientModalHendler} className={button.iconBtn + ' ' + button.modalBtn + ' ' + button.redButton}>
                            <i className="icon icon-plus-circle"></i> Create New User</button>
                    </div>
                    <div className={style.tabs}>
                        <div className={'form-group ' + tableStyle.hassearch}>
                        <input value={searchText} onChange={searchHandler} className={tableStyle.searchInput} type="search" placeholder="Search..." />
                        <i className={"icon icon-search2 " + tableStyle.searchIcon}></i>
                        </div>
                    </div>
            </div>

            <div className="table-design">
            <div className='Modify_PageTitle__VS3pb FontFamily_fontMedium__1Bl7w' style={{"fontSize":14,"color":'green'}}>{message}</div>
            <table className={"table table-hover " + style.categorytable}>
                <thead className={style.heading}>
                    <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">User Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Action</th>

                    </tr>
                </thead>
                    {userData != '' ? (
                        <tbody className={style.tablebody}>
                            {userData.map((user) => {
                                return (
                                    <tr key={user.id}>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>
                                            {authCtx.userData.orgId=='' ?
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 50, hide: 400 }}
                                                overlay={renderTooltip}
                                                >
                                                <a className={style.selectUser}>{user.email}</a>
                                            </OverlayTrigger>:user.email}
                                        </td>
                                        <td>{user.usertype}</td>
                                        <td><i className="fa fa-check" style={{ color: '#45c096' }}></i></td>
                                        <td>{user.phoneNumber}</td>
                                        <td>
                                        {/* {authCtx.userData.orgId=='' ?
                                            <span className='actionStyleButton impersonateBtn'><i className="icon icon-key" onClick={i => userSwitchHandler(user.uuid)} ></i></span>
                                        :null} */}
                                        <span className='actionStyleButton'><i className="icon icon-pencil" onClick={k => handleEdit(user.uuid)} ></i></span>
                                        <span className='actionStyleButton'><a onClick={i => openAlertPop(user)}><i className="fa fa-trash-o"></i></a></span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    ): ( <tr>
                            <td colSpan='8' style={{textAlign:'center'}}>
                                <div className={style.loader}>
                                    <Spinner className={style['text-loader']} animation="border" variant="primary" />
                                    <div className={style['text-loader']}>Loading . . .</div>
                                </div>
                            </td>
                        </tr>)}

            </table>
            <div className={"row justify-content-between " + tableStyle.listShow}>
                {totalCount > pageSize && <div className="col-4">
                    <label className={tableStyle.listLabel}>
                        Show
                        <select className={"form-select " + tableStyle.listSelect} value={PageSize} onChange={showHandler}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>}

                <div className="col-4">
                    <div style={{ float: 'right' }}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={searchData.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>

            </div>
            </div>
            </div>
            <Modal isOpen={modalIsOpen} className="alertBgContainer" status={status} contentLabel="Example Modal" >
                <div className='alertBox'>
                    <h4 className={tableStyle.alertTitle}>Alert</h4>
                    <p className={fontSize.smSize + ' ' + font.fontRegular + ' ' + common.pTag}>Are you sure want to <b className={font.fontBold}>delete this user?</b></p>
                    <div className='bottomArea'>
                        <button onClick={modalClose} className={button.smBtn + ' ' + button.lightBrownButton}>Cancel</button>
                        <button onClick={i => handleDelete(status.id)} className={button.smBtn + ' ' + button.blueButton + ' ' + button.buttonSpaceLeft}>Yes</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default InternalUsers;
