import React, {useState} from "react";

const AppContext = React.createContext({
    pinnedMessage:null,
    setPinnedMessage: (val) => { },
});


export const AppContextProvider =(props)=>{
    const [pinnedMessage,setPinnedMessage]=useState(null);

    return <AppContext.Provider value={{
        pinnedMessage:pinnedMessage,
        setPinnedMessage:setPinnedMessage
    }}>{props.children}</AppContext.Provider>
}

export default AppContext;
