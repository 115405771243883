import {useState, useEffect, useRef, useContext} from 'react';

import { getCategoryTags, createContactGoup } from '../../services/notification.services';
import '../Style/Table-style.css';
import CategoryTagsItem from './CategoryTagsItem';
import Pagination from '../UI/Pagination/Pagination';
import {USERS_API_BASE_URL} from '../../constants';
import style from './CategoryTages.module.css';
import AuthContext from "../../store/auth-context";
import {addNewCategory} from "../../services/notification.services";
import tableStyle from '../Style/TableModel.module.css';
import button from '../Style/ButtonStyle.module.css';
import { GoSearch } from "react-icons/go";
import { toast } from 'react-toastify';

let pageSize = 5;

const CategoryTags = (props) => {
    
    const [Categorytag, setCategorytag] = useState([]);
    const [NewCategory, setNewCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const authCtx = useContext(AuthContext);
    const [searchText, setSearchText] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(pageSize);

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    //console.log(firstPageIndex+'=============='+lastPageIndex)
    const orgUuid=authCtx.userData.orgId;
    console.log('paging',Categorytag);

    
    const showHandler = (e) => {
        setPageSize(+e.target.value);
        setCurrentPage(1);
    }
    // const searchHandler = (searchInputText) => {
    //     setSearchText(searchInputText)
    // }

    const searchHandler = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    }


    //  const CategorytagSlicek = Categorytag.filter((c) => {
    //     if (searchText)
    //         return c.name == searchText;
    //     return true;
    // });
    //console.log(cdata);
    const shortText = 'Hi';
    const longText = 'Long tooltip content to test scheduleUpdate';
    const totalCount = Categorytag.length;
    useEffect(() => {

        getCategoryTags(orgUuid,authCtx.token)
            .then((response) => {
                 console.log('=======cat',response)
                setIsLoading(false);
                //console.log(firstPageIndex+'=============='+lastPageIndex)
                //var datab = response.data;
                //console.log('yyiuiuiui',datab)

                //const datacat = response.data.slice(firstPageIndex, lastPageIndex);
                setCategorytag(response.data);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
    }, []);

    //setCategorytag(datab);
    const NewCategoryAdded = (e) => {
        e.preventDefault();



        //alert(NewCategory)
        if (NewCategory != '') {
            let body = "{\"name\":\"" + NewCategory + "\",\"fkOrgID\":\"" + props.orgUuid + "\"}"
            addNewCategory(body,authCtx.token).then(res=>res.json()).then(
                data=>{toast.success(data.status==302?data.error:data.msg, {position: toast.POSITION.BOTTOM_RIGHT,type: 'dark'}); 
                setNewCategory("");
                getCategoryTags(orgUuid,authCtx.token)
            .then((response) => {
                 console.log(response)
                setIsLoading(false);
                //console.log(firstPageIndex+'=============='+lastPageIndex)
                //var datab = response.data;
                //console.log('yyiuiuiui',datab)

               // const datacat = response.data.slice(firstPageIndex, lastPageIndex);
                setCategorytag(response.data);
            })
            .catch((err) => {
                alert(err);
                console.error(err);
                setIsLoading(false);
            });
        });
        }
    }

    const addCategoryTaghandler =  (catId,tagName) => {

        const allData = Categorytag.map((cat)=> cat.id==catId ? {...cat, tags:[...cat.tags,{id:57, name:tagName}]} : cat );

        setCategorytag(allData);
    }
    //const CategorytagSlice = Categorytag.slice(firstPageIndex, lastPageIndex);


    const searchTextArray = searchText.toLowerCase().trim().split(' ');
    const searchData = Categorytag.filter(tagdata => {
        return searchTextArray.every(i => tagdata.name.toLowerCase().indexOf(i) > -1)
        }
    );

    const CategorytagSlice = searchData.slice(firstPageIndex, lastPageIndex);
    return (
        <>
            <div className={style.addTagArea}>
                <div>
                    <form className={style.newCategory} onSubmit={NewCategoryAdded}>
                        <div className={style.categoryBg}>
                            <input type="text" className={style.formcontrol} title="Create New Contact Group" placeholder="Create New Contact Group" value={NewCategory} onChange={e => setNewCategory(e.target.value)} />
                            <button type="submit" className={style.addBtn}><i class="icon icon-plus-circle"></i> Add</button>
                        </div>
                    </form>

                </div>
            
                <div className={tableStyle.rightAlign + ' ' + style.tagSearch}>
                        {/* <CategoryTagSearchForm onSearch={searchHandler} /> */}
                        <form method="post" onSubmit={(e) => e.preventDefault()}>
                        <div className={'form-group ' + style.hassearch}>
                        <GoSearch className={style.searchIcon} />
                        <label for="SearchInput" className='hidenText'>Search</label>
                            <input
                            value={searchText}
                            onChange={searchHandler}
                            type="search"
                            className={style.formcontrol}
                            placeholder="Search..."
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div className="table-design">
                    {/* <div className={tableStyle.buttonArea + ' ' + tableStyle.bottomSpace}>
                        <div className="row ">
                            <div className={"col-6 " + tableStyle.leftAlign}>

                            </div>

                        </div>
                    </div> */}
                <table className={"table table-hover " + style.categorytable}>
                    <thead className={style.heading}>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Tags</th>
                            <th scope="col">User Count</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody className="tablebody">
                        {CategorytagSlice.map((cat) => {
                            return (
                                <CategoryTagsItem item={cat} addCategoryTaghandler ={addCategoryTaghandler} setCategorytag={setCategorytag} setSelectedCode={props.setSelectedCode} setKey={props.setKey} />
                            )
                        })}
                    </tbody>
                </table>

                <div className={"row justify-content-between " + tableStyle.listShow}>
                {totalCount > pageSize && <div className="col-4">
                    <label className={tableStyle.listLabel}>
                        Show
                        <select className={"form-select " + tableStyle.listSelect} value={PageSize} onChange={showHandler}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>}

                <div className="col-4">
                    <div style={{ float: 'right' }}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={searchData.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>
            </div>

            </div>
        </>
    )
}
export default CategoryTags
