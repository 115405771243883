import React, { useState, useEffect, useRef } from 'react';
import style from "../../Style/EditorStyle.module.css";
import EpubContentBlock from "./EpubContentRenderingBlock";
import 'react-image-lightbox/style.css';
import moreTExtBlockStyle from './MoreTextBlock.module.css';
import AudioPlayer from './AudioPlayer';
import pdfIcon from '../../../assets/admin-pdf-icon.png'
import imgIcon from '../../../assets/admin-img-icon.png'
import articleIcon from '../../../assets/admin-article-icon.png'
import newsletterIcon from '../../../assets/admin-newsletter-icon.png'
import surveyIcon from '../../../assets/admin-survey-icon.png'
import eSignIcon from '../../../assets/admin-esign-icon.png'

import NotificationCardStyle from "../NotificationsList/NotificationCard.module.css";
const customRenderToHtml=(type, data,onGalleryClick=false)=> {



    let media=null;
    if (type === "IMAGES_GALLERY") {
        console.log(data.images.length, data.images);
        let remaining = data.images.length - 4;
        let width = data.images.length === 1 ? "278px!important" : "";
        let height = data.images.length === 1 ? "160px!important" : "";
        let singleImageContainer = data.images.length === 1 ? style.singleImageContainer : "";
        let renderedList = null;

        if (data.images.length === 1) {
            renderedList = data.images.map((e) => {
                return <div style={{width: width, height: height, marginLeft: "0px", marginTop: "0px"}}><img
                    className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
            });
        } else if (data.images.length === 2){

            renderedList = data.images.map((e) => {
                return <div style={{marginLeft: "0px", marginTop: "0px"}}><img
                    className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
            });
        } else if (data.images.length === 3) {
            renderedList = data.images.map((e, index) => {

                if (index<2) {
                    if (index < 1) {
                        return <div style={{marginLeft: "0px", marginTop: "0px"}}><img
                            className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                    } else {
                        return <div style={{position: "relative", marginLeft: "0px", marginTop: "0px"}}>
                            {  <span
                                    className={NotificationCardStyle.FourthImageStyle}><span>+</span>{"1"}</span>}
                            <img
                                className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                    }
                }
            });
        } else {
            renderedList = data.images.map((e, index) => {
                if (index<4) {
                    if (index < 3) {
                        return <div style={{marginLeft: "0px", marginTop: "0px"}}><img
                            className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                    } else {
                        return <div style={{position: "relative", marginLeft: "0px", marginTop: "0px"}}>
                            {remaining > 0 && <span
                                className={NotificationCardStyle.FourthImageStyle}><span>+</span>{remaining + ""}</span>}
                            <img
                                className={NotificationCardStyle.ImageGalleryImage} src={e.url} key={e.name}/></div>
                    }
                }
            });
        }
        media = (
            <div style={{width: width, height: height}} className={style.imageStyleView + ' ' + singleImageContainer}
                 onClick={onGalleryClick}>{renderedList}</div>
        );
    }
    if (type === "VIDEOS") {
        let renderedList = data.videos.map((e) => {
            if(e.embededCode){
                e=e.embededCode;
            }
            //return <video src={e.url} key={e.name} poster={e.thumbUrl} width="320" controls/>
            e=e.replace("640","260");
            e=e.replace("360","156");
            return <div dangerouslySetInnerHTML={{__html: e}}></div>
        })
        media = (
            <div className={style.videoStyleView} style={{boxShadow:"1px 1px 2px #aaa3",display: 'flex', flexWrap: 'wrap',overflow:"hidden",width:"100%",height:"",borderRadius:"10px",border:"0px solid red"}}>{renderedList}</div>
        );
    }
    if (type === "YOUTUBE") {

        media = (
            <div className={style.youtubeStyleView}>
                <iframe width="420" height="315" src={"https://www.youtube.com/embed/" + data.youtube}></iframe>
            </div>
        );
    }
    if (type === "PDF_ATTACHMENTS") {

        let renderedList = data.pdfAttachments.map((e) => {
            //return <a href={e.url} key={"key"+Math.random()}>File: {e.name}<br/><i>File Download</i></a>
            return <>
                <div style={{padding:"9px 5px 8px",background:'#F1F2F3',borderRadius:'10px',margin:'7px 0',}}>
                    <a href={e.url} target="_blank" style={{textDecoration:'none',color:'#8E8E93',fontSize:'12px',lineHeight:'normal',}}>
                        <div style={{display:"flex",flexDirection:"row",width:"auto",padding:"0",alignItems:'center'}} className={style.fileAttachView}>
                            <img src={pdfIcon} className={style.hideIcon} style={{padding:'12px 0px 11px 13px',width:'30px',marginRight:'4px'}}   />
                            <i class="icon icon-file-pdf"></i>
                            <div style={{padding:"0",}}>
                                <p style={{display:'block',color:'#1B1B1B',margin:'0',fontWeight:'600',fontSize:'12px',lineHeight:'normal',}}>{e.name}</p>
                                <span style={{color:"#8E8E93",fontSize:"12px",display:'block',marginTop:'3px'}}>File Download</span>
                            </div>
                        </div>
                    </a>
                </div>
            </>
        })
        media = (
            <div style={{display:'flex',flexDirection:'column'}}><div className={style.fileAttachView} style={{width:"282.89px",textAlign:'left',position:'relative',}}>{renderedList}</div></div>
        );
    }

    if (type === "IMAGE_ATTACHMENTS") {
        let renderedList = data.imageAttachments.map((e) => {
            return <>
                <div style={{padding:"9px 5px 8px",background:'#F1F2F3',borderRadius:'10px',margin:'7px 0',}}>
                    <a href={e.url} download target="_blank" style={{textDecoration:'none',color:'#8E8E93',fontSize:'12px',lineHeight:'normal',}}>
                        <div style={{display:"flex",flexDirection:"row",width:"auto",padding:"0",alignItems:'center'}} className={style.fileAttachView}>
                            <img src={imgIcon} className={style.hideIcon} style={{padding:'12px 0px 11px 13px',width:'30px',marginRight:'4px'}}  />
                            <i class="icon icon-images2"></i>
                            <div style={{padding:"0",}}>
                                <p key={e.name} style={{display:'block',color:'#1B1B1B',margin:'0',fontWeight:'600',fontSize:'12px',lineHeight:'normal',}}>{e.name}</p>
                                <span style={{color:"#8E8E93",fontSize:"12px",display:'block',marginTop:'3px'}}>File download</span>
                            </div>
                        </div>
                    </a>
                </div>
            </>
        })
        media = (
            <div style={{display:'flex',flexDirection:'column'}}><div className={style.fileAttachView} style={{width:"282.89px",textAlign:'left',position:'relative',}}>{renderedList}</div></div>
        );
    }
    if (type === "AUDIO_ATTACHMENTS") {

        let renderedList = data.audioAttachments.map((e) => {

            return <AudioPlayer rawData={e}  />



        })
        media = (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>{renderedList}</div>
        );
    }

    if (type === "PDF_TO_IMAGES_ATTACHMENTS") {
        let renderedList = data.pdfsToImagesAttachments.map((e) => {
            return <a href={e.url} key={e.name}>{e.name}</a>
        })
        media = (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>{renderedList}</div>
        );
    }
    if (type === "EXTERNAL_LINKS") {
        let renderedList = data.externalLinks.map((e) => {
            console.log(e);
            return <>
                <a className={style.link} href={e.url} target="_blank" style={{textDecoration:'none'}}>
                    <div className={style.linkImg} style={{marginBottom:'10px'}}>
                        <img style={{width:"100%"}} src={e.image}/>
                    </div>
                    <div className={style.linkTitle}><h3 style={{fontSize:'12px',color:'#1B1B1B',lineHeight:'16px'}}>{e.title}</h3></div>
                    <div className={style.linkText}><p style={{fontSize:'12px',color:'#8E8E93',lineHeight:'16px',margin:'0'}}>{e.description}</p></div>
                    <a className={style.link} href={e.url} style={{fontSize:'13px',color:'#8E8E93',wordBreak:'break-all',textDecoration:'none',}}>{e.url}</a>
                    {/*{e.url}</a>*/}
                </a>
            </>
        })
        media = (
            <div className={style.externalLinksView} style={{background:'#F1F2F3',display:'flex',boxSizing:'border-box',flexWrap:'wrap',padding:'10px',borderRadius:'10px',margin:'7px 0',width:'282.89px'}}>{renderedList}</div>
        );
    }

    if (type === "ARTICLES") {
        media = data.articles.map((e) => {
            let values=e.split("||");
            return <EpubContentBlock inEditMode={false} link={values[0]} titleName={values[2]} authname={values[1]} imgIcon={articleIcon} iconClass="icon icon-file-text2" deleteAll={null}/>
        });
    }
    if (type === "NEWSLETTERS") {
        media = data.newsletters.map((e) => {
            let values=e.split("||");
            return <EpubContentBlock inEditMode={false} link={values[0]} titleName={values[2]} authname={values[1]} imgIcon={newsletterIcon} iconClass="icon icon-stack" deleteAll={null}/>
        });
    }

    if (type === "CALENDARS") {
        let renderedList = data.calendars.map((e) => {
            return <a href={e.url} key={e.name}>{e.name}</a>
        })
        media = (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>{renderedList}</div>
        );
    }
    if (type === "SURVEYS") {
        media = data.surveys.map((e) => {
            let values=e.split("||");
            return <EpubContentBlock inEditMode={false} link={values[0]} titleName={values[2]} authname={values[1]} imgIcon={surveyIcon} iconClass="icon icon-clipboard2" deleteAll={null}/>
        });
    }

    if (type === "EFLASHES") {
        let renderedList = data.eflashes.map((e) => {
            return <a href={e.url} key={e.name}>{e.name}</a>
        })
        media = (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>{renderedList}</div>
        );
    }
    if (type === "ESIGNS") {
        media = data.esigns.map((e) => {
            let values=e.split("||");
            let svgIcon= <svg style={{width:"28px",height:"28px",margin:"14px"}} className="svg-inline--fa fa-signature fa-w-20" aria-hidden="true" focusable="false"
                              data-prefix="fas" data-icon="signature" role="img" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512" data-fa-i2svg="">
                <path fill="#49c096"
                      d="M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z"></path>
            </svg>

            return <EpubContentBlock inEditMode={false} link={values[0]} titleName={values[2]} authname={values[1]} imgIcon={eSignIcon} svgIcon={svgIcon} iconClass="icon icon-pen5" deleteAll={null}/>
        });
    }
    if (type === "MORETEXT") {
        media = <div className={moreTExtBlockStyle.renderedOuterContainer} dangerouslySetInnerHTML={{__html: data.moretext}}></div>
    }
    return media;
}

export default customRenderToHtml;
